import { AMSCountrySelector, AMSDatePicker, AMSTextField } from '../../../../helpers/ui';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import React, { CSSProperties, Dispatch, SetStateAction, useMemo } from 'react';
import { toDDMMYY, toDateString, toYYYYMMDD } from '../../../../helpers/date-helper';

import AMSLink from '../../../../helpers/ui/AMSLink/AMSLink';
import Country from '../../../../models/country';
import DeleteIcon from '@material-ui/icons/Delete';
import { DeliveryRequestStatus } from '../../../../services/deliveryRequestsService';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { StockReceivingProcessItemResponse } from '../../../../services/api';
import moment from 'moment';

export interface StockReceivingProcessItem extends Partial<StockReceivingProcessItemResponse> {
  quantity: number;
}

interface CreateStockReceivingProcessTableComponentProps {
  supplierId: number;
  items: StockReceivingProcessItem[];
  setItems: Dispatch<SetStateAction<StockReceivingProcessItem[]>>;
  status: DeliveryRequestStatus;
}

const CreateStockReceivingProcessTableComponent = ({
  supplierId,
  items,
  setItems,
  status
}: CreateStockReceivingProcessTableComponentProps) => {
  const isCancelled = useMemo(() => status === DeliveryRequestStatus.CANCELLED, [status]);
  const cellStyle: CSSProperties = useMemo(
    () => ({
      width: '15%',
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 15,
      paddingRight: 15
    }),
    []
  );
  const wideCellStyle = useMemo(() => ({ ...cellStyle, width: '25%' }), [cellStyle]);

  const batchPlaceholder = useMemo(() => `${supplierId}_${toYYYYMMDD(new Date())}`, [supplierId]);

  const datePickerPlaceholder = useMemo(
    () => `${toDDMMYY(new Date(new Date().getTime() + 20 * 365 * 24 * 60 * 60 * 1000))}`,
    []
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={wideCellStyle}>Артикул</TableCell>
          <TableCell style={cellStyle}>Количество</TableCell>
          {!isCancelled && (
            <>
              <TableCell style={cellStyle} align="right">
                Партида
              </TableCell>
              <TableCell style={cellStyle} align="right">
                Срок на годност
              </TableCell>
              <TableCell style={cellStyle} align="right">
                Държава
              </TableCell>
              <TableCell style={cellStyle} align="right">
                Брой
              </TableCell>
              <TableCell style={cellStyle} align="right">
                Действия
              </TableCell>
            </>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((item: StockReceivingProcessItem, index) => (
          <TableRow key={index}>
            <TableCell style={wideCellStyle}>
              <AMSLink href={`/item?id=${item.itemId}`}>
                {item.itemArtNo}, {item.itemName}
              </AMSLink>
            </TableCell>
            <TableCell style={cellStyle}>
              <Typography variant="body2">{item.quantity.toFixed(3)}</Typography>
            </TableCell>
            {!isCancelled && (
              <>
                <TableCell style={cellStyle}>
                  {item.itemShouldTrackBatches && (
                    <AMSTextField
                      label=""
                      value={item.batchNumber ?? ''}
                      onChange={(text?: string) => {
                        setItems(
                          items.reduce((res: any[], i: StockReceivingProcessItem) => {
                            if (item.id === i.id) {
                              i.batchNumber = text;
                            }
                            res.push(i);
                            return res;
                          }, [])
                        );
                      }}
                      placeholder={`по подр. ${batchPlaceholder}`}
                    />
                  )}
                </TableCell>
                <TableCell style={cellStyle}>
                  {item.itemShouldTrackBatches && (
                    <AMSDatePicker
                      label=""
                      value={item.expirationDate ? new Date(item.expirationDate) : null}
                      onChange={(date: Date | null) => {
                        setItems(
                          items.reduce((res: any[], i: StockReceivingProcessItem) => {
                            if (item.id === i.id) {
                              i.expirationDate = date ? toDateString(date) : undefined;
                            }
                            res.push(i);
                            return res;
                          }, [])
                        );
                      }}
                      helperText={
                        item.expirationDate &&
                        moment.duration(moment(item.expirationDate).diff(moment())).asDays() <=
                          item.itemMinimumRequiredDaysOfExpirationForDelivery! - 1
                          ? `Не преди ${moment()
                              .add(item.itemMinimumRequiredDaysOfExpirationForDelivery, 'days')
                              .format('DD/MM/YY')}`
                          : ``
                      }
                      error={
                        item.expirationDate
                          ? moment.duration(moment(item.expirationDate).diff(moment())).asDays() <
                            item.itemMinimumRequiredDaysOfExpirationForDelivery! - 1
                          : undefined
                      }
                      placeholder={`по подр. ${datePickerPlaceholder}`}
                    />
                  )}
                </TableCell>
                <TableCell style={cellStyle}>
                  {item.itemShouldTrackBatches && (
                    <AMSCountrySelector
                      onChange={(country: Country | null) => {
                        setItems(
                          items.reduce((res: any[], i: StockReceivingProcessItem) => {
                            if (item.id === i.id) {
                              i.countryOfOrigin = country?.alpha3;
                            }
                            res.push(i);
                            return res;
                          }, [])
                        );
                      }}
                      placeholder={`по подр. България`}
                    />
                  )}
                </TableCell>
                <TableCell style={cellStyle}>
                  <AMSTextField
                    label=""
                    value={item.documentQuantity ?? ''}
                    onChange={(text?: string) => {
                      setItems(
                        items.reduce((res: any[], i: StockReceivingProcessItem) => {
                          if (item.id === i.id) {
                            i.documentQuantity = text;
                          }
                          res.push(i);
                          return res;
                        }, [])
                      );
                    }}
                    placeholder={`по подр. ${item.quantity.toFixed(3)}`}
                  />
                </TableCell>
                <TableCell style={cellStyle}>
                  <Box style={{ display: 'flex', float: 'right' }}>
                    {item.itemShouldTrackBatches && (
                      <IconButton
                        onClick={() => {
                          setItems(
                            items.reduce((res: any[], i: StockReceivingProcessItem) => {
                              res.push(i);
                              if (item.id === i.id) {
                                res.push({
                                  ...i,
                                  id: Date.now(),
                                  batchNumber: undefined,
                                  expirationDate: toDateString(
                                    new Date(new Date().getTime() + 20 * 365 * 24 * 60 * 60 * 1000)
                                  ),
                                  countryOfOrigin: undefined,
                                  documentQuantity: undefined
                                });
                              }
                              return res;
                            }, [])
                          );
                        }}
                      >
                        <PlaylistAddIcon />
                      </IconButton>
                    )}
                    <IconButton
                      onClick={() => {
                        const tempItems = [...items];
                        tempItems.splice(index, 1);
                        setItems([...tempItems]);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default CreateStockReceivingProcessTableComponent;
