import { IAsyncType, useAsync } from '.';
import {
  ItemDeliverySubscriptionResponse,
  PaginatedListResponseItemDeliverySubscriptionResponse
} from '../../services/api-v3';

import { amsV3Service } from '../../services/services';

export interface ISubscriptions<T> extends IAsyncType<T> {
  subscriptions: T;
}

const useItemDeliverySubscriptions = (
  warehouses?: number[],
  items?: number[]
): ISubscriptions<ItemDeliverySubscriptionResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseItemDeliverySubscriptionResponse>(
      amsV3Service.getItemDeliverySubscriptions,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [warehouses, items],
      true
    );
  return {
    subscriptions: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useItemDeliverySubscriptions;
