import {
  CreateDeliveryItemRequest,
  DeliveryItemResponse,
  DeliveryResponse
} from '../../../../services/api';

import AMSLink from '../../../../helpers/ui/AMSLink/AMSLink';
import AMSTable from '../../../../helpers/ui/AMSTable/AMSTable';
import AddDeliveryItemDialog from './Dialog/AddDeliveryItemDialog';
import Lookup from '../../../../models/lookup';
import { Paper } from '@material-ui/core';
import React from 'react';
import { findCountry } from '../../../../helpers/country-helper';
import { useFlag } from '../../../../helpers/hooks';

interface DeliveryItemsPanelProps {
  delivery: DeliveryResponse | null;
  supplier: Lookup | null;
  warehouse: Lookup | null;
  items: DeliveryItemResponse[] | CreateDeliveryItemRequest[];
  setItems: (items: any[]) => void;
  loading: boolean;
}

const DeliveryItemsPanel = ({
  delivery,
  supplier,
  warehouse,
  items,
  setItems,
  loading
}: DeliveryItemsPanelProps) => {
  const [openDialog, setOpenDialog] = useFlag(false);
  const [actionLoading, setActionLoading] = useFlag(false);

  const handleAdd = async (newOrderItem: CreateDeliveryItemRequest) => {
    setActionLoading(true);
    setItems([...items, newOrderItem]);
    setActionLoading(false);
  };

  const handleDelete = async (deleteOrderItem: any) => {
    setActionLoading(true);
    const tempItems = [...items];
    tempItems.splice(deleteOrderItem.tableData.id, 1);
    setItems(tempItems);
    setActionLoading(false);
  };

  const columns = [
    {
      title: 'Име',
      field: 'itemName',
      cellStyle: { width: '25%' },
      render: (deliveryItem: DeliveryItemResponse) => (
        <AMSLink href={`/item?id=${deliveryItem.itemId}`}>
          {deliveryItem.itemName}
        </AMSLink>
      )
    },
    {
      title: 'Държава',
      field: 'country',
      cellStyle: { width: '9%' },
      render: (row: DeliveryItemResponse) => findCountry(row.country)?.name
    },
    {
      title: 'Срок на годност',
      field: 'expirationDate',
      cellStyle: { width: '10%' }
    },
    {
      title: 'Партида',
      field: 'batchNumber',
      cellStyle: { width: '10%' }
    },
    {
      title: 'Брой',
      field: 'quantity',
      cellStyle: { width: '8%' },
      render: (row: DeliveryItemResponse) => row.quantity.toFixed(3)
    },
    {
      title: 'Ед. цена',
      field: 'price',
      cellStyle: { width: '10%' },
      type: 'currency',
      currencySetting: {
        locale: 'bg',
        currencyCode: 'bgn',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
    },
    {
      title: 'ДДС %',
      field: 'itemVat',
      cellStyle: { width: '8%' },
      type: 'numeric'
    },
    {
      title: 'Общо (без ДДС)',
      field: 'totalWithoutVat',
      cellStyle: { width: '10%' },
      type: 'currency',
      currencySetting: {
        locale: 'bg',
        currencyCode: 'bgn',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
    },
    {
      title: 'Общо (с ДДС)',
      field: 'totalIncludingVat',
      cellStyle: { width: '10%' },
      type: 'currency',
      currencySetting: {
        locale: 'bg',
        currencyCode: 'bgn',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
    }
  ];

  return (
    <>
      <AMSTable
        title="Списък с артикули"
        columns={columns}
        paging={false}
        inlineActions={false}
        components={{
          Container: (props: any) => <Paper {...props} elevation={0} className="padding-0" />
        }}
        data={items}
        onAdd={
          !delivery && supplier && warehouse
            ? () => {
                setOpenDialog(true);
              }
            : undefined
        }
        onDelete={!delivery ? handleDelete : undefined}
        isLoading={loading || actionLoading}
        overflowY="scroll"
        minBodyHeight="45vh"
        maxBodyHeight="45vh"
        addRowPosition="first"
      />
      <AddDeliveryItemDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        supplier={supplier}
        onSave={(orderItem: any, addNew: boolean) => {
          if (!addNew) {
            setOpenDialog(false);
          }
          handleAdd(orderItem);
        }}
      />
    </>
  );
};

export default DeliveryItemsPanel;
