import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { OrderDocumentV2Response } from '../../services/api';
import { ordersService } from '../../services/services';

export interface IOrderDocuments<T> extends IAsyncType<T> {
  orderDocuments: T;
  setOrderDocuments: Dispatch<SetStateAction<T>>;
}

const useOrderDocuments = (id?: number): IOrderDocuments<OrderDocumentV2Response[]> => {
  const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
    OrderDocumentV2Response[]
  >(ordersService.getOrderDocuments, [], [id]);
  return {
    orderDocuments: data,
    setOrderDocuments: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useOrderDocuments;
