import { Container, createStyles, makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { amsV3Service, cookiesService } from '../../services/services';
import { useCompanies, useTreasuries } from '../../helpers/hooks';

import { CreateTreasuryRequest } from '../../services/api-v3';
import FinanceCompaniesTableWidgetComponent from './FinanceCompaniesTableWidgetComponent/FinanceCompaniesTableWidgetComponent';
import FinanceTransactionsWidgetComponent from './FinanceTransactionsWidgetComponent/FinanceTransactionsWidgetComponent';
import FinanceTreasuriesCurrentWidgetComponent from './FinanceTreasuriesCurrentWidgetComponent/FinanceTreasuriesCurrentWidgetComponent';
import { TreasuryListResponse } from '../../services/api-v3';

const useStyles = makeStyles(() =>
  createStyles({
    loading: {
      marginTop: '40vh'
    },
    contentWrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      height: '85vh',
      maxHeight: '85vh'
    },
    companiesWidget: {
      gridColumnStart: 1,
      gridColumnEnd: 1,
      gridRowStart: 1,
      gridRowEnd: 3,
      gridAutoRows: '2fr',
      padding: 8
    },
    chartsWidget: {
      gridColumnStart: 2,
      gridColumnEnd: 2,
      gridRowStart: 1,
      gridRowEnd: 1,
      gridAutoRows: '1fr',
      padding: 8
    },
    transactionsWidget: {
      gridColumnStart: 2,
      gridColumnEnd: 2,
      gridRowStart: 2,
      gridRowEnd: 2,
      gridAutoRows: '1fr',
      padding: 8
    }
  })
);

const FinanceDashboardComponent = () => {
  const classes = useStyles();
  const [selected, setSelected] = useState<any>({});
  const { companies } = useCompanies();

  const { treasuries, setParameters } = useTreasuries();
  const [cashAmountByTreasury, setCashAmountByTreasury] = useState<any>({});
  const [bankAccountAmountByTreasury, setBankAccountAmountByTreasury] = useState<any>({});
  const [selectedTreasuries, setSelectedTreasuries] = useState<TreasuryListResponse[]>([]);
  const [visibleCompanies, setVisibleCompanies] = useState<any>({});

  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    if (Object.keys(selected).length) {
      setSelectedTreasuries(
        treasuries.filter((treasury: TreasuryListResponse) => !!selected[treasury.id])
      );
    } else {
      setSelectedTreasuries(treasuries);
    }
    setVisibleCompanies(treasuries.map((treasury: TreasuryListResponse) => treasury.companyId));
  }, [selected, treasuries]);

  const createTreasury = async (treasury: CreateTreasuryRequest) => {
    const resp = await amsV3Service.createTreasury(treasury);
    setReload(!reload);
    return resp;
  };

  const updateTreasury = async (treasury: any) => {
    const resp = await amsV3Service.updateTreasury(treasury.id, treasury);
    setReload(!reload);
    return resp.data;
  };

  const loadAmounts = useCallback(async () => {
    const cashAmountResponses = await Promise.all(
      treasuries.map((treasury: TreasuryListResponse) =>
        amsV3Service.getTreasuryCashAmount(treasury.id)
      )
    );
    const bankAccountAmountResponses = await Promise.all(
      treasuries.map((treasury: TreasuryListResponse) =>
        amsV3Service.getTreasuryBankAccountAmount(treasury.id)
      )
    );

    setCashAmountByTreasury(
      treasuries.reduce((res: any, value: TreasuryListResponse, id: number) => {
        res[value.id] = +cashAmountResponses[id].data;
        return res;
      }, {})
    );
    setBankAccountAmountByTreasury(
      treasuries.reduce((res: any, value: TreasuryListResponse, id: number) => {
        res[value.id] = +bankAccountAmountResponses[id].data;
        return res;
      }, {})
    );
  }, [treasuries]);

  useEffect(() => {
    loadAmounts();
  }, [loadAmounts]);

  return (
    <Container maxWidth="xl" className="margin-left-0">
      <div className={classes.contentWrapper}>
        <div className={classes.companiesWidget}>
          <FinanceCompaniesTableWidgetComponent
            companies={companies}
            treasuries={treasuries}
            cashAmountByTreasury={cashAmountByTreasury}
            bankAccountAmountByTreasury={bankAccountAmountByTreasury}
            selected={selected}
            setSelected={setSelected}
            visibleCompanies={visibleCompanies}
            setVisibleCompanies={(newVisibleCompanies: any) => {
              setVisibleCompanies(newVisibleCompanies);
              cookiesService.setVisibleCompanies(newVisibleCompanies);
            }}
            onAdd={createTreasury}
            onEdit={updateTreasury}
            reloadForm={() => setParameters([])}
          />
        </div>
        <div className={classes.chartsWidget}>
          <FinanceTreasuriesCurrentWidgetComponent
            treasuries={selectedTreasuries}
            cashAmountByTreasury={cashAmountByTreasury}
            bankAccountAmountByTreasury={bankAccountAmountByTreasury}
          />
        </div>
        <div className={classes.transactionsWidget}>
          <FinanceTransactionsWidgetComponent
            treasuries={treasuries}
            selected={selected}
            loading={false}
            reloadForm={() => setParameters([])}
          />
        </div>
      </div>
    </Container>
  );
};

export default FinanceDashboardComponent;
