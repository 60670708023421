import { AMSCountrySelector, AMSDatePicker, AMSTextField } from '../../../../helpers/ui';
import React, { CSSProperties, useMemo } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';

import Country from '../../../../models/country';
import { DeliveryRequestStatus } from '../../../../services/deliveryRequestsService';
import { StockReceivingProcessItemResponse } from '../../../../services/api';
import { findCountry } from '../../../../helpers/country-helper';
import { toDateString } from '../../../../helpers/date-helper';

interface EditStockReceivingProcessTableComponentProps {
  items: StockReceivingProcessItemResponse[];
  status: DeliveryRequestStatus;
}

const EditStockReceivingProcessTableComponent = ({
  items,
  status
}: EditStockReceivingProcessTableComponentProps) => {
  const isFinalized = useMemo(
    () =>
      status === DeliveryRequestStatus.DELIVERED ||
      status === DeliveryRequestStatus.DECLINED_AFTER_VERIFICATION,
    [status]
  );
  const forInspection = useMemo(() => status === DeliveryRequestStatus.FOR_INSPECTION, [status]);
  const isInspectedWithRemarks = useMemo(
    () => status === DeliveryRequestStatus.INSPECTED_WITH_REMARKS,
    [status]
  );
  const isInspectionInProgress = useMemo(
    () => status === DeliveryRequestStatus.INSPECTION_IN_PROGRESS,
    [status]
  );
  const cellStyle: CSSProperties = useMemo(
    () => ({
      width: '10%',
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 15,
      paddingRight: 15
    }),
    []
  );
  const wideCellStyle = useMemo(() => ({ ...cellStyle, width: '20%' }), [cellStyle]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={wideCellStyle}>Артикул</TableCell>
          <TableCell style={cellStyle} align="right">
            Партида
          </TableCell>
          <TableCell style={cellStyle} align="right">
            Срок на годност
          </TableCell>
          <TableCell style={cellStyle} align="right">
            Държава
          </TableCell>
          <TableCell style={cellStyle} align="right">
            По документ
          </TableCell>
          {(isInspectionInProgress || isFinalized || isInspectedWithRemarks) && (
            <TableCell style={cellStyle} align="right">
              Преброено
            </TableCell>
          )}
          {(isInspectedWithRemarks || isFinalized) && (
            <TableCell style={cellStyle} align="right">
              Потвърдено
            </TableCell>
          )}
          {(isInspectedWithRemarks || isFinalized) && (
            <TableCell style={cellStyle} align="right">
              Заприходено
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((item: StockReceivingProcessItemResponse) => (
          <TableRow key={item.id}>
            <TableCell style={wideCellStyle}>
              <Typography variant="body2">
                {item.itemArtNo}, {item.itemName}
              </Typography>
            </TableCell>
            {!isFinalized && (
              <>
                <TableCell style={cellStyle}>
                  {item.itemShouldTrackBatches && (
                    <AMSTextField
                      label=""
                      defaultValue={item.batchNumber}
                      onChange={(text?: string) => {
                        item.batchNumber = text ?? '';
                      }}
                      disabled={!forInspection}
                    />
                  )}
                </TableCell>
                <TableCell style={cellStyle}>
                  {item.itemShouldTrackBatches && (
                    <AMSDatePicker
                      label=""
                      value={item.expirationDate ? new Date(item.expirationDate) : null}
                      onChange={(date: Date | null) => {
                        item.expirationDate = date ? toDateString(date) : '';
                      }}
                      disabled={!forInspection}
                    />
                  )}
                </TableCell>
                <TableCell style={wideCellStyle}>
                  {item.itemShouldTrackBatches && (
                    <AMSCountrySelector
                      defaultValue={item.countryOfOrigin ? findCountry(item.countryOfOrigin) : null}
                      onChange={(country: Country | null) => {
                        item.countryOfOrigin = country?.alpha3 ?? '';
                      }}
                      placeholder={`по подр. България`}
                      disabled={!forInspection}
                    />
                  )}
                </TableCell>
                <TableCell style={cellStyle}>
                  <AMSTextField
                    label=""
                    defaultValue={item.documentQuantity}
                    onChange={(text?: string) => {
                      const cleanValue = text && text.replace(',', '.');
                      item.documentQuantity = (
                        cleanValue && !isNaN(+cleanValue) ? +cleanValue : 0
                      ).toFixed(3);
                    }}
                    disabled={!forInspection}
                  />
                </TableCell>
                {(isInspectionInProgress || isInspectedWithRemarks) && (
                  <TableCell style={cellStyle}>
                    <Typography variant="body2" align="right">
                      {item.countedQuantity}
                    </Typography>
                  </TableCell>
                )}
                {isInspectedWithRemarks && (
                  <TableCell style={cellStyle}>
                    <AMSTextField
                      label=""
                      defaultValue={item.verifiedQuantity}
                      onChange={(text?: string) => {
                        const cleanValue = text && text.replace(',', '.');
                        item.verifiedQuantity = (
                          cleanValue && !isNaN(+cleanValue) ? +cleanValue : 0
                        ).toFixed(3);
                      }}
                      placeholder={`по подр. ${item.documentQuantity}`}
                    />
                  </TableCell>
                )}
              </>
            )}
            {isFinalized && (
              <>
                <TableCell style={cellStyle}>
                  <Typography variant="body2" align="right">
                    {item.itemShouldTrackBatches ? item.batchNumber : '-'}
                  </Typography>
                </TableCell>
                <TableCell style={cellStyle}>
                  <Typography variant="body2" align="right">
                    {item.itemShouldTrackBatches ? item.expirationDate : '-'}
                  </Typography>
                </TableCell>
                <TableCell style={wideCellStyle} align="right">
                  <Typography variant="body2">
                    {item.itemShouldTrackBatches ? findCountry(item.countryOfOrigin)?.name : '-'}
                  </Typography>
                </TableCell>
                <TableCell style={cellStyle} align="right">
                  <Typography variant="body2">{item.documentQuantity}</Typography>
                </TableCell>
                <TableCell style={cellStyle} align="right">
                  <Typography variant="body2">{item.countedQuantity}</Typography>
                </TableCell>
                <TableCell style={cellStyle} align="right">
                  <Typography variant="body2">{item.verifiedQuantity}</Typography>
                </TableCell>
                <TableCell style={cellStyle} align="right">
                  <Typography variant="body2">{item.finalQuantity}</Typography>
                </TableCell>
              </>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default EditStockReceivingProcessTableComponent;
