import { CreateOrderItemV2Request, OrderItemV2Response } from '../../../../services/api';
import { Paper, Tooltip } from '@material-ui/core';
import React, { CSSProperties, useMemo, useState } from 'react';

import AMSLink from '../../../../helpers/ui/AMSLink/AMSLink';
import AMSTable from '../../../../helpers/ui/AMSTable/AMSTable';
import { AccessPermissions } from '../../../../services/accessManagementService';
import AddOrderItemDialog from './Dialog/AddOrderItemDialog';
import { accessManagementService } from '../../../../services/services';

interface OrderItemsPanelProps {
  id: number;
  items: any[];
  setItems: (items: any[]) => void;
  partnerId?: number;
  hasActiveIntegration: boolean;
  canAdd: boolean;
  canEditItems: boolean;
}

const OrderItemsPanel = ({
  id,
  items,
  setItems,
  partnerId,
  hasActiveIntegration,
  canAdd,
  canEditItems
}: OrderItemsPanelProps) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const handleAdd = async (newOrderItem: CreateOrderItemV2Request) => {
    setLoading(true);
    setItems([...items, newOrderItem]);
    setLoading(false);
  };

  const handleDelete = async (deleteOrderItem: any) => {
    setLoading(true);
    const tempItems = [...items];
    tempItems.splice(deleteOrderItem.tableData.id, 1);
    setItems(tempItems);
    setLoading(false);
  };

  const handleMultipleDelete = () => {
    setLoading(true);
    const tempItems = items.filter((item) => !selectedRows.includes(item.id));
    setItems(tempItems);
    setLoading(false);
  };

  const canCreate = useMemo(
    () => accessManagementService.hasPermission(AccessPermissions.CAN_CREATE_ORDER),
    []
  );

  const canUpdate = useMemo(
    () => accessManagementService.hasPermission(AccessPermissions.CAN_UPDATE_ORDER),
    []
  );

  const canChangeOrderItemPrices = useMemo(
    () => accessManagementService.hasPermission(AccessPermissions.CAN_CHANGE_ORDER_ITEM_PRICES),
    []
  );

  const columns = [
    {
      title: 'Арт №',
      field: 'artNo',
      render: (orderItem: OrderItemV2Response) => (
        <AMSLink href={`/item?id=${orderItem.itemId}`}>{orderItem.artNo}</AMSLink>
      ),
      cellStyle: { width: '8%' }
    },
    {
      title: 'Баркод',
      field: 'barcodeValue',
      cellStyle: { width: '10%' }
    },
    {
      title: 'Име',
      field: 'name',
      render: (orderItem: OrderItemV2Response) => (
        <AMSLink href={`/item?id=${orderItem.itemId}`}>{orderItem.name}</AMSLink>
      ),
      cellStyle: { width: '36%' }
    },
    {
      title: 'Мерна единица',
      field: 'unitName',
      cellStyle: { width: '14%' }
    },
    {
      title: 'Заявено',
      field: 'quantity',
      render: (rowData: OrderItemV2Response) => (
        <div>
          {rowData.bonusQuantity
            ? (+rowData.orderedQuantity + +rowData.bonusQuantity).toFixed(3)
            : rowData.orderedQuantity}
          {rowData.bonusQuantity && +rowData.bonusQuantity > 0
            ? ` (${rowData.orderedQuantity}+${rowData.bonusQuantity})`
            : ''}
        </div>
      ),
      cellStyle: { width: '5%' }
    },
    {
      title: 'Събрано',
      field: 'collected',
      cellStyle: { width: '5%' },
      render: (rowData: any) => (
        <Tooltip
          title={rowData.batchItems
            ?.reduce(
              (res: any[], i: any) => [
                ...res,
                {
                  batchNumber: i.batchNumber,
                  expirationDate: i.expirationDate,
                  collected: i.collected
                }
              ],
              []
            )
            .map((c: any) => (
              <div
                style={{ fontSize: '16px', padding: '2px' }}
              >{`${c.collected} - ${c.batchNumber} / ${c.expirationDate}`}</div>
            ))}
        >
          <div>{rowData.collected}</div>
        </Tooltip>
      )
    },
    {
      title: 'Прието',
      field: 'receivedQuantity',
      cellStyle: { width: '5%' }
    },
    {
      title: 'Ед. цена',
      field: 'price',
      cellStyle: { width: '9%' },
      type: 'currency',
      currencySetting: {
        locale: 'bg',
        currencyCode: 'bgn',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
    },
    {
      title: 'Цена',
      field: 'liveTotalPrice',
      cellStyle: { width: '8%' },
      type: 'currency',
      currencySetting: {
        locale: 'bg',
        currencyCode: 'bgn',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
    }
  ];

  return (
    <>
      <AMSTable
        title="Списък с артикули"
        columns={columns}
        paging={false}
        inlineActions={false}
        components={{
          Container: (props: any) => <Paper {...props} elevation={0} className="padding-0" />
        }}
        actions={
          canEditItems
            ? [
                {
                  tooltip: 'Изтриване на избраните редове',
                  icon: 'delete',
                  onClick: () => handleMultipleDelete()
                }
              ]
            : undefined
        }
        selection={canEditItems}
        onSelectionChange={(data) => setSelectedRows(data.map((row) => row.id) ?? [])}
        data={items}
        onAdd={
          ((canEditItems && canUpdate) || (id <= 0 && canCreate)) && canAdd
            ? () => {
                setOpenDialog(true);
              }
            : undefined
        }
        onDelete={(canEditItems && canUpdate) || (id <= 0 && canCreate) ? handleDelete : undefined}
        isLoading={loading}
        overflowY="scroll"
        minBodyHeight="45vh"
        maxBodyHeight="45vh"
        addRowPosition="first"
        rowStyle={(rowData: OrderItemV2Response) => {
          const style: CSSProperties = {};
          if (!rowData.isOwnedByUs) {
            style.backgroundColor = '#FDE9EE';
          }
          if (canChangeOrderItemPrices && rowData.livePrice && rowData.livePrice > rowData.price) {
            style.borderBottom = '2px solid #FF7B00';
          }
          return style;
        }}
      />
      <AddOrderItemDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSave={(orderItem: any, addNew: boolean) => {
          if (!addNew) {
            setOpenDialog(false);
          }
          handleAdd({ ...orderItem, id: undefined });
        }}
        partnerId={partnerId}
        hasActiveIntegration={hasActiveIntegration}
      />
    </>
  );
};

export default OrderItemsPanel;
