import * as H from 'history';

import { Grid, Tab, Tabs } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';

import DeliveryDocumentAndTransactionsPanel from './Panels/DeliveryDocumentAndTransactionsPanel/DeliveryDocumentAndTransactionsPanel';
import DeliveryInfoPanel from './Panels/DeliveryInfoPanel/DeliveryInfoPanel';
import TabPanel from '../../helpers/ui/TabPanel/TabPanel';
import { parseQuery } from '../../helpers/url';
import useDelivery from '../../helpers/hooks/useDelivery';
import { useHistory } from 'react-router-dom';
import { useLookup } from '../../helpers/hooks';

interface DeliveryComponentProps {
  location: H.Location;
}

const DeliveryComponent = ({ location }: DeliveryComponentProps) => {
  const { id, tabId } = parseQuery(location.search);

  const history = useHistory();

  const [warehouse, setWarehouse] = useLookup();
  const [supplier, setSupplier] = useLookup();
  const [note, setNote] = useState<string>('');
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const { delivery, loading, setParameters } = useDelivery(id);
  const [selectedTab, setSelectedTab] = useState(0);

  const a11yProps = (index: any) => {
    return {
      id: `item-tab-${index}`,
      'aria-controls': `item-tabpanel-${index}`
    };
  };

  useEffect(() => {
    if (tabId) {
      if (id) {
        setSelectedTab(+tabId);
      } else {
        setSelectedTab(0);
        history.push(`delivery?${id ? `id=${id}` : ''}&tabId=0`);
      }
    } else {
      setSelectedTab(0);
    }
  }, [tabId, id, history]);

  useEffect(() => {
    setWarehouse(
      delivery
        ? {
            id: delivery.warehouseId,
            value: delivery.warehouseName
          }
        : null
    );
    setSupplier(
      delivery
        ? {
            id: delivery.supplierId,
            value: delivery.supplierName
          }
        : null
    );
    setNote(delivery?.note ?? '');
    setTotalPrice(
      (delivery?.deliveryItems ?? []).reduce(
        (result: number, item: any) => result + +item.totalIncludingVat,
        0
      )
    );
  }, [delivery, setSupplier, setWarehouse]);

  const loadDelivery = useCallback(async () => {
    setParameters(id ? [id] : null);
  }, [id, setParameters]);

  useEffect(() => {
    loadDelivery();
  }, [id, loadDelivery]);

  const handleChange = (event: React.ChangeEvent<{}>, newSelectedTab: number) => {
    setSelectedTab(newSelectedTab);
    history.replace(`delivery?${id ? `id=${id}` : ''}&tabId=${newSelectedTab}`);
  };

  const reload = () => {
    setSupplier(null);
    setWarehouse(null);
    setNote('');
    setTotalPrice(0);
  };

  return (
    <>
      <Grid container>
        <Grid item lg={12}>
          <Tabs value={selectedTab} onChange={handleChange}>
            <Tab label="Информация" {...a11yProps(0)} />
            <Tab disabled={!id} label="Документи и плащания" {...a11yProps(1)} />
          </Tabs>
        </Grid>
        <Grid item lg={12}>
          <TabPanel value={selectedTab} index={0}>
            <DeliveryInfoPanel
              delivery={delivery}
              supplier={supplier}
              setSupplier={setSupplier}
              warehouse={warehouse}
              setWarehouse={setWarehouse}
              totalPrice={totalPrice}
              setTotalPrice={setTotalPrice}
              note={note}
              setNote={setNote}
              reload={reload}
              loading={loading}
            />
          </TabPanel>
          {id && (
            <TabPanel value={selectedTab} index={1}>
              <DeliveryDocumentAndTransactionsPanel
                delivery={delivery}
                loadDelivery={loadDelivery}
                loading={loading}
              />
            </TabPanel>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default DeliveryComponent;
