import Category, { CategoryForm } from '../models/category';

import { AMSApi } from '../helpers/openapi-axios-helper';

export class CompanyCategoriesService {
  getCompanyCategories = async () => {
    const axios = await AMSApi();
    return await axios.readAllV1CompanyCategoriesGet();
  };

  getCompanyCategory = async (id: number) => {
    const axios = await AMSApi();
    return await axios.readV1CompanyCategoriesIdGet(id);
  };

  createCompanyCategory = async (category: CategoryForm) => {
    const axios = await AMSApi();
    if (category && category.name) {
      return await axios.createV1CompanyCategoriesPost({
        name: category.name,
        parentId: category.parentId
      });
    }
  };

  updateCompanyCategory = async (category: Category) => {
    const axios = await AMSApi();
    return await axios.updateV1CompanyCategoriesPut(category);
  };

  deleteCompanyCategory = async (id: number) => {
    const axios = await AMSApi();
    return await axios.deleteV1CompanyCategoriesIdDelete(id);
  };
}
