import {
  CircularProgress,
  Container,
  FormLabel,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { amsV3Service, legalEntitiesService } from '../../services/services';

import LegalEntityCreditLimitComponent from './Tabs/LegalEntityCreditLimitComponent/LegalEntityCreditLimitComponent';
import LegalEntityInfoComponent from './Tabs/LegalEntityInfoComponent/LegalEntityInfoComponent';
import OrderCreationPolicyForm from '../PartnerComponent/Tabs/IntegrationSettings/Orders/OrderCreationPolicyForm';
import { SetOrderingPolicyRequest } from '../../services/api';
import TabPanel from '../../helpers/ui/TabPanel/TabPanel';
import { parseQuery } from '../../helpers/url';
import { useHistory } from 'react-router-dom';
import { useStyles } from '../../helpers/hooks';

interface LegalEntityProps {
  location: any;
}

const LegalEntityComponent = ({ location }: LegalEntityProps) => {
  const { id, tabId } = parseQuery(location.search);

  const classes = useStyles();
  const history = useHistory();

  const [legalEntity, setLegalEntity] = useState<any>({ isActive: true });

  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    const loadData = async () => {
      if (id) {
        const resp = await amsV3Service.getLegalEntity(id);
        setLegalEntity(resp.data);
        setLoading(false);
      } else {
        setLegalEntity({ isActive: true });
        setLoading(false);
      }
    };
    loadData();
  }, [id]);

  useEffect(() => {
    if (tabId) {
      setTab(+tabId);
    } else {
      setTab(0);
    }
  }, [tabId]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newTab: number) => {
    setTab(newTab);
    history.replace(`legal-entity?${id ? `id=${id}&` : ''}tabId=${newTab}`);
  };

  return loading ? (
    <CircularProgress />
  ) : (
    <Container maxWidth="xl">
      <div style={{ display: 'flex', justifyContent: 'center', width: '80%' }}>
        <Typography component="h5" variant="h5">
          {legalEntity.name ?? 'Ново юридическо лице'}
        </Typography>
        {legalEntity.underForeclosure && (
          <Typography
            component="h5"
            variant="h5"
            style={{ color: legalEntity.underForeclosure ? 'red' : 'black', marginLeft: 6 }}
          >
            (под възбрана)
          </Typography>
        )}
      </div>
      {id && (
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label="Финанси" disabled={!id} />
          <Tab label="Информация" />
          <Tab label="Настройки" disabled={!id} />
        </Tabs>
      )}
      <TabPanel value={tab} index={0}>
        <LegalEntityCreditLimitComponent id={id} legalEntity={legalEntity} />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <LegalEntityInfoComponent legalEntity={legalEntity} setLegalEntity={setLegalEntity} />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <Grid item lg={5} sm={12}>
          <Paper elevation={2} className={classes.root}>
            <FormLabel component="legend" style={{ marginBottom: 12 }}>
              Поръчки
            </FormLabel>
            <OrderCreationPolicyForm
              id={id}
              onLoad={async (id: number) => {
                const resp = await legalEntitiesService.getOrderingPolicy(id);
                return resp.data;
              }}
              onSave={async (id: number, request: SetOrderingPolicyRequest) => {
                const resp = await legalEntitiesService.setOrderingPolicy(id, request);
                return resp.data;
              }}
            />
          </Paper>
        </Grid>
      </TabPanel>
    </Container>
  );
};

export default LegalEntityComponent;
