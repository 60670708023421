import { AMSAutocomplete, AMSButton, AMSTextField } from '../../../../helpers/ui';
import { ChartData, ChartTooltipItem } from 'chart.js';
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Typography
} from '@material-ui/core';
import { LegalEntityResponse, PaymentOptionResponse, SaveLegalEntityRequest } from '../../../../services/api-v3';
import React, { useMemo } from 'react';
import { amsV3Service, snackbarService } from '../../../../services/services';
import {
  useLegalEntityCreditLimit,
  usePaymentOptions,
  usePermissions,
  useStyles
} from '../../../../helpers/hooks';

import { AccessPermissions } from '../../../../services/accessManagementService';
import { HorizontalBar } from 'react-chartjs-2';
import { paymentOptionToLookup } from '../../../../models/lookup';

interface LegalEntityCreditLimitComponentProps {
  id: number;
  legalEntity: LegalEntityResponse;
}

const LegalEntityCreditLimitComponent = ({ id, legalEntity}: LegalEntityCreditLimitComponentProps) => {
  const classes = useStyles();
  const { paymentOptions } = usePaymentOptions();
  const {
    creditLimitAmount,
    setCreditLimitAmount,
    paymentDueDays,
    setPaymentDueDays,
    usesDeferredBilling,
    setUsesDeferredBilling,
    paymentOption,
    setPaymentOption,
    isActive,
    ordersForCollectionAmount,
    ordersForDistributionAmount,
    pendingPaymentsAmount,
    overduePaymentsAmount,
    loading,
    setLoading
  } = useLegalEntityCreditLimit(id);

  const [canCreate, canUpdate] = usePermissions([
    AccessPermissions.CAN_CREATE_LEGAL_ENTITY,
    AccessPermissions.CAN_UPDATE_LEGAL_ENTITY
  ]);

  const handleOnClick = async () => {
    setLoading(true);
    const request: SaveLegalEntityRequest = {
      creditLimitAmount: creditLimitAmount ?? legalEntity.creditLimitAmount,
      paymentDueDays: paymentDueDays ? +paymentDueDays : legalEntity.paymentDueDays,
      usesDeferredBilling: usesDeferredBilling ?? legalEntity.usesDeferredBilling,
      paymentOptionId: paymentOption?.id ?? legalEntity.paymentOptionId,
      name: legalEntity.name,
      city: legalEntity.city,
      country: legalEntity.country,
      companyId: legalEntity.companyId,
      accountable: legalEntity.accountable,
      idnumber: legalEntity.idnumber,
      addressLine: legalEntity.addressLine,
      vat: legalEntity.vat,
      isActive: legalEntity.isActive
    };
    amsV3Service.updateLegalEntity(id, request);
    snackbarService.setSnackbarOpen(true);

    setLoading(false);
  };

  const creditBalance = useMemo(
    () =>
      (creditLimitAmount ? +creditLimitAmount : 0) -
      ordersForCollectionAmount -
      ordersForDistributionAmount -
      pendingPaymentsAmount -
      overduePaymentsAmount,
    [
      creditLimitAmount,
      ordersForCollectionAmount,
      ordersForDistributionAmount,
      pendingPaymentsAmount,
      overduePaymentsAmount
    ]
  );

  const shouldBeDisabled = useMemo(
    () => (id ? !canUpdate : !canCreate) || !isActive,
    [id, canCreate, canUpdate, isActive]
  );

  return loading ? (
    <CircularProgress />
  ) : (
    <Paper elevation={2} className={classes.paper}>
      <Grid container spacing={1}>
        <Grid item lg={3}>
          <Grid container spacing={1}>
            <Grid item lg={6}>
              <AMSTextField
                label="Кредитен лимит"
                value={creditLimitAmount ?? ''}
                disabled={shouldBeDisabled}
                required
                onChange={(value) => {
                  setCreditLimitAmount(value ?? '');
                }}
              />
            </Grid>
            <Grid item lg={6}>
              <AMSTextField
                label="Дни до падеж"
                value={paymentDueDays ?? '0'}
                disabled={shouldBeDisabled}
                required
                onChange={(value) => setPaymentDueDays(value ?? '')}
              />
            </Grid>
            <Grid item lg={12}>
              <AMSAutocomplete
                label="Начин на плащане"
                options={paymentOptions.map(paymentOptionToLookup)}
                value={paymentOption ? paymentOptionToLookup(paymentOption) : ''}
                onChange={(value: any) => {
                  setPaymentOption(
                    value
                      ? paymentOptions.find((po: PaymentOptionResponse) => po.id === value.id) ??
                          null
                      : null
                  );
                }}
                disabled={shouldBeDisabled}
                minChar={0}
                groupBy={(g) => (g.group ? g.group : '')}
                required
              />
            </Grid>
            <Grid item lg={12}>
              <FormControlLabel
                className={classes.label}
                control={
                  <Checkbox
                    checked={usesDeferredBilling}
                    onChange={() => setUsesDeferredBilling(!usesDeferredBilling)}
                  />
                }
                disabled={shouldBeDisabled}
                label="Отложено фактуриране"
                labelPlacement="end"
              />
            </Grid>
            <Grid item lg={12} container justify="center">
              <AMSButton
                text="Запиши"
                color="primary"
                variant="contained"
                onClick={handleOnClick}
                disabled={
                  loading ||
                  isNaN(creditLimitAmount ? +creditLimitAmount : 0) ||
                  (creditLimitAmount ? +creditLimitAmount <= 0 : false) ||
                  !creditLimitAmount
                }
                loading={loading}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={9}>
          <Grid item lg={12}>
            <Typography
              style={{
                color: creditBalance <= 0 ? 'red' : 'black'
              }}
            >
              Баланс: {creditBalance && !isNaN(creditBalance) ? creditBalance.toFixed(2) : '0.00'}{' '}
              лв.
            </Typography>
          </Grid>
          <HorizontalBar
            height={50}
            options={{
              scales: {
                xAxes: [
                  {
                    stacked: true
                  }
                ],
                yAxes: [
                  {
                    stacked: true
                  }
                ]
              },
              responsive: true,
              tooltips: {
                filter: (tooltipItem: ChartTooltipItem, data: ChartData) => {
                  return tooltipItem.index !== 0;
                }
              }
            }}
            data={{
              labels: ['Кредитен лимит', 'Разпределение'],
              datasets: [
                {
                  label: 'Поръчани',
                  data: [0, ordersForCollectionAmount],
                  borderColor: ['#52FFFF', '#52FFFF'],
                  backgroundColor: ['#52FFFF', '#52FFFF']
                },
                {
                  label: 'Експедирани',
                  data: [0, ordersForDistributionAmount],
                  borderColor: ['#00CC66', '#00CC66'],
                  backgroundColor: ['#00CC66', '#00CC66']
                },
                {
                  label: 'Oчаквани',
                  data: [creditLimitAmount ? +creditLimitAmount : 0, pendingPaymentsAmount],
                  borderColor: ['#008800', '#008800'],
                  backgroundColor: ['#008800', '#008800']
                },
                {
                  label: 'Просрочени',
                  data: [0, overduePaymentsAmount],
                  borderColor: ['#FF0000', '#FF0000'],
                  backgroundColor: ['#FF0000', '#FF0000']
                }
              ]
            }}
            legend={{ display: true, fullWidth: true, onClick: () => {} }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default LegalEntityCreditLimitComponent;
