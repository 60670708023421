import { AMSAutocomplete, AMSButton, AMSSnackBar, AMSTextField } from '../../../../../helpers/ui';
import React, { useMemo, useState } from 'react';
import { partnersService, snackbarService } from '../../../../../services/services';
import { useFlag, usePermissions } from '../../../../../helpers/hooks';

import { AccessPermissions } from '../../../../../services/accessManagementService';
import { Grid } from '@material-ui/core';
import Lookup from '../../../../../models/lookup';
import OutlinedDiv from '../../../../../helpers/ui/OutlinedDiv';
import { SetRetailConfigurationRequest } from '../../../../../services/api-v3';

interface CosheraRetailIntegrationFormProps {
  partnerId: number;
}

const retailEnvironments: Lookup[] = [
  {
    id: 'https://5a3ibkx02g.execute-api.eu-west-1.amazonaws.com/prod/',
    value: 'Продуктивна'
  },
  {
    id: 'https://tsx0icmici.execute-api.eu-west-1.amazonaws.com/dev/',
    value: 'Тестова'
  }
];

const CosheraRetailIntegrationForm = ({ partnerId }: CosheraRetailIntegrationFormProps) => {
  const [endpointUrl, setEndpointUrl] = useState<Lookup>(retailEnvironments[0]);
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [storeId, setStoreId] = useState<string>('');
  const [actionLoading, setActionLoading] = useFlag(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const [canCreate, canUpdate] = usePermissions([
    AccessPermissions.CAN_CREATE_PARTNER,
    AccessPermissions.CAN_UPDATE_PARTNER
  ]);

  const handleOnClick = async () => {
    setActionLoading(true);
    const configurationRequest: SetRetailConfigurationRequest = {
      requestType: 'set-retail-configuration',
      configuration: {
        endpointUrl: endpointUrl.id,
        username,
        password,
        storeId: storeId
      }
    };
    const resp = await partnersService.setPartnerIntegrationConfiguration(
      partnerId,
      configurationRequest
    );
    if (resp) {
      snackbarService.setSnackbarOpen(true);
    }
    setActionLoading(false);
  };

  const isValidRetailIntegration = () => username && password && storeId && endpointUrl;

  const shouldBeDisabled = useMemo(
    () => (partnerId > 0 ? !canUpdate : !canCreate),
    [partnerId, canCreate, canUpdate]
  );

  return (
    <Grid container spacing={1}>
      <Grid item lg={12}>
        <OutlinedDiv label={`Данни за интеграция с Coshera Retail`} disabled={shouldBeDisabled}>
          <Grid container spacing={1}>
            <Grid item lg={6} md={12}>
              <AMSAutocomplete
                label="Retail Среда"
                options={retailEnvironments}
                value={endpointUrl}
                onChange={(value) => setEndpointUrl(value ?? '')}
                minChar={0}
                disableCloseOnSelect
                required
              />
            </Grid>
            <Grid item lg={6} md={12}>
              <AMSTextField
                label="Обект ID"
                disabled={shouldBeDisabled}
                value={storeId}
                onChange={(value) => setStoreId(value ?? '')}
                required
              />
            </Grid>
            <Grid item lg={6} md={12}>
              <AMSTextField
                label="Потребителско име"
                disabled={shouldBeDisabled}
                value={username}
                onChange={(value) => setUsername(value ?? '')}
                required
              />
            </Grid>
            <Grid item lg={6} md={12}>
              <AMSTextField
                label="Парола"
                disabled={shouldBeDisabled}
                value={password}
                onChange={(value) => setPassword(value ?? '')}
                type="password"
                required
              />
            </Grid>
          </Grid>
        </OutlinedDiv>
      </Grid>
      <Grid item lg={12} container justify="center">
        <AMSButton
          text="Запиши"
          variant="contained"
          color="primary"
          onClick={handleOnClick || !isValidRetailIntegration()}
          disabled={actionLoading}
          loading={actionLoading}
        />
      </Grid>
      <AMSSnackBar
        open={openSnackBar}
        message={'Промените са записани успешно'}
        onClose={() => setOpenSnackBar(false)}
      />
    </Grid>
  );
};

export default CosheraRetailIntegrationForm;
