import Category, { CategoryForm } from '../models/category';

import { AMSApi } from '../helpers/openapi-axios-helper';

export class CategoriesService {
  getCategories = async () => {
    const axios = await AMSApi();
    return await axios.readAllV1ItemCategoriesGet();
  };

  getCategoriesFlat = async (leafOnly: boolean = false) => {
    const axios = await AMSApi();
    return await axios.readAllFlatV1ItemCategoriesFlatGet(leafOnly);
  };

  getCategory = async (id: number) => {
    const axios = await AMSApi();
    return await axios.readV1ItemCategoriesIdGet(id);
  };

  createCategory = async (category: CategoryForm) => {
    const axios = await AMSApi();
    if (category && category.name) {
      return await axios.createV1ItemCategoriesPost({
        name: category.name,
        parentId: category.parentId
      });
    }
  };

  updateCategory = async (category: Category) => {
    const axios = await AMSApi();
    return await axios.updateV1ItemCategoriesPut(category);
  };

  deleteCategory = async (id: number) => {
    const axios = await AMSApi();
    return await axios.deleteV1ItemCategoriesIdDelete(id);
  };
}
