import { CompanyListResponse, WarehouseListResponse } from '../../../services/api-v3';
import { Container, FormControlLabel, Grid, TextField, makeStyles } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Lookup, { warehouseToLookup } from '../../../models/lookup';
import {
  OrderStatus,
  getOrderStatusLabelByKey,
  orderStatuses
} from '../../../services/ordersService';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { cookiesService, reportsService } from '../../../services/services';
import { ignoreOffset, isValidDate, toEndOfDay, toStartOfDay } from '../../../helpers/date-helper';
import { useCompanies, useWarehouses } from '../../../helpers/hooks';

import AMSAutocomplete from '../../../helpers/ui/AMSAutocomplete/AMSAutocomplete';
import AMSLink from '../../../helpers/ui/AMSLink/AMSLink';
import AMSTable from '../../../helpers/ui/AMSTable/AMSTable';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Checkbox from '@material-ui/core/Checkbox';
import DateFnsUtils from '@date-io/date-fns';
import { ReportOrderBalanceResponse } from '../../../services/api';
import { getExtendedCompanyId } from '../../../helpers/utils';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  checkbox: {
    marginRight: 8
  },
  statusIndicator: {
    borderRadius: 24,
    width: 24,
    height: 24,
    minHeight: 24,
    minWidth: 24,
    maxHeight: 24,
    maxWidth: 24,
    marginRight: 8,
    border: '1px solid #737373'
  },
  statusText: {
    paddingTop: 2
  },
  label: {
    padding: 6
  },
  searchButton: {
    marginTop: 9,
    marginLeft: 6
  },
  searchButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const ReportOrderBalanceComponent = () => {
  const classes = useStyles();

  const [orders, setOrders] = useState<ReportOrderBalanceResponse[]>([]);
  const { companies } = useCompanies();
  const { warehouses } = useWarehouses();

  const filter = cookiesService.getReportOrderBalance();
  const [filterStatus, setFilterStatus] = useState<Lookup[]>(
    filter.filterStatus ? filter.filterStatus : []
  );
  const [filterFromDate, setFilterFromDate] = useState<any>(
    filter.filterFromDate
      ? ignoreOffset(new Date(filter.filterFromDate))
      : new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  const [filterToDate, setFilterToDate] = useState<any>(
    filter.filterToDate ? ignoreOffset(new Date(filter.filterToDate)) : new Date()
  );
  const [filterCompanies, setFilterCompanies] = useState<CompanyListResponse[]>(
    filter.filterCompanies ? filter.filterCompanies : []
  );
  const [filterWarehouses, setFilterWarehouses] = useState<WarehouseListResponse[]>(
    filter.filterWarehouses ? filter.filterWarehouses : []
  );
  const [filterNoInvoice, setFilterNoInvoice] = useState<boolean>(
    filter.filterNoInvoice !== undefined ? filter.filterNoInvoice : true
  );
  const [loading, setLoading] = useState(false);

  const search = useCallback(async () => {
    setLoading(true);
    if (
      (filterFromDate === null || isValidDate(filterFromDate)) &&
      (filterToDate === null || isValidDate(filterToDate))
    ) {
      const fromDate = isValidDate(filterFromDate) ? toStartOfDay(filterFromDate) : undefined;
      const toDate = isValidDate(filterToDate) ? toEndOfDay(filterToDate) : undefined;
      const ordersResponse = await reportsService.getOrdersBalance(
        filterStatus.map((status: Lookup) => status.id),
        filterCompanies.map((company: CompanyListResponse) => company.id),
        filterWarehouses.map((warehouse: WarehouseListResponse) => warehouse.id),
        fromDate,
        toDate,
        filterNoInvoice
      );

      if (ordersResponse) {
        setOrders(ordersResponse.data);
      }
    }
    setLoading(false);
  }, [
    filterStatus,
    filterFromDate,
    filterToDate,
    filterCompanies,
    filterWarehouses,
    filterNoInvoice
  ]);

  useEffect(() => {
    cookiesService.setReportOrderBalance({
      filterStatus,
      filterFromDate: filterFromDate ? toStartOfDay(filterFromDate) : undefined,
      filterToDate: filterToDate ? toEndOfDay(filterToDate) : undefined,
      filterCompanies,
      filterWarehouses,
      filterNoInvoice
    });
    search();
  }, [
    search,
    filterStatus,
    filterFromDate,
    filterToDate,
    filterCompanies,
    filterWarehouses,
    filterNoInvoice
  ]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="text-align-left">
        <Container maxWidth="xl">
          <Grid container spacing={1}>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <AMSAutocomplete
                multiple
                minChar={0}
                limitTags={3}
                options={orderStatuses
                  .sort((a, b) => b.id - a.id)
                  .map((s: OrderStatus) => ({
                    id: s.key,
                    value: getOrderStatusLabelByKey(s.key)
                  }))}
                disableCloseOnSelect
                renderOption={(option, { selected }) => (
                  <Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      className={classes.checkbox}
                      checked={selected}
                      color="primary"
                    />
                    {option.value}
                  </Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Статус"
                    placeholder=""
                    margin="dense"
                    fullWidth
                  />
                )}
                value={filterStatus}
                onChange={(values) => setFilterStatus(values)}
              />
            </Grid>
            <Grid item lg={2} md={6} sm={12} xs={12}>
              <KeyboardDatePicker
                disableToolbar
                autoOk={true}
                variant="inline"
                format="dd/MM/yy"
                margin="dense"
                label="От дата"
                helperText={''}
                value={filterFromDate ? filterFromDate : null}
                onChange={(value: Date | null) => {
                  if (value) {
                    if (isValidDate(value)) {
                      setFilterFromDate(ignoreOffset(value));
                    }
                  } else {
                    setFilterFromDate(null);
                  }
                }}
                inputVariant="outlined"
                fullWidth
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                maxDate={filterToDate}
              />
            </Grid>
            <Grid item lg={2} md={6} sm={12} xs={12}>
              <KeyboardDatePicker
                disableToolbar
                autoOk={true}
                variant="inline"
                format="dd/MM/yy"
                margin="dense"
                label="До дата"
                helperText={''}
                value={filterToDate ? filterToDate : null}
                onChange={(value: Date | null) => {
                  if (value) {
                    if (isValidDate(value)) {
                      setFilterToDate(ignoreOffset(value));
                    }
                  } else {
                    setFilterToDate(null);
                  }
                }}
                inputVariant="outlined"
                fullWidth
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                minDate={filterFromDate}
              />
            </Grid>
            <Grid item lg={2} md={6} sm={12} xs={12}>
              <FormControlLabel
                className={classes.label}
                control={
                  <Checkbox
                    checked={filterNoInvoice}
                    onChange={() => setFilterNoInvoice(!filterNoInvoice)}
                  />
                }
                label="Само без фактура"
                labelPlacement="end"
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <AMSAutocomplete
                multiple
                minChar={0}
                limitTags={3}
                options={companies.map((le) => ({
                  id: le.id,
                  value: `${getExtendedCompanyId(le.id)} ${le.note}`,
                  group: le.name
                }))}
                disableCloseOnSelect
                renderOption={(option, { selected }) => (
                  <Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      className={classes.checkbox}
                      checked={selected}
                      color="primary"
                    />
                    {option.value}
                  </Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Компании"
                    placeholder=""
                    margin="dense"
                    fullWidth
                  />
                )}
                value={filterCompanies}
                onChange={(values) => setFilterCompanies(values)}
                groupBy={(g) => (g.group ? g.group : '')}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <AMSAutocomplete
                multiple
                minChar={0}
                limitTags={3}
                options={(filterCompanies.length > 0
                  ? warehouses.filter((wh: WarehouseListResponse) =>
                      filterCompanies
                        .map((company: CompanyListResponse) => company.id)
                        .includes(wh.companyId)
                    )
                  : warehouses
                ).map(warehouseToLookup)}
                sortOptions={false}
                disableCloseOnSelect
                renderOption={(option, { selected }) => (
                  <Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      className={classes.checkbox}
                      checked={selected}
                      color="primary"
                    />
                    {option.value}
                  </Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Складове"
                    placeholder=""
                    margin="dense"
                    fullWidth
                  />
                )}
                value={filterWarehouses}
                onChange={(values) => setFilterWarehouses(values)}
                groupBy={(g) => (g.group ? g.group : '')}
              />
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <AMSTable
                title="Приходи от поръчки"
                columns={[
                  {
                    title: 'Получател',
                    field: 'receiverName',
                    render: (rowData: ReportOrderBalanceResponse) => (
                      <AMSLink href={`/partner?id=${rowData.receiverId}`}>
                        {rowData.receiverName}
                      </AMSLink>
                    ),
                    cellStyle: { width: '20%' }
                  },
                  {
                    title: 'Компания',
                    field: 'companyName',
                    cellStyle: { width: '10%' }
                  },
                  {
                    title: 'Склад',
                    field: 'warehouseName',
                    cellStyle: { width: '15%' }
                  },
                  {
                    title: 'Сума',
                    field: 'totalAmount',
                    cellStyle: { width: '5%' },
                    type: 'currency',
                    currencySetting: {
                      locale: 'bg',
                      currencyCode: 'bgn',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }
                  }
                ]}
                paging={false}
                minBodyHeight="65vh"
                maxBodyHeight="65vh"
                data={orders}
                isLoading={loading}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default ReportOrderBalanceComponent;
