import {
  AMSAutocomplete,
  AMSButton,
  AMSDatePicker,
  AMSTable,
  AMSViewOnlyTextField
} from '../../helpers/ui';
import { Checkbox, FormControlLabel, Grid, Paper } from '@material-ui/core';
import {
  CreateDeliveryRequestRequest,
  StockReceivingProcessItemResponse
} from '../../services/api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { deliveryRequestsService, snackbarService } from '../../services/services';
import { isValidDate, toDateString } from '../../helpers/date-helper';
import { supplierToLookup, warehouseToLookup } from '../../models/lookup';
import {
  useDeliveryRequest,
  useFlag,
  useLookup,
  usePermissions,
  useStyles,
  useSuppliers,
  useWarehouses
} from '../../helpers/hooks';

import { AccessPermissions } from '../../services/accessManagementService';
import AddDeliveryRequestItemDialog from './Dialog/AddDeliveryRequestItemDialog';
import { DeliveryRequestStatusLabels } from '../../services/deliveryRequestsService';
import OutlinedDiv from '../../helpers/ui/OutlinedDiv';
import { SupplierListResponse } from '../../services/api-v3';
import { useHistory } from 'react-router';
import useStockReceivingProcess from '../../helpers/hooks/useStockReceivingProcess';

interface CreateDeliveryRequestComponentProps {
  originalId?: number;
}

const CreateDeliveryRequestComponent = ({ originalId }: CreateDeliveryRequestComponentProps) => {
  const history = useHistory();
  const classes = useStyles();

  const { stockReceivingProcess } = useStockReceivingProcess(originalId);
  const { deliveryRequest } = useDeliveryRequest(originalId);

  const [shouldSendEmail, setShouldSendEmail] = useFlag(true);
  const [addItemOpenDialog, setAddItemOpenDialog] = useFlag();
  const [deliveryRequestItems, setDeliveryRequestItems] = useState<any[]>([]);
  const [supplier, setSupplier] = useLookup();
  const [warehouse, setWarehouse] = useLookup();
  const { suppliers } = useSuppliers();
  const { warehouses, loading, setLoading } = useWarehouses();
  const [deliveryDate, setDeliveryDate] = useState<Date | null>(null);

  const [canCreate] = usePermissions([AccessPermissions.CAN_CREATE_DELIVERY]);

  const deliveryRequestThreshold = useMemo(() => {
    return +(
      suppliers.find((sup: SupplierListResponse) => sup.id === supplier?.id)
        ?.deliveryRequestThreshold ?? 0
    );
  }, [suppliers, supplier]);

  useEffect(() => {
    if (stockReceivingProcess) {
      setDeliveryRequestItems(
        stockReceivingProcess.items
          .filter(
            (item: StockReceivingProcessItemResponse) =>
              (item.finalQuantity ? +item.finalQuantity : 0) !== +item.documentQuantity
          )
          .map((item: StockReceivingProcessItemResponse) => ({
            ...item,
            name: item.itemName,
            artNo: item.itemArtNo,
            itemId: item.itemId,
            quantity: +item.documentQuantity - (item.finalQuantity ? +item.finalQuantity : 0),
            price: item.price,
            totalPrice:
              (item.price ? +item.price : 0) *
              (+item.documentQuantity - +(item.finalQuantity ? +item.finalQuantity : 0)),
            collected: 0,
            unitName: '-'
          }))
      );
    }
  }, [stockReceivingProcess]);

  useEffect(() => {
    if (deliveryRequest) {
      setSupplier({ id: deliveryRequest.supplierId, value: deliveryRequest.supplierName });
      setWarehouse({ id: deliveryRequest.warehouseId, value: deliveryRequest.warehouseName });
    }
  }, [deliveryRequest, setSupplier, setWarehouse]);

  const supplierEmails = useMemo(() => {
    return suppliers.reduce((res: any, sup: SupplierListResponse) => {
      res[sup.id] = sup.subscriptionEmail;
      return res;
    }, {});
  }, [suppliers]);

  useEffect(() => {
    if (supplier && !supplierEmails[supplier.id]) {
      setShouldSendEmail(false);
    }
  }, [supplierEmails, supplier, setShouldSendEmail]);

  const handleAdd = useCallback(
    async (newDeliveryRequestItem: any) => {
      setLoading!(true);
      setDeliveryRequestItems([...deliveryRequestItems, newDeliveryRequestItem]);
      setLoading!(false);
    },
    [deliveryRequestItems, setDeliveryRequestItems, setLoading]
  );

  const handleDelete = useCallback(
    async (deleteDeliveryRequestItem: any) => {
      setLoading!(true);
      const tempItems = [...deliveryRequestItems];
      tempItems.splice(deleteDeliveryRequestItem.tableData.id, 1);
      setDeliveryRequestItems(tempItems);
      setLoading!(false);
    },
    [deliveryRequestItems, setLoading]
  );

  const totalPrice = useMemo(
    () => deliveryRequestItems.reduce((res, item) => res + +item.lastKnownPrice * item.quantity, 0),
    [deliveryRequestItems]
  );

  return (
    <Paper elevation={2} className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <OutlinedDiv label="Данни за заявка" disabled={true} loading={loading}>
            <Grid container spacing={2}>
              <Grid item sm={12} md={2} lg={2}>
                <AMSViewOnlyTextField label="Номер" value={'Нова заявка'} />
              </Grid>
              <Grid item sm={12} md={4} lg={6}>
                <AMSAutocomplete
                  label="Склад"
                  options={warehouses.map(warehouseToLookup)}
                  value={warehouse}
                  onChange={setWarehouse}
                  minChar={0}
                  disabled={deliveryRequestItems.length > 0}
                  required
                />
              </Grid>
              <Grid item sm={12} md={3} lg={2}>
                <AMSDatePicker
                  label="Доставка"
                  value={deliveryDate ? deliveryDate : null}
                  onChange={(value: Date | null) => {
                    if (value) {
                      if (isValidDate(value)) {
                        setDeliveryDate(value);
                      }
                    } else {
                      setDeliveryDate(null);
                    }
                  }}
                  minDate={new Date()}
                  required
                />
              </Grid>
              <Grid item sm={12} md={3} lg={2}>
                <AMSViewOnlyTextField
                  label="Обща сума"
                  value={`${totalPrice.toFixed(2)}лв.`}
                  inputProps={{
                    min: 0,
                    style: { textAlign: 'right', fontSize: '125%', color: '#3D3D3D' }
                  }}
                />
              </Grid>
              <Grid item sm={12} md={2} lg={2}>
                <AMSViewOnlyTextField label="Дата" value={'-'} />
              </Grid>
              <Grid item sm={12} md={4} lg={6}>
                <AMSAutocomplete
                  label="Доставчик"
                  options={suppliers.map(supplierToLookup)}
                  value={supplier}
                  onChange={setSupplier}
                  disabled={deliveryRequestItems.length > 0}
                  minChar={0}
                  required
                />
              </Grid>
              <Grid item sm={12} md={3} lg={2}>
                <AMSViewOnlyTextField label={'Статус'} value={DeliveryRequestStatusLabels.DRAFT} />
              </Grid>
              <Grid item sm={12} md={3} lg={2}>
                <AMSViewOnlyTextField
                  label="Праг на доставчик"
                  value={`${deliveryRequestThreshold.toFixed(2)}лв.`}
                  inputProps={{
                    min: 0,
                    style: { textAlign: 'right', fontSize: '125%', color: '#3D3D3D' }
                  }}
                  helperText={
                    totalPrice < deliveryRequestThreshold
                      ? `Остават още ${(deliveryRequestThreshold - totalPrice).toFixed(2)}лв.`
                      : undefined
                  }
                />
              </Grid>
            </Grid>
          </OutlinedDiv>
        </Grid>
        <Grid item lg={12}>
          <Grid container spacing={2}>
            <Grid item lg={12}>
              <AMSTable
                title={'Артикули за заявка'}
                columns={[
                  {
                    title: 'Арт №',
                    field: 'artNo',
                    cellStyle: { width: '20%' }
                  },
                  {
                    title: 'Име',
                    field: 'name',
                    cellStyle: { width: '40%' }
                  },
                  {
                    title: 'Брой',
                    field: 'quantity',
                    cellStyle: { width: '20%' },
                    render: (row: any) => row.quantity.toFixed(3)
                  },
                  {
                    title: 'Последна цена',
                    field: 'lastKnownPrice',
                    cellStyle: { width: '20%' },
                    type: 'currency',
                    currencySetting: {
                      locale: 'bg',
                      currencyCode: 'bgn',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }
                  }
                ]}
                data={deliveryRequestItems}
                components={{
                  Container: (props: any) => (
                    <Paper {...props} elevation={0} className="padding-0" />
                  )
                }}
                paging={false}
                overflowY="scroll"
                minBodyHeight="45vh"
                maxBodyHeight="45vh"
                addRowPosition="first"
                onAdd={
                  warehouse && supplier && deliveryDate
                    ? () => {
                        setAddItemOpenDialog(true);
                      }
                    : undefined
                }
                onDelete={handleDelete}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item lg={12} justify="flex-end">
          {canCreate && (
            <div className={classes.shouldSendEmailCheckboxWrapper}>
              <FormControlLabel
                className={classes.label}
                control={
                  <Checkbox
                    checked={shouldSendEmail}
                    onChange={() => setShouldSendEmail(!shouldSendEmail)}
                  />
                }
                disabled={!supplier || !supplierEmails[supplier.id]}
                label="Изпрати имейл"
                labelPlacement="end"
              />
            </div>
          )}
          {canCreate && (
            <AMSButton
              variant="contained"
              color="primary"
              className={classes.saveButton}
              onClick={async () => {
                setLoading!(true);
                if (deliveryDate) {
                  const request: CreateDeliveryRequestRequest = {
                    warehouseId: warehouse?.id,
                    supplierId: supplier?.id,
                    items: deliveryRequestItems,
                    deliveryDate: toDateString(deliveryDate)
                  };
                  const deliveryRequestResp = await deliveryRequestsService.createDeliveryRequest(
                    request,
                    shouldSendEmail
                  );
                  if (deliveryRequestResp && deliveryRequestResp.data) {
                    snackbarService.setSnackbarOpen(true);
                    history.push(`/delivery-request?id=${deliveryRequestResp.data.id}`);
                  }
                }
                setLoading!(false);
              }}
              text={'Запиши'}
              disabled={
                !warehouse ||
                !supplier ||
                !deliveryDate ||
                deliveryRequestItems.length === 0 ||
                totalPrice < deliveryRequestThreshold
              }
              loading={loading}
            />
          )}
        </Grid>
      </Grid>
      {canCreate && (
        <AddDeliveryRequestItemDialog
          open={addItemOpenDialog}
          onClose={() => setAddItemOpenDialog(false)}
          onSave={(item: any, shouldSendEmail: boolean) => {
            if (!shouldSendEmail) {
              setAddItemOpenDialog(false);
            }
            handleAdd(item);
          }}
          supplierIds={supplier ? [supplier.id] : undefined}
        />
      )}
    </Paper>
  );
};

export default CreateDeliveryRequestComponent;
