import { CircularProgress, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { Autocomplete } from '@material-ui/lab';
import { PartnerItemResponse } from '../../../services/api-v3';
import { partnersService } from '../../../services/services';
import { useDebounce } from 'use-debounce/lib';

interface Props {
  label?: string;
  value: any;
  onChange: (option: any) => void;
  minChar?: number;
  required?: boolean;
  disabled?: boolean;
  forDelivery?: boolean;
  partnerId: number;
}

const AMSExternalItemsAutocomplete = ({
  label = '',
  value,
  onChange,
  disabled = false,
  required = false,
  minChar = 3,
  partnerId
}: Props) => {
  const [text, setText] = useState('');
  const [query] = useDebounce(text, 250);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const reloadItems = async (q: string) => {
      setLoading(true);
      setText(q);
      const itemResponse = await partnersService.getPartnerItems(partnerId, q);
      const optionsWithArtNo = (itemResponse?.data ?? [])
        .filter((item: PartnerItemResponse) => !!item.artNo)
        .sort((a: PartnerItemResponse, b: PartnerItemResponse) =>
          (a.name ?? '').localeCompare(b.name ?? '')
        );
      const optionsWithoutArtNo = (itemResponse?.data ?? [])
        .filter((item: PartnerItemResponse) => !item.artNo)
        .sort((a: PartnerItemResponse, b: PartnerItemResponse) =>
          (a.name ?? '').localeCompare(b.name ?? '')
        );
      setOptions(
        [...optionsWithArtNo, ...optionsWithoutArtNo].map((item: PartnerItemResponse) => ({
          id: item.id,
          value: item.name,
          selectedView: item.artNo
        }))
      );
      setLoading(false);
    };
    if (query && query.length >= minChar) {
      reloadItems(query);
    }
  }, [partnerId, query, minChar]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) =>
        `${option.selectedView ? `${option.selectedView} ` : ''}${option.value}`
      }
      options={options}
      filterOptions={(opts: any, state: any) => {
        setText(state.inputValue);
        return opts;
      }}
      loading={loading}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          margin="dense"
          variant="outlined"
          placeholder={!!minChar ? `Минимум ${minChar} символа` : ''}
          fullWidth
          required={required}
          disabled={disabled}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
      value={value}
      onChange={async (_, option: any | null) => {
        onChange(option);
      }}
      loadingText="Зареждане"
      noOptionsText="Няма намерени резултати"
    />
  );
};

export default AMSExternalItemsAutocomplete;
