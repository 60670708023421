import AddTransactionDialog, { DocumentOption } from './Dialog/AddTransactionDialog';
import { Grid, Paper, TextField, Tooltip, createStyles, makeStyles } from '@material-ui/core';
import {
  OrderDocumentV2Response,
  OrderTransactionResponse,
  OrderV2Response
} from '../../../../services/api';
import React, { useMemo, useState } from 'react';
import { TransactionType, getTransactionName } from '../../../../services/AMSV3Service';
import {
  accessManagementService,
  amsV3Service,
  ordersService
} from '../../../../services/services';
import { getOrderDocumentFullUrl, subtract } from '../../../../helpers/utils';

import AMSPdfViewerDialog from '../../../../helpers/ui/AMSPdfViewerDialog/AMSPdfViewerDialog';
import AMSTable from '../../../../helpers/ui/AMSTable/AMSTable';
import { AccessPermissions } from '../../../../services/accessManagementService';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import AccountBalanceWalletTwoToneIcon from '@material-ui/icons/AccountBalanceWalletTwoTone';
import { CreateTransactionRequest } from '../../../../services/api-v3';
import { DocumentTypes } from '../../../../services/ordersService';
import MoneyTwoToneIcon from '@material-ui/icons/MoneyTwoTone';
import OutlinedDiv from '../../../../helpers/ui/OutlinedDiv';
import PaymentTwoToneIcon from '@material-ui/icons/PaymentTwoTone';
import { TransactionResponse } from '../../../../services/api-v3';

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      padding: 20
    },
    saveButton: {
      marginTop: 10,
      marginLeft: 10
    },
    flexStart: {
      alignContent: 'flex-start'
    },
    label: {
      paddingTop: 6
    },
    pdfViewer: {
      width: 480,
      height: 640,
      border: '1px solid black'
    },
    expense: {
      color: '#F03901'
    },
    income: {
      color: '#93AB3B'
    }
  })
);

interface OrderInvoiceAndTransactionsPanelProps {
  order: OrderV2Response;
  setOrder: (order: OrderV2Response) => void;
  receiver: any;
  orderDocuments: OrderDocumentV2Response[];
  onDeleteInvoice: () => void;
  isInvoiceDeletable: boolean;
}

const OrderInvoiceAndTransactionsPanel = ({
  order,
  setOrder,
  receiver,
  orderDocuments,
  onDeleteInvoice,
  isInvoiceDeletable
}: OrderInvoiceAndTransactionsPanelProps) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [openInvoicePreview, setOpenInvoicePreview] = useState(false);
  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('');

  const canUpdate = useMemo(
    () => accessManagementService.hasPermission(AccessPermissions.CAN_UPDATE_ORDER),
    []
  );

  const canCreateTransaction = useMemo(
    () => accessManagementService.hasPermission(AccessPermissions.CAN_CREATE_TRANSACTION),
    []
  );

  const canDeleteTransaction = useMemo(
    () => accessManagementService.hasPermission(AccessPermissions.CAN_DELETE_TRANSACTION),
    []
  );

  const transactionDocuments = useMemo(
    () =>
      orderDocuments
        .filter((doc: OrderDocumentV2Response) => doc.type !== DocumentTypes.ACT_OF_ACCEPTANCE)
        .map((t: OrderDocumentV2Response) => ({
          id: t.id,
          value: `${t.number} ${ordersService.getOrderDocumentTypeAlias(t.type)}`,
          type: t.type,
          remainingAmount: t.totalAmount
        }))
        .sort((do1: DocumentOption, do2: DocumentOption) => do1.id - do2.id),
    [orderDocuments]
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <Paper elevation={2} className={classes.paper}>
            <OutlinedDiv label="Данни за фактура">
              <Grid container spacing={1}>
                <Grid item lg={6}>
                  <TextField
                    label="Визуализация във фактурата"
                    margin="dense"
                    variant="outlined"
                    multiline
                    rows={4}
                    fullWidth
                    value={
                      receiver && receiver.invoiceInfo ? receiver.invoiceInfo.visualization : ''
                    }
                    disabled={true}
                  />
                </Grid>
                <Grid item lg={6}>
                  <TextField
                    label="Адрес за фактуриране"
                    margin="dense"
                    variant="outlined"
                    multiline
                    rows={4}
                    fullWidth
                    value={receiver && receiver.invoiceInfo ? receiver.invoiceInfo.fullAddress : ''}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </OutlinedDiv>
          </Paper>
        </Grid>
        <Grid item lg={4}>
          <AMSTable
            title="Документи"
            data={orderDocuments}
            columns={[
              {
                title: '№',
                field: 'number',
                render: (document: OrderDocumentV2Response) =>
                  document.number ? document.number : '-',
                cellStyle: { width: '40%' }
              },
              {
                title: 'Тип',
                field: 'type',
                render: (document: OrderDocumentV2Response) =>
                  ordersService.getOrderDocumentTypeAlias(document.type),
                cellStyle: { width: '40%' }
              },
              {
                title: 'Сума',
                field: 'totalAmount',
                type: 'currency',
                currencySetting: {
                  locale: 'bg',
                  currencyCode: 'bgn',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                },
                cellStyle: { width: '20%' }
              }
            ]}
            onView={(document: OrderDocumentV2Response) => {
              setOpenInvoicePreview(true);
              setUrl(getOrderDocumentFullUrl(document.documentPath));
              setTitle(
                `${ordersService.getOrderDocumentTypeAlias(document.type)} - ${document.number}`
              );
            }}
            search={false}
            paging={false}
            minBodyHeight="50vh"
            maxBodyHeight="50vh"
          />
        </Grid>
        <Grid item lg={8}>
          <AMSTable
            title="Списък с плащания"
            columns={[
              {
                title: 'Тип',
                field: 'transactionType',
                render: (transaction: TransactionResponse) => {
                  switch (transaction.transactionType) {
                    case TransactionType.BANK_ACCOUNT:
                      return (
                        <Tooltip
                          className={classes.income}
                          title={getTransactionName(TransactionType.BANK_ACCOUNT)}
                        >
                          <PaymentTwoToneIcon />
                        </Tooltip>
                      );
                    case TransactionType.CASH:
                      return (
                        <Tooltip
                          className={classes.income}
                          title={getTransactionName(TransactionType.CASH)}
                        >
                          <AccountBalanceWalletTwoToneIcon />
                        </Tooltip>
                      );
                    case TransactionType.EXPENSE_BANK_ACCOUNT:
                      return (
                        <Tooltip
                          className={classes.expense}
                          title={getTransactionName(TransactionType.EXPENSE_BANK_ACCOUNT)}
                        >
                          <AccountBalanceTwoToneIcon />
                        </Tooltip>
                      );
                    case TransactionType.EXPENSE_CASH:
                      return (
                        <Tooltip
                          className={classes.expense}
                          title={getTransactionName(TransactionType.EXPENSE_CASH)}
                        >
                          <MoneyTwoToneIcon />
                        </Tooltip>
                      );
                  }
                },
                cellStyle: { width: '10%' }
              },
              {
                title: 'Дата / Час',
                field: 'date',
                render: (transaction: TransactionResponse) =>
                  `${transaction.date.substr(0, 10)} ${transaction.date.substr(11, 8)}`,
                cellStyle: { width: '20%' }
              },
              {
                title: 'Каса',
                field: 'treasuryName',
                cellStyle: { width: '40%' }
              },
              {
                title: 'Фактура',
                field: 'invoiceNumber',
                render: (transaction: TransactionResponse) => transaction.info,
                cellStyle: { width: '20%' }
              },
              {
                title: 'Сума',
                field: 'amount',
                cellStyle: { width: '10%' },
                type: 'currency',
                currencySetting: {
                  locale: 'bg',
                  currencyCode: 'bgn',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }
              }
            ]}
            data={
              order.transactions
                ? order.transactions.sort(
                    (i1: OrderTransactionResponse, i2: OrderTransactionResponse) => i1.id - i2.id
                  )
                : []
            }
            onAdd={
              canUpdate && canCreateTransaction
                ? () => {
                    setOpenDialog(true);
                  }
                : undefined
            }
            onDelete={
              canUpdate && canDeleteTransaction
                ? async (transaction: TransactionResponse) => {
                    const resp = await amsV3Service.deleteTransaction(transaction.id);
                    if (resp) {
                      setOrder({
                        ...order,
                        transactions: order.transactions
                          ? order.transactions.filter((i: any) => i.id !== transaction.id)
                          : [],
                        invoiceRemainingAmount: order.invoiceRemainingAmount + +transaction.amount
                      });
                    }
                  }
                : undefined
            }
            paging={false}
            minBodyHeight="50vh"
            maxBodyHeight="50vh"
          />
        </Grid>
      </Grid>
      {canUpdate && canCreateTransaction && (
        <AddTransactionDialog
          isOrderTransaction={true}
          documents={transactionDocuments}
          defaultAmount={order.invoiceRemainingAmount}
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          onSave={async (transaction: CreateTransactionRequest) => {
            const resp = await amsV3Service.createTransaction(transaction);
            if (resp) {
              setOpenDialog(false);
              setOrder({
                ...order,
                transactions: order.transactions
                  ? [
                      ...order.transactions,
                      {
                        ...resp.data,
                        amount: +resp.data.amount,
                        invoiceNumber: '',
                        treasuryName: ''
                      } as OrderTransactionResponse
                    ]
                  : [
                      {
                        ...resp.data,
                        amount: +resp.data.amount,
                        invoiceNumber: '',
                        treasuryName: ''
                      } as OrderTransactionResponse
                    ],
                invoiceRemainingAmount: subtract(order.invoiceRemainingAmount, transaction.amount)
              });
            }
          }}
        />
      )}
      <AMSPdfViewerDialog
        title={title}
        url={url}
        open={openInvoicePreview}
        onClose={() => setOpenInvoicePreview(false)}
      />
    </>
  );
};

export default OrderInvoiceAndTransactionsPanel;
