import {
  CompanyListResponse,
  PaginatedListResponseCompanyListResponse
} from '../../services/api-v3';
import { IAsyncType, useAsync } from '.';

import { amsV3Service } from '../../services/services';

export interface ICompanies<T> extends IAsyncType<T> {
  companies: T;
}

const useCompanies = (
  id?: number[],
  query?: string,
  showInactive: boolean = false
): ICompanies<CompanyListResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseCompanyListResponse>(
      amsV3Service.getCompanies,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [id, query, showInactive]
    );
  return {
    companies: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useCompanies;
