import { AMSAutocomplete, AMSButton, AMSTable } from '../../../../helpers/ui';
import { CheckCircle, WarningOutlined } from '@material-ui/icons';
import { Container, Grid, Paper, Tooltip } from '@material-ui/core';
import {
  ItemListResponse,
  PartnerItemConfigurationResponse,
  PartnerListResponse
} from '../../../../services/api-v3';
import Lookup, { partnerToLookup } from '../../../../models/lookup';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { amsV3Service, snackbarService } from '../../../../services/services';
import { useFlag, usePartners, usePermissions, useStyles } from '../../../../helpers/hooks';

import AMSLink from '../../../../helpers/ui/AMSLink/AMSLink';
import { AccessPermissions } from '../../../../services/accessManagementService';
import AddPartnerItemConfigurationDialog from './AddPartnerItemConfigurationDialog/AddPartnerItemConfigurationDialog';
import EditPartnerItemConfigurationDialog from './EditPartnerItemConfigurationDialog/EditPartnerItemConfigurationDialog';
import { usePartnerItemConfigurations } from '../../../../helpers/hooks';

interface PartnerItemConfigurationPanelProps {
  partnerId: number;
  partnerIntegrationType: number;
  legalEntityId: number;
}

const PartnerItemConfigurationComponent = ({
  partnerId,
  partnerIntegrationType,
  legalEntityId
}: PartnerItemConfigurationPanelProps) => {
  const classes = useStyles();
  const [openAddDialog, setOpenAddDialog] = useFlag(false);
  const [openEditDialog, setOpenEditDialog] = useFlag(false);
  const [partner, setPartner] = useState<PartnerListResponse>();
  const { partners } = usePartners();
  const [partnerOptions, setPartnerOptions] = useState<PartnerListResponse[]>([]);
  const [partnerItemConfiguration, setPartnerItemConfiguration] =
    useState<PartnerItemConfigurationResponse>();

  const { partnerItemConfigurations, loading, setLoading, setParameters } =
    usePartnerItemConfigurations(partnerId);
  const [itemsById, setItemsById] = useState<any>({});

  const loadItems = useCallback(async () => {
    const itemIds = partnerItemConfigurations.map((item) => item.itemId);
    const itemsResp = await amsV3Service.getItems(
      itemIds,
      undefined,
      undefined,
      undefined,
      undefined,
      partnerId,
      true,
      1,
      itemIds.length
    );
    const itemsById = itemsResp.data.data.reduce((acc: any, item: ItemListResponse) => {
      acc[item.id] = item;
      return acc;
    }, {});
    setItemsById(itemsById);
  }, [partnerItemConfigurations, partnerId]);

  useEffect(() => {
    loadItems();
  }, [partnerItemConfigurations, loadItems]);

  const [canUpdate] = usePermissions([AccessPermissions.CAN_UPDATE_PARTNER]);

  useEffect(() => {
    setPartnerOptions(
      (partners || []).filter(
        (p) => p.legalEntityId === legalEntityId && p.id !== partnerId && p.integrationType
      )
    );
  }, [legalEntityId, partnerId, partners]);

  useEffect(() => {
    if (partner) {
      setParameters([partner.id]);
    }
  }, [partner, setParameters]);

  const handleAdd = useCallback(
    async (item, partnerId) => {
      setLoading(true);
      const request = {
        ...item
      };
      if (partnerItemConfiguration?.id) {
        const resp = await amsV3Service.updatePartnerItemConfiguration(
          partnerItemConfiguration.id,
          request
        );
        if (resp?.data) {
          snackbarService.setSnackbarOpen(true);
          setPartnerItemConfiguration(undefined);
          setParameters([partnerId]);
        }
      } else {
        const resp = await amsV3Service.createPartnerItemConfiguration(partnerId, request);
        if (resp?.data) {
          if (!canUpdate) snackbarService.setSnackbarOpen(true);
          setPartnerItemConfiguration(undefined);
          setParameters([partnerId]);
        }
      }
      setLoading(false);
    },
    [setParameters, partnerItemConfiguration, canUpdate, setLoading]
  );

  const handleDelete = useCallback(
    async (deleteItem: any) => {
      setLoading(true);
      const resp = await amsV3Service.deletePartnerItemConfiguration(deleteItem.id);
      if (resp?.data) {
        snackbarService.setSnackbarOpen(true);
        setParameters([partnerId]);
      }
      setLoading(false);
    },
    [partnerId, setParameters, setLoading]
  );

  const handleCopy = useCallback(async () => {
    if (!partner) return;
    setLoading(true);
    await amsV3Service.copyPartnerItemConfigurations(partnerId, partner?.id);
    setPartner(undefined);
    setLoading(false);
  }, [setLoading, partner, partnerId]);

  const titleArtNo = useMemo(
    () =>
      partnerIntegrationType === 1
        ? 'Barsy арт №'
        : partnerIntegrationType === 2
        ? 'I-Cash арт №'
        : partnerIntegrationType === 3
        ? 'Coshera Retail арт №'
        : 'Mistral арт №',
    [partnerIntegrationType]
  );

  const titleName = useMemo(
    () =>
      partnerIntegrationType === 1
        ? 'Barsy артикул'
        : partnerIntegrationType === 2
        ? 'I-Cash артикул'
        : partnerIntegrationType === 3
        ? 'Coshera Retail артикул'
        : 'Mistral артикул',
    [partnerIntegrationType]
  );

  const partnerSelectActive = useMemo(() => {
    return (
      partnerItemConfigurations.length === 0 || (partnerItemConfigurations.length > 0 && partner)
    );
  }, [partnerItemConfigurations, partner]);

  const actionsActive = useMemo(() => !partner, [partner]);

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Grid container spacing={1}>
        {partnerSelectActive && (
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <AMSAutocomplete
              label="Контрагент"
              options={partnerOptions.map(partnerToLookup)}
              value={partner && partner.id ? partnerToLookup(partner) : ''}
              onChange={(value: Lookup | null) => {
                return value
                  ? setPartner(partners.find((p: PartnerListResponse) => p.id === value.id))
                  : null;
              }}
              minChar={0}
            />
          </Grid>
        )}
        {partnerSelectActive && (
          <Grid item container lg={6} md={6} sm={12} xs={12} alignContent="flex-start">
            <AMSButton
              variant="contained"
              color="primary"
              disabled={!partner || loading}
              text={'Копирай'}
              loading={false}
              onClick={handleCopy}
              className={classes.saveButton}
            />
          </Grid>
        )}
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Paper elevation={2} className={classes.paper}>
            <AMSTable
              title="Списък с артикули"
              data={partnerItemConfigurations}
              columns={[
                {
                  title: titleArtNo,
                  field: 'externalItemArtNo',
                  cellStyle: { width: '10%' }
                },
                {
                  title: titleName,
                  field: 'externalItemName',
                  cellStyle: { width: '30%' }
                },
                {
                  title: 'Арт №',
                  field: 'itemArtNo',
                  render: (rowData: any) => {
                    const item = itemsById[rowData.itemId];
                    return (
                      <AMSLink
                        href={`/item?id=${rowData.itemId}`}
                        style={item?.isActive ? undefined : { color: '#6476dd' }}
                      >
                        {item?.artNo}
                      </AMSLink>
                    );
                  },
                  cellStyle: { width: '10%' }
                },
                {
                  title: 'Артикул',
                  field: 'itemName',
                  render: (rowData: any) => {
                    const item = itemsById[rowData.itemId];
                    return (
                      <AMSLink
                        href={`/item?id=${rowData.itemId}`}
                        style={item?.isActive ? undefined : { color: '#6476dd' }}
                      >
                        {item?.name}
                      </AMSLink>
                    );
                  },
                  cellStyle: { width: '30%' }
                },
                {
                  title: 'Минимум',
                  field: 'min',
                  cellStyle: { width: '10%' }
                },
                {
                  title: 'Максимум',
                  field: 'max',
                  cellStyle: { width: '10%' }
                },
                {
                  title: 'Предупреждение',
                  field: 'itemId',
                  render: (rowData: PartnerItemConfigurationResponse) => {
                    let warning = '';
                    const item = itemsById[rowData.itemId];
                    if (item && rowData.max) {
                      const effectiveMin = rowData.min ? +rowData.min : +rowData.max * 0.5;
                      if (
                        +rowData.max - effectiveMin < item.packageQtyDelivery &&
                        +rowData.max > effectiveMin
                      ) {
                        warning = `Разликата между МИН и МАКС не трябва да е по-малка от ${item.packageQtyDelivery}`;
                      }
                      if (+rowData.max > 0 && +rowData.max < item.packageQtyDelivery) {
                        warning = `МАКС не трябва да е по-малко от ${item.packageQtyDelivery}`;
                      }
                    }
                    return (
                      <Tooltip title={warning}>
                        {warning ? (
                          <WarningOutlined color="error" />
                        ) : (
                          <CheckCircle color="primary" />
                        )}
                      </Tooltip>
                    );
                  },
                  align: 'center',
                  cellStyle: { width: '10%' }
                }
              ]}
              paging={false}
              inlineActions={false}
              components={{
                Container: (props: any) => <Paper {...props} elevation={0} className="padding-0" />
              }}
              onAdd={
                actionsActive
                  ? () => {
                      setPartnerItemConfiguration(undefined);
                      setOpenAddDialog(true);
                    }
                  : undefined
              }
              onDelete={actionsActive && partnerId && canUpdate ? handleDelete : undefined}
              onEdit={
                actionsActive && partnerId && canUpdate
                  ? (value: PartnerItemConfigurationResponse) => {
                      const item = itemsById[value.itemId];
                      console.log({ value });
                      setPartnerItemConfiguration({ ...item, ...value });
                      setOpenEditDialog(true);
                    }
                  : undefined
              }
              isLoading={loading}
            />
          </Paper>
          {canUpdate && (
            <AddPartnerItemConfigurationDialog
              partnerId={partnerId}
              partnerIntegrationType={partnerIntegrationType}
              open={openAddDialog}
              onClose={() => setOpenAddDialog(false)}
              onSave={(item: any, addNew: boolean, partnerIds?: number[]) => {
                if (!partnerIds) {
                  partnerIds = [partnerId];
                }
                if (!addNew) {
                  setOpenAddDialog(false);
                }
                for (const partnerId of partnerIds) {
                  handleAdd(item, partnerId);
                }
              }}
              partnerLegalEntityId={legalEntityId}
            />
          )}
          {canUpdate && partnerItemConfiguration && (
            <EditPartnerItemConfigurationDialog
              partnerId={partnerId}
              partnerIntegrationType={partnerIntegrationType}
              partnerItemConfiguration={partnerItemConfiguration}
              internalItem={{
                id: partnerItemConfiguration!.itemId,
                value: itemsById[partnerItemConfiguration!.itemId].name,
                selectedView: itemsById[partnerItemConfiguration!.itemId].artNo
              }}
              open={openEditDialog}
              onClose={() => setOpenEditDialog(false)}
              onSave={(item: any, addNew: boolean) => {
                if (!addNew) {
                  setOpenEditDialog(false);
                }
                handleAdd(item, partnerId);
              }}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default PartnerItemConfigurationComponent;
