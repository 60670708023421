import { AMSAutocomplete, AMSButton, AMSViewOnlyTextField } from '../../../../helpers/ui';
import {
  DeliveryRequestResponse,
  StockReceivingProcessItemResponse,
  UpdateBatchesRequest,
  UpdateDeliveryRequestRequest
} from '../../../../services/api';
import {
  DeliveryRequestStatus,
  DeliveryRequestStatusKey,
  DeliveryRequestStatusKeys,
  DeliveryRequestStatusLabels,
  getDeliveryRequestStatusByKey
} from '../../../../services/deliveryRequestsService';
import { Grid, Paper } from '@material-ui/core';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { deliveryRequestsService, snackbarService } from '../../../../services/services';
import { usePermissions, useStyles } from '../../../../helpers/hooks';

import { AccessPermissions } from '../../../../services/accessManagementService';
import EditStockReceivingProcessTableComponent from './EditStockReceivingProcessTableComponent';
import Lookup from '../../../../models/lookup';
import OutlinedDiv from '../../../../helpers/ui/OutlinedDiv';
import { getExtendedDeliveryRequestId } from '../../../../helpers/utils';
import { toYYYYMMDD } from '../../../../helpers/date-helper';
import useStockReceivingProcess from '../../../../helpers/hooks/useStockReceivingProcess';

interface EditStockReceivingProcessComponentProps {
  deliveryRequest: DeliveryRequestResponse;
  setDeliveryRequest: Dispatch<SetStateAction<DeliveryRequestResponse | null>>;
  supplierInfo: string;
  warehouseInfo: string;
  deliveryDate: string;
  createdDate: string;
  status: DeliveryRequestStatus;
  allowedSubsequentStatuses: DeliveryRequestStatus[];
}

const EditStockReceivingProcessComponent = ({
  deliveryRequest,
  setDeliveryRequest,
  supplierInfo,
  warehouseInfo,
  deliveryDate,
  createdDate,
  status,
  allowedSubsequentStatuses
}: EditStockReceivingProcessComponentProps) => {
  const classes = useStyles();
  const { stockReceivingProcess, loading, setLoading } = useStockReceivingProcess(
    deliveryRequest.id
  );
  const [items, setItems] = useState<StockReceivingProcessItemResponse[]>([]);
  const [newStatus, setNewStatus] = useState<Lookup>({
    id: status.key,
    value: status.label
  });

  useEffect(() => {
    setItems(stockReceivingProcess?.items ?? []);
  }, [stockReceivingProcess]);

  const patchStockReceiveingProcess = useCallback(async () => {
    setLoading(true);
    let shouldShowSnackbar = false;
    if (newStatus.id !== status.key) {
      const updateDeliveryRequestRequest: UpdateDeliveryRequestRequest = {
        status: newStatus.id
      };
      const resp = await deliveryRequestsService.updateDeliveryRequest(
        deliveryRequest.id,
        updateDeliveryRequestRequest
      );
      setDeliveryRequest(resp.data);
      shouldShowSnackbar = true;
    }
    if (newStatus.id === DeliveryRequestStatusKeys.FOR_INSPECTION) {
      const request: UpdateBatchesRequest = {
        items: items.map((item: StockReceivingProcessItemResponse) => ({
          itemId: item.itemId,
          quantity: item.documentQuantity,
          batchNumber: !!item.batchNumber
            ? item.batchNumber
            : `${deliveryRequest.id}_${toYYYYMMDD(new Date())}`,
          expirationDate:
            item.expirationDate ??
            toYYYYMMDD(new Date(new Date().getTime() + 20 * 365 * 24 * 60 * 60 * 1000)),
          countryOfOrigin: item.countryOfOrigin ?? 'BGR'
        })),
        requestType: 'update-batches'
      };
      await deliveryRequestsService.updateStockReceivingProcessUpdateBatches(
        deliveryRequest.id,
        request
      );
      shouldShowSnackbar = true;
    }
    if (newStatus.id === DeliveryRequestStatusKeys.INSPECTED_WITH_REMARKS) {
      const request = {
        items: items.map((item: StockReceivingProcessItemResponse) => ({
          id: item.id,
          verifiedQuantity:
            item.verifiedQuantity && !isNaN(+item.verifiedQuantity) && +item.verifiedQuantity >= 0
              ? item.verifiedQuantity
              : item.documentQuantity
        }))
      };
      await deliveryRequestsService.updateStockReceivingProcessVerifiedQuantities(
        deliveryRequest.id,
        request
      );
      setDeliveryRequest({ ...deliveryRequest, status: DeliveryRequestStatusKeys.VERIFIED });
      setNewStatus({
        id: DeliveryRequestStatusKeys.VERIFIED,
        value: DeliveryRequestStatusLabels.VERIFIED
      });
      shouldShowSnackbar = true;
    }
    snackbarService.setSnackbarOpen(shouldShowSnackbar);
    setLoading(false);
  }, [items, deliveryRequest, setDeliveryRequest, status, newStatus, setLoading]);

  const [canUpdate] = usePermissions([AccessPermissions.CAN_UPDATE_DELIVERY]);

  const isFinalized = useMemo(
    () =>
      status === DeliveryRequestStatus.DELIVERED ||
      status === DeliveryRequestStatus.DECLINED_AFTER_VERIFICATION,
    [status]
  );

  return (
    <Paper elevation={2} className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <OutlinedDiv label="Данни от документ" disabled={true}>
            <Grid container spacing={2}>
              <Grid item sm={12} md={6} lg={2}>
                <AMSViewOnlyTextField
                  label="Номер"
                  value={getExtendedDeliveryRequestId(deliveryRequest.id)}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <AMSViewOnlyTextField label="Склад" value={warehouseInfo} />
              </Grid>
              <Grid item sm={12} md={6} lg={4}>
                <AMSViewOnlyTextField label="Доставка на" value={deliveryDate} />
              </Grid>
              <Grid item sm={12} md={6} lg={2}>
                <AMSViewOnlyTextField label="Създадена на" value={createdDate} />
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <AMSViewOnlyTextField label="Доставчик" value={supplierInfo} />
              </Grid>
              <Grid item sm={12} md={6} lg={4}>
                <AMSAutocomplete
                  label="Статус"
                  options={
                    status
                      ? [status, ...allowedSubsequentStatuses].map((s) => ({
                          id: s.key,
                          value: s.label
                        }))
                      : []
                  }
                  value={newStatus}
                  onChange={(selectedValue: Lookup | null) => {
                    if (selectedValue) {
                      setNewStatus(selectedValue);
                    }
                  }}
                  minChar={0}
                  disabled={!canUpdate || isFinalized}
                  required
                />
              </Grid>
            </Grid>
          </OutlinedDiv>
        </Grid>
        <Grid item lg={12}>
          <Grid container spacing={2}>
            <Grid item lg={12}>
              <EditStockReceivingProcessTableComponent
                items={items}
                status={getDeliveryRequestStatusByKey(newStatus.id as DeliveryRequestStatusKey)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item lg={12} justify="flex-end">
          {canUpdate && !isFinalized && (
            <AMSButton
              variant="contained"
              color="primary"
              className={classes.saveButton}
              onClick={patchStockReceiveingProcess}
              text="Запиши"
              loading={loading}
            />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EditStockReceivingProcessComponent;
