import { IAsyncType, useAsync } from '.';
import {
  PaginatedListResponseTreasuryListResponse,
  TreasuryListResponse
} from '../../services/api-v3';

import { amsV3Service } from '../../services/services';

export interface IV3Treasuries<T> extends IAsyncType<T> {
  treasuries: T;
}

const useTreasuries = (
  showInactive: boolean = false,
  id?: number,
  query?: string,
  skip?: number,
  limit?: number
): IV3Treasuries<TreasuryListResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseTreasuryListResponse>(
      amsV3Service.getTreasuries,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [showInactive, id, query, skip, limit],
      true
    );
  return {
    treasuries: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useTreasuries;
