import { AMSButton, AMSTextField } from '../../helpers/ui';
import { CircularProgress, Container, Grid } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { cookiesService, pricingPoliciesService } from '../../services/services';
import {
  usePermissions,
  usePricingPolicies
} from '../../helpers/hooks';

import AMSLink from '../../helpers/ui/AMSLink/AMSLink';
import AMSTable from '../../helpers/ui/AMSTable/AMSTable';
import { AccessPermissions } from '../../services/accessManagementService';
import PricingPolicy from '../../models/pricingPolicy';
import { PricingPolicyResponse } from '../../services/api';
import SearchIcon from '@material-ui/icons/Search';
import { getExtendedPricingPolicyId } from '../../helpers/utils';
import { parseFilter } from '../../helpers/url';
import { useHistory } from 'react-router-dom';

const PricingPoliciesComponent = ({ location }: any) => {
  useMemo(() => parseFilter(location.search), [location.search]);
  const filter = useMemo(() => cookiesService.getPricingPoliciesFilter(), []);
  const [text, setText] = useState(filter.query ? filter.query : '');
  const { pricingPolicies, setPricingPolicies, setParameters, loading, setLoading } =
    usePricingPolicies(text);

  const history = useHistory();

  const [canCreate, canRead, canDelete, canReadAllPricingPolicies] = usePermissions([
    AccessPermissions.CAN_CREATE_PRICING_POLICY,
    AccessPermissions.CAN_READ_PRICING_POLICY,
    AccessPermissions.CAN_DELETE_PRICING_POLICY,
    AccessPermissions.CAN_READ_ALL_PRICING_POLICIES
  ]);

  const onSearch = useCallback(() => {
    cookiesService.setPricingPoliciesFilter({
      query: text
    });
    let params: any = {};
    if (text) {
      params.search = text;
    }
    history.push({
      pathname: '/pricing-policies',
      search: new URLSearchParams(params).toString()
    });
    setParameters([text]);
  }, [text, history, setParameters]);

  return (
    <Container maxWidth="xl" className="margin-left-0">
      <Grid container spacing={1}>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <AMSTextField
            label="Търсене"
            placeholder="Търсене по име / свързани контрагенти"
            value={text}
            onEnter={onSearch}
            onChange={(value) => setText(value ?? '')}
            InputProps={{
              startAdornment: <SearchIcon />,
              endAdornment: loading ? <CircularProgress /> : null
            }}
          />
        </Grid>
        <Grid item lg={1} md={2} sm={6} xs={12}>
          <AMSButton
            color="primary"
            variant="contained"
            text="Търсене"
            loading={loading}
            disabled={false}
            onClick={onSearch}
            style={{
              marginTop: 8
            }}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <AMSTable
            title="Ценови политики"
            columns={[
              {
                title: 'Номер',
                field: 'id',
                render: (rowData: PricingPolicyResponse) => (
                  <AMSLink href={`/pricing-policy?id=${rowData.id}`}>
                    {getExtendedPricingPolicyId(rowData.id)}
                  </AMSLink>
                ),
                cellStyle: { width: '5%' }
              },
              canReadAllPricingPolicies
                ? {
                    title: 'Компания',
                    field: 'companyNote',
                    cellStyle: { width: '15%' },
                    render: (rowData: PricingPolicyResponse) =>
                      `${rowData.companyNote} (${rowData.companyName})`
                  }
                : null,
              {
                title: 'Име',
                field: 'name',
                cellStyle: { width: canReadAllPricingPolicies ? '20%' : '25%' }
              },
              {
                title: 'Свързaни контрагенти',
                field: 'assignedPartnersNames',
                cellStyle: { width: canReadAllPricingPolicies ? '60%' : '70%' }
              }
            ]}
            data={pricingPolicies.sort((pp1: any, pp2: any) => pp1.id - pp2.id)}
            onAdd={canCreate ? () => history.push(`/pricing-policy`) : undefined}
            onCopy={
              canRead && canCreate
                ? (p: any) => history.push(`/pricing-policy?copyOf=${p.id}`)
                : undefined
            }
            onDelete={
              canDelete
                ? async (pricingPolicy: PricingPolicy) => {
                    if (pricingPolicy?.id) {
                      setLoading(true);
                      await pricingPoliciesService.deletePricingPolicy(pricingPolicy.id);
                      setPricingPolicies(
                        pricingPolicies.filter((pp: PricingPolicy) => pp.id !== pricingPolicy.id)
                      );
                      setLoading(false);
                    }
                  }
                : undefined
            }
            isLoading={loading}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default PricingPoliciesComponent;
