import { IAsyncType, useAsync } from '.';
import {
  ManufacturerResponse,
  PaginatedListResponseManufacturerResponse
} from '../../services/api-v3';

import { amsV3Service } from '../../services/services';

export interface IManufacturers<T> extends IAsyncType<T> {
  manufacturers: T;
}

const useManufacturers = (id?: number[]): IManufacturers<ManufacturerResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseManufacturerResponse>(
      amsV3Service.getManufacturers,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [id],
      true
    );
  return {
    manufacturers: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useManufacturers;
