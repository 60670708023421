import {
  CircularProgress,
  Container,
  Tab,
  Tabs,
  Typography,
  createStyles,
  makeStyles
} from '@material-ui/core';
import { ItemCategoryResponse, PartnerItemCategoryResponse } from '../../services/api';
import React, { useEffect, useState } from 'react';
import {
  amsV3Service,
  categoriesService,
  partnersService,
  snackbarService
} from '../../services/services';

import AMSConfirmDialog from '../../helpers/ui/AMSConfirmDialog/AMSConfirmDialog';
import { AccessPermissions } from '../../services/accessManagementService';
import { ClientResponse } from '../../services/api-v3';
import IntegrationSettingsComponent from './Tabs/IntegrationSettings/IntegrationSettingsComponent';
import PartnerB2BOrdersComponent from './Tabs/PartnerB2BOrdersComponent/PartnerB2BOrdersComponent';
import PartnerCategoriesContainer from './Tabs/PartnerCategoriesContainer/PartnerCategoriesContainer';
import PartnerClientsComponent from './Tabs/PartnerClientsComponent/PartnerClientsComponent';
import PartnerInfoPanel from './Tabs/PartnerInfoPanel/PartnerInfoPanel';
import PartnerItemConfigurationComponent from './Tabs/PartnerItemConfigurationComponent/PartnerItemConfigurationComponent';
import TabPanel from '../../helpers/ui/TabPanel/TabPanel';
import flattenTree from '../../helpers/tree-helper';
import { isB2B } from '../../helpers/openapi-axios-helper';
import { parseQuery } from '../../helpers/url';
import { useHistory } from 'react-router-dom';
import { usePermissions } from '../../helpers/hooks';

const useStyles = makeStyles(() =>
  createStyles({
    panel: {
      maxHeight: '80vh'
    }
  })
);

const PartnerComponent = ({ location }: { location: any }) => {
  const { id, tabId } = parseQuery(location.search);

  const classes = useStyles();
  const history = useHistory();

  const [partner, setPartner] = useState<any>({
    isActive: true,
    collectOnDeliveryDate: true,
    hidePrices: false,
    moveOrdersToDeliveredAfterShipped: false,
    maintainStock: false
  });
  const [contacts, setContacts] = useState<any[]>([{}, {}, {}]);

  const [origPartnerCategories, setOrigPartnerCategories] = useState<any>({});
  const [partnerCategories, setPartnerCategories] = useState<any>({});
  const [categories, setCategories] = useState<any[]>([]);
  const [clients, setClients] = useState<ClientResponse[]>([]);

  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);

  const [canReadPromotions] = usePermissions([AccessPermissions.CAN_READ_PROMOTIONS]);

  useEffect(() => {
    const loadData = async () => {
      if (id) {
        setLoading(true);
        const [partnerResp, partnerCategoriesResp, categoriesResp, clientsResp] = await Promise.all(
          [
            amsV3Service.getPartner(id),
            partnersService.getPartnerVisibleCategories(id),
            categoriesService.getCategories(),
            amsV3Service.getClients([], true, [id], undefined, undefined)
          ]
        );
        if (partnerResp?.data) {
          setPartner(partnerResp.data);
        }
        let currentContacts: any[] = [{}, {}, {}];
        if (
          partnerResp &&
          partnerResp.data &&
          partnerResp.data.contacts &&
          partnerResp.data.contacts.length > 0
        ) {
          for (let i in partnerResp.data.contacts) {
            currentContacts[i] = { ...partnerResp.data.contacts[i] };
          }
          setContacts(currentContacts);
        } else {
          setContacts(currentContacts);
        }
        const ft = flattenTree(categoriesResp ? categoriesResp.data : []);
        const partnerCategories =
          partnerCategoriesResp && partnerCategoriesResp.data
            ? partnerCategoriesResp.data.reduce((res: any, cat: PartnerItemCategoryResponse) => {
                let parentCategory = null;
                if (cat.itemCategoryParentId) {
                  parentCategory = ft.find(
                    (c: ItemCategoryResponse) => c.id === cat.itemCategoryParentId
                  );
                }
                if (cat.itemCategoryParentId && !res[cat.itemCategoryParentId]) {
                  if (parentCategory && !res[parentCategory.parentId]) {
                    return {
                      ...res,
                      [cat.itemCategoryId]: 2,
                      [cat.itemCategoryParentId]: 1,
                      [parentCategory.parentId]: 1
                    };
                  } else {
                    return { ...res, [cat.itemCategoryId]: 2, [cat.itemCategoryParentId]: 1 };
                  }
                } else {
                  return { ...res, [cat.itemCategoryId]: 2 };
                }
              }, {})
            : {};

        setOrigPartnerCategories(partnerCategories);
        setPartnerCategories({ ...partnerCategories });
        setCategories(categoriesResp ? categoriesResp.data : []);
        setClients(clientsResp ? clientsResp.data.data : []);
        setLoading(false);
      } else {
        setLoading(false);
      }
    };
    loadData();
  }, [id]);

  useEffect(() => {
    if (tabId) {
      setTab(+tabId);
    } else {
      setTab(0);
    }
  }, [tabId]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newTab: number) => {
    if (tab === 1 && newTab === 0 && hasCategoryChanges()) {
      setOpenSaveDialog(true);
    } else {
      setTab(newTab);
      history.replace(`partner?${id ? `id=${id}&` : ''}tabId=${newTab}`);
    }
  };

  const hasCategoryChanges = () =>
    JSON.stringify(origPartnerCategories) !== JSON.stringify(partnerCategories);

  const saveCategories = async () => {
    setActionLoading(true);
    const saveResp = await partnersService.updatePartnerVisibleCategories(
      partner.id,
      Object.keys(partnerCategories)
        .map((k) => +k)
        .filter((k) => partnerCategories[k] === 2)
    );
    if (saveResp) {
      snackbarService.setSnackbarOpen(true);
      setOrigPartnerCategories(partnerCategories);
    }
    setActionLoading(false);
  };

  return loading ? (
    <CircularProgress />
  ) : (
    <Container maxWidth="xl">
      <Typography component="h5" variant="h5">
        {partner.name ?? 'Нов контрагент'}
      </Typography>
      {id && partner?.id && (
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label="Информация" id="item-tab-0" aria-controls="item-tab-0" />
          {isB2B && (
            <Tab
              label="Поръчки"
              id="item-tab-1"
              aria-controls="item-tab-1"
              disabled={!partner.id}
            />
          )}
          <Tab
            label="Видими категории"
            id="item-tab-2"
            aria-controls="item-tab-2"
            disabled={!partner.id}
          />
          <Tab
            label="Потребители"
            id="item-tab-3"
            aria-controls="item-tab-3"
            disabled={!partner.id}
          />
          {isB2B && (
            <Tab
              label="Настройки"
              id="item-tab-4"
              aria-controls="item-tab-4"
              disabled={!partner.id}
            />
          )}
          {isB2B && (
            <Tab
              label="Синхронизация"
              id="item-tab-5"
              aria-controls="item-tab-5"
              disabled={!partner.id || partner.integrationType === 0}
            />
          )}
          <Tab
            label="Промоции"
            id="item-tab-6"
            aria-controls="item-tab-6"
            disabled={!partner.id || !canReadPromotions}
          />
        </Tabs>
      )}
      <TabPanel value={tab} index={0} className={classes.panel}>
        <PartnerInfoPanel
          partner={partner}
          setPartner={setPartner}
          contacts={contacts}
          setContacts={setContacts}
        />
      </TabPanel>
      {isB2B && (
        <TabPanel value={tab} index={1} className={classes.panel}>
          <PartnerB2BOrdersComponent partnerId={partner.id} />
        </TabPanel>
      )}
      <TabPanel value={tab} index={isB2B ? 2 : 1} className={classes.panel}>
        <PartnerCategoriesContainer
          categories={categories}
          setPartnerCategories={setPartnerCategories}
          partnerCategories={partnerCategories}
          onSave={saveCategories}
          actionLoading={actionLoading}
        />
      </TabPanel>
      <TabPanel value={tab} index={isB2B ? 3 : 2} className={classes.panel}>
        <PartnerClientsComponent partnerId={partner.id} clients={clients} setClients={setClients} />
      </TabPanel>
      {isB2B && (
        <TabPanel value={tab} index={4} className={classes.panel}>
          <IntegrationSettingsComponent
            partnerId={partner.id}
            partnerIntegrationType={partner.integrationType}
          />
        </TabPanel>
      )}
      {isB2B && (
        <TabPanel value={tab} index={5} className={classes.panel}>
          <PartnerItemConfigurationComponent
            partnerId={partner.id}
            partnerIntegrationType={partner.integrationType}
            legalEntityId={partner.legalEntityId}
          />
        </TabPanel>
      )}
      <AMSConfirmDialog
        open={openSaveDialog}
        onConfirm={async () => {
          await saveCategories();
          setOpenSaveDialog(false);
          history.replace(`partner?${id ? `id=${id}&` : ''}tabId=${0}`);
        }}
        onClose={() => {
          setPartnerCategories(origPartnerCategories);
          setOpenSaveDialog(false);
          history.replace(`partner?${id ? `id=${id}&` : ''}tabId=${0}`);
        }}
        title={'Записване на промените'}
        message={'Искате ли да запишете промените по категориите?'}
      />
    </Container>
  );
};

export default PartnerComponent;
