import { StocktakingResponse } from '../../services/api';
import { stocktakingsService } from '../../services/services';
import { useAsync } from '.';

const useStocktaking = (id?: number) => {
  const { data, setData, loading, setParameters } = useAsync<StocktakingResponse | null>(
    stocktakingsService.getStocktaking,
    null,
    id ? [id] : null
  );

  return {
    stocktaking: data,
    setStocktaking: setData,
    loading,
    setParameters
  };
};

export default useStocktaking;
