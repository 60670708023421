import { AMSAutocomplete, AMSButton, AMSTextField } from '../../helpers/ui';
import {
  CircularProgress,
  Container,
  Grid,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { DeliveryRequestItemResponse, DeliveryRequestResponse } from '../../services/api';
import {
  DeliveryRequestStatus,
  DeliveryRequestStatusKey,
  getDeliveryRequestStatusByKey,
  getDeliveryRequestStatusLabelByKey
} from '../../services/deliveryRequestsService';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Lookup, { companyToLookup, warehouseToLookup } from '../../models/lookup';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { cookiesService, deliveryRequestsService } from '../../services/services';
import {
  ignoreOffset,
  isValidDate,
  toDateString,
  toEndOfDay,
  toStartOfDay
} from '../../helpers/date-helper';
import {
  useCompanies,
  useDeliveryRequests,
  usePermissions,
  useStyles,
  useWarehouses
} from '../../helpers/hooks';

import AMSLink from '../../helpers/ui/AMSLink/AMSLink';
import AMSTable from '../../helpers/ui/AMSTable/AMSTable';
import { AccessPermissions } from '../../services/accessManagementService';
import { BrowserView } from 'react-device-detect';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Checkbox from '@material-ui/core/Checkbox';
import DateFnsUtils from '@date-io/date-fns';
import SearchIcon from '@material-ui/icons/Search';
import { WarehouseListResponse } from '../../services/api-v3';
import { getExtendedDeliveryRequestId } from '../../helpers/utils';
import { parseFilter } from '../../helpers/url';
import { useHistory } from 'react-router-dom';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface DeliveryRequestResponseWithTotal extends DeliveryRequestResponse {
  expectedTotal: number;
}

const DeliveryRequests = ({ location }: any) => {
  const history = useHistory();
  const classes = useStyles();

  const { fromDate, toDate, companyIds, warehouseIds } = useMemo(
    () => parseFilter(location.search),
    [location.search]
  );

  const filter = useMemo(() => cookiesService.getDeliveryRequestsList(), []);
  const [filterFromDate, setFilterFromDate] = useState<Date | null>(
    fromDate
      ? fromDate
      : filter.filterFromDate
      ? ignoreOffset(new Date(filter.filterFromDate))
      : new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  const [filterToDate, setFilterToDate] = useState<Date | null>(
    fromDate
      ? toDate
      : filter.filterToDate
      ? ignoreOffset(new Date(filter.filterToDate))
      : new Date()
  );
  const [filterCompanies, setFilterCompanies] = useState<Lookup[]>(
    filter.filterCompanies ? filter.filterCompanies : []
  );
  const [filterWarehouses, setFilterWarehouses] = useState<Lookup[]>(
    filter.filterWarehouses ? filter.filterWarehouses : []
  );

  const { companies } = useCompanies();
  const { warehouses } = useWarehouses();

  const [text, setText] = useState(filter.query ?? '');

  const { deliveryRequests, setDeliveryRequests, setParameters, loading } = useDeliveryRequests(
    text,
    [],
    companyIds ?? filterCompanies.map((c: Lookup) => c.id),
    warehouseIds ?? filterWarehouses.map((s: Lookup) => s.id),
    filterFromDate ? toDateString(filterFromDate) : undefined,
    filterToDate ? toDateString(filterToDate) : undefined
  );

  const deliveryRequestsWithTotal = useMemo(
    () =>
      deliveryRequests.map((dr: DeliveryRequestResponse) => ({
        ...dr,
        expectedTotal: (dr.items ?? []).reduce(
          (res: number, item: DeliveryRequestItemResponse) =>
            res +
            calculatePriceWithVat(item.itemLastDeliveryPrice ?? 0, item.itemVat) * item.quantity,
          0
        )
      })),
    [deliveryRequests]
  );

  useEffect(() => {
    const { filterCompanies } = filter;
    if (companies) {
      setFilterCompanies(
        companyIds
          ? companies.filter((c: any) => companyIds.includes(c.id)).map(companyToLookup)
          : filterCompanies ?? []
      );
    }
  }, [companyIds, companies, filter]);

  useEffect(() => {
    const { filterWarehouses } = filter;
    if (warehouses) {
      setFilterWarehouses(
        warehouseIds
          ? warehouses.filter((w: any) => warehouseIds.includes(w.id)).map(warehouseToLookup)
          : filterWarehouses ?? []
      );
    }
  }, [warehouseIds, warehouses, filter]);

  const [canCreate, canUpdate] = usePermissions([
    AccessPermissions.CAN_CREATE_DELIVERY,
    AccessPermissions.CAN_UPDATE_DELIVERY
  ]);

  const onSearch = useCallback(() => {
    if (
      (filterFromDate === null || isValidDate(filterFromDate)) &&
      (filterToDate === null || isValidDate(filterToDate))
    ) {
      const fromDate = isValidDate(filterFromDate) ? toStartOfDay(filterFromDate!) : undefined;
      const toDate = isValidDate(filterToDate) ? toEndOfDay(filterToDate!) : undefined;
      cookiesService.setDeliveryRequestsList({
        query: text,
        filterFromDate: fromDate,
        filterToDate: toDate,
        filterCompanies,
        filterWarehouses
      });
      let params: any = {};
      if (text) {
        params.search = text;
      }
      if (filterFromDate) {
        params.fromDate = toDateString(filterFromDate);
      }
      if (filterToDate) {
        params.toDate = toDateString(filterToDate);
      }
      if (filterCompanies?.length > 0) {
        params.companyIds = filterCompanies.map((c) => c.id).join(',');
      }
      if (filterWarehouses?.length > 0) {
        params.warehouseIds = filterWarehouses.map((w) => w.id).join(',');
      }
      history.push({
        pathname: '/delivery-requests',
        search: new URLSearchParams(params).toString()
      });
      setParameters([
        text,
        [],
        filterCompanies?.map((c: Lookup) => c.id),
        filterWarehouses?.map((w: Lookup) => w.id),
        fromDate,
        toDate
      ]);
    }
  }, [
    filterFromDate,
    filterToDate,
    text,
    filterCompanies,
    filterWarehouses,
    history,
    setParameters
  ]);

  const getDeliveryRequestTotalByDateString = useCallback(
    (dateString: string) => {
      return deliveryRequestsWithTotal
        .filter((dr: DeliveryRequestResponseWithTotal) => dr.deliveryDate === dateString)
        .reduce((res: number, dr: DeliveryRequestResponseWithTotal) => res + dr.expectedTotal, 0);
    },
    [deliveryRequestsWithTotal]
  );

  const today = useMemo(() => toDateString(new Date()), []);
  const tomorrow = useMemo(
    () => toDateString(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)),
    []
  );
  const totalForToday = useMemo(() => {
    return getDeliveryRequestTotalByDateString(today);
  }, [today, getDeliveryRequestTotalByDateString]);
  const totalForTomorrow = useMemo(() => {
    return getDeliveryRequestTotalByDateString(tomorrow);
  }, [tomorrow, getDeliveryRequestTotalByDateString]);

  const handleOnDelete = useCallback(
    async (deliveryRequestsWithTotal: any) => {
      if (deliveryRequestsWithTotal) {
        const resp = await deliveryRequestsService.updateDeliveryRequest(
          deliveryRequestsWithTotal.id,
          {
            status: DeliveryRequestStatus.CANCELLED.key
          }
        );
        const data = [...deliveryRequestsWithTotal];
        data.splice(deliveryRequestsWithTotal.tableData.id, 1, {
          ...resp.data,
          expectedTotal: (resp.data.items ?? []).reduce(
            (res: number, item: DeliveryRequestItemResponse) =>
              res +
              calculatePriceWithVat(item.itemLastDeliveryPrice ?? 0, item.itemVat) * item.quantity,
            0
          )
        });
        setDeliveryRequests(data);
      }
    },
    [setDeliveryRequests]
  );

  return (
    <>
      <BrowserView>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="text-align-left">
            <Container maxWidth="xl">
              <Grid container spacing={1}>
                <Grid item lg={8} md={6} sm={12} xs={12}>
                  <AMSTextField
                    label="Търсене"
                    placeholder="Търсене по склад / доставчик"
                    value={text}
                    onEnter={onSearch}
                    onChange={(value) => setText(value ?? '')}
                    InputProps={{
                      startAdornment: <SearchIcon />,
                      endAdornment: loading ? <CircularProgress /> : null
                    }}
                  />
                </Grid>
                <Grid item lg={2} md={3} sm={6} xs={12}>
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk={true}
                    variant="inline"
                    format="dd/MM/yy"
                    margin="dense"
                    label="От"
                    helperText={''}
                    value={filterFromDate ? filterFromDate : null}
                    onChange={(value: Date | null) => {
                      if (value) {
                        if (isValidDate(value)) {
                          setFilterFromDate(ignoreOffset(value));
                        }
                      } else {
                        setFilterFromDate(null);
                      }
                    }}
                    inputVariant="outlined"
                    fullWidth
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    maxDate={filterToDate}
                  />
                </Grid>
                <Grid item lg={2} md={3} sm={6} xs={12}>
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk={true}
                    variant="inline"
                    format="dd/MM/yy"
                    margin="dense"
                    label="До"
                    helperText={''}
                    value={filterToDate ? filterToDate : null}
                    onChange={(value: Date | null) => {
                      if (value) {
                        if (isValidDate(value)) {
                          setFilterToDate(ignoreOffset(value));
                        }
                      } else {
                        setFilterToDate(null);
                      }
                    }}
                    inputVariant="outlined"
                    fullWidth
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    minDate={filterFromDate}
                  />
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <AMSAutocomplete
                    multiple
                    minChar={0}
                    limitTags={3}
                    options={companies.map(companyToLookup)}
                    disableCloseOnSelect
                    getOptionLabel={(option: any) => option.value}
                    renderOption={(option, { selected }) => (
                      <Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          className={classes.checkbox}
                          checked={selected}
                          color="primary"
                        />
                        {option.value}
                      </Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Компании"
                        placeholder=""
                        margin="dense"
                        fullWidth
                      />
                    )}
                    value={filterCompanies}
                    onChange={setFilterCompanies}
                    groupBy={(g) => (g.group ? g.group : '')}
                  />
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <AMSAutocomplete
                    multiple
                    minChar={0}
                    limitTags={3}
                    options={
                      filterCompanies.length > 0
                        ? warehouses
                            .filter((w: WarehouseListResponse) =>
                              filterCompanies.map((c) => c.id).includes(w.companyId)
                            )
                            .map(warehouseToLookup)
                        : warehouses.map(warehouseToLookup)
                    }
                    disableCloseOnSelect
                    getOptionLabel={(option: any) => option.value}
                    renderOption={(option, { selected }) => (
                      <Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          className={classes.checkbox}
                          checked={selected}
                          color="primary"
                        />
                        {option.value}
                      </Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Складове"
                        placeholder=""
                        margin="dense"
                        fullWidth
                      />
                    )}
                    value={filterWarehouses}
                    onChange={setFilterWarehouses}
                    groupBy={(g) => (g.group ? g.group : '')}
                  />
                </Grid>
                <Grid item lg={3} md={8} sm={12} xs={12} container alignContent="flex-end">
                  <Typography
                    className={classes.totalInfo}
                    variant="h6"
                    style={{ marginRight: '32px' }}
                  >
                    За днес: {totalForToday.toFixed(2)}лв.
                  </Typography>
                  <Typography className={classes.totalInfo} variant="h6">
                    За утре: {totalForTomorrow.toFixed(2)}лв.
                  </Typography>
                </Grid>
                <Grid item lg={1} md={4} sm={12} xs={12}>
                  <AMSButton
                    color="primary"
                    variant="contained"
                    text="Търсене"
                    loading={loading}
                    disabled={false}
                    onClick={onSearch}
                    style={{
                      marginTop: 12
                    }}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <AMSTable
                    title="Заявки"
                    columns={[
                      {
                        title: '№',
                        field: 'id',
                        render: (deliveryRequest: DeliveryRequestResponse) => (
                          <AMSLink href={`/delivery-request?id=${deliveryRequest.id}`}>
                            {getExtendedDeliveryRequestId(deliveryRequest.id)}
                          </AMSLink>
                        ),
                        cellStyle: { width: '5%' }
                      },
                      {
                        title: 'Статус',
                        field: 'status',
                        render: (rowData: DeliveryRequestResponse) => (
                          <Tooltip
                            title={getDeliveryRequestStatusLabelByKey(
                              rowData.status as DeliveryRequestStatusKey
                            )}
                          >
                            <div className="d-flex">
                              <div
                                className={classes.statusIndicator}
                                style={{
                                  backgroundColor: getDeliveryRequestStatusByKey(
                                    rowData.status as DeliveryRequestStatusKey
                                  ).color
                                }}
                              />
                              <div className={classes.statusText}>
                                {getDeliveryRequestStatusLabelByKey(
                                  rowData.status as DeliveryRequestStatusKey
                                )}
                              </div>
                            </div>
                          </Tooltip>
                        ),
                        cellStyle: { width: '10%' }
                      },
                      {
                        title: 'Очаквана доставка',
                        field: 'deliveryDate',
                        render: (rowData: DeliveryRequestResponse) => rowData.deliveryDate,
                        cellStyle: { width: '15%' }
                      },
                      {
                        title: 'Компания',
                        field: 'companyName',
                        cellStyle: { width: '15%' }
                      },
                      {
                        title: 'Склад',
                        field: 'warehouseName',
                        cellStyle: { width: '15%' }
                      },
                      {
                        title: 'Доставчик',
                        field: 'supplierName',
                        render: (deliveryRequest: DeliveryRequestResponse) => (
                          <AMSLink href={`/supplier?id=${deliveryRequest.supplierId}`}>
                            {deliveryRequest.supplierName}
                          </AMSLink>
                        ),
                        cellStyle: { width: '15%' }
                      },
                      {
                        title: 'Създадена от',
                        field: 'createdByName',
                        cellStyle: { width: '15%' }
                      },
                      {
                        title: 'Очаквана стойност',
                        field: 'expectedTotal',
                        type: 'currency',
                        currencySetting: {
                          locale: 'bg',
                          currencyCode: 'bgn',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        },
                        cellStyle: { width: '10%' }
                      }
                    ]}
                    pageSize={10}
                    data={deliveryRequestsWithTotal}
                    onAdd={canCreate ? () => history.push(`/delivery-request`) : undefined}
                    onDelete={canUpdate ? handleOnDelete : undefined}
                    isDeletable={(deliveryRequest: DeliveryRequestResponse) =>
                      deliveryRequest.status === DeliveryRequestStatus.REQUESTED.key
                    }
                    isLoading={loading}
                  />
                </Grid>
              </Grid>
            </Container>
          </div>
        </MuiPickersUtilsProvider>
      </BrowserView>
    </>
  );
};

export default DeliveryRequests;

const calculatePriceWithVat = (price: number, vat: number) => {
  return price + price * (vat / 100);
};
