import { Grid, Typography } from '@material-ui/core';

import AMSWidget from '../../../helpers/ui/AMSWidget/AMSWidget';
import Chart from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import React from 'react';
import { TreasuryListResponse } from '../../../services/api-v3';

interface FinanceTreasuriesCurrentWidgetComponentProps {
  treasuries: TreasuryListResponse[];
  cashAmountByTreasury: any;
  bankAccountAmountByTreasury: any;
}

const FinanceTreasuriesCurrentWidgetComponent = ({
  treasuries,
  cashAmountByTreasury,
  bankAccountAmountByTreasury
}: FinanceTreasuriesCurrentWidgetComponentProps) => {
  var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
  Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
    draw: function () {
      originalDoughnutDraw.apply(this, arguments);

      var chart = this.chart.chart;
      var ctx = chart.ctx;
      var width = chart.width;
      var height = chart.height;

      var fontSize = (height / 147).toFixed(2);
      ctx.font = fontSize + 'em Arial';
      ctx.textBaseline = 'middle';

      const sum = chart.config.data.datasets[0].data.reduce(
        (res: number, value: number) => res + value,
        0
      );
      const text =
        Math.round(sum) > 1000000
          ? `> ${Math.floor(sum / 1000000)}М`
          : Math.round(sum) > 10000
          ? `> ${Math.floor(sum / 1000)}K`
          : Math.round(sum * 100) / 100;
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2;

      ctx.fillText(text, textX, textY);
    }
  });

  return (
    <AMSWidget title={<Typography>Наличности</Typography>}>
      <Grid container style={{ overflow: 'hidden' }}>
        <Grid item lg={8}>
          <Grid container>
            <Grid item lg={12}>
              <Typography variant="h5">Общо</Typography>
            </Grid>
            <Grid item lg={12}>
              <Doughnut
                options={{
                  legend: {
                    display: false
                  },
                  responsive: true
                  // maintainAspectRatio: false
                }}
                data={{
                  labels: treasuries
                    .sort(
                      (t1: TreasuryListResponse, t2: TreasuryListResponse) =>
                        bankAccountAmountByTreasury[t2.id] +
                        cashAmountByTreasury[t2.id] -
                        (bankAccountAmountByTreasury[t1.id] + cashAmountByTreasury[t1.id])
                    )
                    .map((t: TreasuryListResponse) => t.name),
                  datasets: [
                    {
                      data: treasuries
                        .sort(
                          (t1: TreasuryListResponse, t2: TreasuryListResponse) =>
                            bankAccountAmountByTreasury[t2.id] +
                            cashAmountByTreasury[t2.id] -
                            (bankAccountAmountByTreasury[t1.id] + cashAmountByTreasury[t1.id])
                        )
                        .map(
                          (t: TreasuryListResponse) =>
                            bankAccountAmountByTreasury[t.id] + cashAmountByTreasury[t.id]
                        ),
                      backgroundColor: treasuries
                        .sort(
                          (t1: TreasuryListResponse, t2: TreasuryListResponse) =>
                            bankAccountAmountByTreasury[t2.id] +
                            cashAmountByTreasury[t2.id] -
                            (bankAccountAmountByTreasury[t1.id] + cashAmountByTreasury[t1.id])
                        )
                        .map((t) => t.color)
                    }
                  ]
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={4}>
          <Grid container>
            <Grid item lg={12}>
              <Typography variant="h6">В сметка</Typography>
            </Grid>
            <Grid item lg={12}>
              <Doughnut
                options={{
                  legend: {
                    display: false
                  },
                  responsive: true
                }}
                data={{
                  labels: treasuries
                    .sort(
                      (t1: TreasuryListResponse, t2: TreasuryListResponse) =>
                        bankAccountAmountByTreasury[t2.id] - bankAccountAmountByTreasury[t1.id]
                    )
                    .map((t: TreasuryListResponse) => t.name),
                  datasets: [
                    {
                      data: treasuries
                        .sort(
                          (t1: TreasuryListResponse, t2: TreasuryListResponse) =>
                            bankAccountAmountByTreasury[t2.id] - bankAccountAmountByTreasury[t1.id]
                        )
                        .map((t: TreasuryListResponse) => bankAccountAmountByTreasury[t.id]),
                      backgroundColor: treasuries
                        .sort(
                          (t1: TreasuryListResponse, t2: TreasuryListResponse) =>
                            bankAccountAmountByTreasury[t2.id] - bankAccountAmountByTreasury[t1.id]
                        )
                        .map((t: TreasuryListResponse) => t.color)
                    }
                  ]
                }}
              />
            </Grid>
            <Grid item lg={12}>
              <Typography variant="h6">В брой</Typography>
            </Grid>
            <Grid item lg={12}>
              <Doughnut
                options={{
                  legend: {
                    display: false
                  },
                  responsive: true
                  // maintainAspectRatio: false
                }}
                data={{
                  labels: treasuries
                    .sort(
                      (t1: TreasuryListResponse, t2: TreasuryListResponse) =>
                        cashAmountByTreasury[t2.id] - cashAmountByTreasury[t1.id]
                    )
                    .map((t: TreasuryListResponse) => t.name),
                  datasets: [
                    {
                      data: treasuries
                        .sort(
                          (t1: TreasuryListResponse, t2: TreasuryListResponse) =>
                            cashAmountByTreasury[t2.id] - cashAmountByTreasury[t1.id]
                        )
                        .map((t: TreasuryListResponse) => cashAmountByTreasury[t.id]),
                      backgroundColor: treasuries
                        .sort(
                          (t1: TreasuryListResponse, t2: TreasuryListResponse) =>
                            cashAmountByTreasury[t2.id] - cashAmountByTreasury[t1.id]
                        )
                        .map((t: TreasuryListResponse) => t.color)
                    }
                  ]
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AMSWidget>
  );
};

export default FinanceTreasuriesCurrentWidgetComponent;
