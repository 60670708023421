import { Checkbox, CircularProgress, Container, Grid, TextField, Tooltip } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Lookup, { partnerToLookup, warehouseToLookup } from '../../../models/lookup';
import { PartnerListResponse, WarehouseListResponse } from '../../../services/api-v3';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { cookiesService, reportsService } from '../../../services/services';
import {
  ignoreOffset,
  isValidDate,
  toDateString,
  toEndOfDay,
  toStartOfDay
} from '../../../helpers/date-helper';
import { usePartners, useStyles, useWarehouses } from '../../../helpers/hooks';

import AMSAutocomplete from '../../../helpers/ui/AMSAutocomplete/AMSAutocomplete';
import { AMSButton } from '../../../helpers/ui';
import AMSConfirmDialog from '../../../helpers/ui/AMSConfirmDialog/AMSConfirmDialog';
import AMSLink from '../../../helpers/ui/AMSLink/AMSLink';
import AMSTable from '../../../helpers/ui/AMSTable/AMSTable';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DateFnsUtils from '@date-io/date-fns';
import { ItemMovementBalance } from '../../../services/api';
import { parseFilter } from '../../../helpers/url';
import { useHistory } from 'react-router-dom';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ReportItemMovementsComponent = ({ location }: any) => {
  const history = useHistory();
  const classes = useStyles();
  const { toDate, fromDate, warehouseIds, partnerIds } = useMemo(
    () => parseFilter(location.search),
    [location.search]
  );

  const [loading, setLoading] = useState(false);
  const [itemMovements, setItemMovements] = useState<ItemMovementBalance[]>([]);

  const filter = cookiesService.getReportItemMovementsFilter();
  const [filterFromDate, setFilterFromDate] = useState<any>(
    fromDate
      ? fromDate
      : filter.filterFromDate
      ? ignoreOffset(new Date(filter.filterFromDate))
      : new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  const [filterToDate, setFilterToDate] = useState<any>(
    toDate ? toDate : filter.filterToDate ? ignoreOffset(new Date(filter.filterToDate)) : new Date()
  );
  const [filterWarehouseIds, setFilterWarehouseIds] = useState<any[]>(
    filter.filterWarehouseIds ? filter.filterWarehouseIds : []
  );
  const [filterPartnerIds, setFilterPartnerIds] = useState<any[]>(
    filter.filterPartnerIds ? filter.filterPartnerIds : []
  );
  const { warehouses } = useWarehouses();
  const { partners } = usePartners();

  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  useEffect(() => {
    const { filterWarehouseIds } = cookiesService.getReportItemMovementsFilter();

    setFilterWarehouseIds(
      warehouseIds && warehouses
        ? warehouses
            .filter((w: WarehouseListResponse) => warehouseIds.includes(w.id))
            .map(warehouseToLookup)
        : filterWarehouseIds ?? []
    );
  }, [warehouses, warehouseIds]);

  useEffect(() => {
    const { filterPartnerIds } = cookiesService.getReportItemMovementsFilter();

    setFilterPartnerIds(
      partnerIds && partners
        ? partners
            .filter((p: PartnerListResponse) => partnerIds.includes(p.id))
            .map(partnerToLookup)
        : filterPartnerIds ?? []
    );
  }, [partners, partnerIds]);

  const loadItemMovements = useCallback(async () => {
    setLoading(true);
    const fromDate = isValidDate(filterFromDate) ? toStartOfDay(filterFromDate) : undefined;
    const toDate = isValidDate(filterToDate) ? toEndOfDay(filterToDate) : undefined;
    const itemMovementsResponse = await reportsService.getItemMovements(
      fromDate,
      toDate,
      filterPartnerIds.map((partner: Lookup) => partner.id),
      filterWarehouseIds.map((warehouse: WarehouseListResponse) => warehouse.id)
    );
    if (itemMovementsResponse) {
      setItemMovements(itemMovementsResponse.data);
    }
    setLoading(false);
  }, [filterFromDate, filterToDate, filterPartnerIds, filterWarehouseIds]);

  const handleOnSearch = useCallback(() => {
    cookiesService.setReportItemMovementsFilter({
      filterFromDate: filterFromDate ? toStartOfDay(filterFromDate) : undefined,
      filterToDate: filterToDate ? toEndOfDay(filterToDate) : undefined,
      filterPartnerIds: filterPartnerIds,
      filterWarehouseIds: filterWarehouseIds
    });

    let params: any = {};
    if (filterPartnerIds?.length > 0) {
      params.partnerIds = filterPartnerIds.map((c) => c.id).join(',');
    }
    if (filterWarehouseIds?.length > 0) {
      params.warehouseIds = filterWarehouseIds.map((r) => r.id).join(',');
    }
    if (filterFromDate) {
      params.fromDate = toDateString(filterFromDate);
    }
    if (filterToDate) {
      params.toDate = toDateString(filterToDate);
    }
    history.push({
      pathname: '/item-movements-balance',
      search: new URLSearchParams(params).toString()
    });
    loadItemMovements();
  }, [
    filterFromDate,
    filterToDate,
    filterPartnerIds,
    filterWarehouseIds,
    history,
    loadItemMovements
  ]);

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="text-align-left">
          <Container maxWidth="xl">
            <Grid container spacing={1}>
              <Grid item lg={2} md={3} sm={12} xs={12}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk={true}
                  variant="inline"
                  format="dd/MM/yy"
                  margin="dense"
                  label="От"
                  helperText={''}
                  value={filterFromDate ? filterFromDate : null}
                  onChange={(value: Date | null) => {
                    if (value) {
                      if (isValidDate(value)) {
                        setFilterFromDate(ignoreOffset(value));
                      }
                    } else {
                      setFilterFromDate(null);
                    }
                  }}
                  inputVariant="outlined"
                  fullWidth
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  maxDate={filterToDate}
                />
              </Grid>
              <Grid item lg={2} md={3} sm={12} xs={12}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk={true}
                  variant="inline"
                  format="dd/MM/yy"
                  margin="dense"
                  label="До"
                  helperText={''}
                  value={filterToDate ? filterToDate : null}
                  onChange={(value: Date | null) => {
                    if (value) {
                      if (isValidDate(value)) {
                        setFilterToDate(ignoreOffset(value));
                      }
                    } else {
                      setFilterToDate(null);
                    }
                  }}
                  inputVariant="outlined"
                  fullWidth
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  minDate={filterFromDate}
                />
              </Grid>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <AMSAutocomplete
                  multiple
                  minChar={0}
                  limitTags={3}
                  options={partners.map(partnerToLookup)}
                  disableCloseOnSelect
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes.checkbox}
                        checked={selected}
                        color="primary"
                      />
                      {option.value}
                    </Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Контрагенти"
                      placeholder=""
                      margin="dense"
                      fullWidth
                    />
                  )}
                  value={filterPartnerIds}
                  onChange={(values) => setFilterPartnerIds(values)}
                  groupBy={(g) => (g.group ? g.group : '')}
                />
              </Grid>
              <Grid item lg={3} md={6} sm={6} xs={12}>
                <AMSAutocomplete
                  multiple
                  minChar={0}
                  limitTags={3}
                  options={warehouses.map(warehouseToLookup)}
                  sortOptions={false}
                  disableCloseOnSelect
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes.checkbox}
                        checked={selected}
                        color="primary"
                      />
                      {option.value}
                    </Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Складове"
                      placeholder=""
                      margin="dense"
                      fullWidth
                    />
                  )}
                  value={filterWarehouseIds}
                  onChange={(values) => setFilterWarehouseIds(values)}
                  groupBy={(g) => (g.group ? g.group : '')}
                />
              </Grid>
              <Grid item lg={1} md={6} sm={12} xs={12}>
                {(!filterPartnerIds || filterPartnerIds.length === 0) &&
                (!filterWarehouseIds || filterWarehouseIds.length === 0) &&
                !filterFromDate &&
                !filterToDate ? (
                  <Tooltip
                    title={
                      <div style={{ fontSize: 14 }}>
                        Търсенето без филтри може да доведе до забавяне на системата
                      </div>
                    }
                    placement="top"
                    style={{ fontSize: 24 }}
                  >
                    <div>
                      <AMSButton
                        color="primary"
                        variant="contained"
                        text="Търсене"
                        loading={loading}
                        disabled={false}
                        onClick={() => setOpenConfirmDialog(true)}
                        style={{
                          marginTop: 8,
                          backgroundColor: '#ff7b00',
                          float: 'right'
                        }}
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <AMSButton
                    color="primary"
                    variant="contained"
                    text="Търсене"
                    loading={loading}
                    disabled={false}
                    onClick={handleOnSearch}
                    style={{
                      marginTop: 8,
                      float: 'right'
                    }}
                  />
                )}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <AMSTable
                    title="Движения"
                    columns={[
                      {
                        title: 'Арт №',
                        field: 'itemArtNo',
                        render: (item: ItemMovementBalance) => (
                          <AMSLink href={`/item?id=${item.itemId}`}>{item.itemArtNo}</AMSLink>
                        ),
                        cellStyle: { width: '10%' }
                      },
                      {
                        title: 'Артикул',
                        field: 'itemName',
                        cellStyle: { width: '20%' },
                        render: (item: ItemMovementBalance) => (
                          <AMSLink href={`/item?id=${item.itemId}&tabId=2`}>
                            {item.itemName}
                          </AMSLink>
                        )
                      },
                      {
                        title: 'Ед. цена',
                        field: 'itemNetPrice',
                        cellStyle: { width: '6%' },
                        type: 'currency',
                        currencySetting: {
                          locale: 'bg',
                          currencyCode: 'bgn',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }
                      },
                      {
                        title: 'Количество',
                        field: 'quantity',
                        cellStyle: { width: '8%' },
                        align: 'right',
                        type: 'numeric'
                      }
                    ]}
                    paging={false}
                    overflowY="scroll"
                    minBodyHeight="55vh"
                    maxBodyHeight="55vh"
                    data={itemMovements}
                  />
                )}
              </Grid>
            </Grid>
          </Container>
          <AMSConfirmDialog
            open={openConfirmDialog}
            onConfirm={() => {
              handleOnSearch();
              setOpenConfirmDialog(false);
            }}
            onClose={() => setOpenConfirmDialog(false)}
            title={'Търсене без филтри!'}
            message={
              'Търсенето без филтри може да доведе до забавяне на системата. Искате да продължите?'
            }
          />
        </div>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default ReportItemMovementsComponent;
