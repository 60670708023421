import { AMSButton, AMSTextField } from '../../helpers/ui';
import {
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  TextField
} from '@material-ui/core';
import { ItemListResponse, PartnerListResponse, SupplierListResponse } from '../../services/api-v3';
import Lookup, {
  itemCategoryToLookup,
  partnerToLookup,
  supplierToLookup
} from '../../models/lookup';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import {
  useFlag,
  useItemCategories,
  useItems,
  usePartners,
  usePermissions,
  useStyles,
  useSuppliers
} from '../../helpers/hooks';

import AMSAutocomplete from '../../helpers/ui/AMSAutocomplete/AMSAutocomplete';
import AMSLink from '../../helpers/ui/AMSLink/AMSLink';
import AMSTable from '../../helpers/ui/AMSTable/AMSTable';
import { AccessPermissions } from '../../services/accessManagementService';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { ItemCategoryResponse } from '../../services/api';
import SearchIcon from '@material-ui/icons/Search';
import { cookiesService } from '../../services/services';
import { parseFilter } from '../../helpers/url';
import { useDebounce } from 'use-debounce';
import { useHistory } from 'react-router-dom';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Items = ({ location }: any) => {
  const classes = useStyles();
  const { showInactive, supplierIds, itemCategoryIds, partnerId } = useMemo(
    () => parseFilter(location.search),
    [location.search]
  );

  const { suppliers } = useSuppliers();
  const { categories } = useItemCategories();
  const { partners } = usePartners();

  const supplierNames: any = useMemo(
    () =>
      suppliers.reduce((res: any, supplier: SupplierListResponse) => {
        if (!res) {
          res = {};
        }
        res[`${supplier.id}`] = supplier.name;
        return res;
      }, {}),
    [suppliers]
  );

  const filter = useMemo(() => cookiesService.getItemsList(), []);
  const [filterCategories, setFilterCategories] = useState<Lookup[]>(
    filter.filterCategories ? filter.filterCategories : []
  );
  const [filterSuppliers, setFilterSuppliers] = useState<Lookup[]>(
    filter.filterSuppliers ? filter.filterSuppliers : []
  );
  const [filterPartner, setFilterPartner] = useState<Lookup>(
    filter.filterPartner ? partnerId : filter.filterPartner ?? undefined
  );
  const [filterShowInactive, setFilterShowInactive] = useFlag(
    showInactive !== undefined && showInactive !== null
      ? showInactive
      : filter.filterShowInactive ?? false
  );

  const [text, setText] = useState(filter.query ? filter.query : undefined);
  const [query] = useDebounce(text, 0);

  const { items, setParameters, loading } = useItems(
    [],
    query,
    itemCategoryIds ?? filterCategories.map((c: Lookup) => c.id),
    supplierIds ?? filterSuppliers.map((s: Lookup) => s.id),
    [],
    filterPartner?.id ?? undefined,
    filterShowInactive
  );

  const history = useHistory();

  const [canCreate] = usePermissions([AccessPermissions.CAN_CREATE_ITEM]);

  useEffect(() => {
    const { filterSuppliers } = cookiesService.getItemsList();

    setFilterSuppliers(
      supplierIds && suppliers
        ? suppliers
            .filter((s: SupplierListResponse) => supplierIds.includes(s.id))
            .map(supplierToLookup)
        : filterSuppliers ?? []
    );
  }, [suppliers, supplierIds]);

  useEffect(() => {
    const { filterCategories } = cookiesService.getItemsList();

    setFilterCategories(
      itemCategoryIds && categories
        ? categories
            .filter((i: ItemCategoryResponse) => itemCategoryIds.includes(i.id))
            .map(itemCategoryToLookup)
        : filterCategories ?? []
    );
  }, [categories, itemCategoryIds]);

  const onSearch = useCallback(() => {
    cookiesService.setItemsList({
      query,
      filterCategories,
      filterSuppliers,
      filterPartner,
      filterShowInactive
    });
    let params: any = {};
    if (filterCategories?.length > 0) {
      params.itemCategoryIds = filterCategories.map((c) => c.id).join(',');
    }
    if (filterSuppliers?.length > 0) {
      params.supplierIds = filterSuppliers.map((s) => s.id).join(',');
    }
    if (filterPartner) {
      params.filterPartner = filterPartner.id;
    }
    if (filterShowInactive) {
      params.showInactive = filterShowInactive;
    }
    if (query) {
      params.search = query;
    }
    history.push({
      pathname: '/items',
      search: new URLSearchParams(params).toString()
    });
    setParameters([
      [],
      query,
      filterCategories?.map((c: Lookup) => c.id),
      filterSuppliers?.map((s: Lookup) => s.id),
      [],
      filterPartner?.id,
      filterShowInactive
    ]);
  }, [
    query,
    filterCategories,
    filterSuppliers,
    filterPartner,
    filterShowInactive,
    history,
    setParameters
  ]);

  const onEnter = useCallback(() => {
    setParameters([
      [],
      query,
      filterCategories?.map((c: Lookup) => c.id),
      filterSuppliers?.map((s: Lookup) => s.id),
      [],
      filterPartner?.id,
      filterShowInactive
    ]);
  }, [setParameters, query, filterCategories, filterSuppliers, filterPartner, filterShowInactive]);

  const handleOnAdd = useCallback(() => history.push(`/item`), [history]);

  return (
    <div className={classes.flex}>
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item lg={6} md={6}>
            <AMSTextField
              label="Търсене"
              placeholder="Минимум 3 символа"
              value={query}
              onEnter={onEnter}
              onChange={(value) => setText(value ?? '')}
              InputProps={{
                startAdornment: <SearchIcon />,
                endAdornment: loading ? <CircularProgress /> : null
              }}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <AMSAutocomplete
              multiple
              minChar={0}
              limitTags={3}
              options={suppliers.map(supplierToLookup)}
              disableCloseOnSelect
              getOptionLabel={(option: Lookup) => option.value}
              renderOption={(option: Lookup, { selected }: any) => (
                <Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    className={classes.checkbox}
                    checked={selected}
                    color="primary"
                  />
                  {option.value}
                </Fragment>
              )}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Доставчик"
                  placeholder=""
                  margin="dense"
                  fullWidth
                />
              )}
              value={filterSuppliers}
              onChange={setFilterSuppliers}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={1}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <AMSAutocomplete
                  multiple
                  minChar={0}
                  limitTags={3}
                  options={categories.map(itemCategoryToLookup)}
                  disableCloseOnSelect
                  getOptionLabel={(option: Lookup) => option.value}
                  renderOption={(option: Lookup, { selected }: any) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes.checkbox}
                        checked={selected}
                        color="primary"
                      />
                      {option.value}
                    </Fragment>
                  )}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Категория"
                      placeholder=""
                      margin="dense"
                      fullWidth
                    />
                  )}
                  value={filterCategories}
                  onChange={setFilterCategories}
                  groupBy={(g) => g.group}
                />
              </Grid>
              <Grid item lg={3} md={6} sm={12} xs={12}>
                <AMSAutocomplete
                  minChar={0}
                  options={partners
                    .filter((p: PartnerListResponse) => p.integrationType)
                    .map(partnerToLookup)}
                  disableCloseOnSelect
                  getOptionLabel={(option: Lookup) => option.value}
                  renderOption={(option: Lookup, { selected }: any) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes.checkbox}
                        checked={selected}
                        color="primary"
                      />
                      {option.value}
                    </Fragment>
                  )}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Контрагент"
                      placeholder=""
                      margin="dense"
                      fullWidth
                    />
                  )}
                  value={filterPartner}
                  onChange={setFilterPartner}
                />
              </Grid>
              <Grid item container lg={2} md={10} sm={12} xs={12} alignContent="flex-start">
                <FormControlLabel
                  className={classes.label}
                  control={
                    <Checkbox
                      checked={filterShowInactive}
                      onChange={() => setFilterShowInactive(!filterShowInactive)}
                    />
                  }
                  label="Покажи неактивни"
                  labelPlacement="end"
                />
              </Grid>
              <Grid item lg={1} md={2} sm={12} xs={12}>
                <AMSButton
                  color="primary"
                  variant="contained"
                  text="Търсене"
                  loading={loading}
                  disabled={false}
                  onClick={onSearch}
                  style={{
                    marginTop: 8
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <AMSTable
              title="Списък с артикули"
              columns={[
                {
                  title: 'Арт №',
                  field: 'artNo',
                  render: (item: ItemListResponse) => (
                    <AMSLink href={`/item?id=${item.id}`}>{item.artNo}</AMSLink>
                  ),
                  cellStyle: { width: '5%' }
                },
                {
                  title: 'Име',
                  field: 'name',
                  render: (item: ItemListResponse) => (
                    <AMSLink href={`/item?id=${item.id}`}>{item.name}</AMSLink>
                  ),
                  cellStyle: { width: '50%' }
                },
                {
                  title: 'Баркод',
                  field: 'barcodeValue',
                  render: (item: ItemListResponse) => (
                    <AMSLink href={`/item?id=${item.id}`}>{item.barcodeValue}</AMSLink>
                  ),
                  cellStyle: { width: '10%' }
                },
                {
                  title: 'Доставчик',
                  field: 'supplierId',
                  render: (item: ItemListResponse) => (
                    <AMSLink href={`/supplier?id=${item.supplierId}`}>
                      {supplierNames ? supplierNames[item.supplierId] : 'Зареждане ...'}
                    </AMSLink>
                  ),
                  cellStyle: { width: '10%' }
                },
                {
                  title: 'Базова цена',
                  field: 'basePrice',
                  align: 'right',
                  type: 'currency',
                  currencySetting: {
                    locale: 'bg',
                    currencyCode: 'bgn',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  },
                  cellStyle: { width: '5%' }
                },
                {
                  title: 'Последна известна цена',
                  field: 'lastKnownPrice',
                  align: 'right',
                  type: 'currency',
                  currencySetting: {
                    locale: 'bg',
                    currencyCode: 'bgn',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  },
                  cellStyle: { width: '5%' }
                },
                {
                  title: 'Наша собственост',
                  field: 'isOwnedByUs',
                  type: 'boolean',
                  cellStyle: { width: '5%' }
                },
                {
                  title: 'Поддържане на наличност',
                  field: 'maintainStock',
                  type: 'boolean',
                  cellStyle: { width: '5%' }
                },
                {
                  title: 'Активен',
                  field: 'isActive',
                  type: 'boolean',
                  cellStyle: { width: '5%' }
                }
              ]}
              pageSize={10}
              data={items}
              onAdd={canCreate ? handleOnAdd : undefined}
              isLoading={loading}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Items;
