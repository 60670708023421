import {
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup
} from '@material-ui/core';
import React, { useState } from 'react';

import BarsyIntegrationForm from './BarsyIntegrationForm/BarsyIntegrationForm';
import CosheraRetailIntegrationForm from './CosheraRetailIntegrationForm/CosheraRetailIntegrationForm';
import ICASHIntegrationForm from './ICASHIntegrationForm/ICashIntegrationForm';
import MistralIntegrationForm from './MistralIntegrationForm/MistralIntegrationForm';
import NoConfigurationForm from './NoConfigurationForm/NoConfigurationForm';
import OrderCreationPolicyForm from './Orders/OrderCreationPolicyForm';
import { SetOrderingPolicyRequest } from '../../../../services/api';
import { partnersService } from '../../../../services/services';
import { useStyles } from '../../../../helpers/hooks';

interface IntegrationSettingsProps {
  partnerId: number;
  partnerIntegrationType?: number;
}

const IntegrationSettingsComponent = ({
  partnerId,
  partnerIntegrationType
}: IntegrationSettingsProps) => {
  const [integrationType, setIntegrationType] = useState(partnerIntegrationType ?? 0);
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={1}>
        <Grid item lg={6} sm={12}>
          <Paper elevation={2} className={classes.root}>
            <Grid container spacing={1}>
              <Grid item lg={12} sm={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={{ marginBottom: 12 }}>
                    Интеграция
                  </FormLabel>
                  <RadioGroup
                    row
                    defaultValue="top"
                    value={integrationType}
                    onChange={(event) => setIntegrationType(+event.target.value)}
                  >
                    <Grid container spacing={8}>
                      <Grid item lg={4}>
                        <FormControlLabel
                          value={0}
                          control={<Radio color="primary" />}
                          label="Изключено"
                          labelPlacement="end"
                        />
                      </Grid>
                      <Grid item lg={4}>
                        <FormControlLabel
                          value={1}
                          control={<Radio color="primary" />}
                          label="Barsy"
                          labelPlacement="end"
                        />
                      </Grid>
                      <Grid item lg={4}>
                        <FormControlLabel
                          value={2}
                          control={<Radio color="primary" />}
                          label="I-Cash"
                          labelPlacement="end"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                      <Grid item lg={6}>
                        <FormControlLabel
                          value={3}
                          control={<Radio color="primary" />}
                          label="Coshera Retail"
                          labelPlacement="end"
                        />
                      </Grid>
                      <Grid item lg={6}>
                        <FormControlLabel
                          value={4}
                          control={<Radio color="primary" />}
                          label="Mistral"
                          labelPlacement="end"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item lg={12} sm={12}>
                {integrationType === 0 && <NoConfigurationForm partnerId={partnerId} />}
                {integrationType === 1 && (
                  <BarsyIntegrationForm
                    partnerId={partnerId}
                    disableTestButton={partnerIntegrationType !== integrationType}
                  />
                )}
                {integrationType === 2 && <ICASHIntegrationForm partnerId={partnerId} />}
                {integrationType === 3 && <CosheraRetailIntegrationForm partnerId={partnerId} />}
                {integrationType === 4 && <MistralIntegrationForm partnerId={partnerId} />}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item lg={6} sm={12}>
          <Paper elevation={2} className={classes.root}>
            <FormLabel component="legend" style={{ marginBottom: 12 }}>
              Поръчки
            </FormLabel>
            <OrderCreationPolicyForm
              id={partnerId}
              onLoad={async (id: number) => {
                const resp = await partnersService.getOrderingPolicy(id);
                return resp.data;
              }}
              onSave={async (id: number, request: SetOrderingPolicyRequest) => {
                const resp = await partnersService.setOrderingPolicy(id, request);
                return resp.data;
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default IntegrationSettingsComponent;
