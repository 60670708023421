import * as H from 'history';

import { AMSAutocomplete, AMSButton } from '../../../helpers/ui';
import { Checkbox, Container, Grid, TextField } from '@material-ui/core';
import { CompanyListResponse, WarehouseListResponse } from '../../../services/api-v3';
import Lookup, {
  companyToLookup,
  orderStatusToLookup,
  warehouseToLookup
} from '../../../models/lookup';
import {
  OrderStatus,
  OrderStatusKey,
  getOrderStatusByKey,
  orderStatuses
} from '../../../services/ordersService';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ignoreOffset,
  isValidDate,
  toDateString,
  toEndOfDay,
  toStartOfDay
} from '../../../helpers/date-helper';
import { useCompanies, useInternalOrders, useStyles, useWarehouses } from '../../../helpers/hooks';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { OrderV2ListResponse } from '../../../services/api';
import OrdersTableComponent from '../OrdersTableComponent/OrdersTableComponent';
import { cookiesService } from '../../../services/services';
import { parseFilter } from '../../../helpers/url';
import { useHistory } from 'react-router-dom';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface InternalTransfersComponentProps {
  location: H.Location;
}

const InternalTransfersComponent = ({ location }: InternalTransfersComponentProps) => {
  const history = useHistory();
  const classes = useStyles();
  const { toDate, fromDate, companyIds, warehouseIds, receiverIds, statusKeys } = useMemo(
    () => parseFilter(location.search),
    [location.search]
  );

  const { companies } = useCompanies();
  const { warehouses } = useWarehouses();

  const filter = cookiesService.getInternalTransfersList();
  const [filterStatus, setFilterStatus] = useState<Lookup[]>(
    statusKeys
      ? statusKeys.map((key: string) =>
          orderStatusToLookup(getOrderStatusByKey(key as OrderStatusKey) as OrderStatus)
        )
      : filter.filterStatus
      ? filter.filterStatus
      : []
  );

  const [filterFromDate, setFilterFromDate] = useState<Date | null>(
    fromDate
      ? fromDate
      : filter.filterFromDate
      ? ignoreOffset(new Date(filter.filterFromDate))
      : new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  const [filterToDate, setFilterToDate] = useState<Date | null>(
    fromDate
      ? toDate
      : filter.filterToDate
      ? ignoreOffset(new Date(filter.filterToDate))
      : new Date()
  );
  const [filterCompanies, setFilterCompanies] = useState<Lookup[]>(
    filter.filterCompanies ? filter.filterCompanies : []
  );
  const [filterWarehouses, setFilterWarehouses] = useState<Lookup[]>(
    filter.filterWarehouses ? filter.filterWarehouses : []
  );
  const [filterReceivers, setFilterReceivers] = useState<Lookup[]>(
    filter.filterReceivers ? filter.filterReceivers : []
  );

  const { internalOrders, loading, setParameters } = useInternalOrders(
    statusKeys ?? filterStatus.map((s: Lookup) => s.id),
    companyIds ?? filterCompanies.map((c: Lookup) => c.id),
    warehouseIds ?? filterWarehouses.map((w: Lookup) => w.id),
    receiverIds ?? filterReceivers.map((r: Lookup) => r.id),
    filterFromDate ? toStartOfDay(filterFromDate) : undefined,
    filterToDate ? toEndOfDay(filterToDate) : undefined
  );

  useEffect(() => {
    const { filterCompanies } = cookiesService.getInternalTransfersList();

    setFilterCompanies(
      companyIds && companies
        ? companies
            .filter((c: CompanyListResponse) => companyIds.includes(c.id))
            .map(companyToLookup)
        : filterCompanies ?? []
    );
  }, [companies, companyIds]);

  useEffect(() => {
    const { filterWarehouses } = cookiesService.getInternalTransfersList();

    setFilterWarehouses(
      warehouseIds && warehouses
        ? warehouses
            .filter((w: WarehouseListResponse) => warehouseIds.includes(w.id))
            .map(warehouseToLookup)
        : filterWarehouses ?? []
    );
  }, [warehouses, warehouseIds]);

  useEffect(() => {
    const { filterReceivers } = cookiesService.getInternalTransfersList();

    setFilterReceivers(
      receiverIds && warehouses
        ? warehouses
            .filter((w: WarehouseListResponse) => receiverIds.includes(w.id))
            .map(warehouseToLookup)
        : filterReceivers ?? []
    );
  }, [warehouses, receiverIds]);

  const onSearch = useCallback(() => {
    const fromDate =
      filterFromDate !== null && isValidDate(filterFromDate)
        ? toStartOfDay(filterFromDate!)
        : undefined;
    const toDate =
      filterToDate !== null && isValidDate(filterToDate) ? toEndOfDay(filterToDate) : undefined;
    cookiesService.setInternalTransfersList({
      filterFromDate: fromDate,
      filterToDate: toDate,
      filterStatus,
      filterCompanies,
      filterWarehouses,
      filterReceivers
    });
    let params: any = {};
    if (filterStatus?.length > 0) {
      params.statusKeys = filterStatus.map((s) => s.id).join(',');
    }
    if (filterCompanies?.length > 0) {
      params.companyIds = filterCompanies.map((c) => c.id).join(',');
    }
    if (filterWarehouses?.length > 0) {
      params.warehouseIds = filterWarehouses.map((w) => w.id).join(',');
    }
    if (filterReceivers?.length > 0) {
      params.receiverIds = filterReceivers.map((r) => r.id).join(',');
    }
    if (filterFromDate) {
      params.fromDate = toDateString(filterFromDate);
    }
    if (filterToDate) {
      params.toDate = toDateString(filterToDate);
    }
    history.push({
      pathname: '/internal-transfers',
      search: new URLSearchParams(params).toString()
    });
    setParameters([
      filterStatus.map((status: Lookup) => status.id),
      filterCompanies.map((c: Lookup) => c.id),
      filterWarehouses.map((w: Lookup) => w.id),
      filterReceivers.map((r: Lookup) => r.id),
      fromDate,
      toDate
    ]);
  }, [
    filterCompanies,
    filterFromDate,
    filterStatus,
    filterToDate,
    filterWarehouses,
    filterReceivers,
    history,
    setParameters
  ]);

  useEffect(() => {
    cookiesService.setOrderIds(
      internalOrders.map((order: OrderV2ListResponse) => order.id).sort((a, b) => a - b)
    );
  }, [internalOrders]);

  return (
    <div className="text-align-left">
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item lg={8} md={12} sm={12} xs={12}>
            <AMSAutocomplete
              multiple
              minChar={0}
              limitTags={3}
              options={orderStatuses.sort((a, b) => a.id - b.id).map(orderStatusToLookup)}
              sortOptions={false}
              disableCloseOnSelect
              renderOption={(option, { selected }) => (
                <Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    className={classes.checkbox}
                    checked={selected}
                    color="primary"
                  />
                  {option.value}
                </Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Статус"
                  placeholder=""
                  margin="dense"
                  fullWidth
                />
              )}
              value={filterStatus}
              onChange={(values) => setFilterStatus(values)}
            />
          </Grid>
          <Grid item lg={2} md={6} sm={12} xs={12}>
            <KeyboardDatePicker
              disableToolbar
              autoOk={true}
              variant="inline"
              format="dd/MM/yy"
              margin="dense"
              label="От дата"
              helperText={''}
              value={filterFromDate ? filterFromDate : null}
              onChange={(value: Date | null) => {
                if (value) {
                  if (isValidDate(value)) {
                    setFilterFromDate(ignoreOffset(value));
                  }
                } else {
                  setFilterFromDate(null);
                }
              }}
              inputVariant="outlined"
              fullWidth
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
              maxDate={filterToDate}
            />
          </Grid>
          <Grid item lg={2} md={6} sm={12} xs={12}>
            <KeyboardDatePicker
              disableToolbar
              autoOk={true}
              variant="inline"
              format="dd/MM/yy"
              margin="dense"
              label="До дата"
              helperText={''}
              value={filterToDate ? filterToDate : null}
              onChange={(value: Date | null) => {
                if (value) {
                  if (isValidDate(value)) {
                    setFilterToDate(ignoreOffset(value));
                  }
                } else {
                  setFilterToDate(null);
                }
              }}
              inputVariant="outlined"
              fullWidth
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
              minDate={filterFromDate}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <AMSAutocomplete
              multiple
              minChar={0}
              limitTags={3}
              options={companies.map(companyToLookup)}
              disableCloseOnSelect
              renderOption={(option, { selected }) => (
                <Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    className={classes.checkbox}
                    checked={selected}
                    color="primary"
                  />
                  {option.value}
                </Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Компании"
                  placeholder=""
                  margin="dense"
                  fullWidth
                />
              )}
              value={filterCompanies}
              onChange={setFilterCompanies}
              groupBy={(g) => (g.group ? g.group : '')}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <AMSAutocomplete
              multiple
              minChar={0}
              limitTags={3}
              options={warehouses.map(warehouseToLookup)}
              sortOptions={false}
              disableCloseOnSelect
              renderOption={(option, { selected }) => (
                <Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    className={classes.checkbox}
                    checked={selected}
                    color="primary"
                  />
                  {option.value}
                </Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Складове"
                  placeholder=""
                  margin="dense"
                  fullWidth
                />
              )}
              value={filterWarehouses}
              onChange={setFilterWarehouses}
              groupBy={(g) => (g.group ? g.group : '')}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <AMSAutocomplete
              multiple
              minChar={0}
              limitTags={3}
              options={warehouses.map(warehouseToLookup)}
              disableCloseOnSelect
              renderOption={(option, { selected }) => (
                <Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    className={classes.checkbox}
                    checked={selected}
                    color="primary"
                  />
                  {option.value}
                </Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Получатели"
                  placeholder=""
                  margin="dense"
                  fullWidth
                />
              )}
              value={filterReceivers}
              onChange={setFilterReceivers}
              groupBy={(g) => (g.group ? g.group : '')}
            />
          </Grid>
          <Grid item lg={1} md={6} sm={12} xs={12}>
            <AMSButton
              color="primary"
              variant="contained"
              text="Търсене"
              loading={loading}
              disabled={false}
              onClick={onSearch}
              style={{
                marginTop: 8,
                float: 'right'
              }}
            />
          </Grid>
          <Grid item lg={12} sm={12}>
            <OrdersTableComponent
              title={'Вътрешни трансфери'}
              orders={internalOrders}
              hideInvoice={true}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default InternalTransfersComponent;
