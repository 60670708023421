import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { DeliveryRequestResponse } from '../../services/api';
import { deliveryRequestsService } from '../../services/services';

export interface IDeliveryRequests<T> extends IAsyncType<T> {
  deliveryRequests: T;
  setDeliveryRequests: Dispatch<SetStateAction<T>>;
}

const useDeliveryRequests = (
  search?: string,
  supplierIds?: number[],
  companyIds?: number[],
  warehouseIds?: number[],
  fromDate?: string,
  toDate?: string,
): IDeliveryRequests<DeliveryRequestResponse[]> => {
  const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
  DeliveryRequestResponse[]
  >(deliveryRequestsService.getDeliveryRequests, [], [search, supplierIds, companyIds, warehouseIds, fromDate, toDate]);
  return {
    deliveryRequests: data,
    setDeliveryRequests: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useDeliveryRequests;
