import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  makeStyles
} from '@material-ui/core';
import { CreateTransactionRequest, TreasuryListResponse } from '../../../../../services/api-v3';
import React, { useEffect, useState } from 'react';
import { TransactionType, getTransactionName } from '../../../../../services/AMSV3Service';

import AMSAutocomplete from '../../../../../helpers/ui/AMSAutocomplete/AMSAutocomplete';
import { DeliveryDocumentTypes } from '../../../../../services/deliveriesService';
import { DocumentTypes } from '../../../../../services/ordersService';
import { useTreasuries } from '../../../../../helpers/hooks';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold'
  },
  typeWrapper: {
    paddingTop: 8
  }
}));

export interface DocumentOption {
  id: number;
  value: string;
  type: number;
  remainingAmount: number;
}

export interface AddTransactionDialogProps {
  isOrderTransaction?: boolean;
  isDeliveryTransaction?: boolean;
  companyId?: number;
  documents?: DocumentOption[];
  defaultAmount?: number;
  defaultTransactionType?: number;
  open: boolean;
  onClose: () => void;
  onSave: (request: CreateTransactionRequest) => void;
}

const AddTransactionDialog = ({
  isOrderTransaction = false,
  isDeliveryTransaction = false,
  companyId,
  documents = [],
  defaultAmount,
  defaultTransactionType,
  open,
  onClose,
  onSave
}: AddTransactionDialogProps) => {
  const classes = useStyles();

  const [treasury, setTreasury] = useState<any | null>(null);
  const [documentOption, setDocumentOption] = useState<DocumentOption | null>(null);
  const [amount, setAmount] = useState<number | undefined>(defaultAmount ?? 0);
  const [info, setInfo] = useState<string | undefined>();
  const [transactionType, setTransactionType] = useState<number>(defaultTransactionType ?? 0);
  const { treasuries } = useTreasuries(false, companyId);

  useEffect(() => {
    setDocumentOption(documents.length > 0 ? documents[0] : null);
  }, [documents]);

  useEffect(() => {
    setAmount(documentOption ? documentOption.remainingAmount : 0);
  }, [documentOption]);

  const isExpense =
    documentOption &&
    ((isOrderTransaction && documentOption.type === DocumentTypes.CREDIT_NOTE) ||
      (isDeliveryTransaction && documentOption.type !== DeliveryDocumentTypes.CREDIT_NOTE));

  const isIncome =
    documentOption &&
    ((isOrderTransaction && documentOption.type !== DocumentTypes.CREDIT_NOTE) ||
      (isDeliveryTransaction && documentOption.type === DeliveryDocumentTypes.CREDIT_NOTE));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>
        <div className={classes.title}>Добавяне на трансакция</div>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={1}>
          {(isOrderTransaction || isDeliveryTransaction) && (
            <Grid item lg={12}>
              <AMSAutocomplete
                label="Документ"
                options={documents}
                value={documentOption}
                onChange={(selectedValue: DocumentOption | null) => {
                  setDocumentOption(selectedValue);
                  if (selectedValue) {
                    setAmount(selectedValue.remainingAmount);
                  }
                }}
                minChar={0}
                required
              />
            </Grid>
          )}
          <Grid item lg={12}>
            <AMSAutocomplete
              label="Каса"
              sortOptions={false}
              options={treasuries.map((t: TreasuryListResponse) => ({
                id: t.id,
                value: `${t.name}`
              }))}
              value={treasury}
              onChange={(selectedValue: TreasuryListResponse | null) => {
                setTreasury(selectedValue);
              }}
              minChar={0}
              required
            />
          </Grid>
          <Grid item lg={6}>
            <TextField
              label="Сума"
              variant="outlined"
              required
              fullWidth
              margin="dense"
              type="number"
              value={amount}
              onChange={(event) => setAmount(+event?.target.value)}
            />
          </Grid>
          <Grid item lg={6}>
            <FormControl className={classes.typeWrapper} component="fieldset" fullWidth>
              <RadioGroup
                value={transactionType}
                onChange={(event) => setTransactionType(+event.target.value)}
              >
                {!isExpense && (
                  <Grid container>
                    <Grid item lg={6}>
                      <FormControlLabel
                        value={TransactionType.BANK_ACCOUNT}
                        control={<Radio />}
                        label={getTransactionName(TransactionType.BANK_ACCOUNT)}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <FormControlLabel
                        value={TransactionType.CASH}
                        control={<Radio />}
                        label={getTransactionName(TransactionType.CASH)}
                      />
                    </Grid>
                  </Grid>
                )}
                {!isIncome && (
                  <Grid container>
                    <Grid item lg={6}>
                      <FormControlLabel
                        value={TransactionType.EXPENSE_BANK_ACCOUNT}
                        control={<Radio />}
                        label={getTransactionName(TransactionType.EXPENSE_BANK_ACCOUNT)}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <FormControlLabel
                        value={TransactionType.EXPENSE_CASH}
                        control={<Radio />}
                        label={getTransactionName(TransactionType.EXPENSE_CASH)}
                      />
                    </Grid>
                  </Grid>
                )}
              </RadioGroup>
            </FormControl>
          </Grid>
          {!isOrderTransaction && !isDeliveryTransaction && (
            <Grid item lg={12}>
              <TextField
                label="Коментар"
                margin="dense"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                value={info}
                onChange={(event: any) => {
                  if (event && event.target) {
                    setInfo(event.target.value);
                  } else {
                    setInfo(undefined);
                  }
                }}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="contained">
          Отказ
        </Button>
        <Button
          onClick={() => {
            if (!treasury || !amount) return;
            if (isOrderTransaction && !isDeliveryTransaction) {
              onSave({
                orderInvoiceId: documentOption ? documentOption.id : undefined,
                treasuryId: treasury.id,
                date: new Date().toISOString(),
                amount,
                transactionType,
                info
              });
            } else if (!isOrderTransaction && isDeliveryTransaction) {
              onSave({
                deliveryInvoiceId: documentOption ? documentOption.id : undefined,
                treasuryId: treasury.id,
                date: new Date().toISOString(),
                amount,
                transactionType,
                info
              });
            } else {
              onSave({
                treasuryId: treasury.id,
                date: new Date().toISOString(),
                amount,
                transactionType,
                info
              });
            }
          }}
          color="primary"
          variant="contained"
          disabled={!amount || !treasury || !transactionType}
        >
          Добре
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTransactionDialog;
