import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { ItemCategoryResponse } from '../../services/api';
import { categoriesService } from '../../services/services';

export interface ICategories<T> extends IAsyncType<T> {
  categories: T;
  setCategories: Dispatch<SetStateAction<T>>;
}

const useItemCategories = (leafOnly: boolean = false): ICategories<ItemCategoryResponse[]> => {
  const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
    ItemCategoryResponse[]
  >(categoriesService.getCategoriesFlat, [], [leafOnly]);
  return {
    categories: data,
    setCategories: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useItemCategories;
