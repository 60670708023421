import React, { CSSProperties, useCallback, useEffect, useState } from 'react';

import AMSLink from '../../../helpers/ui/AMSLink/AMSLink';
import AMSTable from '../../../helpers/ui/AMSTable/AMSTable';
import { StockTakingReportItemResponse } from '../../../services/api';
import { getExtendedStockTakingReportId } from '../../../helpers/utils';
import { stockTakingReportsService } from '../../../services/services';

interface ItemStockTakingComponentProps {
  itemId: number;
}

const ItemStockTakingComponent = ({ itemId }: ItemStockTakingComponentProps) => {
  const [reportItems, setReportItems] = useState<StockTakingReportItemResponse[]>([]);
  const loadData = useCallback(async () => {
    const resp = await stockTakingReportsService.getStockTakingReportItems(itemId);
    if (resp?.data) {
      setReportItems(resp.data);
    }
  }, [itemId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <AMSTable
      title="Ревизии"
      columns={[
        {
          title: 'ИД',
          field: 'id',
          render: (rowData: StockTakingReportItemResponse) => (
            <AMSLink href={`/stock-taking-report?id=${rowData.stockTakingReportId}`}>
              {getExtendedStockTakingReportId(rowData.stockTakingReportId)}
            </AMSLink>
          ),
          cellStyle: { width: '40%' }
        },
        {
          title: 'Очаквано количество',
          field: 'expectedQuantity',
          type: 'numeric',
          cellStyle: { width: '15%' }
        },
        {
          title: 'Преброено количество',
          field: 'countedQuantity',
          type: 'numeric',
          cellStyle: { width: '15%' }
        },
        {
          title: 'Потвърдено количество',
          field: 'confirmedQuantity',
          type: 'numeric',
          cellStyle: { width: '15%' }
        },
        {
          title: 'Баланс',
          render: (row: StockTakingReportItemResponse) =>
            `${row.confirmedQuantity - row.expectedQuantity > 0 ? '+' : ''}${
              row.confirmedQuantity - row.expectedQuantity
            }`,
          cellStyle: (
            data: StockTakingReportItemResponse[],
            row: StockTakingReportItemResponse
          ) => {
            const style: CSSProperties = { width: '15%' };
            if (row.expectedQuantity > row.confirmedQuantity) {
              style.backgroundColor = 'rgba(255, 0, 0, 0.3)';
            }
            if (row.expectedQuantity < row.confirmedQuantity) {
              style.backgroundColor = 'rgba(255, 128, 0, 0.3)';
            }
            return style;
          },
          type: 'numeric'
        }
      ]}
      data={reportItems}
    />
  );
};

export default ItemStockTakingComponent;
