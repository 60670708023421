import { AMSApi } from '../helpers/openapi-axios-helper';

export class DeliveryRequestTaskService {

  getDeliveryRequestTask = async (
    id: number
  ) => {
    const axios = await AMSApi();
    return await  axios.readDeliveryRequestTaskV2DeliveryRequestTasksIdGet(id);
  };
}
