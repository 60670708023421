import { IAsyncType, useAsync } from '.';
import {
  InvoiceListResponse,
  PaginatedListResponseInvoiceListResponse
} from '../../services/api-v3';

import { amsV3Service } from '../../services/services';

export interface IInvoiceCreditNotes<T> extends IAsyncType<T> {
  invoiceCreditNotes: T;
}

const useInvoiceCreditNotes = (invoiceId: number): IInvoiceCreditNotes<InvoiceListResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseInvoiceListResponse>(
      amsV3Service.getInvoiceCreditNotes,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [invoiceId],
      true
    );
  return {
    invoiceCreditNotes: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useInvoiceCreditNotes;
