import { ActOfAcceptanceItemResponse, ItemListResponse } from '../../services/api-v3';
import React, { useCallback, useEffect, useState } from 'react';

import AMSLink from '../../helpers/ui/AMSLink/AMSLink';
import { AMSTable } from '../../helpers/ui';
import { amsV3Service } from '../../services/services';
import { useActOfAcceptanceItems } from '../../helpers/hooks';

interface ActOfAcceptanceItemsPanelProps {
  id: number;
}

const ActOfAcceptanceItemsPanel = ({ id }: ActOfAcceptanceItemsPanelProps) => {
  const { actOfAcceptanceItems, loading } = useActOfAcceptanceItems(id);
  const [itemsLookup, setItemsLookup] = useState<any>({});

  const loadItems = useCallback(async () => {
    const itemIds = actOfAcceptanceItems?.map((item) => item.itemId) || [];
    if (itemIds) {
      const items = await amsV3Service.getItems(
        itemIds,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true
      );
      const itemsLookup = items.data.data.reduce((acc: any, item: ItemListResponse) => {
        acc[item.id] = item;
        return acc;
      }, {});
      setItemsLookup(itemsLookup);
    }
  }, [actOfAcceptanceItems]);

  useEffect(() => {
    if (actOfAcceptanceItems) {
      loadItems();
    }
  }, [actOfAcceptanceItems, loadItems]);

  return (
    <AMSTable
      title={'Списък с артикули'}
      columns={[
        {
          title: 'Артикул',
          lookup: itemsLookup,
          render: (aoa: ActOfAcceptanceItemResponse) => {
            const item = itemsLookup ? itemsLookup[aoa.itemId] : {};
            return (
              <AMSLink
                href={`/item?id=${aoa.itemId}`}
                style={item?.isActive ? undefined : { color: '#6476dd' }}
              >
                {item?.name}
              </AMSLink>
            );
          },
          cellStyle: { width: '20%' }
        },
        {
          title: 'Партида',
          field: 'deliveryItemBatchNumber',
          cellStyle: { width: '10%' }
        },
        {
          title: 'Срок на годност',
          field: 'deliveryItemExpirationDate',
          cellStyle: { width: '10%' }
        },
        {
          title: 'Държава',
          field: 'deliveryItemCountry',
          cellStyle: { width: '10%' }
        },
        {
          title: 'Бройки в кашон',
          field: 'itemPackageQuantityDelivery',
          align: 'right',
          type: 'numeric',
          cellStyle: { width: '10%' }
        },
        {
          title: 'Количество',
          field: 'quantity',
          cellStyle: { width: '10%' },
          align: 'right',
          render: (item: ActOfAcceptanceItemResponse) => (+item.quantity).toFixed(3)
        },
        {
          title: 'Ед. цена (без ДДС)',
          field: 'orderItemNetPriceForOne',
          cellStyle: { width: '10%' },
          align: 'right',
          type: 'currency',
          currencySetting: {
            locale: 'bg',
            currencyCode: 'bgn',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }
        },
        {
          title: 'Сума',
          field: 'netTotal',
          cellStyle: { width: '10%' },
          type: 'currency',
          currencySetting: {
            locale: 'bg',
            currencyCode: 'bgn',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }
        }
      ]}
      pageSize={10}
      search={true}
      data={actOfAcceptanceItems}
      isLoading={loading}
    />
  );
};

export default ActOfAcceptanceItemsPanel;
