import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';
import { OrderListResponse, PaginatedListResponseOrderListResponse } from '../../services/api-v3';

import { amsV3Service } from '../../services/services';

export interface IB2BOrders<T> extends IAsyncType<T> {
  b2bOrders: T;
  setB2BOrders: Dispatch<SetStateAction<T>>;
  setB2BOrdersParameters: Dispatch<any[] | null>;
}

const useB2BOrders = (
  statusKeys?: string[],
  companyIds?: number[],
  warehouseIds?: number[],
  receiverIds?: number[],
  legalEntitiyIds?: number[],
  fromDeliveryDate?: string,
  toDeliveryDate?: string,
  withExecutionPercentage: boolean = false
): IB2BOrders<OrderListResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseOrderListResponse>(
      amsV3Service.getOrders,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [
        statusKeys,
        companyIds,
        warehouseIds,
        receiverIds,
        legalEntitiyIds,
        fromDeliveryDate,
        toDeliveryDate,
        withExecutionPercentage
      ]
    );
  return {
    b2bOrders: data?.data ?? [],
    setB2BOrders: () => {},
    loading,
    setLoading,
    parameters,
    setParameters,
    setB2BOrdersParameters: setParameters
  };
};

export default useB2BOrders;
