import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import {
  CompanyListResponse,
  CreateTreasuryRequest,
  PatchTreasuryRequest,
  TreasuryListResponse
} from '../../../../services/api-v3';
import React, { useEffect, useState } from 'react';

import ColorPicker from 'material-ui-color-picker';
import { contrastFont } from '../../../../helpers/color-helper';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold'
  },
  content: {
    height: '25vh'
  }
}));

export interface AddEditTreasuryDialogProps {
  company: CompanyListResponse;
  treasury: TreasuryListResponse | null;
  open: boolean;
  onClose: () => void;
  onSave: (request: CreateTreasuryRequest | PatchTreasuryRequest) => void;
}

const AddEditTreasuryDialog = ({
  company,
  treasury,
  open,
  onClose,
  onSave
}: AddEditTreasuryDialogProps) => {
  const classes = useStyles();

  const [currentTreasury, setCurrentTreasury] = useState<any>({
    name: '',
    companyId: company.id,
    color: '',
    initialBankAccountAmount: '0',
    initialCashAmount: '0'
  });
  const [title, setTitle] = useState<string>('');

  useEffect(() => {
    if (treasury) {
      setCurrentTreasury(treasury);
      setTitle(`Редактиране на каса '${treasury.name}' към '${company.name}'`);
    } else {
      setTitle(`Създаване на каса към '${company.name}'`);
      setCurrentTreasury({
        name: '',
        companyId: company.id,
        initialBankAccountAmount: '0',
        initialCashAmount: '0',
        color: ''
      });
    }
  }, [open, company, treasury]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="xs"
    >
      <DialogTitle>
        <div className={classes.title}>{title}</div>
      </DialogTitle>
      <DialogContent dividers={true} className={classes.content}>
        <Grid container spacing={1}>
          <Grid item lg={12}>
            <ColorPicker
              label="Цвят"
              variant="outlined"
              required
              fullWidth
              margin="dense"
              InputProps={{ value: currentTreasury.color }}
              inputProps={{
                style: {
                  color:
                    currentTreasury && currentTreasury.color
                      ? contrastFont(currentTreasury.color)
                      : '#F0F0F0',
                  backgroundColor: currentTreasury.color,
                  margin: 8,
                  padding: 4,
                  borderRadius: 4
                }
              }}
              onChange={(color?: string) => {
                if (color) {
                  setCurrentTreasury({ ...currentTreasury, color: color.toUpperCase() });
                }
              }}
            />
          </Grid>
          <Grid item lg={12}>
            <TextField
              label="Име"
              variant="outlined"
              required
              fullWidth
              margin="dense"
              defaultValue={currentTreasury ? currentTreasury.name : ''}
              onChange={(event) =>
                setCurrentTreasury({ ...currentTreasury, name: event?.target.value })
              }
            />
          </Grid>
          {!treasury && (
            <Grid item lg={6}>
              <TextField
                label="В сметка"
                variant="outlined"
                required
                fullWidth
                margin="dense"
                defaultValue={currentTreasury ? currentTreasury.initialBankAccountAmount : ''}
                onChange={(event) =>
                  setCurrentTreasury({
                    ...currentTreasury,
                    initialBankAccountAmount: event?.target.value
                  })
                }
              />
            </Grid>
          )}
          {!treasury && (
            <Grid item lg={6}>
              <TextField
                label="В брой"
                variant="outlined"
                required
                fullWidth
                margin="dense"
                defaultValue={currentTreasury ? currentTreasury.initialCashAmount : ''}
                onChange={(event) =>
                  setCurrentTreasury({ ...currentTreasury, initialCashAmount: event?.target.value })
                }
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="contained">
          Отказ
        </Button>
        <Button onClick={() => onSave(currentTreasury)} color="primary" variant="contained">
          Добре
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditTreasuryDialog;
