import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';
import { OrderListResponse, PaginatedListResponseOrderListResponse } from '../../services/api-v3';

import { ordersService } from '../../services/services';

export interface IInternalOrders<T> extends IAsyncType<T> {
  internalOrders: T;
  setInternalOrders: Dispatch<SetStateAction<T>>;
  setInternalOrdersParameters: Dispatch<any[] | null>;
}

const useInternalOrders = (
  statusKeys?: string[],
  companyIds?: number[],
  warehouseIds?: number[],
  receiverIds?: number[],
  fromDate?: string,
  toDate?: string | undefined
): IInternalOrders<OrderListResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseOrderListResponse>(
      ordersService.getInternalOrders,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [statusKeys, companyIds, warehouseIds, receiverIds, fromDate, toDate]
    );
  return {
    internalOrders: data?.data ?? [],
    setInternalOrders: () => {},
    loading,
    setLoading,
    parameters,
    setParameters,
    setInternalOrdersParameters: setParameters
  };
};

export default useInternalOrders;
