import { Checkbox, Container, Grid, TextField } from '@material-ui/core';
import {
  ItemCategoryResponse,
  ReportPricingPolicyItemCategoryMultipliersResponse,
  ReportPricingPolicyItemPricesResponse,
  ReportPricingPolicyValueResponse
} from '../../../services/api';
import React, { Fragment, useEffect, useState } from 'react';
import { categoriesService, reportsService } from '../../../services/services';
import { getExtendedItemCategoryId, getExtendedPricingPolicyId } from '../../../helpers/utils';

import AMSAsyncAutocompleteMultiple from '../../../helpers/ui/AMSAsyncAutocompleteMultiple/AMSAsyncAutocompleteMultiple';
import AMSAutocomplete from '../../../helpers/ui/AMSAutocomplete/AMSAutocomplete';
import AMSButton from '../../../helpers/ui/AMSButton/AMSButton';
import AMSTable from '../../../helpers/ui/AMSTable/AMSTable';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Lookup from '../../../models/lookup';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },
  checkbox: {
    marginRight: 8
  }
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ReportPricingComponent = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [categories, setCategories] = useState<ItemCategoryResponse[]>([]);

  const [filterCategories, setFilterCategories] = useState<ItemCategoryResponse[]>([]);
  const [filterItems, setFilterItems] = useState<Lookup[]>([]);
  const [columns, setColumns] = useState<any>([]);

  const [title, setTitle] = useState('');

  useEffect(() => {
    const loadCategories = async () => {
      const categoriesResp = await categoriesService.getCategoriesFlat(true);
      setCategories(categoriesResp ? categoriesResp.data : []);
    };
    loadCategories();
  }, []);

  const getItemInfo = (itemId: number) => {
    let itemInfo = `${itemId}`;
    if (filterItems && filterItems.length) {
      const itemLookup = filterItems.find((il: Lookup) => il.id === itemId);
      if (itemLookup) {
        itemInfo = itemLookup.selectedView ? itemLookup.selectedView : itemLookup.value;
      }
    }
    return itemInfo;
  };

  const loadData = async (q?: string) => {
    setLoading(true);
    if (filterCategories && filterCategories.length && !filterItems.length) {
      const dataResponse = await reportsService.getItemCategoryMultipliers(
        filterCategories.map((ic: ItemCategoryResponse) => ic.id)
      );
      if (dataResponse) {
        setTitle('Ценови коефициенти');
        setData(
          dataResponse.data.values.reduce(
            (res: any[], icm: ReportPricingPolicyItemCategoryMultipliersResponse) => [
              ...res,
              {
                itemCategory: `${getExtendedItemCategoryId(icm.itemCategoryId)} ${
                  icm.itemCategoryName
                }`,
                ...icm.values.reduce((reduced: any, current: ReportPricingPolicyValueResponse) => {
                  if (!reduced) {
                    reduced = {};
                  }
                  reduced[`pricingPolicy${current.pricingPolicyId}`] = current.value;
                  return reduced;
                }, {})
              }
            ],
            []
          )
        );
        setColumns([
          {
            title: 'Категория',
            field: 'itemCategory',
            cellStyle: { width: '10%' }
          },
          ...dataResponse.data.pricingPolicyIds.map((id: number) => ({
            title: getExtendedPricingPolicyId(id),
            render: (row: any) => row[`pricingPolicy${id}`].toFixed(2),
            field: `pricingPolicy${id}`,
            cellStyle: { width: '10%' },
            type: 'numeric'
          }))
        ]);
      }
    } else if (filterItems.length) {
      const dataResponse = await reportsService.getItemPrices(
        filterItems.map((item: Lookup) => item.id)
      );
      if (dataResponse) {
        setTitle('Цена без ДДС');
        setData(
          dataResponse.data.values.reduce(
            (res: any[], ip: ReportPricingPolicyItemPricesResponse) => [
              ...res,
              {
                itemInfo: getItemInfo(ip.itemId),
                ...ip.values.reduce((reduced: any, current: ReportPricingPolicyValueResponse) => {
                  if (!reduced) {
                    reduced = {};
                  }
                  reduced[`pricingPolicy${current.pricingPolicyId}`] = current.value;
                  return reduced;
                }, {})
              }
            ],
            []
          )
        );
        setColumns([
          {
            title: 'Артикул',
            field: 'itemInfo',
            cellStyle: { width: '10%' }
          },
          ...dataResponse.data.pricingPolicyIds.map((id: number) => ({
            title: getExtendedPricingPolicyId(id),
            field: `pricingPolicy${id}`,
            cellStyle: { width: '10%' },
            type: 'currency',
            currencySetting: {
              locale: 'bg',
              currencyCode: 'bgn',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }
          }))
        ]);
      }
    } else {
      setTitle('Справка цени');
    }
    setLoading(false);
  };

  const dataTable = (
    <AMSTable title={title} columns={columns} pageSize={10} data={data} isLoading={loading} />
  );

  return (
    <div className={classes.flex}>
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item lg={5} md={6} sm={6} xs={12}>
            <AMSAutocomplete
              multiple
              minChar={0}
              limitTags={3}
              options={categories.map((c) => ({
                id: c.id,
                value: `${getExtendedItemCategoryId(c.id)} ${c.name}`,
                group: `${!!c.parentId ? `${c.parentName}` : 'Основни'}`
              }))}
              disableCloseOnSelect
              getOptionLabel={(option: Lookup) => option.value}
              renderOption={(option: Lookup, { selected }: any) => (
                <Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    className={classes.checkbox}
                    checked={selected}
                    color="primary"
                  />
                  {option.value}
                </Fragment>
              )}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Категория"
                  placeholder=""
                  margin="dense"
                  fullWidth
                />
              )}
              value={filterCategories}
              onChange={(values) => setFilterCategories(values)}
              groupBy={(g) => g.group}
            />
          </Grid>
          <Grid item lg={5} md={12} sm={12} xs={12}>
            <AMSAsyncAutocompleteMultiple
              label="Артикул"
              onChange={(value) => {
                setFilterItems(value);
              }}
              showSelectAll={filterCategories.length > 0}
              value={filterItems}
              minChar={filterCategories.length > 0 ? 0 : 3}
              categoryIds={filterCategories.map((ic: ItemCategoryResponse) => ic.id)}
            />
          </Grid>
          <Grid item lg={2} md={12} sm={12} xs={12}>
            <AMSButton
              color="primary"
              variant="contained"
              text="Търсене"
              loading={loading}
              disabled={!filterCategories.length && !filterItems.length}
              onClick={loadData}
              style={{
                marginTop: 12
              }}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {dataTable}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ReportPricingComponent;
