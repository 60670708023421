import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { SupplierResponse } from '../../services/api-v3';
import { amsV3Service } from '../../services/services';

export interface ISupplier<T> extends IAsyncType<T> {
  supplier: T;
  setSupplier: Dispatch<SetStateAction<T>>;
}

const useSupplier = (id?: number): ISupplier<SupplierResponse | null> => {
  const { data, setData, loading, setLoading, parameters, setParameters } =
    useAsync<SupplierResponse | null>(amsV3Service.getSupplier, null, id ? [id] : null);
  return {
    supplier: data,
    setSupplier: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useSupplier;
