import { IAsyncType, useAsync } from '.';
import { PaginatedListResponseUserListResponse, UserListResponse } from '../../services/api-v3';

import { amsV3Service } from '../../services/services';

export interface IUsers<T> extends IAsyncType<T> {
  users: T;
}

const useUsers = (
  showInactive: boolean = false,
  company?: number,
  query?: string,
  skip?: number,
  limit?: number
): IUsers<UserListResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseUserListResponse>(
      amsV3Service.getUsers,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [showInactive, company, query, skip, limit]
    );
  return {
    users: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useUsers;
