import {
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { CompanyListResponse, LegalEntityListResponse } from '../../services/api-v3';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Lookup, { companyToLookup, legalEntityToLookup } from '../../models/lookup';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { getExtendedCompanyId, getExtendedLegalEntityId } from '../../helpers/utils';
import { ignoreOffset, isValidDate, toEndOfDay, toStartOfDay } from '../../helpers/date-helper';
import { useCompanies, useInvoices, useLegalEntities, useStyles } from '../../helpers/hooks';

import AMSAutocomplete from '../../helpers/ui/AMSAutocomplete/AMSAutocomplete';
import { AMSButton } from '../../helpers/ui';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DateFnsUtils from '@date-io/date-fns';
import InvoicesTableComponent from './InvoicesTableComponent';
import { cookiesService } from '../../services/services';
import { parseFilter } from '../../helpers/url';
import { useFlag } from '../../helpers/hooks';
import { useHistory } from 'react-router-dom';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const InvoicesComponent = ({ location }: any) => {
  const classes = useStyles();
  const history = useHistory();
  const { fromDate, toDate, legalEntityIds, companyIds, hideCancelled, hidePaidInFull } = useMemo(
    () => parseFilter(location.search),
    [location.search]
  );

  const filter = cookiesService.getInvoiceFilter();
  const [filterFromDate, setFilterFromDate] = useState<any>(
    fromDate
      ? fromDate
      : filter.filterFromDate
      ? ignoreOffset(new Date(filter.filterFromDate))
      : new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  const [filterToDate, setFilterToDate] = useState<any>(
    fromDate
      ? toDate
      : filter.filterToDate
      ? ignoreOffset(new Date(filter.filterToDate))
      : new Date()
  );
  const [filterHideCancelled, setFilterHideCancelled] = useFlag(
    hideCancelled !== undefined ? hideCancelled : filter.filterHideCancelled ?? true
  );
  const [filterHidePaidInFull, setFilterHidePaidInFull] = useFlag(
    hidePaidInFull !== undefined ? hidePaidInFull : filter.filterHidePaidInFull ?? true
  );
  const [filterLegalEntities, setFilterLegalEntities] = useState<Lookup[]>([]);
  const [filterCompanies, setFilterCompanies] = useState<Lookup[]>([]);
  const { companies } = useCompanies();
  const { legalEntities } = useLegalEntities();

  const { invoices, setInvoices, loading, setParameters } = useInvoices(
    [],
    filterFromDate ? toStartOfDay(filterFromDate) : undefined,
    filterToDate ? toEndOfDay(filterToDate) : undefined,
    legalEntityIds ?? filterLegalEntities.map((l: Lookup) => l.id),
    companyIds ?? filterCompanies.map((c: Lookup) => c.id),
    filterHideCancelled,
    filterHidePaidInFull
  );

  useEffect(() => {
    const { filterLegalEntities } = cookiesService.getInvoiceFilter();

    setFilterLegalEntities(
      legalEntityIds && legalEntities
        ? legalEntities
            .filter((le: LegalEntityListResponse) => legalEntityIds.includes(le.id))
            .map(legalEntityToLookup)
        : filterLegalEntities ?? []
    );
  }, [legalEntities, legalEntityIds]);

  useEffect(() => {
    const { filterCompanies } = cookiesService.getInvoiceFilter();

    setFilterCompanies(
      companyIds && companies
        ? companies
            .filter((c: CompanyListResponse) => companyIds.includes(c.id))
            .map(companyToLookup)
        : filterCompanies ?? []
    );
  }, [companies, companyIds]);

  const onSearch = useCallback(() => {
    const fromDate = isValidDate(filterFromDate) ? new Date(toStartOfDay(filterFromDate!)) : null;
    const toDate = isValidDate(filterToDate) ? new Date(toEndOfDay(filterToDate!)) : null;
    cookiesService.setInvoiceFilter({
      filterInvoiceIds: [],
      filterFromDate: fromDate,
      filterToDate: toDate,
      filterLegalEntities,
      filterCompanies,
      filterHideCancelled,
      filterHidePaidInFull
    });
    let params: any = {};
    if (filterFromDate) {
      params.fromDate = toStartOfDay(filterFromDate);
    }
    if (filterToDate) {
      params.toDate = toEndOfDay(filterToDate);
    }
    if (filterLegalEntities?.length > 0) {
      params.legalEntityIds = filterLegalEntities.map((l) => l.id).join(',');
    }
    if (filterCompanies?.length > 0) {
      params.companyIds = filterCompanies.map((c) => c.id).join(',');
    }
    params.hideCancelled = filterHideCancelled;
    params.hidePaidInFull = filterHidePaidInFull;
    history.push({
      pathname: '/invoices',
      search: new URLSearchParams(params).toString()
    });
    setParameters([
      [],
      fromDate ? toStartOfDay(fromDate) : undefined,
      toDate ? toEndOfDay(toDate) : undefined,
      filterLegalEntities.map((l: Lookup) => l.id),
      filterCompanies.map((c: Lookup) => c.id),
      filterHideCancelled,
      filterHidePaidInFull
    ]);
  }, [
    filterFromDate,
    filterToDate,
    filterLegalEntities,
    filterCompanies,
    filterHideCancelled,
    filterHidePaidInFull,
    history,
    setParameters
  ]);

  const totalIncludingVat = useMemo(
    () =>
      invoices.reduce(
        (res, invoice) => res + (invoice.isCancelled ? 0 : +invoice.totalIncludingVat),
        0
      ),
    [invoices]
  );
  const totalRemainingAmount = useMemo(
    () =>
      invoices.reduce(
        (res, invoice) => res + (invoice.isCancelled ? 0 : +invoice.remainingAmount),
        0
      ),
    [invoices]
  );

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="text-align-left">
          <Container maxWidth="xl">
            <Grid container spacing={1}>
              <Grid item lg={2} md={6} sm={12} xs={12}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk={true}
                  variant="inline"
                  format="dd/MM/yy"
                  margin="dense"
                  label="От"
                  helperText={''}
                  value={filterFromDate ? filterFromDate : null}
                  onChange={(value: Date | null) => {
                    if (value) {
                      if (isValidDate(value)) {
                        setFilterFromDate(ignoreOffset(value));
                      }
                    } else {
                      setFilterFromDate(null);
                    }
                  }}
                  inputVariant="outlined"
                  fullWidth
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  maxDate={filterToDate}
                />
              </Grid>
              <Grid item lg={2} md={6} sm={12} xs={12}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk={true}
                  variant="inline"
                  format="dd/MM/yy"
                  margin="dense"
                  label="До"
                  helperText={''}
                  value={filterToDate ? filterToDate : null}
                  onChange={(value: Date | null) => {
                    if (value) {
                      if (isValidDate(value)) {
                        setFilterToDate(ignoreOffset(value));
                      }
                    } else {
                      setFilterToDate(null);
                    }
                  }}
                  inputVariant="outlined"
                  fullWidth
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  minDate={filterFromDate}
                />
              </Grid>
              <Grid item lg={6} md={8} sm={12} xs={12}>
                <AMSAutocomplete
                  multiple
                  minChar={0}
                  limitTags={3}
                  options={legalEntities.map((le) => ({
                    id: le.id,
                    value: `${getExtendedLegalEntityId(le.id)} ${le.name}`
                  }))}
                  disableCloseOnSelect
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes.checkbox}
                        checked={selected}
                        color="primary"
                      />
                      {option.value}
                    </Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Юридическо лице"
                      placeholder=""
                      margin="dense"
                      fullWidth
                    />
                  )}
                  value={filterLegalEntities}
                  onChange={(values) => setFilterLegalEntities(values)}
                />
              </Grid>
              {!loading && (
                <Grid item lg={2} md={2} container alignContent="flex-end">
                  <Typography className={classes.totalInfo} variant="h6">
                    Общо: {totalIncludingVat.toFixed(2)} лв.
                  </Typography>
                </Grid>
              )}
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <AMSAutocomplete
                  multiple
                  minChar={0}
                  limitTags={5}
                  options={companies.map((c) => ({
                    id: c.id,
                    value: `${getExtendedCompanyId(c.id)} ${c.note}`,
                    group: c.name
                  }))}
                  disableCloseOnSelect
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes.checkbox}
                        checked={selected}
                        color="primary"
                      />
                      {option.value}
                    </Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Компания"
                      placeholder=""
                      margin="dense"
                      fullWidth
                    />
                  )}
                  value={filterCompanies}
                  onChange={(values) => setFilterCompanies(values)}
                  groupBy={(g) => (g.group ? g.group : '')}
                />
              </Grid>
              <Grid item container lg={4} md={6} sm={12} xs={12} justify="space-between">
                <FormControlLabel
                  className={classes.label}
                  control={
                    <Checkbox
                      checked={filterHideCancelled}
                      onChange={() => setFilterHideCancelled(!filterHideCancelled)}
                    />
                  }
                  label="Скрий анулираните"
                  labelPlacement="end"
                />
                <FormControlLabel
                  className={classes.label}
                  control={
                    <Checkbox
                      checked={filterHidePaidInFull}
                      onChange={() => setFilterHidePaidInFull(!filterHidePaidInFull)}
                    />
                  }
                  label="Скрий платените"
                  labelPlacement="end"
                />
                <AMSButton
                  color="primary"
                  variant="contained"
                  text="Търсене"
                  loading={loading}
                  disabled={false}
                  onClick={onSearch}
                  style={{
                    marginTop: 8,
                    marginBottom: 8
                  }}
                />
              </Grid>
              {!loading && (
                <Grid item lg={2} md={2} container alignContent="flex-end">
                  <Typography className={classes.totalInfo} variant="h6">
                    За плащане: {totalRemainingAmount.toFixed(2)} лв.
                  </Typography>
                </Grid>
              )}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <InvoicesTableComponent
                  invoices={invoices}
                  setInvoices={setInvoices}
                  legalEntities={legalEntities}
                  loading={loading}
                />
              </Grid>
            </Grid>
          </Container>
        </div>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default InvoicesComponent;
