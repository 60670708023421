import { CircularProgress, Container, Paper } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AMSTree from '../../helpers/ui/AMSTable/AMSTree';
import Category, { CategoryForm } from '../../models/category';
import { AccessPermissions } from '../../services/accessManagementService';
import { accessManagementService, companyCategoriesService } from '../../services/services';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 0
  },
  paper: {
    padding: 20,
    minHeight: '85vh'
  }
}));

const CompanyCategories = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [open, setOpen] = useState<any>({ '-1': true });

  const loadCompanyCategories = useCallback(async () => {
    const resp = await companyCategoriesService.getCompanyCategories();
    setData(resp ? resp.data : []);
    setLoading(false);
  }, []);

  useEffect(() => {
    loadCompanyCategories();
  }, [loadCompanyCategories]);

  const canCreate = useMemo(
    () => accessManagementService.hasPermission(AccessPermissions.CAN_CREATE_COMPANY_CATEGORY),
    []
  );

  const canRead = useMemo(
    () => accessManagementService.hasPermission(AccessPermissions.CAN_READ_COMPANY_CATEGORY),
    []
  );

  const canUpdate = useMemo(
    () => accessManagementService.hasPermission(AccessPermissions.CAN_UPDATE_COMPANY_CATEGORY),
    []
  );

  const canDelete = useMemo(
    () => accessManagementService.hasPermission(AccessPermissions.CAN_DELETE_COMPANY_CATEGORY),
    []
  );

  const onAdd = async (category: CategoryForm) => {
    if (category && category.name) {
      await companyCategoriesService.createCompanyCategory(category);
      await loadCompanyCategories();
      setOpenDialog(false);
    }
  };

  const onEdit = async (category: Category) => {
    await companyCategoriesService.updateCompanyCategory(category);
    await loadCompanyCategories();
    setOpenDialog(false);
  };

  const onDelete = async (category: Category) => {
    if (category.id) {
      await companyCategoriesService.deleteCompanyCategory(category.id);
      await loadCompanyCategories();
    }
  };

  return loading ? (
    <CircularProgress />
  ) : (
    <Container maxWidth="sm" className={classes.container}>
      <Paper elevation={2} className={classes.paper}>
        <AMSTree
          size="sm"
          data={data}
          open={open}
          setOpen={setOpen}
          marked={-1}
          onAdd={canCreate ? onAdd : undefined}
          onEdit={canRead && canUpdate ? onEdit : undefined}
          onDelete={canDelete ? onDelete : undefined}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
        />
      </Paper>
    </Container>
  );
};

export default CompanyCategories;
