import { Divider, IconButton, Typography } from '@material-ui/core';
import { MenuItem, deprecatedMenuItems, mainMenuItems, secondaryMenuItems } from './listItems';
import React, { useState } from 'react';

import { AccessPermissions } from '../../../services/accessManagementService';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuItemContainer from './MenuItemContainer/MenuItemContainer';
import { accessManagementService } from '../../../services/services';
import { useHistory } from 'react-router-dom';
import { useObservable } from '../../../helpers/hooks';

interface MenuContainerProps {
  open: boolean;
  toolbarIcon: string;
  handleDrawerToggle: () => void;
}

const MenuContainer = (props: MenuContainerProps) => {
  const { open, toolbarIcon, handleDrawerToggle } = props;
  const history = useHistory();
  const [selected, setSelected] = useState(history.location.pathname);
  history.listen((location) => {
    setSelected(location.pathname);
  });
  const permissions: AccessPermissions[] = useObservable(
    accessManagementService.permissions
  ).permissions;

  return (
    <>
      {mainMenuItems
        .filter((menuItem: MenuItem) =>
          menuItem.permissions.some((permission) => permissions.includes(permission))
        )
        .map((menuItem: MenuItem) => (
          <MenuItemContainer
            key={menuItem.route}
            menuItem={menuItem}
            selected={selected}
            setSelected={setSelected}
            permissions={permissions}
          />
        ))}
      <Divider />
      {secondaryMenuItems
        .filter((menuItem: MenuItem) =>
          menuItem.permissions.some((permission) => permissions.includes(permission))
        )
        .map((menuItem: MenuItem) => (
          <MenuItemContainer
            key={menuItem.route}
            menuItem={menuItem}
            selected={selected}
            setSelected={setSelected}
            permissions={permissions}
          />
        ))}
      {deprecatedMenuItems.length > 0 && <Divider />}
      {deprecatedMenuItems.length > 0 && (
        <Typography variant="h6" style={{ marginLeft: 1, color: '#717171' }}>
          Стари
        </Typography>
      )}
      {deprecatedMenuItems
        .filter((menuItem: MenuItem) =>
          menuItem.permissions.some((permission) => permissions.includes(permission))
        )
        .map((menuItem: MenuItem) => (
          <MenuItemContainer
            key={menuItem.route}
            menuItem={menuItem}
            selected={selected}
            setSelected={setSelected}
            permissions={permissions}
          />
        ))}
      <Divider />
      <div className={toolbarIcon}>
        <IconButton onClick={handleDrawerToggle}>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
    </>
  );
};

export default MenuContainer;
