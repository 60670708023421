import * as Sentry from '@sentry/react';

import React, { useEffect, useState } from 'react';

import AMSErrorDialog from '../../ui/AMSErrorDialog/AMSErrorDialog';
import { AxiosInstance } from 'axios';
import { Box } from '@material-ui/core';
import { QRCodeCanvas } from 'qrcode.react';
import { accessManagementService } from '../../../services/services';
import { backendApiUrl } from '../../openapi-axios-helper';
import firebase from 'firebase/app';
import { useFlag } from '../../hooks';

const defaultTitle = 'Възникна грешка при изпълнение на заявка';

const withErrorHandler = (WrappedComponent: any, axios: AxiosInstance) => {
  return (props: any) => {
    const [error, setError] = useState(null);
    const [title, setTitle] = useState(defaultTitle);
    const [content, setContent] = useState<any>();
    const [showErrorDialog, setShowErrorDialog] = useFlag(true);

    const cleanupError = (error: any) => ({
      message: error.message,
      name: error.name,
      response: {
        status: error.response?.status,
        statusText: error.response?.statusText,
        data: {
          details: error.response?.data?.detail
        }
      },
      config: {
        ...error.config,
        url: error.config.url?.replace(backendApiUrl, ''),
        headers: { ...error.config.headers, Authorization: '<hidden>' }
      }
    });

    useEffect(() => {
      axios.interceptors.request.use((req) => {
        setError(null);
        return req;
      });
      axios.interceptors.response.use(
        (res) => res,
        (error) => {
          Sentry.captureException(error);
          let showErrorDialog = true;
          let supportTitle = defaultTitle;
          const simplifiedError = cleanupError(error);
          setError(error);
          switch (error.response?.status) {
            case 401:
              showErrorDialog = false;
              accessManagementService.logout();
              firebase.auth().signOut();
              break;
            case 403:
              showErrorDialog = false;
              break;
            case 404:
              supportTitle = `Заявеният ресурс е недостъпен или несъществуващ`;
              break;
            case 409:
            case 422:
              supportTitle = `Грешка при валидацията на заявката към сървъра`;
              break;
            case undefined:
              supportTitle = 'Няма връзка със сървъра';
              showErrorDialog = false;
              break;
            default:
          }
          console.log(
            `%c${supportTitle}\n\n%c${JSON.stringify(simplifiedError)}`,
            'color: #ff4d53; font-size: 18px',
            'color: #ff4d53; font-size: 14px'
          );
          setTitle(supportTitle);
          if (error.response?.status !== 422) {
            setContent(
              <QRCodeCanvas
                value={JSON.stringify(simplifiedError)}
                style={{ width: '100%', height: 'auto', padding: 16 }}
              />
            );
          } else {
            setContent(
              <Box style={{ display: 'flex', flexDirection: 'column' }}>
                <Box style={{ fontWeight: 'bold' }}>{simplifiedError.message}</Box>
                {simplifiedError.response?.data?.details?.map((d: any, index: number) => (
                  <Box style={{ fontStyle: 'italic' }}>
                    {index + 1}: {[d.loc?.join('.') ?? '', d.msg, JSON.stringify(d.ctx)].join(', ')}
                  </Box>
                ))}
              </Box>
            );
          }
          setShowErrorDialog(showErrorDialog);
          return error;
        }
      );
    }, [title, setShowErrorDialog]);

    return (
      <div>
        <AMSErrorDialog
          open={showErrorDialog && !!error}
          title={title}
          content={content}
          onClose={() => setError(null)}
        />
        <WrappedComponent {...props} />
      </div>
    );
  };
};

export default withErrorHandler;
