import { AMSButton, AMSSnackBar, AMSTextField } from '../../../../../helpers/ui';
import React, { useMemo, useState } from 'react';
import { partnersService, snackbarService } from '../../../../../services/services';
import { useFlag, usePermissions } from '../../../../../helpers/hooks';

import { AccessPermissions } from '../../../../../services/accessManagementService';
import { Grid } from '@material-ui/core';
import OutlinedDiv from '../../../../../helpers/ui/OutlinedDiv';
import { SetICashConfigurationRequest } from '../../../../../services/api-v3';

interface ICASHIntegrationFormProps {
  partnerId: number;
}

const ICASHIntegrationForm = ({ partnerId }: ICASHIntegrationFormProps) => {
  const [endpointUrl, setEndpointUrl] = useState<string>('');
  const [apiKey, setApiKey] = useState<string>('');
  const [warehouseId, setWarehouseId] = useState<string>('');
  const [userId, serUserId] = useState<string>('');
  const [secretKey, setSecretKey] = useState<string>('');
  const [storeId, setStoreId] = useState<string>('');
  const [contragentId, setContragentId] = useState<string>('');
  const [payTypeId, setPayTypeId] = useState<string>('');
  const [actionLoading, setActionLoading] = useFlag(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const [canCreate, canUpdate] = usePermissions([
    AccessPermissions.CAN_CREATE_PARTNER,
    AccessPermissions.CAN_UPDATE_PARTNER
  ]);

  const handleOnClick = async () => {
    setActionLoading(true);
    const configurationRequest: SetICashConfigurationRequest = {
      requestType: 'set-icash-configuration',
      configuration: {
        endpointUrl: endpointUrl === '' ? 'https://v5.icash.bg/clientapi/v1/' : endpointUrl,
        apiKey,
        secretKey,
        warehouseId: +warehouseId,
        userId: +userId,
        storeId: +storeId,
        contragentId: +contragentId,
        payTypeId: +payTypeId
      }
    };
    const resp = await partnersService.setPartnerIntegrationConfiguration(
      partnerId,
      configurationRequest
    );
    if (resp) {
      snackbarService.setSnackbarOpen(true);
    }
    setActionLoading(false);
  };

  const validUserId = useMemo(() => userId && userId.length > 0 && !isNaN(+userId), [userId]);
  const validWarehouseId = useMemo(
    () => warehouseId && warehouseId.length > 0 && !isNaN(+warehouseId),
    [warehouseId]
  );
  const validStoreId = useMemo(() => storeId && storeId.length > 0 && !isNaN(+storeId), [storeId]);
  const validContragentId = useMemo(
    () => contragentId && contragentId.length > 0 && !isNaN(+contragentId),
    [contragentId]
  );
  const validPayTypeId = useMemo(
    () => payTypeId && payTypeId.length > 0 && !isNaN(+payTypeId),
    [payTypeId]
  );
  const isValidICashIntegration = () =>
    apiKey &&
    userId &&
    validUserId &&
    warehouseId &&
    validWarehouseId &&
    validStoreId &&
    secretKey &&
    contragentId &&
    validContragentId &&
    payTypeId &&
    validPayTypeId;

  const shouldBeDisabled = useMemo(
    () => (partnerId > 0 ? !canUpdate : !canCreate),
    [partnerId, canCreate, canUpdate]
  );

  return (
    <Grid container spacing={1}>
      <Grid item lg={12}>
        <OutlinedDiv label={`Данни за интеграция с I-Cash`} disabled={shouldBeDisabled}>
          <Grid container spacing={1}>
            <Grid item lg={12} md={6}>
              <AMSTextField
                label="URL"
                disabled={shouldBeDisabled}
                value={endpointUrl}
                onChange={(value) => setEndpointUrl(value ?? '')}
                placeholder="https://v5.icash.bg/clientapi/v1/"
              />
            </Grid>
            <Grid item lg={6} md={6}>
              <AMSTextField
                label="API ключ"
                disabled={shouldBeDisabled}
                value={apiKey}
                onChange={(value) => setApiKey(value ?? '')}
                required
              />
            </Grid>
            <Grid item lg={6} md={6}>
              <AMSTextField
                label="Секретен ключ"
                disabled={shouldBeDisabled}
                value={secretKey}
                onChange={(value) => setSecretKey(value ?? '')}
                required
              />
            </Grid>
            <Grid item lg={6} md={6}>
              <AMSTextField
                label="Потребител ID"
                disabled={shouldBeDisabled}
                value={userId}
                onChange={(value) => serUserId(value ?? '')}
                error={isNaN(+userId)}
                helperText={isNaN(+userId) ? 'Въведете число' : undefined}
                required
              />
            </Grid>
            <Grid item lg={6} md={6}>
              <AMSTextField
                label="Обект ID"
                disabled={shouldBeDisabled}
                value={storeId}
                onChange={(value) => setStoreId(value ?? '')}
                error={isNaN(+storeId)}
                helperText={isNaN(+storeId) ? 'Въведете число' : undefined}
                required
              />
            </Grid>
            <Grid item lg={4} md={6}>
              <AMSTextField
                label="Контрагент ID"
                disabled={shouldBeDisabled}
                value={contragentId}
                onChange={(value) => setContragentId(value ?? '')}
                error={isNaN(+contragentId)}
                helperText={isNaN(+contragentId) ? 'Въведете число' : undefined}
                required
              />
            </Grid>
            <Grid item lg={4} md={6}>
              <AMSTextField
                label="Плащане ID"
                disabled={shouldBeDisabled}
                value={payTypeId}
                onChange={(value) => setPayTypeId(value ?? '')}
                error={isNaN(+payTypeId)}
                helperText={isNaN(+payTypeId) ? 'Въведете число' : undefined}
                required
              />
            </Grid>
            <Grid item lg={4} md={6}>
              <AMSTextField
                label="Склад ID"
                disabled={shouldBeDisabled}
                value={warehouseId}
                onChange={(value) => setWarehouseId(value ?? '')}
                error={isNaN(+warehouseId)}
                helperText={isNaN(+warehouseId) ? 'Въведете число' : undefined}
                required
              />
            </Grid>
          </Grid>
        </OutlinedDiv>
      </Grid>
      <Grid item lg={6} container justify="center">
        <AMSButton
          text="Тест"
          variant="outlined"
          color="primary"
          onClick={() => {}}
          disabled={true}
        />
      </Grid>
      <Grid item lg={6} container justify="center">
        <AMSButton
          text="Запиши"
          variant="contained"
          color="primary"
          onClick={handleOnClick}
          disabled={actionLoading || !isValidICashIntegration()}
          loading={actionLoading}
        />
      </Grid>
      <AMSSnackBar
        open={openSnackBar}
        message={'Промените са записани успешно'}
        onClose={() => setOpenSnackBar(false)}
      />
    </Grid>
  );
};

export default ICASHIntegrationForm;
