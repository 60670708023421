import { Checkbox, FormControlLabel, Grid, Paper, TextField } from '@material-ui/core';
import {
  CreateDeliveryItemRequest,
  CreateDeliveryRequest,
  DeliveryResponse
} from '../../../../services/api';
import Lookup, { supplierToLookup, warehouseToLookup } from '../../../../models/lookup';
import React, { useEffect, useState } from 'react';
import {
  accessManagementService,
  deliveriesService,
  snackbarService
} from '../../../../services/services';
import { useFlag, useObservable, useSuppliers, useWarehouses } from '../../../../helpers/hooks';

import AMSAutocomplete from '../../../../helpers/ui/AMSAutocomplete/AMSAutocomplete';
import AMSButton from '../../../../helpers/ui/AMSButton/AMSButton';
import DateFnsUtils from '@date-io/date-fns';
import DeliveryItemsPanel from '../DeliveryItemsPanel/DeliveryItemsPanel';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import OutlinedDiv from '../../../../helpers/ui/OutlinedDiv';
import { UserAccessResponse } from '../../../../services/api-v3';
import { getExtendedDeliveryId } from '../../../../helpers/utils';
import { useHistory } from 'react-router-dom';
import { useStyles } from '../../../../helpers/hooks';

interface DeliveryInfoPanelProps {
  delivery: DeliveryResponse | null;
  supplier: Lookup | null;
  setSupplier: (supplier: Lookup | null) => void;
  warehouse: Lookup | null;
  setWarehouse: (warehouse: Lookup | null) => void;
  totalPrice: number;
  setTotalPrice: (totalPrice: number) => void;
  note: string;
  setNote: (note: string) => void;
  reload: () => void;
  loading: boolean;
}

const DeliveryInfoPanel = ({
  delivery,
  supplier,
  setSupplier,
  warehouse,
  setWarehouse,
  totalPrice,
  setTotalPrice,
  note,
  setNote,
  reload,
  loading
}: DeliveryInfoPanelProps) => {
  const history = useHistory();
  const classes = useStyles();
  const currentUser: UserAccessResponse | undefined = useObservable(
    accessManagementService.user
  ).user;

  const [actionLoading, setActionLoading] = useFlag(false);
  const { suppliers } = useSuppliers();
  const { warehouses } = useWarehouses();
  const [deliveryItems, setDeliveryItems] = useState<CreateDeliveryItemRequest[]>(
    delivery?.deliveryItems ?? []
  );

  const [addNew, setAddNew] = useFlag(false);

  const hasEmptyFields = () => !supplier || !warehouse;

  const handleDeliverySave = async () => {
    if (warehouse && supplier) {
      setActionLoading(true);
      const createDeliveryRequest: CreateDeliveryRequest = {
        warehouseId: warehouse.id,
        supplierId: supplier.id,
        deliveryItems: deliveryItems
          ? deliveryItems
              .map((stock: any) => ({
                itemId: stock.itemId,
                country: stock.country,
                expirationDate: stock.expirationDate,
                batchNumber: stock.batchNumber,
                quantity: stock.quantity,
                price: stock.price
              }))
              .sort((a: any, b: any) => (a.itemId === b.itemId ? a.price - b.price : 0))
          : [],
        note: note
      };
      const resp = await deliveriesService.createDelivery(createDeliveryRequest);
      setActionLoading(false);
      if (resp) {
        if (addNew) {
          reload();
          setDeliveryItems([]);
        } else {
          history.push(`delivery?id=${resp.data.id}`);
        }
        snackbarService.setSnackbarOpen(true);
      }
    }
  };

  useEffect(() => {
    setTotalPrice(
      deliveryItems.reduce((result: number, item: any) => result + +item.totalIncludingVat, 0)
    );
  }, [deliveryItems, setTotalPrice]);

  return (
    <Paper elevation={2} className={classes.paper}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <OutlinedDiv label="Данни за доставката" disabled={!!delivery} loading={loading}>
          <Grid container spacing={2}>
            <Grid container spacing={1} item sm={12} md={8} lg={8}>
              <Grid item sm={12} md={2} lg={2}>
                <TextField
                  label="Номер"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  value={
                    delivery && delivery.id > 0 ? getExtendedDeliveryId(delivery.id) : 'Нов запис'
                  }
                  disabled={true}
                />
              </Grid>
              <Grid item sm={12} md={2} lg={2}>
                <TextField
                  label="Дата"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  value={
                    delivery?.deliveryDate ? delivery.deliveryDate.substring(0, 10) : 'Нова дата'
                  }
                  disabled={true}
                />
              </Grid>
              <Grid item sm={12} md={8} lg={8}>
                <AMSAutocomplete
                  label="Склад"
                  options={warehouses.map(warehouseToLookup)}
                  value={warehouse ? warehouse : null}
                  onChange={setWarehouse}
                  disabled={!!delivery || deliveryItems.length > 0}
                  minChar={0}
                  required
                />
              </Grid>
              <Grid item sm={12} md={4} lg={4}>
                <TextField
                  label="Създадена от"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  value={
                    delivery?.createdByName
                      ? delivery?.createdByName
                      : currentUser
                      ? `${currentUser.firstName} ${currentUser.lastName}`
                      : ''
                  }
                  disabled={true}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <AMSAutocomplete
                  label="Доставчик"
                  options={suppliers.map(supplierToLookup)}
                  value={supplier ? supplier : null}
                  onChange={setSupplier}
                  disabled={!!delivery || deliveryItems.length > 0}
                  minChar={0}
                  required
                />
              </Grid>
              <Grid item sm={12} md={2} lg={2}>
                <TextField
                  label="Обща сума"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  value={totalPrice ? totalPrice.toFixed(2) : '0.00'}
                  inputProps={{
                    min: 0,
                    style: { textAlign: 'right', color: '#3D3D3D' }
                  }}
                  disabled={true}
                />
              </Grid>
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <Grid style={{ height: '100%' }}>
                <TextField
                  label="Забележка"
                  margin="dense"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                  value={note}
                  onChange={(event: any) => {
                    if (event && event.target) {
                      setNote(event.target.value);
                    }
                  }}
                  disabled={!!delivery}
                />
              </Grid>
            </Grid>
          </Grid>
        </OutlinedDiv>
        <Grid container spacing={2}>
          <Grid item sm={12} lg={12}>
            <DeliveryItemsPanel
              delivery={delivery}
              supplier={supplier}
              warehouse={warehouse}
              items={
                delivery && delivery.id > 0
                  ? delivery.deliveryItems
                    ? delivery.deliveryItems
                    : []
                  : deliveryItems
              }
              setItems={
                delivery && delivery.id > 0
                  ? () => {}
                  : (items: any[]) => {
                      setDeliveryItems(items);
                    }
              }
              loading={loading}
            />
          </Grid>
          {!delivery && (
            <Grid container item sm={12} lg={12} justify="flex-end">
              <Grid item sm={10} lg={10} container justify="flex-end">
                <div className={classes.addNewCheckboxWrapper}>
                  <FormControlLabel
                    className={classes.label}
                    control={<Checkbox checked={addNew} onChange={() => setAddNew(!addNew)} />}
                    label="Добави нова след запис"
                    labelPlacement="end"
                  />
                </div>
              </Grid>
              <Grid item sm={2} lg={2} container justify="flex-end">
                <AMSButton
                  variant="contained"
                  color="primary"
                  disabled={actionLoading || hasEmptyFields()}
                  className={classes.saveButton}
                  onClick={handleDeliverySave}
                  text={addNew ? 'Запиши и добави нова' : 'Запиши'}
                  loading={actionLoading}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </MuiPickersUtilsProvider>
    </Paper>
  );
};

export default DeliveryInfoPanel;
