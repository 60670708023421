import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { OrderStatusLogResponse } from '../../services/api';
import { ordersService } from '../../services/services';

export interface IOrderStatusLogs<T> extends IAsyncType<T> {
    orderStatusLogs: T;
    setOrderStatusLogs: Dispatch<SetStateAction<T>>;
}

const useOrderStatusLogs = (
    id: number,
): IOrderStatusLogs<OrderStatusLogResponse[]> => {
    const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
    OrderStatusLogResponse[]
    >(ordersService.getOrderStatusLogs, [], [id]);
    return {
        orderStatusLogs: data,
        setOrderStatusLogs: setData,
        loading,
        setLoading,
        parameters,
        setParameters
    };
};

export default useOrderStatusLogs;
