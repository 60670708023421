import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { UnitEnum, unitEnumLocalizations } from '../../../services/AMSV3Service';

import AMSAsyncAutocomplete from '../../../helpers/ui/AMSAsyncAutocomplete/AMSAsyncAutocomplete';
import AMSViewOnlyTextField from '../../../helpers/ui/AMSViewOnlyTextField/AMSViewOnlyTextField';
import { ItemResponse } from '../../../services/api-v3';
import { s3ImagesUrl } from '../../../helpers/openapi-axios-helper';
import { useStyles } from '../../../helpers/hooks';

export interface AddDeliveryRequestItemDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (request: any, addNew: boolean) => void;
  supplierIds?: number[];
}

const AddDeliveryRequestItemDialog = ({
  open,
  onClose,
  onSave,
  supplierIds
}: AddDeliveryRequestItemDialogProps) => {
  const classes = useStyles();

  const [item, setItem] = useState<ItemResponse | null>(null);
  const [quantity, setQuantity] = useState<string>('1');

  const [addNew, setAddNew] = useState<boolean>(false);

  useEffect(() => {
    setItem(null);
    setQuantity('1');
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
    >
      <DialogTitle>
        <div className={classes.title}>Добавяне на артикул</div>
      </DialogTitle>
      <DialogContent dividers={true}>
        <div className={classes.imageWrapper}>
          {!!item && (
            <img
              src={`${s3ImagesUrl}/items/${item.artNo}.png`}
              onError={(ev: any) => (ev.target.src = require('../../../images/fallback.png'))}
              alt="-"
              className={classes.image}
            />
          )}
          {!item && (
            <img src={require('../../../images/fallback.png')} alt="-" className={classes.image} />
          )}
        </div>
        <Grid container spacing={1}>
          <Grid item lg={12}>
            <AMSAsyncAutocomplete
              label="Търсене"
              onChange={(value: ItemResponse | null) => {
                setItem(value);
              }}
              value={item}
              minChar={3}
              supplierIds={supplierIds}
            />
          </Grid>
          <Grid item lg={9}>
            <AMSViewOnlyTextField label="Име" value={item ? item.name : ''} />
          </Grid>
          <Grid item lg={3}>
            <TextField
              label="Брой"
              margin="dense"
              variant="outlined"
              fullWidth
              value={quantity}
              type="number"
              onChange={(event) => setQuantity(event.target.value)}
            />
          </Grid>
          <Grid item lg={6}>
            <AMSViewOnlyTextField
              label="Артикулен номер"
              value={item && item.artNo ? item.artNo : ''}
            />
          </Grid>
          <Grid item lg={6}>
            <AMSViewOnlyTextField
              label="Баркод"
              value={item && item.barcodeValue ? item.barcodeValue : ''}
            />
          </Grid>
          <Grid item lg={6}>
            <AMSViewOnlyTextField
              label="Мерна единица"
              value={
                item
                  ? `${unitEnumLocalizations[item.unitId as UnitEnum]}${
                      item.packageQty && item.packageQty > 1 ? ` от ${item.packageQty}` : ''
                    }`
                  : ''
              }
            />
          </Grid>
          <Grid item lg={3}>
            <AMSViewOnlyTextField
              label="Единична цена (без ДДС)"
              value={item ? item.lastKnownPrice : '0.00'}
            />
          </Grid>
          <Grid item lg={3}>
            <AMSViewOnlyTextField
              label="Обща цена (без ДДС)"
              value={item ? (+item.lastKnownPrice * +quantity).toFixed(2) : '0.00'}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.addNewCheckboxWrapper}>
          <FormControlLabel
            className={classes.label}
            control={<Checkbox checked={addNew} onChange={() => setAddNew(!addNew)} />}
            label="Добави нов след запис"
            labelPlacement="end"
          />
        </div>
        <Button onClick={onClose} color="secondary" variant="contained">
          Отказ
        </Button>
        <Button
          onClick={() => {
            if (item) {
              onSave(
                {
                  ...item,
                  itemId: item.id,
                  quantity: +quantity,
                  price: item.lastKnownPrice,
                  totalPrice: +item.lastKnownPrice * +quantity,
                  collected: 0,
                  unitName: unitEnumLocalizations[item.unitId as UnitEnum]
                },
                addNew
              );
              if (addNew) {
                setItem(null);
                setQuantity('1');
              }
            }
          }}
          color="primary"
          variant="contained"
        >
          Добре
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDeliveryRequestItemDialog;
