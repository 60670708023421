import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  createStyles,
  makeStyles
} from '@material-ui/core';
import { CompanyCategoryResponse, PricingPolicyResponse } from '../../../../services/api';
import { LegalEntityListResponse, WarehouseListResponse } from '../../../../services/api-v3';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  accessManagementService,
  amsV3Service,
  companyCategoriesService,
  snackbarService
} from '../../../../services/services';
import {
  companyCategoryToLookup,
  legalEntityToLookup,
  pricingPolicyToLookup,
  warehouseToLookup
} from '../../../../models/lookup';
import { flattenTree, getExtendedPartnerId, isValidEmail } from '../../../../helpers/utils';
import {
  useLegalEntities,
  useLookup,
  usePricingPolicies,
  useWarehouses
} from '../../../../helpers/hooks';

import AMSAutocomplete from '../../../../helpers/ui/AMSAutocomplete/AMSAutocomplete';
import AMSButton from '../../../../helpers/ui/AMSButton/AMSButton';
import AMSCountryAutocomplete from '../../../../helpers/ui/AMSCountryAutocomplete/AMSCountryAutocomplete';
import AMSViewOnlyTextField from '../../../../helpers/ui/AMSViewOnlyTextField/AMSViewOnlyTextField';
import { AccessPermissions } from '../../../../services/accessManagementService';
import Country from '../../../../models/country';
import InputMask from 'react-input-mask';
import OutlinedDiv from '../../../../helpers/ui/OutlinedDiv';
import { findCountry } from '../../../../helpers/country-helper';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    section: {
      padding: 10
    },
    label: {
      paddingTop: 6
    },
    paper: {
      padding: 20
    },
    saveButton: { marginTop: 10 },
    addNewCheckboxWrapper: {
      paddingTop: 8
    }
  })
);

interface PartnerInfoPanelProps {
  partner: any;
  setPartner: (company: any) => void;
  contacts: any[];
  setContacts: (contacts: any[]) => void;
}

const PartnerInfoPanel = ({
  partner,
  setPartner,
  contacts,
  setContacts
}: PartnerInfoPanelProps) => {
  const classes = useStyles();
  const history = useHistory();

  const { pricingPolicies } = usePricingPolicies('');
  const { legalEntities } = useLegalEntities();
  const { warehouses } = useWarehouses();

  const [companyCategories, setCompanyCategories] = useState<CompanyCategoryResponse[]>([]);
  const [country, setCountry] = useState<Country | null>(null);
  const [errors, setErrors] = useState<any>({ email1: false, email2: false, email3: false });
  const [warehouse, setWarehouse] = useLookup(null);
  const [companyCategory, setCompanyCategory] = useLookup(null);
  const [pricingPolicy, setPricingPolicy] = useLookup(null);
  const [legalEntityLookup, setLegalEntityLookup] = useLookup(null);
  const [legalEntity, setLegalEntity] = useState<LegalEntityListResponse | null>(null);

  const [addNew, setAddNew] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  useEffect(() => {
    if (warehouses?.length > 0) {
      const wh = warehouses.find(
        (w: WarehouseListResponse) => w.id === partner.preferredWarehouseId
      );
      if (wh) {
        setWarehouse(warehouseToLookup(wh));
      } else {
        setWarehouse(null);
      }
    }
  }, [warehouses, partner.preferredWarehouseId, setWarehouse]);

  useEffect(() => {
    const loadData = async () => {
      const companyCategoriesResp = await companyCategoriesService.getCompanyCategory(1);
      setCompanyCategories(
        flattenTree(companyCategoriesResp ? [companyCategoriesResp.data] : []).sort(
          (cc1: CompanyCategoryResponse, cc2: CompanyCategoryResponse) => cc2.id - cc1.id
        )
      );
    };
    loadData();
  }, []);

  useEffect(() => {
    if (companyCategories?.length > 0) {
      const cc = companyCategories.find(
        (c: CompanyCategoryResponse) => c.id === partner.companyCategoryId
      );
      if (cc) {
        setCompanyCategory(companyCategoryToLookup(cc));
      } else {
        setCompanyCategory(null);
      }
    }
  }, [companyCategories, partner.companyCategoryId, setCompanyCategory]);

  useEffect(() => {
    if (pricingPolicies?.length > 0) {
      const pp = pricingPolicies.find(
        (p: PricingPolicyResponse) => p.id === partner.pricingPolicyId
      );
      if (pp) {
        setPricingPolicy(pricingPolicyToLookup(pp));
      } else {
        setPricingPolicy(null);
      }
    }
  }, [partner.pricingPolicyId, pricingPolicies, setPricingPolicy]);

  useEffect(() => {
    if (legalEntities?.length > 0) {
      const le = legalEntities.find(
        (le: LegalEntityListResponse) =>
          le.id === legalEntityLookup?.id || le.id === partner.legalEntityId
      );
      if (le) {
        setLegalEntity(le);
      } else {
        setLegalEntity(null);
      }
    }
  }, [legalEntities, legalEntityLookup, partner.legalEntityId]);

  useEffect(() => {
    if (legalEntities?.length > 0) {
      const le = legalEntities.find(
        (le: LegalEntityListResponse) => le.id === partner.legalEntityId
      );
      if (le) {
        setLegalEntityLookup(legalEntityToLookup(le));
      } else {
        setLegalEntityLookup(null);
      }
    }
  }, [legalEntities, partner.legalEntityId, setLegalEntityLookup]);

  useEffect(() => {
    if (partner?.country) {
      const country = findCountry(partner.country);
      if (country) {
        setCountry(country);
      }
    } else {
      setCountry(null);
    }
  }, [partner]);

  const canCreate = useMemo(
    () => accessManagementService.hasPermission(AccessPermissions.CAN_CREATE_PARTNER),
    []
  );

  const canUpdate = useMemo(
    () => accessManagementService.hasPermission(AccessPermissions.CAN_UPDATE_PARTNER),
    []
  );

  const shouldBeDisabled = useMemo(
    () => (partner?.id > 0 ? !canUpdate : !canCreate),
    [partner, canCreate, canUpdate]
  );

  const isValidContact = useCallback((contact: any) => {
    return (
      contact &&
      (contact.email ? isValidEmail(contact.email) && contact.name : true) &&
      (contact.phone ? contact.phone.replace(/\s/g, '') && contact.name : true)
    );
  }, []);

  const areContactsValid = useCallback(
    () => isValidContact(contacts[0]) && isValidContact(contacts[1]) && isValidContact(contacts[2]),
    [contacts, isValidContact]
  );

  const isValidPartner = useMemo(
    () =>
      partner?.name &&
      partner?.country &&
      partner?.city &&
      partner?.addressLine &&
      warehouse &&
      companyCategory &&
      pricingPolicy &&
      legalEntityLookup &&
      areContactsValid(),
    [partner, warehouse, companyCategory, pricingPolicy, legalEntityLookup, areContactsValid]
  );

  const reload = useCallback(() => {
    setPartner({
      isActive: true,
      collectOnDeliveryDate: true,
      hidePrices: false,
      moveOrdersToDeliveredAfterShipped: false,
      maintainStock: false
    });
    setContacts([{}, {}, {}]);
    setWarehouse(null);
    setCompanyCategory(null);
    setPricingPolicy(null);
    setLegalEntityLookup(null);
    setLegalEntity(null);
  }, [
    setPartner,
    setContacts,
    setWarehouse,
    setCompanyCategory,
    setPricingPolicy,
    setLegalEntity,
    setLegalEntityLookup
  ]);

  const handleOnClick = useCallback(async () => {
    setActionLoading(true);
    if (isValidPartner) {
      const partnerRequest = {
        ...partner,
        preferredWarehouseId: warehouse?.id ?? null,
        companyCategoryId: companyCategory?.id ?? null,
        pricingPolicyId: pricingPolicy?.id ?? null,
        legalEntityId: legalEntityLookup?.id ?? null,
        contacts: contacts.filter((c: any) => c.name)
      };
      if (partner.id) {
        const resp = await amsV3Service.updatePartner(partner.id, partnerRequest);
        if (resp) {
          setPartner({
            ...partner,
            preferredWarehouseId: warehouse?.id,
            companyCategoryId: companyCategory?.id,
            pricingPolicyId: pricingPolicy?.id,
            legalEntityId: legalEntityLookup?.id
          });
          snackbarService.setSnackbarOpen(true);
        }
      } else {
        const resp = await amsV3Service.createPartner(partnerRequest);
        if (resp) {
          if (addNew) {
            reload();
            history.push('/partner');
          } else {
            history.push(`/partner?id=${resp.data.id}&tabId=0`);
          }
          snackbarService.setSnackbarOpen(true);
        }
      }
    }
    setActionLoading(false);
  }, [
    history,
    isValidPartner,
    partner,
    warehouse,
    companyCategory,
    pricingPolicy,
    legalEntityLookup,
    contacts,
    setPartner,
    reload,
    addNew
  ]);

  const contactEntity = useCallback(
    (index: number) => (
      <OutlinedDiv label={`Контакт ${index + 1}`} disabled={shouldBeDisabled || !partner.isActive}>
        <Grid container spacing={1}>
          <Grid item lg={6}>
            <TextField
              label="Име"
              variant="outlined"
              fullWidth
              margin="dense"
              required={!!contacts[index].email || !!contacts[index].phone}
              value={contacts[index].name ? contacts[index].name : ''}
              onChange={(event) => {
                const currentContacts = [...contacts];
                currentContacts[index].name = event?.target.value;
                setContacts([...currentContacts]);
                setPartner({ ...partner, contacts: currentContacts });
              }}
              disabled={shouldBeDisabled || !partner.isActive}
            />
          </Grid>
          <Grid item lg={6}>
            <InputMask
              mask="+35\9 999 999 999"
              value={contacts[index].phone ? contacts[index].phone : ''}
              disabled={shouldBeDisabled || !partner.isActive}
              onChange={(event) => {
                const currentContacts = [...contacts];
                if (event?.target.value && event?.target.value.length > 0) {
                  currentContacts[index].phone = event?.target.value;
                } else {
                  currentContacts[index].phone = null;
                }
                setContacts([...currentContacts]);
                setPartner({ ...partner, contacts: currentContacts });
              }}
            >
              {() => (
                <TextField
                  label="Телефон"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  type="text"
                  disabled={shouldBeDisabled || !partner.isActive}
                />
              )}
            </InputMask>
          </Grid>
          <Grid item lg={12}>
            <TextField
              error={errors[`contactEmail${index}`]}
              label="Е-Поща"
              variant="outlined"
              fullWidth
              margin="dense"
              disabled={shouldBeDisabled || !partner.isActive}
              value={contacts[index].email ? contacts[index].email : ''}
              onChange={(event) => {
                const currentContacts = [...contacts];
                currentContacts[index].email = event?.target.value;
                setContacts([...currentContacts]);
                setPartner({ ...partner, contacts: currentContacts });
              }}
              onBlur={(event: any) => {
                const errs = { ...errors };
                const val = event?.target.value;
                if (val && !!val.length) {
                  errs[`contactEmail${index}`] = !new RegExp(
                    /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
                  ).test(val);
                } else {
                  errs[`contactEmail${index}`] = false;
                }
                setErrors(errs);
              }}
              helperText={errors[`contactEmail${index}`] ? 'Невалиден формат на Е-Поща' : ''}
            />
          </Grid>
        </Grid>
      </OutlinedDiv>
    ),
    [contacts, errors, partner, setContacts, setPartner, shouldBeDisabled]
  );

  return (
    <Paper elevation={2} className={classes.paper}>
      <Grid container spacing={1}>
        <Grid item lg={4}>
          <Grid container spacing={1}>
            <Grid item lg={12}>
              <OutlinedDiv
                label={`Данни за юридическото лице`}
                disabled={shouldBeDisabled || !partner.isActive}
              >
                <Grid container spacing={1}>
                  <Grid item lg={12}>
                    <AMSAutocomplete
                      label="Юридическо лице"
                      options={legalEntities.map(legalEntityToLookup)}
                      value={legalEntityLookup}
                      onChange={(le) => setLegalEntityLookup(le)}
                      disabled={shouldBeDisabled || !partner.isActive}
                      minChar={0}
                      required
                    />
                  </Grid>
                  <Grid item lg={12}>
                    <OutlinedDiv
                      label="Данни за фактура"
                      disabled={shouldBeDisabled || !partner.isActive}
                    >
                      <Grid container spacing={1}>
                        <Grid item lg={6}>
                          <AMSViewOnlyTextField
                            label="ИН"
                            value={(legalEntity && legalEntity.idnumber) ?? ''}
                          />
                        </Grid>
                        <Grid item lg={6}>
                          <AMSViewOnlyTextField
                            label="ДДС №"
                            value={(legalEntity && legalEntity.vat) ?? ''}
                          />
                        </Grid>
                        <Grid item lg={12}>
                          <AMSViewOnlyTextField
                            label="МОЛ"
                            value={(legalEntity && legalEntity.accountable) ?? ''}
                          />
                        </Grid>
                        <Grid item lg={12}>
                          <AMSViewOnlyTextField
                            label="Визуализация във фактурата"
                            value={
                              legalEntity
                                ? `${legalEntity.name || '-'}\nИ№ ${
                                    legalEntity.idnumber || '-'
                                  }, ДДС № ${legalEntity.vat || '-'}, ${
                                    legalEntity.fullAddress || '-'
                                  }\nМОЛ:${legalEntity.accountable || '-'}`
                                : ''
                            }
                            multiline
                            rows={5}
                          />
                        </Grid>
                        <Grid item lg={12}>
                          <AMSViewOnlyTextField
                            label="Адрес за изпращане на фактура"
                            value={(legalEntity && legalEntity.fullAddress) ?? ''}
                            multiline
                            rows={5}
                          />
                        </Grid>
                      </Grid>
                    </OutlinedDiv>
                  </Grid>
                </Grid>
              </OutlinedDiv>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={8}>
          <OutlinedDiv
            label={`Данни за контрагента`}
            disabled={shouldBeDisabled || !partner.isActive}
          >
            <Grid container spacing={1}>
              <Grid item lg={6}>
                <Grid container spacing={1}>
                  <Grid item lg={4} md={4} xs={12}>
                    <FormControlLabel
                      className={classes.label}
                      control={
                        <Checkbox
                          checked={partner.isActive}
                          onChange={() => setPartner({ ...partner, isActive: !partner.isActive })}
                        />
                      }
                      disabled={!partner || !partner.id}
                      label="Активен"
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid item lg={8} md={8} xs={12}>
                    <AMSViewOnlyTextField
                      label="Персонален код"
                      value={partner?.id ? getExtendedPartnerId(partner.id) : ''}
                    />
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item lg={12} md={12} xs={12}>
                      <TextField
                        label="Име"
                        variant="outlined"
                        required
                        fullWidth
                        margin="dense"
                        value={partner?.name ?? ''}
                        onChange={(event) => setPartner({ ...partner, name: event?.target.value })}
                        disabled={shouldBeDisabled || !partner.isActive}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <AMSCountryAutocomplete
                        onChange={(_, value: any) => {
                          if (value) {
                            setPartner({ ...partner, country: value.alpha3 });
                            setCountry(value);
                          }
                        }}
                        country={country}
                        required
                        disabled={shouldBeDisabled || !partner.isActive}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <TextField
                        label="Населено място"
                        variant="outlined"
                        fullWidth
                        required
                        margin="dense"
                        value={partner?.city ?? ''}
                        onChange={(event) => setPartner({ ...partner, city: event?.target.value })}
                        disabled={shouldBeDisabled || !partner.isActive}
                      />
                    </Grid>
                    <Grid item lg={12}>
                      <TextField
                        label="Адрес"
                        variant="outlined"
                        fullWidth
                        required
                        multiline
                        margin="dense"
                        value={partner?.addressLine ?? ''}
                        onChange={(event) =>
                          setPartner({ ...partner, addressLine: event?.target.value })
                        }
                        disabled={shouldBeDisabled || !partner.isActive}
                      />
                    </Grid>
                    <Grid item lg={12}>
                      <AMSAutocomplete
                        label="Склад"
                        options={warehouses.map(warehouseToLookup)}
                        value={warehouse}
                        onChange={(wh) => setWarehouse(wh)}
                        disabled={shouldBeDisabled || !partner.isActive}
                        minChar={0}
                        required
                      />
                    </Grid>
                    <Grid item lg={12}>
                      <AMSAutocomplete
                        label="Категория"
                        options={companyCategories.map(companyCategoryToLookup)}
                        value={companyCategory}
                        onChange={(cc) => setCompanyCategory(cc)}
                        disabled={shouldBeDisabled || !partner.isActive}
                        minChar={0}
                        required
                      />
                    </Grid>
                    <Grid item lg={12}>
                      <AMSAutocomplete
                        label="Ценова политика"
                        options={pricingPolicies.map(pricingPolicyToLookup)}
                        value={pricingPolicy}
                        onChange={(pp) => setPricingPolicy(pp)}
                        disabled={shouldBeDisabled || !partner.isActive}
                        minChar={0}
                        required
                      />
                    </Grid>
                    <Grid item lg={12}>
                      <TextField
                        variant="outlined"
                        label={`Забележка за контрагент`}
                        fullWidth
                        multiline
                        rows={6}
                        margin="dense"
                        disabled={shouldBeDisabled || !partner.isActive}
                        value={partner?.note ?? ''}
                        onChange={(event) => setPartner({ ...partner, note: event?.target.value })}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={6}>
                <Grid container spacing={1}>
                  <Grid item lg={12}>
                    {contactEntity(0)}
                  </Grid>
                  <Grid item lg={12}>
                    {contactEntity(1)}
                  </Grid>
                  <Grid item lg={12}>
                    {contactEntity(2)}
                  </Grid>
                  <Grid item lg={6} container justify="flex-start">
                    <FormControlLabel
                      className={classes.label}
                      control={
                        <Checkbox
                          checked={partner.hidePrices}
                          onChange={() =>
                            setPartner({ ...partner, hidePrices: !partner.hidePrices })
                          }
                          value={false}
                        />
                      }
                      disabled={shouldBeDisabled || !partner.isActive}
                      label="Скриване на цени"
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid item lg={6} container justify="flex-start">
                    <FormControlLabel
                      className={classes.label}
                      control={
                        <Checkbox
                          checked={partner.moveOrdersToDeliveredAfterShipped}
                          onChange={() =>
                            setPartner({
                              ...partner,
                              moveOrdersToDeliveredAfterShipped:
                                !partner.moveOrdersToDeliveredAfterShipped
                            })
                          }
                        />
                      }
                      disabled={shouldBeDisabled || !partner.isActive}
                      label="Без проверка при доставка"
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid item lg={6} container justify="flex-start">
                    <FormControlLabel
                      className={classes.label}
                      control={
                        <Checkbox
                          checked={partner.maintainStock}
                          onChange={() =>
                            setPartner({ ...partner, maintainStock: !partner.maintainStock })
                          }
                        />
                      }
                      disabled={shouldBeDisabled || !partner.isActive}
                      label="Поддръжка на наличности"
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid item lg={6} container justify="flex-start">
                    <FormControlLabel
                      className={classes.label}
                      control={
                        <Checkbox
                          checked={partner.collectOnDeliveryDate}
                          onChange={() =>
                            setPartner({
                              ...partner,
                              collectOnDeliveryDate: !partner.collectOnDeliveryDate
                            })
                          }
                        />
                      }
                      disabled={shouldBeDisabled || !partner.isActive}
                      label="Събиране в деня на доставка"
                      labelPlacement="end"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </OutlinedDiv>
        </Grid>
        <Grid item lg={12}>
          {(canUpdate || canCreate) && (
            <Grid container>
              <Grid item lg={9} container justify="flex-end">
                {canCreate && !(partner?.id > 0) && (
                  <div className={classes.addNewCheckboxWrapper}>
                    <FormControlLabel
                      className={classes.label}
                      control={<Checkbox checked={addNew} onChange={() => setAddNew(!addNew)} />}
                      label="Добави нов след запис"
                      labelPlacement="end"
                    />
                  </div>
                )}
              </Grid>
              {((canCreate && !(partner?.id > 0)) || canUpdate) && (
                <Grid item lg={3} container justify="flex-end">
                  <AMSButton
                    text={addNew ? 'Запиши и добави нов' : 'Запиши'}
                    variant="contained"
                    color="primary"
                    onClick={handleOnClick}
                    disabled={actionLoading || !isValidPartner}
                    loading={actionLoading}
                    className={classes.saveButton}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PartnerInfoPanel;
