import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { DeliveryResponse } from '../../services/api';
import { deliveriesService } from '../../services/services';

export interface IDelivery<T> extends IAsyncType<T> {
  delivery: T;
  setDelivery: Dispatch<SetStateAction<T>>;
}

const useDelivery = (id?: number): IDelivery<DeliveryResponse | null> => {
  const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<DeliveryResponse | null>(
    deliveriesService.getDelivery,
    null,
    id ? [id] : null
  );
  return {
    delivery: data,
    setDelivery: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useDelivery;
