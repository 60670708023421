import { IAsyncType, useAsync } from '.';

import { Dispatch, } from 'react';
import { ReportDepletedAmountWithPrices } from '../../services/api';
import { reportsService } from '../../services/services';

export interface IReportDetailedTurnover<T> extends IAsyncType<T> {
  reportDetailedTurnover: T;
  setReportDetailedTurnover: Dispatch<T>;
  setReportDetailedTurnoverParameters: Dispatch<any[] | null>;
  reportDetailedTurnoverLoading: boolean;
}

const useReportDetailedTurnover = (
  fromDate: string,
  toDate: string,
  partnerIds: number[],
  warehouseIds: number[],
): IReportDetailedTurnover<ReportDepletedAmountWithPrices[]> => {
  const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
    ReportDepletedAmountWithPrices[]
  >(reportsService.getDepletedAmountsWithPrices, [], [fromDate, toDate, partnerIds?.join(","), undefined, warehouseIds?.join(",")]);
  return {
    reportDetailedTurnover: data,
    setReportDetailedTurnover: setData,
    loading,
    reportDetailedTurnoverLoading: loading,
    setLoading,
    parameters,
    setParameters,
    setReportDetailedTurnoverParameters: setParameters
  };
};

export default useReportDetailedTurnover;
