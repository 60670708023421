import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { SupplierReserveSubscriptionResponse } from '../../services/api-v3';
import { amsV3Service } from '../../services/services';

export interface ISupplierReserveSubscription<T> extends IAsyncType<T> {
  supplierReserveSubscription: T;
  setSupplierReserveSubscription: Dispatch<SetStateAction<T>>;
}

const useSupplierReserveSubscription = (
  id?: number
): ISupplierReserveSubscription<SupplierReserveSubscriptionResponse | null> => {
  const { data, setData, loading, setLoading, parameters, setParameters } =
    useAsync<SupplierReserveSubscriptionResponse | null>(
      amsV3Service.getSupplierReserveSubscription,
      null,
      id ? [id] : null
    );

  return {
    supplierReserveSubscription: data,
    setSupplierReserveSubscription: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useSupplierReserveSubscription;