import { AMSApi } from '../helpers/openapi-axios-helper';

export class StockTakingReportService {
  getStockTakingReports = async (
    fromExecutionDate?: string,
    toExecutionDate?: string,
    userId?: number
  ) => {
    const axios = await AMSApi();
    return await axios.listStockTakingReportsV2StockTakingReportsGet(
      fromExecutionDate,
      toExecutionDate,
      userId
    );
  };

  getStockTakingReport = async (id: number) => {
    const axios = await AMSApi();
    return await axios.readStockTakingReportV2StockTakingReportsIdGet(id);
  };

  getStockTakingReportItems = async (itemId: number) => {
    const axios = await AMSApi();
    return await axios.listStockTakingReportItemsV2StockTakingReportItemsGet(itemId);
  };
}
