import { Grid, Tooltip } from '@material-ui/core';
import {
  OrderStatus,
  OrderTypeKeys,
  OrderTypes,
  getOrderStatusByKey,
  getOrderStatusLabelByKey
} from '../../../services/ordersService';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useClients,
  useCompanies,
  usePartners,
  usePermissions,
  useStyles,
  useWarehouses
} from '../../../helpers/hooks';

import AMSLink from '../../../helpers/ui/AMSLink/AMSLink';
import { AMSTable } from '../../../helpers/ui';
import { AccessPermissions } from '../../../services/accessManagementService';
import { OrderListResponse } from '../../../services/api-v3';
import { amsV3Service } from '../../../services/services';
import { getExtendedOrderId } from '../../../helpers/utils';
import { useHistory } from 'react-router-dom';

interface OrdersTableComponentProps {
  title: string;
  orders: any[];
  hideInvoice: boolean;
  hideReceiver?: boolean;
  filterWithExecutionPercentage?: boolean;
  loading: boolean;
}

const OrdersTableComponent = ({
  title,
  orders,
  hideInvoice,
  hideReceiver = false,
  filterWithExecutionPercentage,
  loading
}: OrdersTableComponentProps) => {
  const classes = useStyles();
  const history = useHistory();
  const handleOnAdd = useCallback(() => history.push(`/order`), [history]);

  const { companies } = useCompanies();
  const { warehouses } = useWarehouses();
  const { partners } = usePartners();
  const { clients } = useClients();
  const [ordersExecutionPercentages, setOrderExecutionPercentages] = useState<any>({});

  const partnerNames: any = useMemo(
    () =>
      partners.reduce((res: any, partner) => {
        if (!res) {
          res = {};
        }
        res[`${partner.id}`] = partner.name;
        return res;
      }, {}),
    [partners]
  );

  const clientNames: any = useMemo(
    () =>
      clients.reduce((res: any, client) => {
        if (!res) {
          res = {};
        }
        res[`${client.id}`] = `${client.firstName} ${client.lastName}`;
        return res;
      }, {}),
    [clients]
  );

  const companyNames: any = useMemo(
    () =>
      companies.reduce((res: any, company) => {
        if (!res) {
          res = {};
        }
        res[`${company.id}`] = company.note;
        return res;
      }, {}),
    [companies]
  );

  const warehouseCompanies: any = useMemo(
    () =>
      warehouses.reduce((res: any, warehouse) => {
        if (!res) {
          res = {};
        }
        res[`${warehouse.id}`] = warehouse.companyId;
        return res;
      }, {}),
    [warehouses]
  );

  const warehouseNames: any = useMemo(
    () =>
      warehouses.reduce((res: any, warehouse) => {
        if (!res) {
          res = {};
        }
        res[`${warehouse.id}`] = warehouse.name;
        return res;
      }, {}),
    [warehouses]
  );

  const loadExecutionOrders = useCallback(async () => {
    const ordersExecutionPercentagesResp = await Promise.all(
      orders
        .filter((order) =>
          [
            OrderStatus.DELAYED.key,
            OrderStatus.DELAYED_DUE_TO_SUPPLIER.key,
            OrderStatus.DELAYED_DUE_TO_WAREHOUSE_STOCK.key
          ].includes(order.status)
        )
        .map((order: any) => amsV3Service.getOrderExecutionPercentage(order.id))
    );
    setOrderExecutionPercentages(
      ordersExecutionPercentagesResp.reduce((res, resp, index) => {
        if (!res) {
          res = {};
        }
        return {
          ...res,
          [orders[index].id]: resp.data
        };
      }, {})
    );
  }, [orders]);

  useEffect(() => {
    if (filterWithExecutionPercentage) {
      loadExecutionOrders();
    }
  }, [loadExecutionOrders, filterWithExecutionPercentage]);

  const [canCreate] = usePermissions([AccessPermissions.CAN_CREATE_ORDER]);

  return (
    <Grid container spacing={1}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <AMSTable
          title={title}
          data={orders}
          pageSize={12}
          isLoading={loading}
          columns={[
            {
              title: '№',
              field: 'id',
              render: (order: OrderListResponse) => (
                <AMSLink href={`/order?id=${order.id}`}>{getExtendedOrderId(order.id)}</AMSLink>
              ),
              cellStyle: { width: '5%' }
            },
            {
              title: 'Статус',
              field: 'status',
              render: (rowData: any) => (
                <Tooltip title={getOrderStatusLabelByKey(rowData.status)}>
                  <div className="d-flex">
                    <div
                      className={classes.statusIndicator}
                      style={{
                        backgroundColor: getOrderStatusByKey(rowData.status).color
                      }}
                    />
                    <div className={classes.statusText}>
                      {getOrderStatusLabelByKey(rowData.status)}
                    </div>
                  </div>
                </Tooltip>
              ),
              cellStyle: { width: '15%' }
            },
            {
              title: 'Дата за доставка',
              field: 'deliveryDate',
              type: 'datetime',
              cellStyle: { width: '10%' }
            },
            !hideReceiver
              ? {
                  title: 'Получател',
                  field: 'receiverName',
                  render: (order: OrderListResponse) =>
                    order.type === OrderTypeKeys.B2B ? (
                      <AMSLink href={`/partner?id=${order.receiverId}`}>
                        {partnerNames ? partnerNames[order.receiverId] : 'Зареждане ...'}
                      </AMSLink>
                    ) : (
                      <>
                        {order.type === OrderTypes.INTERNAL && warehouseNames[order.receiverId]}
                        {order.type === OrderTypes.EXTERNAL_B2C && clientNames[order.receiverId]}
                      </>
                    ),
                  cellStyle: { width: '20%' }
                }
              : null,
            {
              title: 'Компания',
              field: 'companyNote',
              render: (order: OrderListResponse) =>
                companyNames && warehouseCompanies
                  ? companyNames[warehouseCompanies[order.warehouseId]]
                  : 'Зареждане ...',
              cellStyle: { width: '15%' }
            },
            {
              title: 'Склад',
              field: 'warehouseName',
              render: (order: OrderListResponse) =>
                warehouseNames ? warehouseNames[order.warehouseId] : 'Зареждане ...',
              cellStyle: { width: '20%' }
            },
            {
              title: 'Протокол',
              field: 'actOfAcceptanceNumber',
              render: (order: OrderListResponse) =>
                order.actOfAcceptanceId ? (
                  <AMSLink href={`/act-of-acceptance?id=${order.actOfAcceptanceId}`}>
                    {getExtendedOrderId(order.id)}
                  </AMSLink>
                ) : undefined,
              cellStyle: { width: '10%' }
            },
            !hideInvoice && filterWithExecutionPercentage
              ? {
                  title: 'Възможно изпълнение (%)',
                  field: 'executionPercentage',
                  cellStyle: { width: '10%', textAlign: 'right' },
                  render: (order: any) => ordersExecutionPercentages[order.id] ?? '0.00',
                  customSort: (o1: any, o2: any) =>
                    +(ordersExecutionPercentages[o1.id] ?? 0) -
                    +(ordersExecutionPercentages[o2.id] ?? 0)
                }
              : !hideInvoice
              ? {
                  title: 'Фактура',
                  field: 'invoiceNumber',
                  render: (order: OrderListResponse) =>
                    order.invoiceId ? (
                      <AMSLink href={`/invoice?id=${order.invoiceId}`}>фактура</AMSLink>
                    ) : null,
                  cellStyle: { width: '10%' }
                }
              : null,
            {
              title: 'Сума',
              field: 'billableNetTotal',
              cellStyle: { width: '5%' },
              type: 'currency',
              currencySetting: {
                locale: 'bg',
                currencyCode: 'bgn',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }
            }
          ]}
          onAdd={canCreate ? handleOnAdd : undefined}
        />
      </Grid>
    </Grid>
  );
};

export default OrdersTableComponent;
