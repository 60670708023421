import { AMSApi, AMSV3Api, MAX_NUMBER_OF_RESULTS } from '../helpers/openapi-axios-helper';
import {
  CreateDeliveryDocumentRequest,
  CreateDeliveryRequest,
  UpdateDeliveryDocumentRequest
} from './api';

export const documentTypeAliasMap = ['ПРОФОРМА', 'ФАКТУРА', 'КРЕДИТНО ИЗВЕСТИЕ'];

export enum DeliveryDocumentTypes {
  PROFORMA = 0,
  INVOICE = 1,
  CREDIT_NOTE = 2
}

export class DeliveriesService {
  getDeliveryDocumentTypeAlias = (id: number) => documentTypeAliasMap[id];

  createDelivery = async (delivery: CreateDeliveryRequest) => {
    const axios = await AMSApi();
    return await axios.createV1DeliveriesPost(delivery);
  };

  getDeliveries = async (
    suppliers?: number[],
    warehouses?: number[],
    fromDeliveryDate?: string,
    toDeliveryDate?: string
  ) => {
    const axios = await AMSV3Api();
    return await axios.getDeliveriesDeliveriesGet(
      suppliers,
      warehouses,
      fromDeliveryDate,
      toDeliveryDate,
      1,
      MAX_NUMBER_OF_RESULTS
    );
  };

  /**
   *
   * @deprecated
   */
  getDelivery = async (id: number) => {
    const axios = await AMSApi();
    return await axios.readV1DeliveriesIdGet(id);
  };

  getDeliveryItems = async (
    id: number,
    warehouseId?: number,
    skipDepleted: boolean = false,
    skip?: number,
    limit?: number
  ) => {
    const axios = await AMSApi();
    return await axios.readAllItemsV1DeliveriesItemsGet(id, warehouseId, skipDepleted, skip, limit);
  };

  deleteDelivery = async (id: number) => {
    const axios = await AMSApi();
    return await axios.deleteV1DeliveriesIdDelete(id);
  };

  getDeliveryDocuments = async (deliveryId: number) => {
    const axios = await AMSApi();
    return await axios.readDeliveryDocumentsV1DeliveriesDeliveryDocumentGet(deliveryId);
  };

  createDeliveryDocument = async (deliveryDocument: CreateDeliveryDocumentRequest) => {
    const axios = await AMSApi();
    return await axios.createDeliveryDocumentV1DeliveriesDeliveryDocumentPost(deliveryDocument);
  };

  updateDeliveryDocument = async (deliveryDocument: UpdateDeliveryDocumentRequest) => {
    const axios = await AMSApi();
    return await axios.updateDeliveryDocumentV1DeliveriesDeliveryDocumentPut(deliveryDocument);
  };

  deleteDeliveryDocument = async (id: number) => {
    const axios = await AMSApi();
    return await axios.deleteV1DeliveriesDeliveryDocumentIdDelete(id);
  };
}
