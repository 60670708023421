import { } from '../../../../services/api';

import { CircularProgress, Grid, Tooltip } from '@material-ui/core';
import { OrderListResponse, PartnerListResponse } from '../../../../services/api-v3';
import {
  OrderStatusKey,
  getOrderStatusByKey,
  getOrderStatusLabelByKey
} from '../../../../services/ordersService';
import React, { forwardRef, useMemo, useState } from 'react';
import { useInvoiceOrders, usePartners, usePermissions, useStyles } from '../../../../helpers/hooks';

import AMSLink from '../../../../helpers/ui/AMSLink/AMSLink';
import { AMSTable } from '../../../../helpers/ui';
import { AccessPermissions } from '../../../../services/accessManagementService';
import CancelOrderDialog from '../../../OrdersComponent/Dialog/CancelOrderDialog';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { OrderV2Response } from '../../../../services/api';
import { getExtendedOrderId } from '../../../../helpers/utils';
import moment from 'moment';
import { ordersService } from '../../../../services/services';
import { useHistory } from 'react-router-dom';

interface InvoiceOrdersComponentProps {
  id: number;
}

const InvoiceOrdersComponent = ({ id }: InvoiceOrdersComponentProps) => {
  const classes = useStyles();
  const history = useHistory();

  const { invoiceOrders, loading } = useInvoiceOrders(id);
  const [order, setOrder] = useState<OrderV2Response>();
  const [openDialog, setOpenDialog] = useState(false);

  const [canViewOrder] = usePermissions([
    AccessPermissions.CAN_READ_ORDER || AccessPermissions.CAN_UPDATE_ORDER
  ]);

  const { partners } = usePartners();
  const partnersMap = useMemo(
    () =>
      partners.reduce((res: any, partner: PartnerListResponse) => {
        res[partner.id] = partner.name;
        return res;
      }, {}),
    [partners]
  );

  return loading ? (
    <CircularProgress />
  ) : (
    <Grid container spacing={2}>
      <Grid item lg={12}>
        <AMSTable
          title="Поръчки"
          columns={[
            {
              title: '№',
              field: 'id',
              render: (rowData: OrderListResponse) => (
                <AMSLink href={`/order?id=${rowData.id}`}>{getExtendedOrderId(rowData.id)}</AMSLink>
              ),
              cellStyle: { width: '10%' }
            },
            {
              title: 'Статус',
              field: 'status',
              render: (rowData: OrderListResponse) => (
                <Tooltip title={getOrderStatusLabelByKey(rowData.status as OrderStatusKey)}>
                  <div className="d-flex">
                    <div
                      className={classes.statusIndicator}
                      style={{
                        backgroundColor: getOrderStatusByKey(rowData.status as OrderStatusKey).color
                      }}
                    />
                    <div className={classes.statusText}>
                      {getOrderStatusLabelByKey(rowData.status as OrderStatusKey)}
                    </div>
                  </div>
                </Tooltip>
              ),
              cellStyle: { width: '10%' }
            },
            {
              title: 'Дата за доставка',
              field: 'deliveryDate',
              type: 'date',
              render: (rowData: OrderListResponse) => {
                return (
                  <Tooltip title={rowData.deliveryDate ?? ''}>
                    <div>{moment(rowData.deliveryDate).format('DD/MM/yy, hh:mm')}</div>
                  </Tooltip>
                );
              },
              cellStyle: { width: '15%' }
            },
            {
              title: 'Контрагент',
              field: 'receiverId',
              cellStyle: { width: '50%' },
              render: (rowData: OrderListResponse) => (
                <AMSLink href={`/partner?id=${rowData.receiverId}`}>
                  {partnersMap[rowData.receiverId]}</AMSLink>
              )
            },
            {
              title: 'Сума',
              field: 'billableNetTotal',
              cellStyle: { width: '15%' },
              type: 'currency',
              currencySetting: {
                locale: 'bg',
                currencyCode: 'bgn',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }
            }
          ]}
          deleteText={'Наистина ли искате да анулирате поръчката?'}
          deleteTooltip={'Анулиране'}
          deleteIcon={forwardRef((props: any, ref: any) => (
            <CancelOutlinedIcon {...props} ref={ref} />
          ))}
          pageSize={12}
          data={invoiceOrders}
          onAdd={undefined}
          onEdit={canViewOrder ? (order: any) => history.push(`/order?id=${order.id}`) : undefined}
          onDelete={async (rowData: OrderListResponse) => {
            setOpenDialog(true);
            const orderResp = await ordersService.getOrder(rowData.id);
            setOrder(orderResp.data);
          }}
          isDeletable={() => true}
          isLoading={loading}
        />
      </Grid>
      {order && (
        <CancelOrderDialog
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
          }}
          order={order}
        />
      )}
    </Grid>
  );
};

export default InvoiceOrdersComponent;
