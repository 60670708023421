import {
  CompanyCategoryResponse,
  ItemCategoryResponse,
  PricingPolicyResponse
} from '../services/api';
import {
  CompanyListResponse,
  GroupResponse,
  ItemListResponse,
  ItemResponse,
  LegalEntityListResponse,
  ManufacturerResponse,
  PartnerListResponse,
  PaymentOptionResponse,
  RoleListResponse,
  SupplierListResponse,
  UserListResponse,
  WarehouseListResponse
} from '../services/api-v3';
import { UnitEnum, unitEnumLocalizations } from '../services/AMSV3Service';
import {
  getExtendedCategoryId,
  getExtendedCompanyId,
  getExtendedItemCategoryId,
  getExtendedItemId,
  getExtendedLegalEntityId,
  getExtendedManufacturerId,
  getExtendedPartnerId,
  getExtendedPaymentOptionId,
  getExtendedPricingPolicyId,
  getExtendedSupplierId,
  getExtendedWarehouseId
} from '../helpers/utils';

import { OrderStatus } from '../services/ordersService';

export default interface Lookup {
  id: any;
  value: string;
  group?: string;
  selectedView?: string;
}

export const getItemValue = (item: any) => {
  let itemValue = '';
  if (item.artNo) {
    itemValue += item.artNo + ', ';
  }
  if (item.barcodeValue) {
    itemValue += item.barcodeValue + ', ';
  }
  itemValue += item.name + ', ';
  if (item.unitName || item.unit || item.unitId) {
    if (item.unitName) {
      itemValue += item.unitName + ' ';
    }
    if (item.unit) {
      itemValue += item.unit.name + ' ';
    }
    if (item.unitId) {
      itemValue += unitEnumLocalizations[item.unitId as UnitEnum] + ' ';
    }
  }
  if (item.packageQty && item.packageQty > 1) {
    itemValue += `от ${item.packageQty}`;
  }
  return itemValue;
};

export const getItemSelectedView = (item: ItemListResponse) => {
  return `${item.artNo}, ${item.name}`;
};

export const partnerToLookup = (partner: PartnerListResponse): Lookup => {
  return {
    id: partner.id,
    value: `${getExtendedPartnerId(partner.id)} ${partner.name}`
  };
};

export const manufacturerToLookup = (manufacturer: ManufacturerResponse): Lookup => {
  return {
    id: manufacturer.id,
    value: `${getExtendedManufacturerId(manufacturer.id)} ${manufacturer.name}`
  };
};

export const warehouseToLookup = (warehouse: WarehouseListResponse): Lookup => {
  return {
    id: warehouse.id,
    value: `${getExtendedWarehouseId(warehouse.id)} ${warehouse.name}`
  };
};

export const itemToLookup = (item: ItemListResponse | ItemResponse): Lookup => {
  return {
    id: item.id,
    value: `${getExtendedItemId(item.id)} ${item.name}`
  };
};

export const companyToLookup = (company: CompanyListResponse): Lookup => {
  return {
    id: company.id,
    value: `${getExtendedCompanyId(company.id)} ${company.note}`,
    group: company.name
  };
};

export const itemCategoryToLookup = (itemCategory: ItemCategoryResponse): Lookup => {
  return {
    id: itemCategory.id,
    value: `${getExtendedItemCategoryId(itemCategory.id)} ${itemCategory.name}`,
    group: `${!!itemCategory.parentId ? `${itemCategory.parentName}` : 'Основни'}`
  };
};

export const legalEntityToLookup = (legalEntity: LegalEntityListResponse) => ({
  id: legalEntity.id,
  value: `${getExtendedLegalEntityId(legalEntity.id)} ${legalEntity.name}`
});

export const supplierToLookup = (supplier: SupplierListResponse): Lookup => ({
  id: supplier.id,
  value: `${getExtendedSupplierId(supplier.id)} ${supplier.name}`
});

export const userToLookup = (user: UserListResponse): Lookup => ({
  id: user.id,
  value: user.name
});

export const groupToLookup = (group: GroupResponse): Lookup => ({
  id: group.id,
  value: group.name
});
export const roleToLookup = (role: RoleListResponse): Lookup => ({
  id: role.id,
  value: role.name
});

export const transactionTypeToLookup = (transactionType: any): Lookup => ({
  id: transactionType.id,
  value: transactionType.name
});

export const treasuryToLookup = (treasury: any): Lookup => ({
  id: treasury.id,
  value: treasury.name,
  group: treasury.companyName
});

export const orderStatusToLookup = (s: OrderStatus) => ({
  id: s.key,
  value: s.label
});

export const paymentOptionToLookup = (paymentOption: PaymentOptionResponse): Lookup => ({
  id: paymentOption.id,
  value: `${getExtendedPaymentOptionId(paymentOption.id)} ${paymentOption.account} - ${
    paymentOption.holder
  }`,
  group: getExtendedCompanyId(paymentOption.companyId)
});

export const companyCategoryToLookup = (companyCategory: CompanyCategoryResponse): Lookup => {
  return {
    id: companyCategory.id,
    value: `${getExtendedCategoryId(companyCategory.id)} ${companyCategory.name}`
  };
};

export const pricingPolicyToLookup = (pricingPolicy: PricingPolicyResponse): Lookup => {
  return {
    id: pricingPolicy.id,
    value: `${getExtendedPricingPolicyId(pricingPolicy.id)} ${pricingPolicy.name}`
  };
};
