import AddTransactionDialog, {
  DocumentOption
} from '../../../OrderComponent/Panels/OrderInvoiceAndTransactionsPanel/Dialog/AddTransactionDialog';
import {
  DeliveryDocumentResponse,
  DeliveryResponse,
  TransactionResponse
} from '../../../../services/api';
import { Grid, Paper, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import { TransactionType, getTransactionName } from '../../../../services/AMSV3Service';
import { amsV3Service, deliveriesService } from '../../../../services/services';
import { useFlag, usePermissions, useStyles } from '../../../../helpers/hooks';

import AMSTable from '../../../../helpers/ui/AMSTable/AMSTable';
import { AccessPermissions } from '../../../../services/accessManagementService';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import AccountBalanceWalletTwoToneIcon from '@material-ui/icons/AccountBalanceWalletTwoTone';
import { CreateTransactionRequest } from '../../../../services/api-v3';
import DateFnsUtils from '@date-io/date-fns';
import DeliveryDocumentDialog from './DeliveryDocumentDialog/DeliveryDocumentDialog';
import { DeliveryDocumentTypes } from '../../../../services/deliveriesService';
import MoneyTwoToneIcon from '@material-ui/icons/MoneyTwoTone';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import PaymentTwoToneIcon from '@material-ui/icons/PaymentTwoTone';

interface DeliveryDocumentAndTransactionsPanelProps {
  delivery: DeliveryResponse | null;
  loadDelivery: () => void;
  loading: boolean;
}

const DeliveryDocumentAndTransactionsPanel = ({
  delivery,
  loadDelivery,
  loading
}: DeliveryDocumentAndTransactionsPanelProps) => {
  const classes = useStyles();

  const [deliveryDocument, setDeliveryDocument] = useState<DeliveryDocumentResponse | null>(null);
  const [openDialog, setOpenDialog] = useFlag(false);
  const [openDocumentViewDialog, setOpenDocumentViewDialog] = useFlag(false);

  const [canUpdate, canCreateTransaction, canDeleteTransaction] = usePermissions([
    AccessPermissions.CAN_UPDATE_DELIVERY,
    AccessPermissions.CAN_CREATE_TRANSACTION,
    AccessPermissions.CAN_DELETE_TRANSACTION
  ]);

  return delivery ? (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container spacing={1}>
        <Grid item md={6} sm={12}>
          <AMSTable
            title="Документи"
            isLoading={loading}
            data={delivery.documents ? delivery.documents : []}
            columns={[
              {
                title: '№',
                field: 'number',
                render: (deliveryDocument: DeliveryDocumentResponse) =>
                  deliveryDocument.invoiceNumber ? deliveryDocument.invoiceNumber : '-',
                cellStyle: { width: '15%' }
              },
              {
                title: 'Тип',
                field: 'type',
                render: (deliveryDocument: DeliveryDocumentResponse) =>
                  deliveriesService.getDeliveryDocumentTypeAlias(
                    deliveryDocument.deliveryDocumentType
                  ),
                cellStyle: { width: '20%' }
              },
              {
                title: 'Падежна дата',
                field: 'paymentDue',
                type: 'date',
                cellStyle: { width: '15%' }
              },
              {
                title: 'Търговски документ №',
                field: 'tradeDocumentNumber',
                type: 'date',
                cellStyle: { width: '20%' }
              },
              {
                title: 'Плащане',
                field: 'paymentInfo',
                type: 'date',
                cellStyle: { width: '10%' }
              },
              {
                title: 'Сума',
                field: 'invoiceTotal',
                type: 'currency',
                currencySetting: {
                  locale: 'bg',
                  currencyCode: 'bgn',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                },
                cellStyle: { width: '20%' }
              }
            ]}
            onAdd={
              canUpdate
                ? () => {
                    setOpenDocumentViewDialog(true);
                    setDeliveryDocument(null);
                  }
                : undefined
            }
            onEdit={
              canUpdate
                ? (deliveryDocument: DeliveryDocumentResponse) => {
                    setOpenDocumentViewDialog(true);
                    setDeliveryDocument(deliveryDocument);
                  }
                : undefined
            }
            onDelete={
              canUpdate
                ? async (deliveryDocument: DeliveryDocumentResponse) => {
                    const deliveryResp = await deliveriesService.deleteDeliveryDocument(
                      deliveryDocument.id
                    );
                    if (deliveryResp && deliveryResp.data) {
                      loadDelivery();
                    }
                  }
                : undefined
            }
            paging={false}
            minBodyHeight="65vh"
            maxBodyHeight="65vh"
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <Paper elevation={2} className={classes.paper}>
            <AMSTable
              title="Списък с плащания"
              isLoading={loading}
              columns={[
                {
                  title: 'Тип',
                  field: 'transactionType',
                  render: (transaction: TransactionResponse) => {
                    switch (transaction.transactionType) {
                      case TransactionType.BANK_ACCOUNT:
                        return (
                          <Tooltip
                            className={classes.income}
                            title={getTransactionName(TransactionType.BANK_ACCOUNT)}
                          >
                            <PaymentTwoToneIcon />
                          </Tooltip>
                        );
                      case TransactionType.CASH:
                        return (
                          <Tooltip
                            className={classes.income}
                            title={getTransactionName(TransactionType.CASH)}
                          >
                            <AccountBalanceWalletTwoToneIcon />
                          </Tooltip>
                        );
                      case TransactionType.EXPENSE_BANK_ACCOUNT:
                        return (
                          <Tooltip
                            className={classes.expense}
                            title={getTransactionName(TransactionType.EXPENSE_BANK_ACCOUNT)}
                          >
                            <AccountBalanceTwoToneIcon />
                          </Tooltip>
                        );
                      case TransactionType.EXPENSE_CASH:
                        return (
                          <Tooltip
                            className={classes.expense}
                            title={getTransactionName(TransactionType.EXPENSE_CASH)}
                          >
                            <MoneyTwoToneIcon />
                          </Tooltip>
                        );
                    }
                  },
                  cellStyle: { width: '10%' }
                },
                {
                  title: 'Дата / Час',
                  field: 'date',
                  render: (transaction: TransactionResponse) =>
                    `${transaction.date.substr(0, 10)} ${transaction.date.substr(11, 8)}`,
                  cellStyle: { width: '20%' }
                },
                {
                  title: 'Каса',
                  field: 'treasuryName',
                  cellStyle: { width: '40%' }
                },
                {
                  title: 'Документ',
                  field: 'invoiceNumber',
                  render: (transaction: TransactionResponse) =>
                    transaction.invoiceNumber ? transaction.invoiceNumber : '-',
                  cellStyle: { width: '20%' }
                },
                {
                  title: 'Сума',
                  field: 'amount',
                  cellStyle: { width: '10%' },
                  type: 'currency',
                  currencySetting: {
                    locale: 'bg',
                    currencyCode: 'bgn',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }
                }
              ]}
              components={{
                Container: (props: any) => <Paper {...props} elevation={0} className="padding-0" />
              }}
              data={
                delivery && delivery.transactions
                  ? delivery.transactions.sort(
                      (i1: TransactionResponse, i2: TransactionResponse) => i1.id - i2.id
                    )
                  : []
              }
              onAdd={
                canUpdate && canCreateTransaction
                  ? () => {
                      setDeliveryDocument(deliveryDocument);
                      setOpenDialog(true);
                    }
                  : undefined
              }
              onDelete={
                canUpdate && canDeleteTransaction
                  ? async (transaction: TransactionResponse) => {
                      const resp = await amsV3Service.deleteTransaction(transaction.id);
                      if (resp) {
                        loadDelivery();
                      }
                    }
                  : undefined
              }
              paging={false}
              minBodyHeight="65vh"
              maxBodyHeight="65vh"
            />
          </Paper>
          {delivery && openDocumentViewDialog && (
            <DeliveryDocumentDialog
              open={openDocumentViewDialog}
              onClose={(deliveryDocument: DeliveryDocumentResponse | null) => {
                if (deliveryDocument) {
                  loadDelivery();
                }
                setOpenDocumentViewDialog(false);
              }}
              delivery={delivery}
              deliveryDocument={deliveryDocument}
              documents={(delivery.documents ? delivery.documents : [])
                .filter(
                  (doc: DeliveryDocumentResponse) =>
                    doc.deliveryDocumentType === DeliveryDocumentTypes.PROFORMA
                )
                .sort(
                  (do1: DeliveryDocumentResponse, do2: DeliveryDocumentResponse) => do1.id - do2.id
                )}
            />
          )}
          {delivery && openDialog && (
            <AddTransactionDialog
              isDeliveryTransaction={true}
              companyId={delivery.companyId}
              open={openDialog}
              defaultAmount={delivery.remainingAmount}
              documents={(delivery.documents ? delivery.documents : [])
                .map((t: DeliveryDocumentResponse) => ({
                  id: t.id,
                  value: `${t.invoiceNumber} ${deliveriesService.getDeliveryDocumentTypeAlias(
                    t.deliveryDocumentType
                  )}`,
                  type: t.deliveryDocumentType,
                  remainingAmount: t.remainingAmount
                }))
                .sort((do1: DocumentOption, do2: DocumentOption) => do1.id - do2.id)}
              onClose={() => setOpenDialog(false)}
              onSave={async (transaction: CreateTransactionRequest) => {
                const resp = await amsV3Service.createTransaction(transaction);
                if (resp) {
                  setOpenDialog(false);
                  loadDelivery();
                }
              }}
            />
          )}
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  ) : (
    <></>
  );
};

export default DeliveryDocumentAndTransactionsPanel;
