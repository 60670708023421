import {
  DeliveryRequestStatus,
  DeliveryRequestStatusKey,
  getDeliveryRequestStatusByKey
} from '../../../services/deliveryRequestsService';
import React, { useMemo } from 'react';
import { getExtendedSupplierId, getExtendedWarehouseId } from '../../../helpers/utils';

import { CircularProgress } from '@material-ui/core';
import CreateStockReceivingProcessComponent from './CreateStockReceivingProcessComponent/CreateStockReceivingProcessComponent';
import EditStockReceivingProcessComponent from './EditStockReceivingProcessComponent/EditStockReceivingProcessComponent';
import FinalizeStockReceivingProcessComponent from './FinalizeStockReceivingProcessComponent/FinalizeStockReceivingProcessComponent';
import { isoToDatetimeString } from '../../../helpers/date-helper';
import { useDeliveryRequest } from '../../../helpers/hooks';

interface StockReceivingProcessComponentProps {
  id: number;
}

const StockReceivingProcessComponent = ({ id }: StockReceivingProcessComponentProps) => {
  const { deliveryRequest, setDeliveryRequest } = useDeliveryRequest(id);

  const supplierInfo = useMemo(
    () =>
      `${getExtendedSupplierId(deliveryRequest?.supplierId ?? 0)} ${
        deliveryRequest?.supplierName ?? ''
      }`,
    [deliveryRequest]
  );

  const warehouseInfo = useMemo(
    () =>
      `${getExtendedWarehouseId(deliveryRequest?.warehouseId ?? 0)} ${
        deliveryRequest?.warehouseName ?? ''
      }`,
    [deliveryRequest]
  );

  const deliveryDate = useMemo(
    () => (deliveryRequest?.deliveryDate ? isoToDatetimeString(deliveryRequest.deliveryDate) : ''),
    [deliveryRequest]
  );

  const createdDate = useMemo(
    () => (deliveryRequest?.createdDate ? isoToDatetimeString(deliveryRequest.createdDate) : ''),
    [deliveryRequest]
  );

  const status = useMemo(
    () => getDeliveryRequestStatusByKey(deliveryRequest?.status as DeliveryRequestStatusKey),
    [deliveryRequest]
  );

  if (!deliveryRequest) {
    return <CircularProgress />;
  }

  if (
    status === DeliveryRequestStatus.REQUESTED ||
    status === DeliveryRequestStatus.DELAYED ||
    status === DeliveryRequestStatus.CANCELLED
  ) {
    return (
      <CreateStockReceivingProcessComponent
        deliveryRequestId={deliveryRequest.id}
        supplierId={deliveryRequest?.supplierId ?? 0}
        supplierInfo={supplierInfo}
        warehouseInfo={warehouseInfo}
        deliveryDate={deliveryDate}
        createdDate={createdDate}
        status={status}
        items={deliveryRequest.items ? [...deliveryRequest.items] : []}
      />
    );
  }

  if (
    status === DeliveryRequestStatus.FOR_INSPECTION ||
    status === DeliveryRequestStatus.INSPECTION_IN_PROGRESS ||
    status === DeliveryRequestStatus.INSPECTED ||
    status === DeliveryRequestStatus.INSPECTED_WITH_REMARKS ||
    status === DeliveryRequestStatus.DELIVERED ||
    status === DeliveryRequestStatus.DECLINED_AFTER_VERIFICATION
  ) {
    return (
      <EditStockReceivingProcessComponent
        deliveryRequest={deliveryRequest}
        setDeliveryRequest={setDeliveryRequest}
        supplierInfo={supplierInfo}
        warehouseInfo={warehouseInfo}
        deliveryDate={deliveryDate}
        createdDate={createdDate}
        status={status}
        allowedSubsequentStatuses={deliveryRequest.allowedSubsequentStatuses.map((statusKey) =>
          getDeliveryRequestStatusByKey(statusKey as DeliveryRequestStatusKey)
        )}
      />
    );
  }
  return (
    <FinalizeStockReceivingProcessComponent
      deliveryRequestId={deliveryRequest!.id}
      supplierInfo={supplierInfo}
      warehouseInfo={warehouseInfo}
      deliveryDate={deliveryDate}
      createdDate={createdDate}
      status={status}
    />
  );
};

export default StockReceivingProcessComponent;
