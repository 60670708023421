import { Checkbox, CircularProgress, Container, Grid, TextField, Tooltip } from '@material-ui/core';
import {
  CompanyListResponse,
  ItemResponse,
  LegalEntityListResponse,
  PaginatedListResponseReportSalesResponse,
  PartnerListResponse,
  ReportSalesResponse,
  WarehouseListResponse
} from '../../../services/api-v3';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Lookup, {
  companyToLookup,
  itemCategoryToLookup,
  itemToLookup,
  legalEntityToLookup,
  partnerToLookup,
  warehouseToLookup
} from '../../../models/lookup';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { amsV3Service, cookiesService } from '../../../services/services';
import {
  ignoreOffset,
  isValidDate,
  toDateString,
  toEndOfDay,
  toStartOfDay
} from '../../../helpers/date-helper';
import {
  useCompanies,
  useItemCategories,
  useItems,
  useLegalEntities,
  usePartners,
  useStyles,
  useWarehouses
} from '../../../helpers/hooks';

import AMSAsyncAutocomplete from '../../../helpers/ui/AMSAsyncAutocomplete/AMSAsyncAutocomplete';
import AMSAutocomplete from '../../../helpers/ui/AMSAutocomplete/AMSAutocomplete';
import { AMSButton } from '../../../helpers/ui';
import AMSConfirmDialog from '../../../helpers/ui/AMSConfirmDialog/AMSConfirmDialog';
import AMSLink from '../../../helpers/ui/AMSLink/AMSLink';
import AMSTable from '../../../helpers/ui/AMSTable/AMSTable';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DateFnsUtils from '@date-io/date-fns';
import { ItemCategoryResponse } from '../../../services/api';
import { getExtendedOrderId } from '../../../helpers/utils';
import { parseFilter } from '../../../helpers/url';
import { useHistory } from 'react-router-dom';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ReportOrderItemsComponent = ({ location }: any) => {
  const history = useHistory();
  const classes = useStyles();
  const {
    toDate,
    fromDate,
    companyIds,
    warehouseIds,
    legalEntityIds,
    partnerIds,
    itemCategoryIds
  } = useMemo(() => parseFilter(location.search), [location.search]);

  const [loading, setLoading] = useState(false);
  const [orderItems, setOrderItems] = useState<PaginatedListResponseReportSalesResponse>();

  const filter = cookiesService.getReportOrderItemsFilter();
  const [filterFromDate, setFilterFromDate] = useState<any>(
    fromDate
      ? fromDate
      : filter.filterFromDate
      ? ignoreOffset(new Date(filter.filterFromDate))
      : new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  const [filterToDate, setFilterToDate] = useState<any>(
    toDate ? toDate : filter.filterToDate ? ignoreOffset(new Date(filter.filterToDate)) : new Date()
  );
  const [filterCompanyIds, setFilterCompanyIds] = useState<any[]>(
    filter.filterCompanyIds ? filter.filterCompanyIds : []
  );
  const [filterWarehouseIds, setFilterWarehouseIds] = useState<any[]>(
    filter.filterWarehouseIds ? filter.filterWarehouseIds : []
  );
  const [filterPartnerIds, setFilterPartnerIds] = useState<any[]>(
    filter.filterPartnerIds ? filter.filterPartnerIds : []
  );
  const [filterLegalEntityIds, setFilterLegalEntityIds] = useState<any[]>(
    filter.filterLegalEntityIds ? filter.filterLegalEntityIds : []
  );
  const [filterCategories, setFilterCategories] = useState<ItemCategoryResponse[]>(
    filter.filterCategories ? filter.filterCategories : []
  );
  const [filterItemId, setFilterItemId] = useState<number>();

  const { companies } = useCompanies();
  const { items } = useItems();
  const { warehouses } = useWarehouses();
  const { partners } = usePartners();
  const { legalEntities } = useLegalEntities();
  const { categories } = useItemCategories();

  const [item, setItem] = useState<Lookup | null>(null);

  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  useEffect(() => {
    const { filterCompanyIds } = cookiesService.getReportOrderItemsFilter();

    setFilterCompanyIds(
      companyIds && companies
        ? companies
            .filter((c: CompanyListResponse) => companyIds.includes(c.id))
            .map(companyToLookup)
        : filterCompanyIds ?? []
    );
  }, [companies, companyIds]);

  useEffect(() => {
    const { filterWarehouseIds } = cookiesService.getReportOrderItemsFilter();

    setFilterWarehouseIds(
      warehouseIds && warehouses
        ? warehouses
            .filter((w: WarehouseListResponse) => warehouseIds.includes(w.id))
            .map(warehouseToLookup)
        : filterWarehouseIds ?? []
    );
  }, [warehouses, warehouseIds]);

  useEffect(() => {
    const { filterLegalEntityIds } = cookiesService.getReportOrderItemsFilter();

    setFilterLegalEntityIds(
      legalEntityIds && legalEntities
        ? legalEntities
            .filter((le: LegalEntityListResponse) => legalEntityIds.includes(le.id))
            .map(legalEntityToLookup)
        : filterLegalEntityIds ?? []
    );
  }, [legalEntities, legalEntityIds]);

  useEffect(() => {
    const { filterPartnerIds } = cookiesService.getReportOrderItemsFilter();

    setFilterPartnerIds(
      partnerIds && partners
        ? partners
            .filter((p: PartnerListResponse) => partnerIds.includes(p.id))
            .map(partnerToLookup)
        : filterPartnerIds ?? []
    );
  }, [partners, partnerIds]);

  useEffect(() => {
    const { filterItemCategoryIds } = cookiesService.getReportOrderItemsFilter();

    setFilterCategories(
      itemCategoryIds && categories
        ? categories
            .filter((ic: ItemCategoryResponse) => itemCategoryIds.includes(ic.id))
            .map(itemCategoryToLookup)
        : filterItemCategoryIds ?? []
    );
  }, [categories, itemCategoryIds]);

  const loadOrderItems = useCallback(async () => {
    setLoading(true);
    const fromDate = isValidDate(filterFromDate) ? toStartOfDay(filterFromDate) : undefined;
    const toDate = isValidDate(filterToDate) ? toEndOfDay(filterToDate) : undefined;
    const orderItemsResponse = await amsV3Service.getSales(
      fromDate,
      toDate,
      filterPartnerIds.map((partner: PartnerListResponse) => partner.id),
      filterLegalEntityIds.map((legalEntity: LegalEntityListResponse) => legalEntity.id),
      filterCompanyIds.map((company: CompanyListResponse) => company.id),
      filterWarehouseIds.map((warehouse: WarehouseListResponse) => warehouse.id),
      filterCategories.map((itemCategory: ItemCategoryResponse) => itemCategory.id),
      filterItemId ? filterItemId : undefined
    );
    if (orderItemsResponse) {
      setOrderItems(orderItemsResponse.data);
    }
    setLoading(false);
  }, [
    filterFromDate,
    filterToDate,
    filterPartnerIds,
    filterLegalEntityIds,
    filterCompanyIds,
    filterWarehouseIds,
    filterCategories,
    filterItemId
  ]);

  const handleOnSearch = useCallback(() => {
    cookiesService.setReportOrderItemsFilter({
      filterFromDate: filterFromDate ? toStartOfDay(filterFromDate) : undefined,
      filterToDate: filterToDate ? toEndOfDay(filterToDate) : undefined,
      filterLegalEntityIds: filterLegalEntityIds,
      filterPartnerIds: filterPartnerIds,
      filterCompanyIds: filterCompanyIds,
      filterWarehouseIds: filterWarehouseIds,
      filterCategories: filterCategories
    });

    let params: any = {};
    if (filterLegalEntityIds?.length > 0) {
      params.legalEntityIds = filterLegalEntityIds.map((s) => s.id).join(',');
    }
    if (filterPartnerIds?.length > 0) {
      params.partnerIds = filterPartnerIds.map((c) => c.id).join(',');
    }
    if (filterCompanyIds?.length > 0) {
      params.companyIds = filterPartnerIds.map((w) => w.id).join(',');
    }
    if (filterWarehouseIds?.length > 0) {
      params.warehouseIds = filterWarehouseIds.map((r) => r.id).join(',');
    }
    if (filterCategories?.length > 0) {
      params.itemCategoryIds = filterCategories.map((r) => r.id).join(',');
    }
    if (filterFromDate) {
      params.fromDate = toDateString(filterFromDate);
    }
    if (filterToDate) {
      params.toDate = toDateString(filterToDate);
    }
    history.push({
      pathname: '/reports-order-item',
      search: new URLSearchParams(params).toString()
    });
    loadOrderItems();
  }, [
    filterFromDate,
    filterToDate,
    filterLegalEntityIds,
    filterPartnerIds,
    filterCompanyIds,
    filterWarehouseIds,
    filterCategories,
    history,
    loadOrderItems
  ]);

  const itemsLookup: any = useMemo(
    () =>
      items.reduce((res: any, item) => {
        if (!res) {
          res = {};
        }
        res[`${item.id}`] = item.name;
        return res;
      }, {}),
    [items]
  );

  const warehouseLookup: any = useMemo(
    () =>
      warehouses.reduce((res: any, warehouse) => {
        if (!res) {
          res = {};
        }
        res[`${warehouse.id}`] = warehouse.name;
        return res;
      }, {}),
    [warehouses]
  );

  const categoryLookup: any = useMemo(
    () =>
      categories.reduce((res: any, category) => {
        if (!res) {
          res = {};
        }
        res[`${category.id}`] = category.name;
        return res;
      }, {}),
    [categories]
  );

  const partnerLookup = useMemo(
    () =>
      partners.reduce((res: any, partner) => {
        if (!res) {
          res = {};
        }
        res[partner.id] = partner.name;
        return res;
      }, {}),
    [partners]
  );

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="text-align-left">
          <Container maxWidth="xl">
            <Grid container spacing={1}>
              <Grid item lg={2} md={3} sm={12} xs={12}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk={true}
                  variant="inline"
                  format="dd/MM/yy"
                  margin="dense"
                  label="От"
                  helperText={''}
                  value={filterFromDate ? filterFromDate : null}
                  onChange={(value: Date | null) => {
                    if (value) {
                      if (isValidDate(value)) {
                        setFilterFromDate(ignoreOffset(value));
                      }
                    } else {
                      setFilterFromDate(null);
                    }
                  }}
                  inputVariant="outlined"
                  fullWidth
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  maxDate={filterToDate}
                />
              </Grid>
              <Grid item lg={2} md={3} sm={12} xs={12}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk={true}
                  variant="inline"
                  format="dd/MM/yy"
                  margin="dense"
                  label="До"
                  helperText={''}
                  value={filterToDate ? filterToDate : null}
                  onChange={(value: Date | null) => {
                    if (value) {
                      if (isValidDate(value)) {
                        setFilterToDate(ignoreOffset(value));
                      }
                    } else {
                      setFilterToDate(null);
                    }
                  }}
                  inputVariant="outlined"
                  fullWidth
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  minDate={filterFromDate}
                />
              </Grid>
              <Grid item lg={4} md={6} sm={6} xs={12}>
                <AMSAutocomplete
                  multiple
                  minChar={0}
                  limitTags={3}
                  options={companies.map(companyToLookup)}
                  disableCloseOnSelect
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes.checkbox}
                        checked={selected}
                        color="primary"
                      />
                      {option.value}
                    </Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Компания"
                      placeholder=""
                      margin="dense"
                      fullWidth
                    />
                  )}
                  value={filterCompanyIds}
                  onChange={(values) => setFilterCompanyIds(values)}
                  groupBy={(g) => (g.group ? g.group : '')}
                />
              </Grid>
              <Grid item lg={4} md={6} sm={6} xs={12}>
                <AMSAutocomplete
                  multiple
                  minChar={0}
                  limitTags={3}
                  options={(filterCompanyIds.length > 0
                    ? warehouses.filter((wh: WarehouseListResponse) =>
                        filterCompanyIds
                          .map((company: CompanyListResponse) => company.id)
                          .includes(wh.companyId)
                      )
                    : warehouses
                  ).map(warehouseToLookup)}
                  sortOptions={false}
                  disableCloseOnSelect
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes.checkbox}
                        checked={selected}
                        color="primary"
                      />
                      {option.value}
                    </Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Складове"
                      placeholder=""
                      margin="dense"
                      fullWidth
                    />
                  )}
                  value={filterWarehouseIds}
                  onChange={(values) => setFilterWarehouseIds(values)}
                  groupBy={(g) => (g.group ? g.group : '')}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <AMSAutocomplete
                  multiple
                  minChar={0}
                  limitTags={3}
                  options={legalEntities.map(legalEntityToLookup)}
                  disableCloseOnSelect
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes.checkbox}
                        checked={selected}
                        color="primary"
                      />
                      {option.value}
                    </Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Юридически лица"
                      placeholder=""
                      margin="dense"
                      fullWidth
                    />
                  )}
                  value={filterLegalEntityIds}
                  onChange={(values) => setFilterLegalEntityIds(values)}
                  groupBy={(g) => (g.group ? g.group : '')}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <AMSAutocomplete
                  multiple
                  minChar={0}
                  limitTags={3}
                  options={(filterLegalEntityIds.length > 0
                    ? partners.filter((p: PartnerListResponse) =>
                        filterLegalEntityIds
                          .map((le: LegalEntityListResponse) => le.id)
                          .includes(p.legalEntityId)
                      )
                    : partners
                  ).map(partnerToLookup)}
                  disableCloseOnSelect
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes.checkbox}
                        checked={selected}
                        color="primary"
                      />
                      {option.value}
                    </Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Контрагенти"
                      placeholder=""
                      margin="dense"
                      fullWidth
                    />
                  )}
                  value={filterPartnerIds}
                  onChange={(values) => setFilterPartnerIds(values)}
                  groupBy={(g) => (g.group ? g.group : '')}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <AMSAutocomplete
                  multiple
                  minChar={0}
                  limitTags={3}
                  options={categories.map(itemCategoryToLookup)}
                  disableCloseOnSelect
                  getOptionLabel={(option: Lookup) => option.value}
                  renderOption={(option: Lookup, { selected }: any) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes.checkbox}
                        checked={selected}
                        color="primary"
                      />
                      {option.value}
                    </Fragment>
                  )}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Категория"
                      placeholder=""
                      margin="dense"
                      fullWidth
                    />
                  )}
                  value={filterCategories}
                  onChange={(values) => setFilterCategories(values)}
                  groupBy={(g) => g.group}
                />
              </Grid>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <AMSAsyncAutocomplete
                  label="Артикул"
                  onChange={(item: ItemResponse | null) => {
                    if (item) {
                      setFilterItemId(item?.id);
                      setItem(itemToLookup(item));
                    } else {
                      setFilterItemId(undefined);
                      setItem(null);
                    }
                  }}
                  value={item}
                  minChar={3}
                />
              </Grid>
              <Grid item lg={2} md={1} sm={12} xs={12}>
                {(!filterLegalEntityIds || filterLegalEntityIds.length === 0) &&
                (!filterPartnerIds || filterPartnerIds.length === 0) &&
                (!filterCompanyIds || filterCompanyIds.length === 0) &&
                (!filterWarehouseIds || filterWarehouseIds.length === 0) &&
                (!filterCategories || filterCategories.length === 0) &&
                !filterItemId &&
                !filterFromDate &&
                !filterToDate ? (
                  <Tooltip
                    title={
                      <div style={{ fontSize: 14 }}>
                        Търсенето без филтри може да доведе до забавяне на системата
                      </div>
                    }
                    placement="top"
                    style={{ fontSize: 24 }}
                  >
                    <div>
                      <AMSButton
                        color="primary"
                        variant="contained"
                        text="Търсене"
                        loading={loading}
                        disabled={false}
                        onClick={() => setOpenConfirmDialog(true)}
                        style={{
                          marginTop: 8,
                          backgroundColor: '#ff7b00',
                          float: 'right'
                        }}
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <AMSButton
                    color="primary"
                    variant="contained"
                    text="Търсене"
                    loading={loading}
                    disabled={false}
                    onClick={handleOnSearch}
                    style={{
                      marginTop: 8,
                      float: 'right'
                    }}
                  />
                )}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <AMSTable
                    title="Продажби"
                    columns={[
                      {
                        title: 'Дата',
                        field: 'orderCreatedDate',
                        type: 'date',
                        cellStyle: { width: '8%' }
                      },
                      {
                        title: 'Поръчка №',
                        field: 'orderId',
                        cellStyle: { width: '10%' },
                        render: (orderItem: ReportSalesResponse) => (
                          <AMSLink href={`/order?id=${orderItem.orderId}`}>
                            {getExtendedOrderId(orderItem.orderId)}
                          </AMSLink>
                        )
                      },
                      {
                        title: 'Контрагент',
                        field: 'receiverId',
                        cellStyle: { width: '16%' },
                        lookup: partnerLookup,
                        render: (s: ReportSalesResponse) => (
                          <AMSLink href={`/partner?id=${s.receiverId}`}>
                            {partnerLookup ? partnerLookup[s.receiverId] : 'Зареждане ...'}
                          </AMSLink>
                        )
                      },
                      {
                        title: 'Склад',
                        field: 'warehouseId',
                        lookup: warehouseLookup,
                        render: (s: ReportSalesResponse) =>
                          warehouseLookup ? warehouseLookup[s.warehouseId] : 'Зареждане ...',
                        cellStyle: { width: '16%' }
                      },
                      {
                        title: 'Група',
                        field: 'itemCategoryId',
                        lookup: categoryLookup,
                        render: (s: ReportSalesResponse) =>
                          categoryLookup ? categoryLookup[s.itemCategoryId] : 'Зареждане ...',
                        cellStyle: { width: '20%' }
                      },
                      {
                        title: 'Артикул',
                        field: 'itemId',
                        cellStyle: { width: '20%' },
                        lookup: itemsLookup,
                        render: (s: ReportSalesResponse) => (
                          <AMSLink href={`/item?id=${s.itemId}`}>
                            {itemsLookup ? itemsLookup[s.itemId] : 'Зареждане ...'}
                          </AMSLink>
                        )
                      },
                      {
                        title: 'Ед. цена',
                        field: 'itemPrice',
                        cellStyle: { width: '6%' },
                        type: 'currency',
                        currencySetting: {
                          locale: 'bg',
                          currencyCode: 'bgn',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }
                      },
                      {
                        title: 'Срок на годност',
                        field: 'expirationDate',
                        cellStyle: { width: '8%' }
                      },
                      {
                        title: 'Партида',
                        field: 'batchNumber',
                        cellStyle: { width: '8%' }
                      },
                      {
                        title: 'Държава',
                        field: 'countryOfOrigin',
                        cellStyle: { width: '8%' }
                      },
                      {
                        title: 'Количество',
                        field: 'quantityBalance',
                        cellStyle: { width: '8%' },
                        align: 'right',
                        type: 'numeric'
                      }
                    ]}
                    paging={false}
                    overflowY="scroll"
                    minBodyHeight="55vh"
                    maxBodyHeight="55vh"
                    data={orderItems?.data ?? []}
                  />
                )}
              </Grid>
            </Grid>
          </Container>
          <AMSConfirmDialog
            open={openConfirmDialog}
            onConfirm={() => {
              handleOnSearch();
              setOpenConfirmDialog(false);
            }}
            onClose={() => setOpenConfirmDialog(false)}
            title={'Търсене без филтри!'}
            message={
              'Търсенето без филтри може да доведе до забавяне на системата. Искате да продължите?'
            }
          />
        </div>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default ReportOrderItemsComponent;
