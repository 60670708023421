import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { OrderV2Response } from '../../services/api';
import { ordersService } from '../../services/services';

export interface IOrders<T> extends IAsyncType<T> {
  orders: T;
  setOrders: Dispatch<SetStateAction<T>>;
  setOrdersParameters: Dispatch<any[] | null>;
}

const useOrders = (
  statusKeys?: string[],
  companyIds?: number[],
  warehouseIds?: number[],
  fromDate?: string,
  toDate?: string | undefined,
  noInvoice?: boolean,
  withExecutionPercentage?: boolean,
  withItems?: boolean
): IOrders<OrderV2Response[]> => {
  const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
    OrderV2Response[]
  >(ordersService.getOrders, [], [statusKeys, companyIds, warehouseIds, fromDate, toDate, noInvoice, withExecutionPercentage, withItems]);
  return {
    orders: data,
    setOrders: setData,
    loading,
    setLoading,
    parameters,
    setParameters,
    setOrdersParameters: setParameters
  };
};

export default useOrders;
