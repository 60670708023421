import { DeliveryRequestResponse } from '../../services/api';
import { deliveryRequestsService } from '../../services/services';
import { useAsync } from '.';

const useDeliveryRequest = (id?: number) => {
  const { data, setData, loading } = useAsync<DeliveryRequestResponse | null>(
    deliveryRequestsService.getDeliveryRequest,
    null,
    id ? [id] : null
  );
  return {
    deliveryRequest: data,
    setDeliveryRequest: setData,
    loading
  };
};

export default useDeliveryRequest;
