import { IAsyncType, useAsync } from '.';
import {
  ItemPartnerConfigurationResponse,
  PaginatedListResponseItemPartnerConfigurationResponse
} from '../../services/api-v3';

import { amsV3Service } from '../../services/services';

export interface IItemPartnerConfiguration<T> extends IAsyncType<T> {
  itemPartnerConfigurations: T;
}

const useItemPartnerConfiguration = (
  id: number
): IItemPartnerConfiguration<ItemPartnerConfigurationResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseItemPartnerConfigurationResponse>(
      amsV3Service.getItemPartnerConfigurations,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [id],
      true
    );
  return {
    itemPartnerConfigurations: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useItemPartnerConfiguration;
