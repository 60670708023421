import { AMSConfirmDialog, AMSTable } from '../../../../helpers/ui';
import { CircularProgress, Grid, Tooltip } from '@material-ui/core';
import {
  CreateTransactionRequest,
  TransactionListResponse,
  TreasuryListResponse
} from '../../../../services/api-v3';
import { OrderStatusKeys, OrderStatusLabels } from '../../../../services/ordersService';
import React, { useMemo, useState } from 'react';
import { TransactionType, getTransactionName } from '../../../../services/AMSV3Service';
import { amsV3Service, ordersService } from '../../../../services/services';
import {
  useInvoiceTransactions,
  usePermissions,
  useStyles,
  useTreasuries
} from '../../../../helpers/hooks';

import { AccessPermissions } from '../../../../services/accessManagementService';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import AccountBalanceWalletTwoToneIcon from '@material-ui/icons/AccountBalanceWalletTwoTone';
import AddTransactionDialog from '../../../OrderComponent/Panels/OrderInvoiceAndTransactionsPanel/Dialog/AddTransactionDialog';
import MoneyTwoToneIcon from '@material-ui/icons/MoneyTwoTone';
import PaymentTwoToneIcon from '@material-ui/icons/PaymentTwoTone';

interface InvoiceTransactionsComponentProps {
  id: number;
  invoice: any;
  documentsOptions: any[];
}

const InvoiceTransactionsComponent = ({
  id,
  invoice,
  documentsOptions
}: InvoiceTransactionsComponentProps) => {
  const classes = useStyles();

  const { invoiceTransactions, setParameters, loading } = useInvoiceTransactions(id);
  const [openTransactionDialog, setOpenTransactionDialog] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const { treasuries } = useTreasuries();

  const treasuryNames: any = useMemo(
    () =>
      treasuries.reduce((res: any, treasury: TreasuryListResponse) => {
        if (!res) {
          res = {};
        }
        res[`${treasury.id}`] = treasury.name;
        return res;
      }, {}),
    [treasuries]
  );

  const [canCreateTransaction, canDeleteTransaction] = usePermissions([
    AccessPermissions.CAN_CREATE_TRANSACTION,
    AccessPermissions.CAN_DELETE_TRANSACTION
  ]);

  return loading ? (
    <CircularProgress />
  ) : (
    <Grid container spacing={2}>
      <Grid item lg={12}>
        <AMSTable
          title="Трансакции"
          columns={[
            {
              title: 'Тип',
              field: 'transactionType',
              render: (transaction: TransactionListResponse) => {
                switch (transaction.transactionType) {
                  case TransactionType.BANK_ACCOUNT:
                    return (
                      <Tooltip
                        className={classes.income}
                        title={getTransactionName(TransactionType.BANK_ACCOUNT)}
                      >
                        <PaymentTwoToneIcon />
                      </Tooltip>
                    );
                  case TransactionType.CASH:
                    return (
                      <Tooltip
                        className={classes.income}
                        title={getTransactionName(TransactionType.CASH)}
                      >
                        <AccountBalanceWalletTwoToneIcon />
                      </Tooltip>
                    );
                  case TransactionType.EXPENSE_BANK_ACCOUNT:
                    return (
                      <Tooltip
                        className={classes.expense}
                        title={getTransactionName(TransactionType.EXPENSE_BANK_ACCOUNT)}
                      >
                        <AccountBalanceTwoToneIcon />
                      </Tooltip>
                    );
                  case TransactionType.EXPENSE_CASH:
                    return (
                      <Tooltip
                        className={classes.expense}
                        title={getTransactionName(TransactionType.EXPENSE_CASH)}
                      >
                        <MoneyTwoToneIcon />
                      </Tooltip>
                    );
                }
              },
              cellStyle: { width: '10%' }
            },
            {
              title: 'Дата / Час',
              field: 'date',
              render: (transaction: TransactionListResponse) =>
                `${transaction.date.substr(0, 10)} ${transaction.date.substr(11, 8)}`,
              cellStyle: { width: '20%' }
            },
            {
              title: 'Каса',
              field: 'treasuryId',
              render: (transaction: TransactionListResponse) =>
                treasuryNames[transaction.treasuryId] ?? '',
              cellStyle: { width: '40%' }
            },
            {
              title: 'Фактура',
              field: 'displayInfo',
              render: (transaction: TransactionListResponse) =>
                transaction.displayInfo ? transaction.displayInfo : '-',
              cellStyle: { width: '20%' }
            },
            {
              title: 'Сума',
              field: 'amount',
              cellStyle: { width: '10%' },
              type: 'currency',
              currencySetting: {
                locale: 'bg',
                currencyCode: 'bgn',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }
            }
          ]}
          data={
            invoiceTransactions
              ? invoiceTransactions.sort(
                  (t1: TransactionListResponse, t2: TransactionListResponse) => t1.id - t2.id
                )
              : []
          }
          onAdd={
            canCreateTransaction
              ? () => {
                  setOpenTransactionDialog(true);
                }
              : undefined
          }
          onDelete={
            canDeleteTransaction
              ? async (transaction: any) => {
                  setActionLoading(true);
                  await amsV3Service.deleteTransaction(transaction.id);
                  const tempInvoiceTransactions = [...invoiceTransactions];
                  tempInvoiceTransactions.splice(transaction.tableData.id, 1);
                  setParameters(tempInvoiceTransactions);
                  setActionLoading(false);
                }
              : undefined
          }
          isLoading={actionLoading}
          paging={false}
          minBodyHeight="50vh"
          maxBodyHeight="50vh"
        />
      </Grid>
      <AddTransactionDialog
        isOrderTransaction={true}
        documents={documentsOptions}
        defaultAmount={invoice.remainingAmount}
        open={openTransactionDialog}
        onClose={() => setOpenTransactionDialog(false)}
        onSave={async (transaction: CreateTransactionRequest) => {
          setActionLoading(true);
          const resp = await amsV3Service.createTransaction(transaction);
          if (resp) {
            setOpenTransactionDialog(false);
            if (
              transaction.orderInvoiceId === invoice.id &&
              transaction.amount === invoice.remainingAmount
            ) {
              setOpenConfirmDialog(true);
            }
          }
          setParameters([...invoiceTransactions, { ...resp.data, amount: `${resp.data.amount}` }]);
          setActionLoading(false);
        }}
      />
      <AMSConfirmDialog
        open={openConfirmDialog}
        onConfirm={async () => {
          for (const order in invoice.orders) {
            if (invoice.orders[order].statusKey === OrderStatusKeys.AWAITING_PAYMENT) {
              await ordersService.updateOrderStatus(
                invoice.orders[order].id,
                OrderStatusKeys.FINISHED_SUCCESSFULLY
              );
            }
          }
          setOpenConfirmDialog(false);
        }}
        onClose={() => setOpenConfirmDialog(false)}
        title={'Промяна на статус!'}
        message={`Желате ли да промените статуса на поръчките в "${OrderStatusLabels.AWAITING_PAYMENT}" на "${OrderStatusLabels.FINISHED_SUCCESSFULLY}". Искате да продължите?`}
      />
    </Grid>
  );
};

export default InvoiceTransactionsComponent;
