import { Observable } from '../helpers/observable';

export interface Route {
  readonly text: string;
}

export class RoutingService {
  readonly route = new Observable<Route>({ text: '/home' });

  setRoute(text: string) {
    this.route.set({ text });
  }
}
