import { AMSApi } from '../helpers/openapi-axios-helper';
import { StocktakingRequest } from './api';

export enum StocktakingTaskStatusKeys {
  ASSIGNED = 'ASSIGNED',
  IN_PROGRESS = 'IN_PROGRESS',
  PAUSED = 'PAUSED',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED'
}

export enum StocktakingTaskStatusLabels {
  ASSIGNED = 'Зададена',
  IN_PROGRESS = 'В процес',
  PAUSED = 'На пауза',
  DONE = 'Приключена',
  CANCELLED = 'Отказана'
}

export type StocktakingTaskStatusKey =
  | StocktakingTaskStatusKeys.ASSIGNED
  | StocktakingTaskStatusKeys.IN_PROGRESS
  | StocktakingTaskStatusKeys.PAUSED
  | StocktakingTaskStatusKeys.DONE
  | StocktakingTaskStatusKeys.CANCELLED;

export type StocktakingTaskStatusLabel =
  | StocktakingTaskStatusLabels.ASSIGNED
  | StocktakingTaskStatusLabels.IN_PROGRESS
  | StocktakingTaskStatusLabels.PAUSED
  | StocktakingTaskStatusLabels.DONE
  | StocktakingTaskStatusLabels.CANCELLED;

export class StocktakingTaskStatus {
  static readonly ASSIGNED = new StocktakingTaskStatus(
    StocktakingTaskStatusKeys.ASSIGNED,
    StocktakingTaskStatusLabels.ASSIGNED,
    '#52FFFF'
  );
  static readonly IN_PROGRESS = new StocktakingTaskStatus(
    StocktakingTaskStatusKeys.IN_PROGRESS,
    StocktakingTaskStatusLabels.IN_PROGRESS,
    '#FFD452'
  );
  static readonly PAUSED = new StocktakingTaskStatus(
    StocktakingTaskStatusKeys.PAUSED,
    StocktakingTaskStatusLabels.PAUSED,
    '#00CC66'
  );
  static readonly DONE = new StocktakingTaskStatus(
    StocktakingTaskStatusKeys.DONE,
    StocktakingTaskStatusLabels.DONE,
    '#00BB00'
  );
  static readonly CANCELLED = new StocktakingTaskStatus(
    StocktakingTaskStatusKeys.CANCELLED,
    StocktakingTaskStatusLabels.CANCELLED,
    '#FF3300'
  );

  private constructor(
    public readonly key: StocktakingTaskStatusKey,
    public readonly label: StocktakingTaskStatusLabel,
    public readonly color: string
  ) {}

  toString() {
    return this.key + ' ' + this.label;
  }
}

export const stocktakingTaskStatuses: StocktakingTaskStatus[] = [
  StocktakingTaskStatus.ASSIGNED,
  StocktakingTaskStatus.IN_PROGRESS,
  StocktakingTaskStatus.PAUSED,
  StocktakingTaskStatus.DONE,
  StocktakingTaskStatus.CANCELLED
];

const invalidStatus = {
  key: 'INVALID_STATUS' as StocktakingTaskStatusKey,
  label: '<НЕВАЛИДЕН СТАТУС>',
  color: '#BBBBBB'
};

export const getStocktakingTaskStatusByKey = (key: StocktakingTaskStatusKey) =>
  stocktakingTaskStatuses.find((status: StocktakingTaskStatus) => status.key === key) ||
  invalidStatus;

export class StocktakingsService {
  getStocktakings = async (fromDate?: string, toDate?: string, warehouses?: string) => {
    const axios = await AMSApi();
    return await axios.readStocktakingsV2StocktakingsGet(fromDate, toDate, warehouses);
  };

  getStocktaking = async (id: number) => {
    const axios = await AMSApi();
    return await axios.readStocktakingV2StocktakingsIdGet(id);
  };

  getStocktakingTasks = async (id: number) => {
    const axios = await AMSApi();
    return await axios.readStocktakingTasksV2StocktakingsIdTasksGet(id);
  };

  createStocktaking = async (request: StocktakingRequest) => {
    const axios = await AMSApi();
    return await axios.createStocktakingV2StocktakingsPost(request);
  };
}
