import { AMSAutocomplete, AMSButton } from '../../../helpers/ui';
import { Container, Grid, TextField } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Lookup, { warehouseToLookup } from '../../../models/lookup';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ignoreOffset,
  isValidDate,
  toDateString,
  toEndOfDay,
  toStartOfDay
} from '../../../helpers/date-helper';
import { usePermissions, useStocktakings, useStyles, useWarehouses } from '../../../helpers/hooks';

import AMSLink from '../../../helpers/ui/AMSLink/AMSLink';
import AMSTable from '../../../helpers/ui/AMSTable/AMSTable';
import { AccessPermissions } from '../../../services/accessManagementService';
import { BrowserView } from 'react-device-detect';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Checkbox from '@material-ui/core/Checkbox';
import DateFnsUtils from '@date-io/date-fns';
import { StocktakingResponse } from '../../../services/api';
import { WarehouseListResponse } from '../../../services/api-v3';
import { cookiesService } from '../../../services/services';
import { parseFilter } from '../../../helpers/url';
import { useHistory } from 'react-router-dom';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const StocktakingsComponent = ({ location }: any) => {
  const history = useHistory();
  const classes = useStyles();

  const { fromDate, toDate, warehouseIds } = useMemo(
    () => parseFilter(location.search),
    [location.search]
  );

  const filter = useMemo(() => cookiesService.getStocktakingsFilter(), []);
  const [filterFromDate, setFilterFromDate] = useState<Date | null>(
    fromDate
      ? fromDate
      : filter.filterFromDate
      ? ignoreOffset(new Date(filter.filterFromDate))
      : new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  const [filterToDate, setFilterToDate] = useState<Date | null>(
    fromDate
      ? toDate
      : filter.filterToDate
      ? ignoreOffset(new Date(filter.filterToDate))
      : new Date()
  );
  const [filterWarehouses, setFilterWarehouses] = useState<Lookup[]>([]);

  const { stocktakings, loading, setParameters } = useStocktakings(
    filterFromDate ? toDateString(filterFromDate) : undefined,
    filterToDate ? toDateString(filterToDate) : undefined,
    warehouseIds?.join(',') ?? filterWarehouses.map((p: Lookup) => p.id).join(',')
  );
  const { warehouses } = useWarehouses();

  useEffect(() => {
    const { filterWarehouses } = filter;
    setFilterWarehouses(
      warehouseIds && warehouses
        ? warehouses
            .filter((w: WarehouseListResponse) => warehouseIds.includes(w.id))
            .map(warehouseToLookup)
        : filterWarehouses ?? []
    );
  }, [warehouseIds, warehouses, filter]);

  const [canCreate] = usePermissions([
    AccessPermissions.CAN_CREATE_STOCKTAKING,
  ]);

  const onSearch = useCallback(() => {
    const fromDate = isValidDate(filterFromDate) ? new Date(toStartOfDay(filterFromDate!)) : null;
    const toDate = isValidDate(filterToDate) ? new Date(toEndOfDay(filterToDate!)) : null;

    cookiesService.setStocktakingsFilter({
      filterFromDate: fromDate,
      filterToDate: toDate,
      filterWarehouses
    });
    let params: any = {};
    if (filterFromDate) {
      params.fromDate = toDateString(filterFromDate);
    }
    if (filterToDate) {
      params.toDate = toDateString(filterToDate);
    }
    if (filterWarehouses?.length > 0) {
      params.warehouseIds = filterWarehouses.map((w) => w.id).join(',');
    }

    history.push({
      pathname: '/stocktakings',
      search: new URLSearchParams(params).toString()
    });
    setParameters([
      fromDate ? toDateString(fromDate) : undefined,
      toDate ? toDateString(toDate) : undefined,
      filterWarehouses.map((p: Lookup) => p.id).join(',')
    ]);
  }, [filterWarehouses, filterFromDate, filterToDate, history, setParameters]);

  return (
    <>
      <BrowserView>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="text-align-left">
            <Container maxWidth="md" className={classes.container}>
              <Grid container spacing={1}>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk={true}
                    variant="inline"
                    format="dd/MM/yy"
                    margin="dense"
                    label="От"
                    helperText={''}
                    value={filterFromDate ? filterFromDate : null}
                    onChange={(value: Date | null) => {
                      if (value) {
                        if (isValidDate(value)) {
                          setFilterFromDate(ignoreOffset(value));
                        }
                      } else {
                        setFilterFromDate(null);
                      }
                    }}
                    inputVariant="outlined"
                    fullWidth
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    maxDate={filterToDate}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk={true}
                    variant="inline"
                    format="dd/MM/yy"
                    margin="dense"
                    label="До"
                    helperText={''}
                    value={filterToDate ? filterToDate : null}
                    onChange={(value: Date | null) => {
                      if (value) {
                        if (isValidDate(value)) {
                          setFilterToDate(ignoreOffset(value));
                        }
                      } else {
                        setFilterToDate(null);
                      }
                    }}
                    inputVariant="outlined"
                    fullWidth
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    minDate={filterFromDate}
                  />
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <AMSAutocomplete
                    multiple
                    minChar={0}
                    limitTags={3}
                    options={warehouses.map(warehouseToLookup)}
                    disableCloseOnSelect
                    getOptionLabel={(option: any) => option.value}
                    renderOption={(option, { selected }) => (
                      <Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          className={classes.checkbox}
                          checked={selected}
                          color="primary"
                        />
                        {option.value}
                      </Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Складове"
                        placeholder=""
                        margin="dense"
                        fullWidth
                      />
                    )}
                    value={filterWarehouses}
                    onChange={setFilterWarehouses}
                    groupBy={(g) => (g.group ? g.group : '')}
                  />
                </Grid>
                <Grid item lg={1} md={4} sm={12} xs={12}>
                  <AMSButton
                    color="primary"
                    variant="contained"
                    text="Търсене"
                    loading={loading}
                    disabled={false}
                    onClick={onSearch}
                    style={{
                      marginTop: 8
                    }}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <AMSTable
                    title="Ревизии"
                    columns={[
                      {
                        title: '№',
                        field: 'id',
                        render: (stocktaking: StocktakingResponse) => (
                          <AMSLink href={`/stocktaking?id=${stocktaking.id}`}>
                            {stocktaking.id}
                          </AMSLink>
                        ),
                        cellStyle: { width: '10%' }
                      },
                      {
                        title: 'Склад',
                        field: 'warehouseName',
                        cellStyle: { width: '30%' }
                      },
                      {
                        title: 'Дата на изпълнение',
                        field: 'executionDate',
                        cellStyle: { width: '30%' }
                      },
                      {
                        title: 'Брой артикули',
                        field: 'items',
                        render: (stocktaking: StocktakingResponse) => stocktaking.items.length,
                        cellStyle: { width: '30%' }
                      }
                    ]}
                    pageSize={10}
                    data={stocktakings}
                    onAdd={canCreate ? () => history.push(`/stocktaking`) : undefined}
                    isLoading={loading}
                  />
                </Grid>
              </Grid>
            </Container>
          </div>
        </MuiPickersUtilsProvider>
      </BrowserView>
    </>
  );
};

export default StocktakingsComponent;
