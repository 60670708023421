import { ClientResponse, CreateClientRequest } from '../../../../services/api-v3';
import React, { useMemo, useState } from 'react';
import { accessManagementService, amsV3Service } from '../../../../services/services';

import AMSConfirmDialog from '../../../../helpers/ui/AMSConfirmDialog/AMSConfirmDialog';
import AMSTable from '../../../../helpers/ui/AMSTable/AMSTable';
import { AccessPermissions } from '../../../../services/accessManagementService';

interface PartnerClientsProps {
  partnerId: number;
  clients: ClientResponse[];
  setClients: (clients: ClientResponse[]) => void;
}

const PartnerClientsComponent = ({ partnerId, clients, setClients }: PartnerClientsProps) => {
  const [client, setClient] = useState<ClientResponse | null>(null);
  const [openEmailPasswordDialog, setOpenEmailPasswordDialog] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  const canUpdate = useMemo(
    () => accessManagementService.hasPermission(AccessPermissions.CAN_UPDATE_PARTNER),
    []
  );
  return (
    <>
      <AMSTable
        title="Потребители"
        columns={[
          {
            title: 'Е-мейл',
            field: 'email',
            cellStyle: { width: '20%' },
            editable: 'onAdd',
            validate: (rowData: ClientResponse) =>
              rowData.email === '' ? 'Полето е задължително' : ''
          },
          {
            title: 'Име',
            field: 'firstName',
            cellStyle: { width: '15%' },
            validate: (rowData: ClientResponse) =>
              rowData.firstName === '' ? 'Полето е задължително' : ''
          },
          {
            title: 'Фамилия',
            field: 'lastName',
            cellStyle: { width: '15%' },
            validate: (rowData: ClientResponse) =>
              rowData.lastName === '' ? 'Полето е задължително' : ''
          },
          {
            title: 'Телефон',
            field: 'phone',
            cellStyle: { width: '20%' },
            validate: (rowData: ClientResponse) =>
              rowData.phone === '' ? 'Полето е задължително' : ''
          },
          {
            title: 'Основен',
            field: 'isMain',
            type: 'boolean',
            cellStyle: { width: '10%' }
          },
          {
            title: 'Активен',
            field: 'isActive',
            type: 'boolean',
            cellStyle: { width: '10%' }
          }
        ]}
        data={clients}
        options={{
          search: true,
          sorting: true,
          pageSize: 15,
          pageSizeOptions: [],
          actionsColumnIndex: -1
        }}
        inlineActions={true}
        onAdd={
          canUpdate
            ? async (client: CreateClientRequest) => {
                setActionLoading(true);
                client.partnerId = partnerId;
                const resp = await amsV3Service.createClient(client);
                if (resp && resp.data) {
                  setClients([...clients, resp.data].sort((a, b) => a.id - b.id));
                }
                setActionLoading(false);
              }
            : undefined
        }
        onEdit={
          canUpdate
            ? async (client: any) => {
                setActionLoading(true);
                const resp = await amsV3Service.updateClient(client.id, client);
                if (resp && resp.data) {
                  setClients(
                    [
                      ...clients.filter((c: ClientResponse) => c.id !== resp.data.id),
                      resp.data
                    ].sort((a, b) => a.id - b.id)
                  );
                }
                setActionLoading(false);
              }
            : undefined
        }
        onDelete={
          canUpdate
            ? async (client: ClientResponse) => {
                setActionLoading(true);
                const resp = await amsV3Service.deleteClient(client.id);
                if (resp && resp.data) {
                  setClients([...clients.filter((c: ClientResponse) => c.id !== resp.data.id)]);
                }
                setActionLoading(false);
              }
            : undefined
        }
        actions={
          canUpdate
            ? [
                {
                  icon: 'mail',
                  tooltip: 'Е-мейл за смяна на парола',
                  onClick: (event, rowData) => {
                    setClient(rowData);
                    setOpenEmailPasswordDialog(true);
                  }
                }
              ]
            : undefined
        }
        parentChildData={(row: ClientResponse, rows: ClientResponse[]) =>
          rows.find((a: ClientResponse) => a.id === row.parentId)
        }
        isLoading={actionLoading}
      />
      <AMSConfirmDialog
        open={openEmailPasswordDialog}
        onConfirm={async () => {
          if (client) {
            await amsV3Service.sendPasswordResetEmail(client.email);
            setOpenEmailPasswordDialog(false);
            setClient(null);
          }
        }}
        onClose={() => {
          setOpenEmailPasswordDialog(false);
          setClient(null);
        }}
        title={'Изпращане на имейл за задаване на парола'}
        message={
          client ? `Искате ли да изпратите имейл за задаване на порола на "${client.email}"?` : ''
        }
      />
    </>
  );
};

export default PartnerClientsComponent;
