import {
  FavoriteItemResponse,
  PaginatedListResponseFavoriteItemResponse
} from '../../services/api-v3';
import { IAsyncType, useAsync } from '.';

import { amsV3Service } from '../../services/services';

export interface IClientFavorites<T> extends IAsyncType<T> {
  clientFavorites: T;
}

const useClientFavorites = (id: number): IClientFavorites<FavoriteItemResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseFavoriteItemResponse>(
      amsV3Service.getClientFavorites,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [id ? [id] : null],
      true
    );
  return {
    clientFavorites: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useClientFavorites;
