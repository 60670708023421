import {
  CreateDeliveryRequestRequest,
  SetPricesRequest,
  StartStockReceivingProcessRequest,
  UpdateBatchesRequest,
  UpdateDeliveryRequestRequest,
  VerifyQuantitiesRequest
} from './api';

import { AMSApi } from '../helpers/openapi-axios-helper';

export enum DeliveryRequestStatusKeys {
  DRAFT = 'DRAFT',
  REQUESTED = 'REQUESTED',
  DELAYED = 'DELAYED',
  FOR_INSPECTION = 'FOR_INSPECTION',
  INSPECTION_IN_PROGRESS = 'INSPECTION_IN_PROGRESS',
  INSPECTED = 'INSPECTED',
  INSPECTED_WITH_REMARKS = 'INSPECTED_WITH_REMARKS',
  VERIFIED = 'VERIFIED',
  DELIVERED = 'DELIVERED',
  CANCELLED = 'CANCELLED',
  DECLINED_AFTER_VERIFICATION = 'DECLINED_AFTER_VERIFICATION'
}

export enum DeliveryRequestStatusLabels {
  DRAFT = 'Чернова',
  REQUESTED = 'Заявена',
  DELAYED = 'Забавена',
  FOR_INSPECTION = 'За проверка',
  INSPECTION_IN_PROGRESS = 'В процес на проверка',
  INSPECTED = 'Проверена',
  INSPECTED_WITH_REMARKS = 'Проверена, със забележки',
  VERIFIED = 'Потвърдена',
  DELIVERED = 'Доставена',
  CANCELLED = 'Отказана',
  DECLINED_AFTER_VERIFICATION = 'Отказана след проверка'
}

export type DeliveryRequestStatusKey =
  | DeliveryRequestStatusKeys.DRAFT
  | DeliveryRequestStatusKeys.REQUESTED
  | DeliveryRequestStatusKeys.DELAYED
  | DeliveryRequestStatusKeys.FOR_INSPECTION
  | DeliveryRequestStatusKeys.INSPECTION_IN_PROGRESS
  | DeliveryRequestStatusKeys.INSPECTED
  | DeliveryRequestStatusKeys.INSPECTED_WITH_REMARKS
  | DeliveryRequestStatusKeys.VERIFIED
  | DeliveryRequestStatusKeys.DELIVERED
  | DeliveryRequestStatusKeys.CANCELLED
  | DeliveryRequestStatusKeys.DECLINED_AFTER_VERIFICATION;

export type DeliveryRequestStatusLabel =
  | DeliveryRequestStatusLabels.DRAFT
  | DeliveryRequestStatusLabels.REQUESTED
  | DeliveryRequestStatusLabels.DELAYED
  | DeliveryRequestStatusLabels.FOR_INSPECTION
  | DeliveryRequestStatusLabels.INSPECTION_IN_PROGRESS
  | DeliveryRequestStatusLabels.INSPECTED
  | DeliveryRequestStatusLabels.INSPECTED_WITH_REMARKS
  | DeliveryRequestStatusLabels.VERIFIED
  | DeliveryRequestStatusLabels.DELIVERED
  | DeliveryRequestStatusLabels.CANCELLED
  | DeliveryRequestStatusLabels.DECLINED_AFTER_VERIFICATION;

export class DeliveryRequestStatus {
  static readonly DRAFT = new DeliveryRequestStatus(
    DeliveryRequestStatusKeys.DRAFT,
    DeliveryRequestStatusLabels.DRAFT,
    '#BBBBBB'
  );

  static readonly REQUESTED = new DeliveryRequestStatus(
    DeliveryRequestStatusKeys.REQUESTED,
    DeliveryRequestStatusLabels.REQUESTED,
    '#52FFFF'
  );
  static readonly DELAYED = new DeliveryRequestStatus(
    DeliveryRequestStatusKeys.DELAYED,
    DeliveryRequestStatusLabels.DELAYED,
    '#52A8FF'
  );
  static readonly FOR_INSPECTION = new DeliveryRequestStatus(
    DeliveryRequestStatusKeys.FOR_INSPECTION,
    DeliveryRequestStatusLabels.FOR_INSPECTION,
    '#FFD452'
  );
  static readonly INSPECTION_IN_PROGRESS = new DeliveryRequestStatus(
    DeliveryRequestStatusKeys.INSPECTION_IN_PROGRESS,
    DeliveryRequestStatusLabels.INSPECTION_IN_PROGRESS,
    '#FFD452'
  );
  static readonly INSPECTED = new DeliveryRequestStatus(
    DeliveryRequestStatusKeys.INSPECTED,
    DeliveryRequestStatusLabels.INSPECTED,
    '#00CC66'
  );
  static readonly INSPECTED_WITH_REMARKS = new DeliveryRequestStatus(
    DeliveryRequestStatusKeys.INSPECTED_WITH_REMARKS,
    DeliveryRequestStatusLabels.INSPECTED_WITH_REMARKS,
    '#00BB00'
  );
  static readonly VERIFIED = new DeliveryRequestStatus(
    DeliveryRequestStatusKeys.VERIFIED,
    DeliveryRequestStatusLabels.VERIFIED,
    '#00AA00'
  );
  static readonly DELIVERED = new DeliveryRequestStatus(
    DeliveryRequestStatusKeys.DELIVERED,
    DeliveryRequestStatusLabels.DELIVERED,
    '#009900'
  );
  static readonly CANCELLED = new DeliveryRequestStatus(
    DeliveryRequestStatusKeys.CANCELLED,
    DeliveryRequestStatusLabels.CANCELLED,
    '#FF3300'
  );
  static readonly DECLINED_AFTER_VERIFICATION = new DeliveryRequestStatus(
    DeliveryRequestStatusKeys.DECLINED_AFTER_VERIFICATION,
    DeliveryRequestStatusLabels.DECLINED_AFTER_VERIFICATION,
    '#FF3300'
  );

  private constructor(
    public readonly key: DeliveryRequestStatusKey,
    public readonly label: DeliveryRequestStatusLabel,
    public readonly color: string
  ) {}

  toString() {
    return this.key + ' ' + this.label;
  }
}

export const deliveryRequestStatuses: DeliveryRequestStatus[] = [
  DeliveryRequestStatus.DRAFT,
  DeliveryRequestStatus.REQUESTED,
  DeliveryRequestStatus.DELAYED,
  DeliveryRequestStatus.FOR_INSPECTION,
  DeliveryRequestStatus.INSPECTION_IN_PROGRESS,
  DeliveryRequestStatus.INSPECTED,
  DeliveryRequestStatus.INSPECTED_WITH_REMARKS,
  DeliveryRequestStatus.VERIFIED,
  DeliveryRequestStatus.DELIVERED,
  DeliveryRequestStatus.CANCELLED,
  DeliveryRequestStatus.DECLINED_AFTER_VERIFICATION
];

const invalidStatus = {
  key: 'INVALID_STATUS' as DeliveryRequestStatusKey,
  label: '<НЕВАЛИДЕН СТАТУС>' as DeliveryRequestStatusLabel,
  color: '#BBBBBB'
};

export const getDeliveryRequestStatusByKey = (
  key: DeliveryRequestStatusKey
): DeliveryRequestStatus =>
  deliveryRequestStatuses.find((status: DeliveryRequestStatus) => status.key === key) ||
  invalidStatus;

export const getDeliveryRequestStatusLabelByKey = (key: DeliveryRequestStatusKey) =>
  getDeliveryRequestStatusByKey(key).label;

export class DeliveryRequestsService {
  getDeliveryRequests = async (
    search?: string,
    supplierIds?: number[],
    companyIds?: number[],
    warehouseIds?: number[],
    fromDate?: string,
    toDate?: string
  ) => {
    const axios = await AMSApi();
    return await axios.readListV1DeliveryRequestsGet(
      search,
      supplierIds?.join(','),
      companyIds?.join(','),
      warehouseIds?.join(','),
      fromDate,
      toDate
    );
  };

  getDeliveryRequest = async (id: number) => {
    const axios = await AMSApi();
    return await axios.readV1DeliveryRequestsIdGet(id);
  };

  updateDeliveryRequest = async (
    id: number,
    updateDeliveryRequestRequest: UpdateDeliveryRequestRequest
  ) => {
    const axios = await AMSApi();
    return await axios.updateDeliveryRequestV1DeliveryRequestsDeliveryRequestIdPatch(
      id,
      updateDeliveryRequestRequest
    );
  };

  createDeliveryRequest = async (
    request: CreateDeliveryRequestRequest,
    shouldSendEmail = false
  ) => {
    const axios = await AMSApi();
    if (shouldSendEmail) {
      return await axios.createAndSendEmailV1DeliveryRequestsSendEmailPost(request);
    }
    return await axios.createV1DeliveryRequestsPost(request);
  };

  createStockReceivingProcess = async (
    deliveryRequestId: number,
    request: StartStockReceivingProcessRequest
  ) => {
    const axios = await AMSApi();
    return await axios.startStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPost(
      deliveryRequestId,
      request
    );
  };

  updateStockReceivingProcessUpdateBatches = async (
    deliveryRequestId: number,
    request: UpdateBatchesRequest
  ) => {
    const axios = await AMSApi();
    return await axios.updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch(
      deliveryRequestId,
      JSON.parse(JSON.stringify(request))
    );
  };

  updateStockReceivingProcessVerifiedQuantities = async (
    deliveryRequestId: number,
    request: VerifyQuantitiesRequest
  ) => {
    const axios = await AMSApi();
    return await axios.updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch(
      deliveryRequestId,
      JSON.parse(JSON.stringify(request))
    );
  };

  updateStockReceivingProcessSetPrices = async (
    deliveryRequestId: number,
    request: SetPricesRequest
  ) => {
    const axios = await AMSApi();
    return await axios.updateStockReceivingProcessV2DeliveryRequestsDeliveryRequestIdProcessPatch(
      deliveryRequestId,
      JSON.parse(JSON.stringify(request))
    );
  };

  getStockReceivingProcess = async (deliveryRequestId: number) => {
    const axios = await AMSApi();
    return await axios.readStockReceivingProcessByDeliveryRequestIdV2DeliveryRequestsDeliveryRequestIdProcessGet(
      deliveryRequestId
    );
  };
}
