import React, { useMemo } from 'react';
import { StockTakingTaskItemResponse, StockTakingTaskResponse } from '../../../../services/api';
import {
  StocktakingTaskStatusKey,
  getStocktakingTaskStatusByKey
} from '../../../../services/stocktakingsService';

import { AMSTable } from '../../../../helpers/ui';
import { Grid } from '@material-ui/core';
import { useStocktakingTasks } from '../../../../helpers/hooks';

interface StocktakingTasksComponentProps {
  id: number;
}

const StocktakingTasksComponent = ({ id }: StocktakingTasksComponentProps) => {
  const { tasks, loading } = useStocktakingTasks(id);
  const columns = useMemo(
    () => [
      {
        title: 'Артикул',
        field: 'itemName',
        cellStyle: { width: `58%` }
      },
      ...tasks.map((task: StockTakingTaskResponse) => ({
        title: `${task.userResponsibleName} (${
          getStocktakingTaskStatusByKey(task.status as StocktakingTaskStatusKey).label
        })`,
        field: `${task.id}${task.userResponsibleName}`,
        cellStyle: { width: `${42 / (tasks.length + 1)}%` }
      }))
    ],
    [tasks]
  );
  const rows = useMemo(
    () =>
      Object.values(
        tasks.reduce((res: any, task: StockTakingTaskResponse) => {
          if (!res) {
            res = {};
          }
          const items = task.items.reduce((itemsMap: any, ti: StockTakingTaskItemResponse) => {
            if (!itemsMap) {
              itemsMap = {};
            }
            const itemRecord = {
              [ti.itemId]: {
                ...(res[ti.itemId] ? res[ti.itemId] : { itemName: ti.name }),
                [`${task.id}${task.userResponsibleName}`]: ti.quantity
              }
            };
            return { ...itemsMap, ...itemRecord };
          }, {});
          return { ...res, ...items };
        }, {})
      ),
    [tasks]
  );
  return (
    <Grid container spacing={2}>
      <Grid item lg={12}>
        <AMSTable
          title={'Артикули'}
          isLoading={loading}
          data={rows}
          columns={columns}
          paging={false}
        />
      </Grid>
    </Grid>
  );
};

export default StocktakingTasksComponent;
