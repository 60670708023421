import { AMSApi } from '../helpers/openapi-axios-helper';
import { SetOrderingPolicyRequest } from './api';

export class LegalEntitiesService {
  getOrderingPolicy = async (id: number) => {
    const axios = await AMSApi();
    return await axios.readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet(id);
  };

  setOrderingPolicy = async (id: number, orderPolicy: SetOrderingPolicyRequest) => {
    const axios = await AMSApi();
    return await axios.setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut(id, orderPolicy);
  };

  getOrdersForCollectionAmount = async (legalEntityId: number) => {
    const axios = await AMSApi();
    return await axios.getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet(
      legalEntityId
    );
  };

  getOrdersForDistributionAmount = async (legalEntityId: number) => {
    const axios = await AMSApi();
    return await axios.getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet(
      legalEntityId
    );
  };

  getPendingPaymentsAmount = async (legalEntityId: number) => {
    const axios = await AMSApi();
    return await axios.getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet(
      legalEntityId
    );
  };

  getOverduePaymentsAmount = async (legalEntityId: number) => {
    const axios = await AMSApi();
    return await axios.getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet(
      legalEntityId
    );
  };
}
