import { IAsyncType, useAsync } from '.';
import {
  PaginatedListResponseReportDeliveryItemExtendedResponse,
  ReportDeliveryItemExtendedResponse
} from '../../services/api-v3';

import { amsV3Service } from '../../services/services';

export interface IReportDeliveryItems<T> extends IAsyncType<T> {
  deliveryItems: T;
}

const useReportDeliveryItems = (
  fromDeliveryDate?: string,
  toDeliveryDate?: string,
  supplierId?: number,
  companies?: number[],
  warehouses?: number[],
  itemCategories?: number[],
  itemId?: number
): IReportDeliveryItems<ReportDeliveryItemExtendedResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseReportDeliveryItemExtendedResponse>(
      amsV3Service.getDeliveryItems,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [fromDeliveryDate, toDeliveryDate, supplierId, companies, warehouses, itemCategories, itemId]
    );
  return {
    deliveryItems: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useReportDeliveryItems;
