import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { PricingPolicyResponse } from '../../services/api';
import { pricingPoliciesService } from '../../services/services';

export interface IPricingPolicies<T> extends IAsyncType<T> {
  pricingPolicies: T;
  setPricingPolicies: Dispatch<SetStateAction<T>>;
}

const usePricingPolicies = (
  search?: string,
): IPricingPolicies<PricingPolicyResponse[]> => {
  const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
  PricingPolicyResponse[]
  >(pricingPoliciesService.getPricingPolicies, [], [search]);
  return {
    pricingPolicies: data,
    setPricingPolicies: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default usePricingPolicies;
