export const parseQuery = (query: any): Record<string, any> =>
  query
    .substring(1)
    .split('&')
    .map((str: any) => {
      let [key, value] = str.split('=');
      return { [key]: decodeURI(value) };
    })
    .reduce((prev: any, curr: any) => Object.assign(prev, curr));

interface FormFilter {
  fromStartDate: Date;
  toStartDate: Date;
  fromEndDate: Date;
  toEndDate: Date;
  toDate: Date;
  fromDate: Date;
  warehouseIds: number[];
  companyIds: number[];
  partnerIds: number[];
  itemCategoryIds: number[];
  itemId: number;
  itemIds: number[];
  userIds: number[];
  legalEntityIds: number[];
  typeIds: string[];
  supplierIds: number[];
  receiverIds: number[];
  hidePaid: boolean;
  hideDepleted: boolean;
  showInactive: boolean;
  noInvoice: boolean;
  withExecutionPercentage: boolean;
  realTime: boolean;
  transactionTypeIds: number[];
  treasuryIds: number[];
  statusKeys: string[];
  hideCancelled: boolean;
  hidePaidInFull: boolean;
  partnerId: number;
  supplierId: number;
}

const dateKeys = ['toDate', 'fromDate', 'fromStartDate', 'toStartDate', 'fromEndDate', 'toEndDate'];
const flagKeys = [
  'realTime',
  'hideDepleted',
  'noInvoice',
  'hideDepleted',
  'showInactive',
  'noInvoice',
  'withExecutionPercentage',
  'hidePaidInFull',
  'hideCancelled'
];

export const parseFilter = (query: any): FormFilter =>
  query
    .substring(1)
    .split('&')
    .map((str: any) => {
      let [key, value] = str.split('=');
      let parsedValue: Date | number[] | string[] | boolean | null = null;
      if (dateKeys.includes(key)) {
        parsedValue = new Date(decodeURIComponent(value));
      }
      if (flagKeys.includes(key)) {
        parsedValue = value ? decodeURIComponent(value) === 'true' : null;
      }
      if (key === 'statusKeys') {
        parsedValue = decodeURIComponent(value).split(',');
      }
      if (!dateKeys.includes(key) && !flagKeys.includes(key)) {
        parsedValue = decodeURIComponent(value)
          .split(',')
          .map((v) => +v)
          .filter((v) => v >= 0);
      }
      return { [key]: parsedValue };
    })
    .reduce((prev: any, curr: any) => Object.assign(prev, curr));
