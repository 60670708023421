import { IAsyncType, useAsync } from '.';
import {
  PaginatedListResponseWarehouseListResponse,
  WarehouseListResponse
} from '../../services/api-v3';

import { amsV3Service } from '../../services/services';

export interface IWarehouses<T> extends IAsyncType<T> {
  warehouses: T;
}

const useWarehouses = (
  search?: string,
  showInactive: boolean = false
): IWarehouses<WarehouseListResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseWarehouseListResponse>(
      amsV3Service.getWarehouses,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [search, showInactive]
    );
  return {
    warehouses: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useWarehouses;
