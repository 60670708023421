import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { StockReceivingProcessResponse } from '../../services/api';
import { deliveryRequestsService } from '../../services/services';

export interface IStockReceivingProcess<T> extends IAsyncType<T> {
  stockReceivingProcess: T | null;
  setStockReceivingProcess: Dispatch<SetStateAction<T | null>>;
}

const useStockReceivingProcess = (
  id?: number
): IStockReceivingProcess<StockReceivingProcessResponse | null> => {
  const { data, setData, loading, setLoading, parameters, setParameters } =
    useAsync<StockReceivingProcessResponse | null>(
      deliveryRequestsService.getStockReceivingProcess,
      null,
      id ? [id] : null
    );

  return {
    stockReceivingProcess: data,
    setStockReceivingProcess: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useStockReceivingProcess;
