import { AMSApi, AMSV3Api } from '../helpers/openapi-axios-helper';

import { OrderStatusKey } from './ordersService';

export class ReportsService {
  getDeliveryDocuments = async (
    fromDate?: string,
    toDate?: string,
    suppliersIds?: number[],
    companyIds?: number[],
    hidePaid?: boolean
  ) => {
    const axios = await AMSApi();
    return await axios.readDeliveryInvoicesV1ReportsDeliveryInvoiceGet(
      fromDate,
      toDate,
      suppliersIds?.join(','),
      companyIds?.join(','),
      hidePaid
    );
  };

  getStockByOrders = async (
    warehouseId: number,
    toDate: string,
    hideOrdersWithoutDeliveryDate?: boolean
  ) => {
    const axios = await AMSApi();
    return await axios.readWarehouseStockByOrdersV1ReportsStockByOrdersGet(
      warehouseId,
      toDate,
      hideOrdersWithoutDeliveryDate
    );
  };

  getItemPrices = async (itemIds: number[]) => {
    const axios = await AMSApi();
    return await axios.readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet(
      itemIds.join(',')
    );
  };

  getItemCategoryMultipliers = async (itemCategoryIds: number[]) => {
    const axios = await AMSApi();
    return await axios.readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet(
      itemCategoryIds.join(',')
    );
  };

  getAvailabilities = async (
    itemIds?: number[],
    warehouseIds?: number[],
    realTime: boolean = false,
    page: number = 1,
    size: number = 10000
  ) => {
    const axios = await AMSV3Api();
    return await axios.readAvailabilitiesAvailabilitiesGet(
      itemIds,
      warehouseIds,
      realTime,
      page,
      size
    );
  };

  getOrdersBalance = async (
    statusKeys?: OrderStatusKey[],
    companyIds?: number[],
    warehouseIds?: number[],
    fromDate?: string,
    toDate?: string,
    noInvoice: boolean = false
  ) => {
    const axios = await AMSApi();
    return await axios.readOrdersBalanceV1ReportsOrdersBalanceGet(
      statusKeys?.join(','),
      companyIds?.join(','),
      warehouseIds?.join(','),
      fromDate,
      toDate,
      noInvoice,
      false
    );
  };

  getDepletedAmountsWithPrices = async (
    fromDate?: string,
    toDate?: string,
    partners?: string,
    companies?: string,
    warehouses?: string,
    itemCategories?: string,
    itemId?: number
  ) => {
    const axios = await AMSApi();
    return await axios.readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet(
      fromDate,
      toDate,
      partners,
      companies,
      warehouses,
      itemCategories,
      itemId
    );
  };

  getItemMovements = async (
    fromDate?: string,
    toDate?: string,
    partners?: number[],
    warehouses?: number[]
  ) => {
    const axios = await AMSApi();
    return await axios.getAggregatedItemMovementsV2ReportsItemMovementsBalanceGet(
      fromDate,
      toDate,
      partners,
      warehouses
    );
  };
}
