import { AMSAutocomplete, AMSButton } from '../../../../helpers/ui';
import { Checkbox, Container, FormControlLabel, Grid, TextField } from '@material-ui/core';
import Lookup, {
  companyToLookup,
  orderStatusToLookup,
  warehouseToLookup
} from '../../../../models/lookup';
import React, { Fragment, useCallback, useState } from 'react';
import {
  ignoreOffset,
  isValidDate,
  toEndOfDay,
  toStartOfDay
} from '../../../../helpers/date-helper';
import {
  useB2BOrders,
  useCompanies,
  useFlag,
  useStyles,
  useWarehouses
} from '../../../../helpers/hooks';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { KeyboardDatePicker } from '@material-ui/pickers';
import OrdersTableComponent from '../../../OrdersComponent/OrdersTableComponent/OrdersTableComponent';
import { orderStatuses } from '../../../../services/ordersService';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface B2BOrdersComponentProps {
  partnerId: number;
}

const PartnerB2BOrdersComponent = ({ partnerId }: B2BOrdersComponentProps) => {
  const classes = useStyles();
  const { companies } = useCompanies();
  const { warehouses } = useWarehouses();

  const [filterStatus, setFilterStatus] = useState<Lookup[]>([]);
  const [filterFromDate, setFilterFromDate] = useState<Date | null>(
    new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  const [filterToDate, setFilterToDate] = useState<Date | null>(new Date());
  const [filterCompanies, setFilterCompanies] = useState<Lookup[]>([]);
  const [filterWarehouses, setFilterWarehouses] = useState<Lookup[]>([]);
  const [filterWithExecutionPercentage, setFilterWithExecutionPercentage] = useFlag(false);

  const { b2bOrders, loading, setLoading, setParameters } = useB2BOrders(
    filterStatus.map((s: Lookup) => s.id),
    filterCompanies.map((c: Lookup) => c.id),
    filterWarehouses.map((w: Lookup) => w.id),
    [partnerId],
    undefined,
    !filterWithExecutionPercentage && filterFromDate ? toStartOfDay(filterFromDate) : undefined,
    !filterWithExecutionPercentage && filterToDate ? toEndOfDay(filterToDate) : undefined,
    filterWithExecutionPercentage
  );

  const onSearch = useCallback(() => {
    setLoading(true);
    const fromDate =
      filterFromDate === null || isValidDate(filterFromDate)
        ? toStartOfDay(filterFromDate!)
        : undefined;
    const toDate =
      filterToDate === null || isValidDate(filterToDate) ? toEndOfDay(filterToDate!) : undefined;

    setParameters([
      filterStatus.map((status: Lookup) => status.id),
      filterCompanies.map((c: Lookup) => c.id),
      filterWarehouses.map((w: Lookup) => w.id),
      [partnerId],
      undefined,
      filterWithExecutionPercentage ? undefined : fromDate,
      filterWithExecutionPercentage ? undefined : toDate,
      filterWithExecutionPercentage
    ]);
    setLoading(false);
  }, [
    setLoading,
    filterFromDate,
    filterToDate,
    filterStatus,
    filterCompanies,
    filterWarehouses,
    partnerId,
    filterWithExecutionPercentage,
    setParameters
  ]);

  return (
    <div className="text-align-left">
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          {!filterWithExecutionPercentage && (
            <Grid item lg={8} md={12} sm={12} xs={12}>
              <AMSAutocomplete
                multiple
                minChar={0}
                limitTags={3}
                options={orderStatuses.sort((a, b) => a.id - b.id).map(orderStatusToLookup)}
                sortOptions={false}
                disableCloseOnSelect
                renderOption={(option, { selected }) => (
                  <Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      className={classes.checkbox}
                      checked={selected}
                      color="primary"
                    />
                    {option.value}
                  </Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Статус"
                    placeholder=""
                    margin="dense"
                    fullWidth
                  />
                )}
                value={filterStatus}
                onChange={(values) => setFilterStatus(values)}
              />
            </Grid>
          )}
          {!filterWithExecutionPercentage && (
            <Grid item lg={2} md={6} sm={12} xs={12}>
              <KeyboardDatePicker
                disableToolbar
                autoOk={true}
                variant="inline"
                format="dd/MM/yy"
                margin="dense"
                label="От дата"
                helperText={''}
                value={filterFromDate ? filterFromDate : null}
                onChange={(value: Date | null) => {
                  if (value) {
                    if (isValidDate(value)) {
                      setFilterFromDate(ignoreOffset(value));
                    }
                  } else {
                    setFilterFromDate(null);
                  }
                }}
                inputVariant="outlined"
                fullWidth
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                maxDate={filterToDate}
              />
            </Grid>
          )}
          {!filterWithExecutionPercentage && (
            <Grid item lg={2} md={6} sm={12} xs={12}>
              <KeyboardDatePicker
                disableToolbar
                autoOk={true}
                variant="inline"
                format="dd/MM/yy"
                margin="dense"
                label="До дата"
                helperText={''}
                value={filterToDate ? filterToDate : null}
                onChange={(value: Date | null) => {
                  if (value) {
                    if (isValidDate(value)) {
                      setFilterToDate(ignoreOffset(value));
                    }
                  } else {
                    setFilterToDate(null);
                  }
                }}
                inputVariant="outlined"
                fullWidth
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                minDate={filterFromDate}
              />
            </Grid>
          )}
          <Grid item lg={5} md={6} sm={12} xs={12}>
            <AMSAutocomplete
              multiple
              minChar={0}
              limitTags={3}
              options={companies.map(companyToLookup)}
              disableCloseOnSelect
              renderOption={(option, { selected }) => (
                <Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    className={classes.checkbox}
                    checked={selected}
                    color="primary"
                  />
                  {option.value}
                </Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Компании"
                  placeholder=""
                  margin="dense"
                  fullWidth
                />
              )}
              value={filterCompanies}
              onChange={setFilterCompanies}
              groupBy={(g) => (g.group ? g.group : '')}
            />
          </Grid>
          <Grid item lg={5} md={6} sm={12} xs={12}>
            <AMSAutocomplete
              multiple
              minChar={0}
              limitTags={3}
              options={warehouses.map(warehouseToLookup)}
              sortOptions={false}
              disableCloseOnSelect
              renderOption={(option, { selected }) => (
                <Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    className={classes.checkbox}
                    checked={selected}
                    color="primary"
                  />
                  {option.value}
                </Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Складове"
                  placeholder=""
                  margin="dense"
                  fullWidth
                />
              )}
              value={filterWarehouses}
              onChange={setFilterWarehouses}
              groupBy={(g) => (g.group ? g.group : '')}
            />
          </Grid>
          <Grid item lg={1} md={6} sm={12} xs={12}>
            <FormControlLabel
              className={classes.label}
              control={
                <Checkbox
                  checked={filterWithExecutionPercentage}
                  onChange={() => {
                    if (!filterWithExecutionPercentage) {
                      setFilterStatus([]);
                    }
                    setFilterWithExecutionPercentage(!filterWithExecutionPercentage);
                  }}
                />
              }
              label="Забавени"
              labelPlacement="end"
            />
          </Grid>
          <Grid item lg={1} md={6} sm={12} xs={12}>
            <AMSButton
              color="primary"
              variant="contained"
              text="Търсене"
              loading={loading}
              disabled={false}
              onClick={onSearch}
              style={{
                marginTop: 8,
                float: 'right'
              }}
            />
          </Grid>
          <Grid item lg={12} sm={12}>
            <OrdersTableComponent
              title={'Поръчки B2B'}
              orders={b2bOrders}
              hideReceiver={true}
              hideInvoice={false}
              filterWithExecutionPercentage={filterWithExecutionPercentage}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default PartnerB2BOrdersComponent;
