import { IAsyncType, useAsync } from '.';
import {
  PaginatedListResponseReportDeliveryItemResponse,
  ReportDeliveryItemResponse
} from '../../services/api-v3';

import { amsV3Service } from '../../services/services';

export interface IReportStocks<T> extends IAsyncType<T> {
  stocks: T;
}

const useReportStockByDeliveries = (
  companyId?: number,
  warehouseId?: number,
  itemId?: number,
  hideDepleted?: boolean,
  suppliers?: number[],
  itemCategories?: number[],
  manufacturerId?: number
): IReportStocks<ReportDeliveryItemResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseReportDeliveryItemResponse>(
      amsV3Service.getStockByDeliveries,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [companyId, warehouseId, itemId, hideDepleted, suppliers, itemCategories, manufacturerId]
    );
  return {
    stocks: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useReportStockByDeliveries;
