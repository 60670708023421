import { AMSButton, AMSTextField } from '../../helpers/ui';
import { Check, Clear } from '@material-ui/icons';
import { Checkbox, CircularProgress, Container, FormControlLabel, Grid } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import {
  useCompanies,
  useFlag,
  useLegalEntities,
  usePermissions,
  useStyles
} from '../../helpers/hooks';

import AMSLink from '../../helpers/ui/AMSLink/AMSLink';
import AMSTable from '../../helpers/ui/AMSTable/AMSTable';
import { AccessPermissions } from '../../services/accessManagementService';
import { LegalEntityListResponse } from '../../services/api-v3';
import SearchIcon from '@material-ui/icons/Search';
import { cookiesService } from '../../services/services';
import { getExtendedLegalEntityId } from '../../helpers/utils';
import { parseFilter } from '../../helpers/url';
import { useHistory } from 'react-router-dom';

const LegalEntitiesComponent = ({ location }: any) => {
  const classes = useStyles();
  const history = useHistory();
  const { showInactive } = useMemo(() => parseFilter(location.search), [location.search]);
  const filter = cookiesService.getLegalEntitiesList();
  const [text, setText] = useState(filter.query ? filter.query : '');
  const [filterShowInactive, setFilterShowInactive] = useFlag(
    showInactive !== undefined && showInactive !== null
      ? showInactive
      : filter.filterShowInactive ?? false
  );
  const { legalEntities, setParameters, loading } = useLegalEntities([], filterShowInactive, text);
  const { companies } = useCompanies();

  const [canCreate, canReadAllLegalEntities] = usePermissions([
    AccessPermissions.CAN_CREATE_LEGAL_ENTITY,
    AccessPermissions.CAN_READ_ALL_LEGAL_ENTITIES
  ]);

  const companyLookup: any = useMemo(
    () =>
      companies.reduce((res: any, company) => {
        if (!res) {
          res = {};
        }
        res[`${company.id}`] = company.note ? `${company.note} (${company.name})` : company.name;
        return res;
      }, {}),
    [companies]
  );

  const onSearch = useCallback(() => {
    cookiesService.setLegalEntitiesList({
      query: text,
      filterShowInactive
    });
    let params: any = {};
    if (text) {
      params.search = text;
    }
    if (filterShowInactive) {
      params.showInactive = filterShowInactive;
    }
    history.push({
      pathname: '/legal-entities',
      search: new URLSearchParams(params).toString()
    });
    setParameters([[], filterShowInactive, text]);
  }, [text, filterShowInactive, history, setParameters]);

  const handleAdd = () => history.push(`/legal-entity?tabId=1`);

  const legalEntitiesTable = (
    <AMSTable
      title="Юридически лица"
      columns={[
        {
          title: 'ИД',
          field: 'id',
          render: (rowData: LegalEntityListResponse) => (
            <AMSLink href={`/legal-entity?id=${rowData.id}`}>
              {getExtendedLegalEntityId(rowData.id)}
            </AMSLink>
          ),
          cellStyle: { width: '5%' }
        },
        canReadAllLegalEntities
          ? {
              title: 'Компания',
              field: 'companyId',
              lookup: companyLookup,
              cellStyle: { width: '20%' }
            }
          : null,
        {
          title: 'Име',
          field: 'name',
          render: (rowData: LegalEntityListResponse) => (
            <AMSLink href={`/legal-entity?id=${rowData.id}`}>{rowData.name}</AMSLink>
          ),
          cellStyle: { width: canReadAllLegalEntities ? '20%' : '25%' }
        },
        {
          title: 'ДДС №',
          field: 'vat',
          cellStyle: { width: canReadAllLegalEntities ? '15%' : '20%' }
        },
        {
          title: 'МОЛ',
          field: 'accountable',
          cellStyle: { width: '20%' }
        },
        {
          title: 'Кредитен лимит',
          field: 'creditLimitAmount',
          cellStyle: { width: canReadAllLegalEntities ? '15%' : '15%' },
          type: 'currency',
          currencySetting: {
            locale: 'bg',
            currencyCode: 'bgn',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }
        },
        {
          title: 'Под възбрана',
          field: 'underForeclosure',
          render: (rowData: any) => (
            <div className="d-flex">
              <div>{rowData.underForeclosure ? <Check /> : <Clear />}</div>
            </div>
          ),
          cellStyle: { width: canReadAllLegalEntities ? '10%' : '10%' }
        }
      ]}
      data={legalEntities}
      onAdd={canCreate ? handleAdd : undefined}
      isLoading={loading}
    />
  );

  return (
    <Container maxWidth="xl" className={classes.flex}>
      <Grid container spacing={1}>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <AMSTextField
            label="Търсене"
            placeholder="Търсене по име / ДДС № / МОЛ / адрес"
            value={text}
            onEnter={onSearch}
            onChange={(value) => setText(value ?? '')}
            InputProps={{
              startAdornment: <SearchIcon />,
              endAdornment: loading ? <CircularProgress /> : null
            }}
          />
        </Grid>
        <Grid item container lg={2} md={4} sm={12} xs={12} alignContent="flex-start">
          <FormControlLabel
            control={
              <Checkbox
                checked={filterShowInactive}
                onChange={() => setFilterShowInactive(!showInactive)}
              />
            }
            label="Покажи неактивни"
            labelPlacement="end"
          />
        </Grid>
        <Grid item lg={1} md={2} sm={12} xs={12}>
          <AMSButton
            color="primary"
            variant="contained"
            text="Търсене"
            loading={loading}
            disabled={false}
            onClick={onSearch}
            style={{
              marginTop: 8
            }}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {legalEntitiesTable}
        </Grid>
      </Grid>
    </Container>
  );
};

export default LegalEntitiesComponent;
