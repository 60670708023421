import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import { OrderItemV2Response, OrderV2Response, UpdateOrderV2Request } from '../../../services/api';
import React, { useState } from 'react';

import AMSConfirmDialog from '../../../helpers/ui/AMSConfirmDialog/AMSConfirmDialog';
import { OrderStatusKeys } from '../../../services/ordersService';
import { ordersService } from '../../../services/services';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold'
  },
  typeWrapper: {
    paddingTop: 8
  }
}));

export interface PreviewOrderDialogProps {
  order: OrderV2Response;
  open: boolean;
  onClose: () => void;
}

const PreviewOrderDialog = ({ order, open, onClose }: PreviewOrderDialogProps) => {
  const classes = useStyles();

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="lg"
      fullWidth={true}
    >
      <DialogTitle>
        <div className={classes.title}>Преглед на изпълнение</div>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="body2">Име</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">Заявено количество</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">Наличност</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order.items?.map((item: OrderItemV2Response) => (
              <TableRow
                key={item.id}
                style={item.isOwnedByUs ? {} : { backgroundColor: '#FDE9EE' }}
              >
                <TableCell width="60%">
                  <Typography variant="body2">{item.name}</Typography>
                </TableCell>
                <TableCell align="right" width="20%">
                  <Typography variant="body2">{item.quantity}</Typography>
                </TableCell>
                <TableCell align="right" width="20%">
                  <Typography variant="body2">{item.availableQuantity}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow key="footer">
              <TableCell align="right" colSpan={3} style={{ borderBottom: '0px black solid' }}>
                <Typography variant="h6">
                  Възможност за изпълнение: {order.executionPercentage}%
                </Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="contained">
          Отказ
        </Button>
        <Button onClick={() => setOpenConfirmDialog(true)} color="primary" variant="contained">
          За пикиране
        </Button>
        <AMSConfirmDialog
          open={openConfirmDialog}
          onConfirm={async () => {
            const updateOrderRequest: UpdateOrderV2Request = {
              statusKey: OrderStatusKeys.FOR_PICKING
            };
            await ordersService.updateOrder(order.id, updateOrderRequest);
            onClose();
          }}
          onClose={() => setOpenConfirmDialog(false)}
          title={'Преместване към "За пикиране"!'}
          message={'Поръчката ще бъде преместена в статус "За пикиране". Искате да продължите?'}
        />
      </DialogActions>
    </Dialog>
  );
};

export default PreviewOrderDialog;
