import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useState } from 'react';
import { getFirstDayOfWeek, ignoreOffset, isValidDate } from '../../helpers/date-helper';

import { AccessPermissions } from '../../services/accessManagementService';
import DateFnsUtils from '@date-io/date-fns';
import { Grid } from '@material-ui/core';
import HomeOrders from './HomeOrders';
import HomeProfitRatioReport from './HomeProfitRatioReport';
import { OrderStatusKeys } from '../../services/ordersService';
import { usePermissions } from '../../helpers/hooks';

export const orderStatusesInProgress = [
  OrderStatusKeys.PRELIMINARY,
  OrderStatusKeys.FOR_PICKING,
  OrderStatusKeys.PICKING_IN_PROGRESS,
  OrderStatusKeys.FOR_CLARIFICATION,
  OrderStatusKeys.CLARIFICATION_IN_PROGRESS,
  OrderStatusKeys.FOR_INSPECTION,
  OrderStatusKeys.INSPECTION_IN_PROGRESS,
  OrderStatusKeys.FOR_SHIPPING,
  OrderStatusKeys.SHIPPED,
  OrderStatusKeys.RECEIVED_WITH_REMARKS,
  OrderStatusKeys.DELIVERED,
  OrderStatusKeys.AWAITING_PAYMENT,
  OrderStatusKeys.FINISHED_SUCCESSFULLY
];

export interface OrderAggregatedNumbers {
  label: string;
  receiverId: number;
  receiverName: string;
  forPicking: number;
  collected: number;
  delivered: number;
  totalItems: number;
}

const Home = () => {
  const [fromDate, setFromDate] = useState<Date | null>(getFirstDayOfWeek(new Date()));
  const [toDate, setToDate] = useState<Date | null>(new Date());
  const [canReadOrders, canReadProfitRatioReport] = usePermissions([
    AccessPermissions.CAN_READ_ORDERS,
    AccessPermissions.CAN_READ_REPORTS_PROFIT_RATIO
  ]);


  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container spacing={2}>
        {(canReadOrders || canReadProfitRatioReport) && (
          <Grid item lg={12} style={{ display: 'flex' }}>
            <div style={{ margin: '0px 10px', width: '25%' }}>
              <KeyboardDatePicker
                disableToolbar
                autoOk={true}
                variant="inline"
                format="dd/MM/yy"
                margin="dense"
                label="От"
                helperText={''}
                value={fromDate ? fromDate : null}
                onChange={(value: Date | null) => {
                  if (value) {
                    if (isValidDate(value)) {
                      setFromDate(ignoreOffset(value));
                    }
                  } else {
                    setFromDate(null);
                  }
                }}
                inputVariant="outlined"
                fullWidth
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                required
                maxDate={toDate}
              />
            </div>
            <div style={{ margin: '0px 10px', width: '25%' }}>
              <KeyboardDatePicker
                disableToolbar
                autoOk={true}
                variant="inline"
                format="dd/MM/yy"
                margin="dense"
                label="От"
                helperText={''}
                value={toDate ? toDate : null}
                onChange={(value: Date | null) => {
                  if (value) {
                    if (isValidDate(value)) {
                      setToDate(ignoreOffset(value));
                    }
                  } else {
                    setToDate(null);
                  }
                }}
                inputVariant="outlined"
                fullWidth
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                required
                minDate={fromDate}
              />
            </div>
          </Grid>
        )}
        {canReadOrders && (
          <Grid item lg={6}>
            <HomeOrders fromDate={fromDate} toDate={toDate} />
          </Grid>
        )}
        {canReadProfitRatioReport && (
          <Grid item lg={6}>
            <HomeProfitRatioReport fromDate={fromDate} toDate={toDate} />
          </Grid>
        )}
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default Home;
