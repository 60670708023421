import { AccessPermissions } from "../../services/accessManagementService";
import { accessManagementService } from "../../services/services";
import { useMemo } from "react";

const usePermissions = (permissions: AccessPermissions[]) => {
  const values = useMemo(
    () => permissions.map((permission: AccessPermissions) => accessManagementService.hasPermission(permission)),
    [permissions]
  );
  return values;
}

export default usePermissions;