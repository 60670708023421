import * as H from 'history';

import { AMSAutocomplete, AMSButton } from '../../../helpers/ui';
import { Checkbox, Container, FormControlLabel, Grid, TextField } from '@material-ui/core';
import {
  CompanyListResponse,
  PartnerListResponse,
  WarehouseListResponse
} from '../../../services/api-v3';
import Lookup, {
  companyToLookup,
  orderStatusToLookup,
  partnerToLookup,
  warehouseToLookup
} from '../../../models/lookup';
import {
  OrderStatus,
  OrderStatusKey,
  getOrderStatusByKey,
  orderStatuses
} from '../../../services/ordersService';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ignoreOffset,
  isValidDate,
  toDateString,
  toEndOfDay,
  toStartOfDay
} from '../../../helpers/date-helper';
import {
  useB2COrders,
  useCompanies,
  useFlag,
  usePartners,
  useStyles,
  useWarehouses
} from '../../../helpers/hooks';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { OrderV2ListResponse } from '../../../services/api';
import OrdersTableComponent from '../OrdersTableComponent/OrdersTableComponent';
import { cookiesService } from '../../../services/services';
import { parseFilter } from '../../../helpers/url';
import { useHistory } from 'react-router-dom';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface B2COrdersComponentProps {
  location: H.Location;
}

const B2COrdersComponent = ({ location }: B2COrdersComponentProps) => {
  const history = useHistory();
  const classes = useStyles();
  const {
    toDate,
    fromDate,
    companyIds,
    warehouseIds,
    receiverIds,
    statusKeys,
    withExecutionPercentage
  } = useMemo(() => parseFilter(location.search), [location.search]);

  const { companies } = useCompanies();
  const { warehouses } = useWarehouses();
  const { partners } = usePartners();

  const filter = cookiesService.getB2COrdersList();
  const [filterStatus, setFilterStatus] = useState<Lookup[]>(
    statusKeys
      ? statusKeys.map((key: string) =>
          orderStatusToLookup(getOrderStatusByKey(key as OrderStatusKey) as OrderStatus)
        )
      : filter.filterStatus
      ? filter.filterStatus
      : []
  );

  const [filterFromDate, setFilterFromDate] = useState<Date | null>(
    !withExecutionPercentage && fromDate
      ? fromDate
      : filter.filterFromDate
      ? ignoreOffset(new Date(filter.filterFromDate))
      : new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  const [filterToDate, setFilterToDate] = useState<Date | null>(
    !withExecutionPercentage && toDate
      ? toDate
      : filter.filterToDate
      ? ignoreOffset(new Date(filter.filterToDate))
      : new Date()
  );
  const [filterCompanies, setFilterCompanies] = useState<Lookup[]>(
    filter.filterCompanies ? filter.filterCompanies : []
  );
  const [filterReceivers, setFilterReceivers] = useState<Lookup[]>(
    filter.filterReceivers ? filter.filterReceivers : []
  );
  const [filterWarehouses, setFilterWarehouses] = useState<Lookup[]>(
    filter.filterWarehouses ? filter.filterWarehouses : []
  );
  const [filterWithExecutionPercentage, setFilterWithExecutionPercentage] = useFlag(
    withExecutionPercentage !== undefined && withExecutionPercentage !== null
      ? withExecutionPercentage
      : filter.filterWithExecutionPercentage ?? false
  );

  const { b2cOrders, loading, setLoading, setParameters } = useB2COrders(
    statusKeys ?? filterStatus.map((s: Lookup) => s.id),
    companyIds ?? filterCompanies.map((c: Lookup) => c.id),
    warehouseIds ?? filterWarehouses.map((w: Lookup) => w.id),
    receiverIds ?? filterReceivers.map((r: Lookup) => r.id),
    undefined,
    !filterWithExecutionPercentage && filterFromDate ? toStartOfDay(filterFromDate) : undefined,
    !filterWithExecutionPercentage && filterToDate ? toEndOfDay(filterToDate) : undefined,
    withExecutionPercentage ?? filterWithExecutionPercentage
  );
  useEffect(() => {
    const { filterCompanies } = cookiesService.getB2COrdersList();

    setFilterCompanies(
      companyIds && companies
        ? companies
            .filter((c: CompanyListResponse) => companyIds.includes(c.id))
            .map(companyToLookup)
        : filterCompanies ?? []
    );
  }, [companies, companyIds]);

  useEffect(() => {
    const { filterWarehouses } = cookiesService.getB2COrdersList();

    setFilterWarehouses(
      warehouseIds && warehouses
        ? warehouses
            .filter((w: WarehouseListResponse) => warehouseIds.includes(w.id))
            .map(warehouseToLookup)
        : filterWarehouses ?? []
    );
  }, [warehouses, warehouseIds]);

  useEffect(() => {
    const { filterReceivers } = cookiesService.getB2COrdersList();

    setFilterReceivers(
      receiverIds && partners
        ? partners
            .filter((p: PartnerListResponse) => receiverIds.includes(p.id))
            .map(partnerToLookup)
        : filterReceivers ?? []
    );
  }, [partners, receiverIds]);

  const onSearch = useCallback(() => {
    setLoading(true);
    const fromDate =
      filterFromDate !== null && isValidDate(filterFromDate)
        ? toStartOfDay(filterFromDate!)
        : undefined;
    const toDate =
      filterToDate !== null && isValidDate(filterToDate) ? toEndOfDay(filterToDate) : undefined;
    cookiesService.setB2COrdersList({
      filterFromDate: fromDate,
      filterToDate: toDate,
      filterStatus,
      filterCompanies,
      filterWarehouses,
      filterReceivers,
      filterWithExecutionPercentage
    });
    let params: any = {};
    if (filterStatus?.length > 0) {
      params.statusKeys = filterStatus.map((s) => s.id).join(',');
    }
    if (filterCompanies?.length > 0) {
      params.companyIds = filterCompanies.map((c) => c.id).join(',');
    }
    if (filterWarehouses?.length > 0) {
      params.warehouseIds = filterWarehouses.map((w) => w.id).join(',');
    }
    if (filterReceivers?.length > 0) {
      params.receiverIds = filterReceivers.map((r) => r.id).join(',');
    }
    if (!filterWithExecutionPercentage && filterFromDate) {
      params.fromDate = toDateString(filterFromDate);
    }
    if (!filterWithExecutionPercentage && filterToDate) {
      params.toDate = toDateString(filterToDate);
    }
    if (filterWithExecutionPercentage) {
      params.withExecutionPercentage = filterWithExecutionPercentage;
    }
    history.push({
      pathname: '/b2c-orders',
      search: new URLSearchParams(params).toString()
    });
    setParameters([
      filterStatus.map((status: Lookup) => status.id),
      filterCompanies.map((c: Lookup) => c.id),
      filterWarehouses.map((w: Lookup) => w.id),
      filterReceivers.map((r: Lookup) => r.id),
      undefined,
      filterWithExecutionPercentage ? undefined : fromDate,
      filterWithExecutionPercentage ? undefined : toDate,
      filterWithExecutionPercentage
    ]);
    setLoading(false);
  }, [
    setLoading,
    filterFromDate,
    filterToDate,
    filterStatus,
    filterCompanies,
    filterWarehouses,
    filterReceivers,
    filterWithExecutionPercentage,
    history,
    setParameters
  ]);

  useEffect(() => {
    cookiesService.setOrderIds(
      b2cOrders.map((order: OrderV2ListResponse) => order.id).sort((a, b) => a - b)
    );
  }, [b2cOrders]);

  return (
    <div className="text-align-left">
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          {!filterWithExecutionPercentage && (
            <Grid item lg={8} md={12} sm={12} xs={12}>
              <AMSAutocomplete
                multiple
                minChar={0}
                limitTags={3}
                options={orderStatuses.sort((a, b) => a.id - b.id).map(orderStatusToLookup)}
                sortOptions={false}
                disableCloseOnSelect
                renderOption={(option, { selected }) => (
                  <Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      className={classes.checkbox}
                      checked={selected}
                      color="primary"
                    />
                    {option.value}
                  </Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Статус"
                    placeholder=""
                    margin="dense"
                    fullWidth
                  />
                )}
                value={filterStatus}
                onChange={(values) => setFilterStatus(values)}
              />
            </Grid>
          )}
          {!filterWithExecutionPercentage && (
            <Grid item lg={2} md={6} sm={12} xs={12}>
              <KeyboardDatePicker
                disableToolbar
                autoOk={true}
                variant="inline"
                format="dd/MM/yy"
                margin="dense"
                label="От дата"
                helperText={''}
                value={filterFromDate ? filterFromDate : null}
                onChange={(value: Date | null) => {
                  if (value) {
                    if (isValidDate(value)) {
                      setFilterFromDate(ignoreOffset(value));
                    }
                  } else {
                    setFilterFromDate(null);
                  }
                }}
                inputVariant="outlined"
                fullWidth
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                maxDate={filterToDate}
              />
            </Grid>
          )}
          {!filterWithExecutionPercentage && (
            <Grid item lg={2} md={6} sm={12} xs={12}>
              <KeyboardDatePicker
                disableToolbar
                autoOk={true}
                variant="inline"
                format="dd/MM/yy"
                margin="dense"
                label="До дата"
                helperText={''}
                value={filterToDate ? filterToDate : null}
                onChange={(value: Date | null) => {
                  if (value) {
                    if (isValidDate(value)) {
                      setFilterToDate(ignoreOffset(value));
                    }
                  } else {
                    setFilterToDate(null);
                  }
                }}
                inputVariant="outlined"
                fullWidth
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                minDate={filterFromDate}
              />
            </Grid>
          )}
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <AMSAutocomplete
              multiple
              minChar={0}
              limitTags={3}
              options={companies.map(companyToLookup)}
              disableCloseOnSelect
              renderOption={(option, { selected }) => (
                <Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    className={classes.checkbox}
                    checked={selected}
                    color="primary"
                  />
                  {option.value}
                </Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Компании"
                  placeholder=""
                  margin="dense"
                  fullWidth
                />
              )}
              value={filterCompanies}
              onChange={setFilterCompanies}
              groupBy={(g) => (g.group ? g.group : '')}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <AMSAutocomplete
              multiple
              minChar={0}
              limitTags={3}
              options={warehouses.map(warehouseToLookup)}
              sortOptions={false}
              disableCloseOnSelect
              renderOption={(option, { selected }) => (
                <Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    className={classes.checkbox}
                    checked={selected}
                    color="primary"
                  />
                  {option.value}
                </Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Складове"
                  placeholder=""
                  margin="dense"
                  fullWidth
                />
              )}
              value={filterWarehouses}
              onChange={setFilterWarehouses}
              groupBy={(g) => (g.group ? g.group : '')}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <AMSAutocomplete
              multiple
              minChar={0}
              limitTags={3}
              options={partners.map(partnerToLookup)}
              disableCloseOnSelect
              renderOption={(option, { selected }) => (
                <Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    className={classes.checkbox}
                    checked={selected}
                    color="primary"
                  />
                  {option.value}
                </Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Контрагенти"
                  placeholder=""
                  margin="dense"
                  fullWidth
                />
              )}
              value={filterReceivers}
              onChange={setFilterReceivers}
              groupBy={(g) => (g.group ? g.group : '')}
            />
          </Grid>
          <Grid item lg={1} md={6} sm={12} xs={12}>
            <FormControlLabel
              className={classes.label}
              control={
                <Checkbox
                  checked={filterWithExecutionPercentage}
                  onChange={() => {
                    if (!filterWithExecutionPercentage) {
                      setFilterStatus([]);
                    }
                    setFilterWithExecutionPercentage(!filterWithExecutionPercentage);
                  }}
                />
              }
              label="Забавени"
              labelPlacement="end"
            />
          </Grid>
          <Grid item lg={1} md={6} sm={12} xs={12}>
            <AMSButton
              color="primary"
              variant="contained"
              text="Търсене"
              loading={loading}
              disabled={false}
              onClick={onSearch}
              style={{
                marginTop: 8,
                float: 'right'
              }}
            />
          </Grid>
          <Grid item lg={12} sm={12}>
            <OrdersTableComponent
              title={'Поръчки B2C'}
              orders={b2cOrders}
              hideInvoice={true}
              filterWithExecutionPercentage={filterWithExecutionPercentage}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default B2COrdersComponent;
