import {
  ActOfAcceptanceListResponse,
  PaginatedListResponseActOfAcceptanceListResponse
} from '../../services/api-v3';
import { IAsyncType, useAsync } from '.';

import { amsV3Service } from '../../services/services';

export interface IActsOfAcceptance<T> extends IAsyncType<T> {
  actsOfAcceptance: T;
}

const useActsOfAcceptance = (
  fromDate: Date | null,
  toDate: Date | null,
  receivers?: number[],
  legalEntities?: number[],
  companies?: number[],
  users?: number[],
  orderTypes?: string[]
): IActsOfAcceptance<ActOfAcceptanceListResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseActOfAcceptanceListResponse>(
      amsV3Service.getActsOfAcceptance,
      {
        data: [],
        total: 0,
        page: 1,
        size: 100
      },
      [fromDate, toDate, receivers, legalEntities, companies, users, orderTypes]
    );
  return {
    actsOfAcceptance: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useActsOfAcceptance;
