import { OrderAggregatedNumbers, orderStatusesInProgress } from './Home';
import { OrderItemV2Response, OrderV2Response } from '../../services/api';
import { OrderStatusKeys, OrderTypeIds } from '../../services/ordersService';
import React, { useEffect, useMemo } from 'react';
import { toEndOfDay, toStartOfDay } from '../../helpers/date-helper';

import { HorizontalBar } from 'react-chartjs-2';
import { getExtendedOrderId } from '../../helpers/utils';
import { useOrders } from '../../helpers/hooks';

interface HomeOrdersProps {
  fromDate: Date | null;
  toDate: Date | null;
}

const HomeOrders = ({ fromDate, toDate }: HomeOrdersProps) => {
  const { orders, loading, setParameters } = useOrders(
    orderStatusesInProgress,
    undefined,
    undefined,
    fromDate ? toStartOfDay(fromDate) : undefined,
    toDate ? toEndOfDay(toDate) : undefined,
    undefined,
    undefined,
    true
  );

  useEffect(() => {
    setParameters([
      orderStatusesInProgress,
      undefined,
      undefined,
      fromDate ? toStartOfDay(fromDate) : undefined,
      toDate ? toEndOfDay(toDate) : undefined,
      undefined,
      undefined,
      true
    ]);
  }, [fromDate, toDate, setParameters]);

  const ordersByPartner = useMemo(
    () =>
      orders.filter(
        (order: OrderV2Response) =>
          order.orderType !== OrderTypeIds.B2B ||
          (order.orderType === OrderTypeIds.B2B)
      ),
    [orders]
  );

  const ordersInfo: OrderAggregatedNumbers[] = useMemo(
    () =>
      ordersByPartner.map((order: OrderV2Response) => ({
        label: `${getExtendedOrderId(order.id)} ${order.receiverName}`,
        receiverId: order.receiverId,
        receiverName: order.receiverName ?? '',
        forPicking:
          order.statusKey === OrderStatusKeys.PRELIMINARY ||
          order.statusKey === OrderStatusKeys.FOR_PICKING ||
          order.statusKey === OrderStatusKeys.PICKING_IN_PROGRESS ||
          order.statusKey === OrderStatusKeys.FOR_CLARIFICATION ||
          order.statusKey === OrderStatusKeys.CLARIFICATION_IN_PROGRESS
            ? order.items?.filter((orderItem: OrderItemV2Response) => !orderItem.collected)
                .length ?? 0
            : 0,
        collected:
          order.statusKey === OrderStatusKeys.FOR_PICKING ||
          order.statusKey === OrderStatusKeys.PICKING_IN_PROGRESS ||
          order.statusKey === OrderStatusKeys.FOR_CLARIFICATION ||
          order.statusKey === OrderStatusKeys.CLARIFICATION_IN_PROGRESS ||
          order.statusKey === OrderStatusKeys.FOR_INSPECTION ||
          order.statusKey === OrderStatusKeys.INSPECTION_IN_PROGRESS ||
          order.statusKey === OrderStatusKeys.FOR_SHIPPING
            ? order.items?.filter(
                (orderItem: OrderItemV2Response) =>
                  orderItem.collected && !orderItem.receivedQuantity
              ).length ?? 0
            : 0,
        delivered:
          order.statusKey === OrderStatusKeys.SHIPPED ||
          order.statusKey === OrderStatusKeys.RECEIVED_WITH_REMARKS ||
          order.statusKey === OrderStatusKeys.DELIVERED ||
          order.statusKey === OrderStatusKeys.AWAITING_PAYMENT ||
          order.statusKey === OrderStatusKeys.FINISHED_SUCCESSFULLY
            ? order.items?.length ?? 0
            : 0,
        totalItems: order.items?.length ?? 0
      })),
    [ordersByPartner]
  );

  const ordersAggregatedByReceiverName = useMemo(
    () =>
      ordersInfo.reduce((res: any, orderInfo: OrderAggregatedNumbers) => {
        if (!res[orderInfo.receiverName]) {
          res[orderInfo.receiverName] = {
            forPicking: 0,
            collected: 0,
            delivered: 0,
            totalItems: 0
          };
        }
        res[orderInfo.receiverName] = {
          forPicking: res[orderInfo.receiverName].forPicking + orderInfo.forPicking,
          collected: res[orderInfo.receiverName].collected + orderInfo.collected,
          delivered: res[orderInfo.receiverName].delivered + orderInfo.delivered,
          totalItems: res[orderInfo.receiverName].totalItems + orderInfo.totalItems
        };
        return res;
      }, {}),
    [ordersInfo]
  );

  const labels = useMemo(
    () =>
      !loading
        ? Object.keys(ordersAggregatedByReceiverName).sort(
            (key1, key2) =>
              ordersAggregatedByReceiverName[key2].totalItems -
              ordersAggregatedByReceiverName[key1].totalItems
          )
        : [],
    [ordersAggregatedByReceiverName, loading]
  );

  const values = useMemo(
    () =>
      !loading
        ? Object.values(ordersAggregatedByReceiverName).sort(
            (value1: any, value2: any) => value2.totalItems - value1.totalItems
          )
        : [],
    [ordersAggregatedByReceiverName, loading]
  );

  return (
    <HorizontalBar
      options={{
        scales: {
          xAxes: [
            {
              stacked: true
            }
          ],
          yAxes: [
            {
              stacked: true
            }
          ]
        },
        responsive: true
      }}
      data={{
        labels: labels,
        datasets: [
          {
            label: 'Доставени',
            data: values.map((orderInfo: any) => orderInfo.delivered),
            borderColor: '#008800',
            backgroundColor: '#008800'
          },
          {
            label: 'Събрани',
            data: values.map((orderInfo: any) => orderInfo.collected),
            borderColor: '#00CC66',
            backgroundColor: '#00CC66'
          },
          {
            label: 'За пикиране',
            data: values.map((orderInfo: any) => orderInfo.forPicking),
            borderColor: '#52FFFF',
            backgroundColor: '#52FFFF'
          }
        ]
      }}
    />
  );
};
export default HomeOrders;
