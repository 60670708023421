import { Container, Grid, createStyles, makeStyles } from '@material-ui/core';
import {
  ItemDeliverySubscriptionResponse,
  ItemListResponse,
  PaginatedListResponseItemListResponse
} from '../../../services/api-v3';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useItemDeliverySubscriptions, useUsers, useWarehouses } from '../../../helpers/hooks';

import AMSTable from '../../../helpers/ui/AMSTable/AMSTable';
import { amsV3Service } from '../../../services/services';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    root: { marginLeft: 0 }
  })
);

const ItemDeliverySubscriptionsComponent = () => {
  const classes = useStyles();
  const history = useHistory();

  const { subscriptions, setParameters, loading, setLoading } = useItemDeliverySubscriptions(
    [],
    []
  );
  const [items, setItems] = useState<PaginatedListResponseItemListResponse>();
  const { warehouses } = useWarehouses();
  const { users } = useUsers();

  const handleAdd = useCallback(() => history.push(`/item-delivery-subscription`), [history]);

  const handleEdit = useCallback(
    (ids: ItemDeliverySubscriptionResponse) =>
      history.push(`/item-delivery-subscription?id=${ids.id}`),
    [history]
  );

  const handleDelete = async (legalEntity: ItemDeliverySubscriptionResponse) => {
    setLoading(true);
    if (legalEntity && legalEntity.id) {
      await amsV3Service.deleteItemDeliverySubscription(legalEntity.id);
      setParameters(
        subscriptions.filter((s: ItemDeliverySubscriptionResponse) => s.id !== legalEntity.id)
      );
    }
    setLoading(false);
  };

  const loadItems = useCallback(async (itemIds: number[]) => {
    const resp = await amsV3Service.getItems(itemIds);
    setItems(resp.data);
  }, []);
  const itemNameById = useMemo(() => {
    if (!items || !items.data) return {};

    return items.data.reduce((nameById: { [itemId: number]: string }, item: ItemListResponse) => {
      nameById[item.id] = item.name;
      return nameById;
    }, {});
  }, [items]);

  useEffect(() => {
    const itemIds = subscriptions.map((sub: ItemDeliverySubscriptionResponse) => sub.itemId);
    loadItems(itemIds);
  }, [subscriptions, loadItems]);

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Grid container spacing={1}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <AMSTable
            title="Критични количества"
            columns={[
              {
                title: 'Артикул',
                field: 'itemId',
                cellStyle: { width: '20%' },
                render: (rowData: ItemDeliverySubscriptionResponse) =>
                  itemNameById[rowData.itemId] || 'Неактивен артикул'
              },
              {
                title: 'Склад',
                field: 'warehouseId',
                render: (rowData: ItemDeliverySubscriptionResponse) => {
                  const warehouse = warehouses.find((w) => w.id === rowData.warehouseId);
                  return warehouse ? warehouse.name : 'Неактивен склад';
                }
              },
              {
                title: 'Дни за доставка',
                field: 'daysForDelivery',
                cellStyle: { width: '10%' }
              },
              {
                title: 'Мин. количество на поръчка',
                field: 'batchSize',
                cellStyle: { width: '10%' }
              },
              {
                title: 'Буфер (%)',
                field: 'bufferPercentage',
                cellStyle: { width: '10%' }
              },
              {
                title: 'Създаден от',
                field: 'createdById',
                render: (rowData: ItemDeliverySubscriptionResponse) => {
                  const createdById = users.find((u) => u.id === rowData.createdById);
                  return createdById ? createdById.name : 'Неактивен потребител';
                },
                cellStyle: { width: '15%' }
              },
              {
                title: 'Редактиран от',
                field: 'lastUpdatedById',
                render: (rowData: ItemDeliverySubscriptionResponse) => {
                  const lastUpdatedById = users.find((u) => u.id === rowData.lastUpdatedById);
                  return lastUpdatedById ? lastUpdatedById.name : 'Неактивен потребител';
                },
                cellStyle: { width: '15%' }
              }
              // can u make these .find(()) to be in useeffect
            ]}
            data={subscriptions}
            onAdd={handleAdd}
            onEdit={handleEdit}
            onDelete={handleDelete}
            isLoading={loading}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ItemDeliverySubscriptionsComponent;
