import { AMSApi, AMSV3Api } from '../helpers/openapi-axios-helper';
import {
  SetBarsyConfigurationRequest,
  SetICashConfigurationRequest,
  SetMistralConfigurationRequest,
  SetNoConfigurationRequest,
  SetOrderingPolicyRequest,
  SetRetailConfigurationRequest
} from './api-v3';

export enum TestConnectionResultKeys {
  SUCCESSFUL = 'SUCCESSFUL',
  HTTP_ERROR = 'HTTP_ERROR',
  MISSING_REQUIRED_CONFIGURATION = 'MISSING_REQUIRED_CONFIGURATION',
  INCORRECT_CREDENTIALS = 'INCORRECT_CREDENTIALS',
  DEPOT_NOT_FOUND = 'DEPOT_NOT_FOUND',
  SUPPLIER_NOT_FOUND = 'SUPPLIER_NOT_FOUND'
}

export enum TestConnectionResultLabels {
  SUCCESSFUL = 'Успешно',
  HTTP_ERROR = 'Грешка при връзка',
  MISSING_REQUIRED_CONFIGURATION = 'Липсваща конфигурация',
  INCORRECT_CREDENTIALS = 'Невалидни данни',
  DEPOT_NOT_FOUND = 'Не е намерен склад',
  SUPPLIER_NOT_FOUND = 'Не е намерен доставчик'
}

export const getTestConnectionLabelByKey = (key: TestConnectionResultKeys) => {
  switch (key) {
    case TestConnectionResultKeys.SUCCESSFUL:
      return TestConnectionResultLabels.SUCCESSFUL;
    case TestConnectionResultKeys.HTTP_ERROR:
      return TestConnectionResultLabels.HTTP_ERROR;
    case TestConnectionResultKeys.MISSING_REQUIRED_CONFIGURATION:
      return TestConnectionResultLabels.MISSING_REQUIRED_CONFIGURATION;
    case TestConnectionResultKeys.INCORRECT_CREDENTIALS:
      return TestConnectionResultLabels.INCORRECT_CREDENTIALS;
    case TestConnectionResultKeys.DEPOT_NOT_FOUND:
      return TestConnectionResultLabels.DEPOT_NOT_FOUND;
    case TestConnectionResultKeys.SUPPLIER_NOT_FOUND:
      return TestConnectionResultLabels.SUPPLIER_NOT_FOUND;
    default:
      return `<${key}>`;
  }
};

export class PartnersService {
  getPartnerVisibleCategories = async (id: number) => {
    const axios = await AMSApi();
    return await axios.readV1PartnersPartnerIdItemCategoryAssociationGet(id);
  };

  updatePartnerVisibleCategories = async (partnerId: number, ids: number[]) => {
    const axios = await AMSApi();
    return await axios.updateV1PartnersPartnerIdItemCategoryAssociationPost(partnerId, ids);
  };

  setPartnerIntegrationConfiguration = async (
    partnerId: number,
    request:
      | SetNoConfigurationRequest
      | SetBarsyConfigurationRequest
      | SetICashConfigurationRequest
      | SetRetailConfigurationRequest
      | SetMistralConfigurationRequest
  ) => {
    const axios = await AMSV3Api();
    return await axios.setPartnerIntegrationSettingsPartnersPartnerIdIntegrationSettingsPost(
      partnerId,
      JSON.parse(JSON.stringify(request))
    );
  };

  getPartnerItems = async (partnerId: number, search?: string) => {
    const axios = await AMSV3Api();
    return await axios.readItemsByPartnerPartnersPartnerIdItemsGet(partnerId, search);
  };

  getOrderingPolicy = async (id: number) => {
    const axios = await AMSV3Api();
    return await axios.readOrderingPolicyPartnersIdOrderingPolicyGet(id);
  };

  setOrderingPolicy = async (id: number, orderPolicy: SetOrderingPolicyRequest) => {
    const axios = await AMSV3Api();
    return await axios.setOrderingPolicyPartnersIdOrderingPolicyPut(id, orderPolicy);
  };
}
