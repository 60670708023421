import { CreatePricingPolicyRequest, UpdatePricingPolicyRequest } from './api';

import { AMSApi } from '../helpers/openapi-axios-helper';

export enum PricingPolicyItemExceptionTypeKeys {
  FIXED = 'FIXED',
  MULTIPLIER = 'MULTIPLIER'
}

export class PricingPoliciesService {
  getPricingPolicies = async (search?: string) => {
    const axios = await AMSApi();
    return await axios.readListV1PricingPoliciesGet(search);
  };

  getPricingPolicy = async (id: number) => {
    const axios = await AMSApi();
    return await axios.readV1PricingPoliciesIdGet(id);
  };

  getPricingPolicyCategories = async (id: number) => {
    const axios = await AMSApi();
    return await axios.readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet(id);
  };

  getPricingPolicyItems = async (id: number) => {
    const axios = await AMSApi();
    return await axios.readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet(id);
  };

  createPricingPolicy = async (pricingPolicy: CreatePricingPolicyRequest) => {
    const axios = await AMSApi();
    return await axios.createV1PricingPoliciesPost(pricingPolicy);
  };

  updatePricingPolicy = async (pricingPolicy: UpdatePricingPolicyRequest) => {
    const axios = await AMSApi();
    return await axios.updateV1PricingPoliciesPut(pricingPolicy);
  };

  deletePricingPolicy = async (id: number) => {
    const axios = await AMSApi();
    return await axios.deleteV1PricingPoliciesIdDelete(id);
  };
}
