import { AMSAutocomplete, AMSButton, AMSTable } from '../../helpers/ui';
import {
  Checkbox,
  CircularProgress,
  Grid,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import Lookup, { transactionTypeToLookup, treasuryToLookup } from '../../models/lookup';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { TransactionListResponse, TreasuryListResponse } from '../../services/api-v3';
import { ignoreOffset, isValidDate } from '../../helpers/date-helper';
import { useStyles, useTransactions, useTreasuries } from '../../helpers/hooks';

import AMSLink from '../../helpers/ui/AMSLink/AMSLink';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import AccountBalanceWalletTwoToneIcon from '@material-ui/icons/AccountBalanceWalletTwoTone';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { KeyboardDatePicker } from '@material-ui/pickers';
import MoneyTwoToneIcon from '@material-ui/icons/MoneyTwoTone';
import PaymentTwoToneIcon from '@material-ui/icons/PaymentTwoTone';
import { cookiesService } from '../../services/services';
import { parseFilter } from '../../helpers/url';
import { transactionTypes } from '../../services/AMSV3Service';
import { useHistory } from 'react-router';

export const transactionTypeLabels = [
  'Приход по банка',
  'Приход в брой',
  'Разход по банка',
  'Разход в брой'
];

const transactionTypeIcon = [
  <PaymentTwoToneIcon
    style={{
      color: '#93AB3B'
    }}
  />,
  <AccountBalanceWalletTwoToneIcon
    style={{
      color: '#93AB3B'
    }}
  />,
  <AccountBalanceTwoToneIcon
    style={{
      color: '#F03901'
    }}
  />,
  <MoneyTwoToneIcon
    style={{
      color: '#F03901'
    }}
  />
];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const TransactionsComponent = ({ location }: any) => {
  const classes = useStyles();
  const history = useHistory();
  const { fromDate, toDate, treasuryIds, transactionTypeIds } = useMemo(
    () => parseFilter(location.search),
    [location.search]
  );

  const filter = useMemo(() => cookiesService.getTransactionsFilter(), []);
  const [filterTreasuries, setFilterTreasuries] = useState<Lookup[]>([]);
  const [filterTransactionTypes, setFilterTransactionTypes] = useState<Lookup[]>([]);
  const [filterFromDate, setFilterFromDate] = useState<Date | null>(
    fromDate
      ? fromDate
      : filter.filterFromDate
      ? ignoreOffset(new Date(filter.filterFromDate))
      : new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  const [filterToDate, setFilterToDate] = useState<Date | null>(
    fromDate
      ? toDate
      : filter.filterToDate
      ? ignoreOffset(new Date(filter.filterToDate))
      : new Date()
  );

  const { treasuries } = useTreasuries();

  const { transactions, loading, setParameters } = useTransactions(
    undefined,
    undefined,
    undefined,
    undefined,
    filterFromDate,
    filterToDate
  );

  useEffect(() => {
    const { filterTreasuries } = cookiesService.getTransactionsFilter();

    if (treasuries) {
      setFilterTreasuries(
        treasuryIds
          ? treasuries.filter((t: any) => treasuryIds.includes(t.id)).map(treasuryToLookup)
          : filterTreasuries ?? []
      );
    }
  }, [treasuryIds, treasuries]);

  useEffect(() => {
    const { filterTransactionTypes } = cookiesService.getTransactionsFilter();
    setFilterTransactionTypes(
      transactionTypeIds
        ? transactionTypes
            .filter((t: any) => transactionTypeIds.includes(t.id))
            .map(transactionTypeToLookup)
        : filterTransactionTypes ?? []
    );
  }, [transactionTypeIds]);

  const onSearch = useCallback(() => {
    cookiesService.setTransactionsFilter({
      filterTreasuries
    });
    let params: any = {};
    if (filterTreasuries?.length > 0) {
      params.treasuryIds = filterTreasuries.map((t) => t.id).join(',');
    }
    history.push({
      pathname: '/transactions',
      search: new URLSearchParams(params).toString()
    });
    setParameters([
      undefined,
      undefined,
      undefined,
      filterTreasuries.map((t) => t.id).join(','),
      filterFromDate,
      filterToDate
    ]);
  }, [filterTreasuries, filterFromDate, filterToDate, history, setParameters]);

  const treasuryNames: any = useMemo(
    () =>
      treasuries.reduce((res: any, treasury: TreasuryListResponse) => {
        if (!res) {
          res = {};
        }
        res[`${treasury.id}`] = treasury.name;
        return res;
      }, {}),
    [treasuries]
  );

  return (
    <Grid container spacing={1}>
      <Grid item lg={2} md={6} sm={12} xs={12}>
        <KeyboardDatePicker
          disableToolbar
          autoOk={true}
          variant="inline"
          format="dd/MM/yy"
          margin="dense"
          label="От"
          helperText={''}
          value={filterFromDate ? filterFromDate : null}
          onChange={(value: Date | null) => {
            if (value) {
              if (isValidDate(value)) {
                setFilterFromDate(ignoreOffset(value));
              }
            } else {
              setFilterFromDate(null);
            }
          }}
          inputVariant="outlined"
          fullWidth
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          maxDate={filterToDate}
        />
      </Grid>
      <Grid item lg={2} md={6} sm={12} xs={12}>
        <KeyboardDatePicker
          disableToolbar
          autoOk={true}
          variant="inline"
          format="dd/MM/yy"
          margin="dense"
          label="До"
          helperText={''}
          value={filterToDate ? filterToDate : null}
          onChange={(value: Date | null) => {
            if (value) {
              if (isValidDate(value)) {
                setFilterToDate(ignoreOffset(value));
              }
            } else {
              setFilterToDate(null);
            }
          }}
          inputVariant="outlined"
          fullWidth
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          minDate={filterFromDate}
        />
      </Grid>
      <Grid item lg={3} md={6} sm={12} xs={12}>
        <AMSAutocomplete
          multiple
          minChar={0}
          limitTags={3}
          options={treasuries.map(treasuryToLookup)}
          disableCloseOnSelect
          getOptionLabel={(option: any) => option.value}
          renderOption={(option, { selected }) => (
            <Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                className={classes.checkbox}
                checked={selected}
                color="primary"
              />
              {option.value}
            </Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Каси"
              placeholder=""
              margin="dense"
              fullWidth
            />
          )}
          value={filterTreasuries}
          onChange={setFilterTreasuries}
          groupBy={(g) => (g.group ? g.group : '')}
        />
      </Grid>
      <Grid item lg={3} md={6} sm={12} xs={12}>
        <AMSAutocomplete
          multiple
          minChar={0}
          limitTags={3}
          options={transactionTypes.map(transactionTypeToLookup)}
          disableCloseOnSelect
          getOptionLabel={(option: any) => option.value}
          renderOption={(option, { selected }) => (
            <Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                className={classes.checkbox}
                checked={selected}
                color="primary"
              />
              {option.value}
            </Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Тип"
              placeholder=""
              margin="dense"
              fullWidth
            />
          )}
          value={filterTransactionTypes}
          onChange={setFilterTransactionTypes}
        />
      </Grid>
      <Grid item lg={2} md={12} sm={12} xs={12}>
        <AMSButton
          color="primary"
          variant="contained"
          text="Търсене"
          loading={loading}
          disabled={false}
          onClick={onSearch}
          style={{
            marginTop: 8
          }}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        {loading ? (
          <CircularProgress color="inherit" size={20} />
        ) : (
          <AMSTable
            title="Трансакции"
            columns={[
              {
                title: 'Тип',
                field: 'transactionType',
                render: (transaction: TransactionListResponse) => (
                  <Tooltip title={transactionTypeLabels[transaction.transactionType - 1]}>
                    {transactionTypeIcon[transaction.transactionType - 1] ?? ''}
                  </Tooltip>
                ),
                cellStyle: { width: '5%' }
              },
              {
                title: 'Каса',
                field: 'treasuryId',
                render: (transaction: TransactionListResponse) =>
                  treasuryNames[transaction.treasuryId],
                cellStyle: { width: '25%' }
              },
              {
                title: 'Дата/Час',
                field: 'date',
                type: 'datetime',
                cellStyle: { width: '35%' }
              },
              {
                title: 'Сума',
                field: 'amount',
                type: 'currency',
                currencySetting: {
                  locale: 'bg',
                  currencyCode: 'bgn',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                },
                cellStyle: { width: '5%' }
              },
              {
                title: 'Основание',
                field: 'displayInfo',
                render: (transaction: TransactionListResponse) => {
                  if (transaction.orderInvoiceId) {
                    return (
                      <AMSLink href={`/invoice?id=${transaction.orderInvoiceId}`}>
                        {transaction.displayInfo}
                      </AMSLink>
                    );
                  }
                  if (transaction.deliveryInvoiceId) {
                    const deliveryId = parseInt(
                      transaction.displayInfo?.split(' / ')[0].replace('ДК', '') ?? ''
                    );
                    return (
                      <AMSLink href={`/delivery?id=${deliveryId}&tabId=1`}>
                        {transaction.displayInfo}
                      </AMSLink>
                    );
                  }
                  return <Typography variant="body2">{transaction.displayInfo}</Typography>;
                },
                cellStyle: { width: '30%' }
              }
            ]}
            paging={true}
            overflowY="scroll"
            minBodyHeight="55vh"
            maxBodyHeight="55vh"
            data={
              filterTransactionTypes.length > 0
                ? transactions.filter((transaction: TransactionListResponse) =>
                    filterTransactionTypes.map((t) => t.id).includes(transaction.transactionType)
                  )
                : transactions
            }
          />
        )}
      </Grid>
    </Grid>
  );
};

export default TransactionsComponent;
