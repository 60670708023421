import { Checkbox, CircularProgress, TextField, makeStyles } from '@material-ui/core';
import Lookup, { getItemSelectedView, getItemValue } from '../../../models/lookup';
import React, { Fragment, useEffect, useState } from 'react';

import { Autocomplete } from '@material-ui/lab';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { ItemListResponse } from '../../../services/api-v3';
import { amsV3Service } from '../../../services/services';
import { useDebounce } from 'use-debounce/lib';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  checkbox: {
    marginRight: 8
  },
  transparent: {
    color: '#00000000'
  }
}));

interface AMSAsyncAutocompleteMultipleProps {
  label?: string;
  value: any;
  showSelectAll?: boolean;
  onChange: (option: any) => void;
  minChar?: number;
  required?: boolean;
  disabled?: boolean;
  categoryIds?: number[];
}

const AMSAsyncAutocompleteMultiple = ({
  label = '',
  value,
  onChange,
  showSelectAll = false,
  disabled = false,
  required = false,
  minChar = 3,
  categoryIds = []
}: AMSAsyncAutocompleteMultipleProps) => {
  const classes = useStyles();
  const [text, setText] = useState('');
  const [query] = useDebounce(text, 250);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const reloadItems = async (q: string, categoryIds: number[]) => {
      setLoading(true);
      setText(q);
      const itemResponse = await amsV3Service.getItems([], q, categoryIds);
      setOptions(
        itemResponse.data.data.map((item: ItemListResponse) => ({
          id: item.id,
          value: getItemValue(item),
          selectedView: getItemSelectedView(item)
        }))
      );
      setLoading(false);
    };
    if ((query && query.length >= minChar) || minChar === 0) {
      reloadItems(query, categoryIds);
    }
  }, [query, categoryIds, minChar]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => (option.selectedView ? option.selectedView : option.value)}
      options={showSelectAll ? [{ id: -1, value: 'Всички' }, ...options] : options}
      filterOptions={(opts: any, state: any) => {
        setText(state.inputValue);
        return opts;
      }}
      loading={loading}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          margin="dense"
          variant="outlined"
          placeholder={!!minChar ? `Минимум ${minChar} символа` : ''}
          fullWidth
          required={required}
          disabled={disabled}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
      value={value}
      renderOption={(option: Lookup, { selected }: any) => (
        <Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            className={option.id > -1 ? classes.checkbox : classes.transparent}
            checked={selected}
            color="primary"
          />
          {option.value}
        </Fragment>
      )}
      onChange={async (_, selectedOptions: any[] | null) => {
        if (selectedOptions !== null && selectedOptions.findIndex((o) => o.id === -1) > -1) {
          onChange(options);
        } else {
          onChange(selectedOptions);
        }
      }}
      multiple={true}
      limitTags={3}
      loadingText="Зареждане"
      noOptionsText="Няма намерени резултати"
    />
  );
};

export default AMSAsyncAutocompleteMultiple;
