import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import AMSConfirmDialog from '../../../helpers/ui/AMSConfirmDialog/AMSConfirmDialog';
import { OrderItemV2Response, OrderV2Response, UpdateOrderV2Request } from '../../../services/api';
import { ordersService } from '../../../services/services';
import EditOrderItemPricesRow from './Widget/EditOrderItemPricesRow';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold'
  },
  typeWrapper: {
    paddingTop: 8
  }
}));

export interface EditOrderItemPricesDialogProps {
  orderId: number;
  items: OrderItemV2Response[];
  open: boolean;
  onSave: (order: OrderV2Response) => void;
  onClose: () => void;
}

const EditOrderitemPricesDialog = ({ orderId, items, open, onSave, onClose }: EditOrderItemPricesDialogProps) => {
  const classes = useStyles();

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [itemsMap, setItemsMap] = useState(items.reduce((res, item) => {
    res[`${item.id}`] = item;
    return res;
  }, {} as any));

  const round = (num: number) => Math.round((num + Number.EPSILON) * 100) / 100;

  const newSum = useMemo(() => {
    return Object.keys(itemsMap).reduce((res, id)=> res + +((itemsMap[id].liveTotalPrice || isNaN(itemsMap[id].liveTotalPrice)) ?? 0), 0).toFixed(2);
  },[itemsMap])

  const isFormValid = useMemo(() => Object.keys(itemsMap).reduce((res, id)=> {
      return res && +itemsMap[id].livePrice > 0;
    }, true),
  [itemsMap]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="xl"
      fullWidth={true}
    >
      <DialogTitle>
        <div className={classes.title}>Корекция на цена</div>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="body2">Име</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">Заявено количество</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">Единична цена</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">Обща цена</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">Нова единична цена</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">Нова обща цена</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(itemsMap).map((id) => (
              <EditOrderItemPricesRow
                key={id}
                item={itemsMap[id]}
                setNewPrice={(newPrice) => {
                  setItemsMap({
                    ...itemsMap,
                    [id]: {
                        ...itemsMap[id],
                        livePrice: newPrice,
                        liveTotalPrice: round(+newPrice * (!!itemsMap[id].collected ? itemsMap[id].collected : itemsMap[id].quantity))
                    }
                  });
                }}
                setNewTotalPrice={(newTotalPrice) => {
                  setItemsMap({
                    ...itemsMap,
                    [id]: {
                        ...itemsMap[id],
                        livePrice: round(+newTotalPrice/(!!itemsMap[id].collected ? itemsMap[id].collected : itemsMap[id].quantity)),
                        liveTotalPrice: newTotalPrice
                    }
                  });
                }} />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow key="footer">
              <TableCell align="right" colSpan={6} style={{ borderBottom: '0px black solid' }}>
                <Typography variant="h6">
                  Нова сума: {newSum}
                </Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="contained">
          Отказ
        </Button>
        <Button onClick={() => setOpenConfirmDialog(true)} color="primary" variant="contained" disabled={!isFormValid}>
          Запиши
        </Button>
        <AMSConfirmDialog
          open={openConfirmDialog}
          onConfirm={async () => {
            const updateOrderRequest: UpdateOrderV2Request = {
              items: Object.keys(itemsMap).map((orderItemId: any) => ({
                id: orderItemId,
                itemId: itemsMap[orderItemId].itemId,
                quantity: itemsMap[orderItemId].quantity,
                priceOverride: itemsMap[orderItemId].livePrice
              }))
            };
            await ordersService.updateOrder(orderId, updateOrderRequest);
            const orderResp = await ordersService.getOrder(orderId, true);
            setOpenConfirmDialog(false);
            setItemsMap(orderResp.data.items?.reduce((res, item) => {
              res[`${item.id}`] = item;
              return res;
            }, {} as any))
            onSave(orderResp.data);
          }}
          onClose={() => setOpenConfirmDialog(false)}
          title={'Записване на промени!'}
          message={'Промените ще бъдат записани. Искате да продължите?'}
        />
      </DialogActions>
    </Dialog>
  );
};

export default EditOrderitemPricesDialog;
