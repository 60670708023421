import React, { useMemo, useState } from 'react';

import AMSTable from '../../../helpers/ui/AMSTable/AMSTable';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import bgLocale from 'date-fns/locale/bg';
import { deliveriesService } from '../../../services/services';
import { findCountry } from '../../../helpers/country-helper';
import { getExtendedWarehouseId } from '../../../helpers/utils';

interface StockContainerProps {
  id: number;
}

const StockContainer = (props: StockContainerProps) => {
  const { id } = props;

  const [stockRecords, setStockRecords] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      if (id) {
        const resp = await deliveriesService.getDeliveryItems(id, undefined, true);
        setStockRecords(
          resp
            ? resp.data.map((s: any) => ({
                ...s,
                expirationDate: new Date(s.expirationDate)
              }))
            : []
        );
      } else {
        setStockRecords([]);
      }
      setLoading(false);
    };
    loadData();
  }, [id]);

  const columns = useMemo(
    () => [
      {
        title: 'Склад',
        field: 'warehouseId',
        render: (row: any) => {
          return `${getExtendedWarehouseId(row.warehouseId)} ${row.warehouseName}`;
        },
        cellStyle: { width: '20%' }
      },
      {
        title: 'Държава',
        field: 'country',
        render: (row: any) => {
          return `${findCountry(row.country)?.name}`;
        },
        cellStyle: { width: '20%' }
      },
      {
        title: 'Срок на годност',
        field: 'expirationDate',
        type: 'date',
        cellStyle: { width: '15%' }
      },
      {
        title: 'Партида',
        field: 'batchNumber',
        cellStyle: { width: '15%' }
      },
      {
        title: 'Брой',
        field: 'quantity',
        cellStyle: { width: '15%' },
        render: (row: any) => {
          return `${row.availableQuantity} от ${row.quantity}`;
        }
      },
      {
        title: 'Цена',
        field: 'price',
        cellStyle: { width: '15%' },
        type: 'currency',
        currencySetting: {
          locale: 'bg',
          currencyCode: 'bgn',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
      }
    ],
    []
  );

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={bgLocale}>
        <AMSTable
          title={'Наличности'}
          columns={columns}
          data={stockRecords}
          inlineActions={true}
          pageSize={8}
          isLoading={loading}
        />
      </MuiPickersUtilsProvider>
    </>
  );
};

export default StockContainer;
