import {
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { CompanyListResponse, SupplierListResponse } from '../../../services/api-v3';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Lookup, { companyToLookup, supplierToLookup } from '../../../models/lookup';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ignoreOffset,
  isValidDate,
  toDateString,
  toEndOfDay,
  toStartOfDay
} from '../../../helpers/date-helper';
import {
  useCompanies,
  useDeliveryDocuments,
  useFlag,
  useStyles,
  useSuppliers
} from '../../../helpers/hooks';

import AMSAutocomplete from '../../../helpers/ui/AMSAutocomplete/AMSAutocomplete';
import { AMSButton } from '../../../helpers/ui';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DateFnsUtils from '@date-io/date-fns';
import DeliveryDocumentsTableComponent from './DeliveryDocumentsTableComponent';
import { cookiesService } from '../../../services/services';
import { parseFilter } from '../../../helpers/url';
import { useHistory } from 'react-router';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ReportDeliveryDocumentsComponent = ({ location }: any) => {
  const classes = useStyles();
  const history = useHistory();
  const { fromDate, toDate, supplierIds, companyIds, hidePaid } = useMemo(
    () => parseFilter(location.search),
    [location.search]
  );

  const filter = useMemo(() => cookiesService.getReportDeliveryDocumentFilter(), []);
  const [filterSuppliers, setFilterSuppliers] = useState<Lookup[]>([]);
  const [filterCompanies, setFilterCompanies] = useState<Lookup[]>([]);
  const [filterFromDate, setFilterFromDate] = useState<Date | null>(
    fromDate
      ? fromDate
      : filter.filterFromDate
      ? ignoreOffset(new Date(filter.filterFromDate))
      : new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  const [filterToDate, setFilterToDate] = useState<Date | null>(
    fromDate
      ? toDate
      : filter.filterToDate
      ? ignoreOffset(new Date(filter.filterToDate))
      : new Date()
  );
  const [filterHidePaid, setFilterHidePaid] = useFlag(true);

  const { deliveryDocuments, loading, setParameters } = useDeliveryDocuments(
    filterFromDate ? toStartOfDay(filterFromDate) : undefined,
    filterToDate ? toEndOfDay(filterToDate) : undefined,
    supplierIds ?? filterSuppliers.map((s: Lookup) => s.id),
    companyIds ?? filterCompanies.map((c: Lookup) => c.id),
    hidePaid ?? filterHidePaid
  );

  const { suppliers } = useSuppliers();
  const { companies } = useCompanies();

  useEffect(() => {
    const { filterSuppliers } = cookiesService.getReportDeliveryDocumentFilter();

    setFilterSuppliers(
      supplierIds && suppliers
        ? suppliers
            .filter((s: SupplierListResponse) => supplierIds.includes(s.id))
            .map(supplierToLookup)
        : filterSuppliers ?? []
    );
  }, [suppliers, supplierIds]);

  useEffect(() => {
    const { filterCompanies } = cookiesService.getReportDeliveryDocumentFilter();

    setFilterCompanies(
      companyIds && companies
        ? companies
            .filter((c: CompanyListResponse) => companyIds.includes(c.id))
            .map(companyToLookup)
        : filterCompanies ?? []
    );
  }, [companies, companyIds]);

  const onSearch = useCallback(() => {
    const fromDate = isValidDate(filterFromDate) ? toStartOfDay(filterFromDate!) : undefined;
    const toDate = isValidDate(filterToDate) ? toEndOfDay(filterToDate!) : undefined;
    cookiesService.setReportDeliveryDocumentFilter({
      filterFromDate: fromDate,
      filterToDate: toDate,
      filterSuppliers,
      filterCompanies,
      filterHidePaid
    });
    let params: any = {};
    if (filterFromDate) {
      params.fromDate = toDateString(filterFromDate);
    }
    if (filterToDate) {
      params.toDate = toDateString(filterToDate);
    }
    if (filterSuppliers?.length > 0) {
      params.supplierIds = filterSuppliers.map((s) => s.id).join(',');
    }
    if (filterCompanies?.length > 0) {
      params.companyIds = filterCompanies.map((c) => c.id).join(',');
    }
    if (filterHidePaid) {
      params.hidePaid = filterHidePaid;
    }
    history.push({
      pathname: '/reports-delivery-invoice',
      search: new URLSearchParams(params).toString()
    });
    setParameters([
      fromDate,
      toDate,
      filterSuppliers?.map((s: Lookup) => s.id),
      filterCompanies?.map((c: Lookup) => c.id),
      filterHidePaid
    ]);
  }, [
    filterFromDate,
    filterToDate,
    filterHidePaid,
    filterSuppliers,
    filterCompanies,
    history,
    setParameters
  ]);

  const totalIncludingVat = useMemo(
    () =>
      deliveryDocuments.reduce(
        (res, invoice) =>
          res + +invoice.totalIncludingVat * (invoice.deliveryDocumentType === 2 ? -1 : 1),
        0
      ),
    [deliveryDocuments]
  );

  const totalRemainingAmount = useMemo(
    () =>
      deliveryDocuments.reduce(
        (res, invoice) =>
          res + +invoice.remainingAmount * (invoice.deliveryDocumentType === 2 ? -1 : 1),
        0
      ),
    [deliveryDocuments]
  );
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="text-align-left">
          <Container maxWidth="xl">
            <Grid container spacing={1}>
              <Grid item lg={2} md={6} sm={12} xs={12}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk={true}
                  variant="inline"
                  format="dd/MM/yy"
                  margin="dense"
                  label="От"
                  helperText={''}
                  value={filterFromDate ? filterFromDate : null}
                  onChange={(value: Date | null) => {
                    if (value) {
                      if (isValidDate(value)) {
                        setFilterFromDate(ignoreOffset(value));
                      }
                    } else {
                      setFilterFromDate(null);
                    }
                  }}
                  inputVariant="outlined"
                  fullWidth
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                />
              </Grid>
              <Grid item lg={2} md={6} sm={12} xs={12}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk={true}
                  variant="inline"
                  format="dd/MM/yy"
                  margin="dense"
                  label="До"
                  helperText={''}
                  value={filterToDate ? filterToDate : null}
                  onChange={(value: Date | null) => {
                    if (value) {
                      if (isValidDate(value)) {
                        setFilterToDate(ignoreOffset(value));
                      }
                    } else {
                      setFilterToDate(null);
                    }
                  }}
                  inputVariant="outlined"
                  fullWidth
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                />
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <AMSAutocomplete
                  multiple
                  minChar={0}
                  limitTags={3}
                  options={suppliers.map(supplierToLookup)}
                  disableCloseOnSelect
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes.checkbox}
                        checked={selected}
                        color="primary"
                      />
                      {option.value}
                    </Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Издател"
                      placeholder=""
                      margin="dense"
                      fullWidth
                    />
                  )}
                  value={filterSuppliers}
                  onChange={setFilterSuppliers}
                />
              </Grid>
              <Grid item lg={2} md={12} sm={12} xs={12}>
                <FormControlLabel
                  style={{ paddingTop: 6, marginLeft: 12 }}
                  control={
                    <Checkbox
                      checked={filterHidePaid}
                      onChange={() => {
                        setFilterHidePaid(!filterHidePaid);
                      }}
                    />
                  }
                  label="Скриване на платени"
                  labelPlacement="end"
                />
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <AMSAutocomplete
                  multiple
                  minChar={0}
                  limitTags={3}
                  options={companies.map(companyToLookup)}
                  disableCloseOnSelect
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes.checkbox}
                        checked={selected}
                        color="primary"
                      />
                      {option.value}
                    </Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Компания"
                      placeholder=""
                      margin="dense"
                      fullWidth
                    />
                  )}
                  value={filterCompanies}
                  onChange={setFilterCompanies}
                  groupBy={(g) => (g.group ? g.group : '')}
                />
              </Grid>
              {!loading && (
                <Grid lg={2} md={2} container alignContent="flex-end">
                  <Typography className={classes.totalInfo} variant="h6">
                    Общо: {totalIncludingVat.toFixed(2)} лв.
                  </Typography>
                </Grid>
              )}
              {!loading && (
                <Grid lg={2} md={2} container alignContent="flex-end">
                  <Typography className={classes.totalInfo} variant="h6">
                    За плащане: {totalRemainingAmount.toFixed(2)} лв.
                  </Typography>
                </Grid>
              )}
              <Grid item lg={2} md={12} sm={12} xs={12}>
                <AMSButton
                  color="primary"
                  variant="contained"
                  text="Търсене"
                  loading={loading}
                  disabled={false}
                  onClick={onSearch}
                  style={{
                    marginTop: 8
                  }}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <DeliveryDocumentsTableComponent deliveryDocuments={deliveryDocuments} />
                )}
              </Grid>
            </Grid>
          </Container>
        </div>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default ReportDeliveryDocumentsComponent;
