import { CircularProgress, Grid, Tab, Tabs, createStyles, makeStyles } from '@material-ui/core';
import { DocumentTypes, OrderStatusKey, OrderStatusKeys } from '../../services/ordersService';
import React, { useCallback, useEffect, useState } from 'react';
import {
  accessManagementService,
  amsV3Service,
  cookiesService,
  ordersService
} from '../../services/services';
import { useObservable, usePermissions } from '../../helpers/hooks';

import AMSButton from '../../helpers/ui/AMSButton/AMSButton';
import { AccessPermissions } from '../../services/accessManagementService';
import { OrderDocumentV2Response } from '../../services/api';
import OrderInfoPanel from './Panels/OrderInfoPanel/OrderInfoPanel';
import OrderInvoiceAndTransactionsPanel from './Panels/OrderInvoiceAndTransactionsPanel/OrderInvoiceAndTransactionsPanel';
import OrderNoteDialog from './OrderNoteDialog';
import OrderProcessPanel from './Panels/OrderProcessPanel/OrderProcessPanel';
import TabPanel from '../../helpers/ui/TabPanel/TabPanel';
import { UserAccessResponse } from '../../services/api-v3';
import { getExtendedWarehouseId } from '../../helpers/utils';
import { isB2B } from '../../helpers/openapi-axios-helper';
import { parseQuery } from '../../helpers/url';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    saveButton: {
      marginTop: 10,
      marginLeft: 25,
      marginRight: 25
    }
  })
);

const OrderComponent = ({ location }: any) => {
  const { id, tabId } = parseQuery(location.search);

  const history = useHistory();
  const classes = useStyles();

  const [order, setOrder] = useState<any | null>(null);
  const [invoiceNumber, setInvoiceNumber] = useState<string>();
  const [orderDocuments, setOrderDocuments] = useState<OrderDocumentV2Response[]>([]);
  const [originalStatusKey, setOriginalStatusKey] = useState<OrderStatusKey>(
    OrderStatusKeys.PRELIMINARY
  );
  const [allowedSubsequentStatuses, setAllowedSubsequentStatuses] = useState<OrderStatusKey[]>([]);
  const [loading, setLoading] = useState(false);

  const [openNoteDialog, setOpenNoteDialog] = useState<boolean>(false);

  const [value, setValue] = React.useState(0);

  const [sender, setSender] = useState<any>(null);
  const [receiver, setReceiver] = useState<any>(null);
  const currentUser: UserAccessResponse | undefined = useObservable(
    accessManagementService.user
  ).user;

  const [orderIds, setOrderIds] = useState<number[]>([]);
  const [index, setIndex] = useState(-1);

  const [canChangeOrderItemPrices] = usePermissions([
    AccessPermissions.CAN_CHANGE_ORDER_ITEM_PRICES
  ]);

  useEffect(() => {
    if (tabId) {
      if (id) {
        setValue(+tabId);
      } else {
        setValue(0);
        history.push(`order?${id ? `id=${id}` : ''}&tabId=0`);
      }
    } else {
      setValue(0);
    }
  }, [tabId, id, history]);

  const loadOrder = useCallback(async () => {
    setLoading(true);
    if (id) {
      const orderResp = await ordersService.getOrder(id, true);
      if (orderResp.data) {
        setOrder(orderResp.data);
        setInvoiceNumber(orderResp.data.invoiceNumber);
        setSender({
          id: orderResp.data.warehouseId,
          value: `${getExtendedWarehouseId(orderResp.data.warehouseId)} ${
            orderResp.data.warehouseName
          }`
        });
        setOriginalStatusKey(orderResp.data.statusKey as OrderStatusKey);
        setAllowedSubsequentStatuses([
          orderResp.data.statusKey as OrderStatusKey,
          ...(orderResp.data.allowedSubsequentStatuses as OrderStatusKey[])
        ]);
        if (orderResp.data.isInternal) {
          const warehouseResp = await amsV3Service.getWarehouse(orderResp.data.receiverId);
          if (warehouseResp.data) {
            setReceiver({
              ...warehouseResp.data
              // invoiceInfo: {
              //   visualization: warehouseResp.data.legalEntity?.visualization,
              //   fullAddress: warehouseResp.data.legalEntity?.fullAddress
              // }
            });
          }
        } else {
          if (isB2B) {
            const partnerResp = await amsV3Service.getPartner(orderResp.data.receiverId);
            const legalEntityResp = await amsV3Service.getLegalEntity(
              partnerResp.data.legalEntityId
            );
            if (partnerResp.data) {
              setReceiver({
                ...partnerResp.data,
                invoiceInfo: {
                  visualization: legalEntityResp.data?.visualization,
                  fullAddress: legalEntityResp.data?.fullAddress
                }
              });
            }
          } else {
            const clientsResp = await amsV3Service.getClient(orderResp.data.receiverId);
            if (clientsResp.data) {
              setReceiver({
                ...clientsResp.data,
                name: `${clientsResp.data.firstName} ${clientsResp.data.lastName}`
              });
            }
          }
        }
        if (orderResp.data.note && orderResp.data.note.length > 0) {
          setOpenNoteDialog(true);
        }
      }
    } else {
      setOrder({
        id: -1,
        orderTimestamp: '',
        statusKey: OrderStatusKeys.PRELIMINARY,
        items: [],
        isInternal: false,
        receiverInfo: { id: -1, name: '<име>', deliveryAddress: '<адрес за доставка>' },
        warehouse: { id: 1 },
        totalPrice: 0,
        totalWeight: 0,
        userResponsibleId: currentUser?.id,
        userResponsible: currentUser,
        createdById: currentUser?.id,
        createdBy: currentUser,
        allowedSubsequentStatuses: []
      });
      setAllowedSubsequentStatuses([OrderStatusKeys.PRELIMINARY]);
      setInvoiceNumber(undefined);
      setOrderDocuments([]);
      setSender(null);
      setReceiver(null);
      setLoading(false);
    }
    setLoading(false);
  }, [id, currentUser]);

  useEffect(() => {
    loadOrder();
  }, [id, loadOrder]);

  useEffect(() => {
    if (id) {
      const loadDocuments = async () => {
        const orderDocsResp = await ordersService.getOrderDocuments(id);
        setOrderDocuments(orderDocsResp.data);
      };
      loadDocuments();
    }
  }, [id, invoiceNumber]);

  useEffect(() => {
    setOrderIds(cookiesService.getOrderIds());
  }, []);

  useEffect(() => {
    setIndex(orderIds.indexOf(+id));
  }, [orderIds, id]);

  const a11yProps = (index: any) => {
    return {
      id: `item-tab-${index}`,
      'aria-controls': `item-tabpanel-${index}`
    };
  };

  const reload = () => {
    setOrder({
      id: -1,
      orderTimestamp: '',
      statusKey: OrderStatusKeys.PRELIMINARY,
      items: [],
      isInternal: false,
      receiverInfo: { id: -1, name: '<име>', deliveryAddress: '<адрес за доставка>' },
      warehouse: { id: 1 },
      totalPrice: 0,
      totalWeight: 0,
      userResponsibleId: currentUser?.id,
      createdById: currentUser?.id,
      createdBy: currentUser,
      allowedSubsequentStatuses: []
    });
    setAllowedSubsequentStatuses([OrderStatusKeys.PRELIMINARY]);
    setInvoiceNumber(undefined);
    setOrderDocuments([]);
    setSender(null);
    setReceiver(null);
    setLoading(false);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    history.replace(`order?${id ? `id=${id}` : ''}&tabId=${newValue}`);
  };

  return loading || !order ? (
    <CircularProgress color="inherit" size={20} />
  ) : (
    <>
      <Grid container>
        <Grid item lg={12}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Информация" {...a11yProps(0)} />
            {isB2B && <Tab disabled={!id} label="Документи и плащания" {...a11yProps(1)} />}
            {canChangeOrderItemPrices && <Tab disabled={!id} label="Процес" {...a11yProps(2)} />}
          </Tabs>
        </Grid>
        <Grid item lg={12}>
          <TabPanel value={value} index={0}>
            <OrderInfoPanel
              order={order}
              setOrder={setOrder}
              sender={sender}
              setSender={setSender}
              receiver={receiver}
              setReceiver={setReceiver}
              setInvoiceNumber={setInvoiceNumber}
              originalStatusKey={originalStatusKey}
              setOriginalStatusKey={setOriginalStatusKey}
              allowedSubsequentStatuses={allowedSubsequentStatuses}
              setAllowedSubsequentStatuses={setAllowedSubsequentStatuses}
              reload={reload}
            />
          </TabPanel>
          {isB2B && (
            <TabPanel value={value} index={1}>
              <OrderInvoiceAndTransactionsPanel
                order={order}
                setOrder={setOrder}
                receiver={receiver}
                orderDocuments={orderDocuments}
                isInvoiceDeletable={
                  orderDocuments != null &&
                  orderDocuments.findIndex(
                    (document: OrderDocumentV2Response) =>
                      document.type === DocumentTypes.CREDIT_NOTE
                  ) === -1
                }
                onDeleteInvoice={() => {
                  setOrderDocuments([]);
                  setInvoiceNumber(undefined);
                }}
              />
            </TabPanel>
          )}
          {canChangeOrderItemPrices && (
            <TabPanel value={value} index={isB2B ? 2 : 1}>
              <OrderProcessPanel id={order.id} />
            </TabPanel>
          )}
        </Grid>
        <Grid item lg={12}>
          <AMSButton
            text={`Предишна`}
            color="primary"
            variant="contained"
            onClick={() => {
              history.push(`/order?id=${orderIds[index - 1]}`);
            }}
            disabled={index <= 0}
            className={classes.saveButton}
            loading={false}
          />
          <AMSButton
            text={`Следваща`}
            color="primary"
            variant="contained"
            onClick={() => {
              history.push(`/order?id=${orderIds[index + 1]}`);
            }}
            disabled={index === orderIds.length - 1}
            className={classes.saveButton}
            loading={false}
          />
        </Grid>
      </Grid>
      <OrderNoteDialog
        open={openNoteDialog}
        viewMode={true}
        onSave={async () => {
          setOpenNoteDialog(false);
        }}
        onClose={() => setOpenNoteDialog(false)}
        note={order && order.note ? order.note : ''}
        setNote={(note: string) => setOrder({ ...order, items: order.items, note })}
        order={order}
      />
    </>
  );
};

export default OrderComponent;
