import React, { CSSProperties, Dispatch, SetStateAction, useMemo } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';

import { AMSTextField } from '../../../../helpers/ui';
import { StockReceivingProcessItemResponse } from '../../../../services/api';
import { findCountry } from '../../../../helpers/country-helper';
import { toDDMMYY } from '../../../../helpers/date-helper';

interface FinalizeStockReceivingProcessTableComponentProps {
  items: StockReceivingProcessItemResponse[];
  setItems: Dispatch<SetStateAction<StockReceivingProcessItemResponse[]>>;
}

const FinalizeStockReceivingProcessTableComponent = ({
  items,
  setItems
}: FinalizeStockReceivingProcessTableComponentProps) => {
  const cellStyle: CSSProperties = useMemo(
    () => ({
      width: '10%',
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 15,
      paddingRight: 15
    }),
    []
  );
  const wideCellStyle = useMemo(() => ({ ...cellStyle, width: '20%' }), [cellStyle]);
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={wideCellStyle}>Артикул</TableCell>
          <TableCell style={cellStyle} align="right">
            Партида
          </TableCell>
          <TableCell style={cellStyle} align="right">
            Срок на годност
          </TableCell>
          <TableCell style={cellStyle} align="right">
            Държава
          </TableCell>
          <TableCell style={cellStyle} align="right">
            Потвърдено количество
          </TableCell>
          <TableCell style={cellStyle} align="right">
            Финално количество
          </TableCell>
          <TableCell style={cellStyle} align="right">
            Цена
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((item: StockReceivingProcessItemResponse) => (
          <TableRow key={item.id}>
            <TableCell style={wideCellStyle}>
              <Typography variant="body2">
                {item.itemArtNo}, {item.itemName}
              </Typography>
            </TableCell>
            <TableCell style={cellStyle} align="right">
              <Typography variant="body2">
                {item.itemShouldTrackBatches ? item.batchNumber : '-'}
              </Typography>
            </TableCell>
            <TableCell style={cellStyle}>
              <Typography variant="body2" align="right">
                {item.itemShouldTrackBatches ? toDDMMYY(new Date(item.expirationDate)) : '-'}
              </Typography>
            </TableCell>
            <TableCell style={wideCellStyle}>
              <Typography variant="body2" align="right">
                {item.itemShouldTrackBatches ? findCountry(item.countryOfOrigin)?.name : '-'}
              </Typography>
            </TableCell>
            <TableCell style={cellStyle}>
              <Typography variant="body2" align="right">
                {item.verifiedQuantity}
              </Typography>
            </TableCell>
            <TableCell style={cellStyle}>
              <AMSTextField
                label=""
                defaultValue={item.finalQuantity}
                onChange={(text?: string) => {
                  setItems(
                    items.reduce((res: any[], i: StockReceivingProcessItemResponse) => {
                      if (item.id === i.id) {
                        i.finalQuantity = text;
                      }
                      res.push(i);
                      return res;
                    }, [])
                  );
                }}
                placeholder={`по подр. ${item.verifiedQuantity}`}
              />
            </TableCell>
            <TableCell style={cellStyle}>
              <AMSTextField
                label=""
                defaultValue={item.price}
                onChange={(text?: string) => {
                  setItems(
                    items.reduce((res: any[], i: StockReceivingProcessItemResponse) => {
                      if (item.id === i.id) {
                        i.price = text;
                      }
                      res.push(i);
                      return res;
                    }, [])
                  );
                }}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default FinalizeStockReceivingProcessTableComponent;
