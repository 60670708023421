import { StockTakingTaskResponse } from '../../services/api';
import { stocktakingsService } from '../../services/services';
import { useAsync } from '.';

export interface IStockTakingTasks<T> {
  tasks: T;
  loading: boolean;
}

const useStocktakingTasks = (id?: number): IStockTakingTasks<StockTakingTaskResponse[]> => {
  const { data, loading } = useAsync<StockTakingTaskResponse[]>(
    stocktakingsService.getStocktakingTasks,
    [],
    id ? [id] : null
  );
  return {
    tasks: data,
    loading
  };
};

export default useStocktakingTasks;
